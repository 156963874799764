/*========== EXCHANGE ACTIONS ============= */

export const getExchangeGraph = (data) => ({
  type: "GET_EXCHANGE_GRAPH",
  payload: data,
});

export const setExchangeGraph = (data) => ({
  type: "SET_EXCHANGE_GRAPH",
  payload: data,
});

export const updateExchangeGraph = (data) => ({
  type: "UPDATE_EXCHANGE_GRAPH",
  payload: data,
});

export const getExchangePairsDetails = (data) => ({
  type: "GET_EXCHANGE_PAIRS_DETAILS",
  payload: data,
});

export const setExchangePairsDetails = (data) => ({
  type: "SET_EXCHANGE_PAIRS_DETAILS",
  payload: data,
});

export const getBalancesPairs = (data) => ({
  type: "GET_PAIRS_BALANCE",
  payload: data,
});

export const setPairsBalance = (data) => ({
  type: "SET_PAIRS_BALANCE",
  payload: data,
});

export const setAllUsersOrder = (data) => ({
  type: "SET_ALL_USER_ORDERS",
  payload: data,
})

export const getPairsData = (data) => ({
  type: "GET_PAIRS_DATA",
  payload: data,
})

export const setPairsData = (data) => ({
  type: "SET_PAIRS_DATA",
  payload: data,
})