import axios from 'axios'
import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import "react-responsive-modal/styles.css";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import uploadimg from "../../images/plusicons.svg";
import React, { useState, useEffect } from "react";
import alternate from "../../images/alternate.jpg";
import { toFixed } from "../../store/numberFormat";
import modalcloseicon from "../../images/close.png";
import approvedkyc from "../../images/approvedkyc.png";
import approvedkyb from "../../images/approvedkyb.png";
import stakedGov from "../../images/gov-stake-badge.png";
import stakedVip from "../../images/vip-stake-badge.png";
import mintstepinfo from "../../images/mint-token-created.png";
import walletaddresscopyicon from "../../images/wallet-address-copy-icon.png";
import { networkId, ApiUrl, XRC20ABI, XRC721ABI, CIFI } from "../../store/config";
import { web3 } from "../../store/web3";
import nftcollectiondetailsstepinfo from "../../images/nft-collection-details-step-info.png";
import { setLoader, getXRC20, getXRC721, getStaking, getUriData, setBalance, setGrootBalance, updateUserImage } from "../../store/actions/Auth.js";
import { Link, useNavigate } from 'react-router-dom';


function Profile(props) {

  let { kycValue } = props;

  let [from, setFrom] = useState("");
  let [data, setData] = useState({});
  let [txHash, setTxHash] = useState("");
  let [bToken, setBToken] = useState("");
  let [tokens, setTokens] = useState([]);
  let [bSymbol, setBSymbol] = useState("");
  let [stakings, setStakings] = useState([]);
  let [stakedNFTs, setStakedNFTs] = useState([]);
  let [mintAmount, setMintAmount] = useState("");
  let [baseURINFT, setBaseURINFT] = useState("");
  let [collections, setCollections] = useState([]);
  let [ownedTokens, setOwnedTokens] = useState([]);
  let [stakedNFTNew, setStakedNFTNew] = useState([]);
  let [baseURIToken, setBaseURIToken] = useState("");
  let [walletAddress, setWalletAddress] = useState("");
  let [selectedImage, setSelectedImage] = useState(kycValue?.image ? kycValue?.image : alternate);
  let [ownedCollections, setOwnedCollections] = useState([]);
  let [apiKeyPolygon, setapiKeyPolygon] = useState("DKVNFIMZYM3GJCZB2751SVU4CURE9FAW3V");
  let [apiKeyBinance, setapiKeyBinance] = useState("5P3XPCS5T4E61VFZVRQEES7RXG6J4Z4J58");

  const [microeconomyMintModal, setMicroeconomyMintModal] = useState(false);
  const [microeconomyTransferModal, setMicroeconomyTransferModal] = useState(false);
  const [stepModal1, setStepModal1] = useState(true);
  const [stepModal2, setStepModal2] = useState(false);
  const [stepModal3, setStepModal3] = useState(false);
  const [stepModal4, setStepModal4] = useState(false);
  const [stepModal5, setStepModal5] = useState(false);

  const navigate = useNavigate()

  const [model, setModel] = useState(true);

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  setTimeout(() => {
    const modalRoot = document.querySelector('.react-responsive-modal-root');

    if (modalRoot) {
      //Add a custom class to the root element
      const stepsModal = modalRoot.querySelector('.steps-modal');

      if (stepsModal) {
        // Perform your action here
        modalRoot.classList.add('custom-modal-root');
      }

    } else {
      console.error("Element not found.");
    }
  }, 1000);

  useEffect(() => {
    if (props.publicAddress == null || props.publicAddress == undefined) {
      return;
    } else {
      if (web3 && props.publicAddress) {
        props.setLoader({
          message: "Loading Data...",
          status: true,
        });
      }
    }
  }, [props.publicAddress, web3])

  useEffect(() => {
    if (props.publicAddress && web3) {
      if (networkId == 80001) {
        setBToken("0x4fA1f87E3831335f00Fa4ec41ecb8f50F5acEa6b");
        setBSymbol("MATIC");
        setBaseURIToken(`https://api-testnet.polygonscan.com/api?module=account&action=tokentx&address=${props.publicAddress}&page=1&offset=100&startblock=0&endblock=99999999&sort=asc&apikey=${apiKeyPolygon}`);
        setBaseURINFT(`https://api-testnet.polygonscan.com/api?module=account&action=tokennfttx&address=${props.publicAddress}&page=1&offset=100&startblock=0&endblock=99999999&sort=asc&apikey=${apiKeyPolygon}`);
      } else if (networkId == 137) {
        setBToken("0x4fA1f87E3831335f00Fa4ec41ecb8f50F5acEa6b");
        setBSymbol("MATIC");
        setBaseURIToken(`https://api.polygonscan.com/api?module=account&action=tokentx&address=${props.publicAddress}&page=1&offset=100&startblock=0&endblock=99999999&sort=asc&apikey=${apiKeyPolygon}`);
        setBaseURINFT(`https://api.polygonscan.com/api?module=account&action=tokennfttx&address=${props.publicAddress}&page=1&offset=100&startblock=0&endblock=99999999&sort=asc&apikey=${apiKeyPolygon}`);
      } else if (networkId == 97) {
        setBToken("0x153F54dEf755C589c6607adEB889D9B4340c6198");
        setBSymbol("BNB");
        setBaseURIToken(`https://api-testnet.bscscan.com/api?module=account&action=tokentx&address=${props.publicAddress}&page=1&offset=100&startblock=0&endblock=99999999&sort=asc&apikey=${apiKeyBinance}`);
        setBaseURINFT(`https://api-testnet.bscscan.com/api?module=account&action=tokennfttx&address=${props.publicAddress}&page=1&offset=100&startblock=0&endblock=99999999&sort=asc&apikey=${apiKeyBinance}`);
      } else if (networkId == 56) {
        setBToken("0x153F54dEf755C589c6607adEB889D9B4340c6198");
        setBSymbol("BNB");
        setBaseURIToken(`https://api.bscscan.com/api?module=account&action=tokentx&address=${props.publicAddress}&page=1&offset=100&startblock=0&endblock=99999999&sort=asc&apikey=${apiKeyBinance}`);
        setBaseURINFT(`https://api.bscscan.com/api?module=account&action=tokennfttx&address=${props.publicAddress}&page=1&offset=100&startblock=0&endblock=99999999&sort=asc&apikey=${apiKeyBinance}`);
      } else if (networkId == 51) {
        setBToken("0xd667E515833a5285FDDeF96e8C6E02370A74eadf");
        setBSymbol("XDC");
        setBaseURIToken(`https://apothem.blocksscan.io/api/tokens/holding/xrc20/${props.publicAddress}`);
        setBaseURINFT(`https://apothem.blocksscan.io/api/tokens/holding/xrc721/${props.publicAddress}`);
      } else if (networkId == 50) {
        setBToken("0xd667E515833a5285FDDeF96e8C6E02370A74eadf");
        setBSymbol("XDC");
        setBaseURIToken(`https://xdc.blocksscan.io/api/tokens/holding/xrc20/${props.publicAddress}`);
        setBaseURINFT(`https://xdc.blocksscan.io/api/tokens/holding/xrc721/${props.publicAddress}`);
      }
    }
  }, [networkId, props.publicAddress, web3]);

  useEffect(() => {
    if (props.publicAddress && web3) {
      if (baseURIToken) getOwnedTokens();
    }
  }, [baseURIToken, props.publicAddress, web3])

  useEffect(() => {
    if (props.publicAddress && web3) {
      if (baseURINFT) getOwnedNFTs();
    }
  }, [baseURINFT, props.publicAddress, web3])

  useEffect(() => {
    props.getXRC20();
    props.getXRC721();
    props.getStaking();
    if (props.publicAddress == null || props.publicAddress == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    } else {
      let address = props.publicAddress;
      let output = props.publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        address = "0x" + props.publicAddress.substring(3);
        setFrom(address);
      } else {
        address = props.publicAddress;
        setFrom(address);
      }
    }
  }, [props.publicAddress])

  useEffect(() => {
    if (props.publicAddress == null || props.publicAddress == undefined) {
      return;
    } else {
      if (props.xrc721Collections.length > 0 && web3) {
        setMarketplaceData();
      }
    }
  }, [props.xrc721Collections, props.selectDefaultNetwok, props.publicAddress, web3]);

  useEffect(() => {
    if (props.publicAddress == null || props.publicAddress == undefined) {
      return;
    } else {
      if (props.xrc20Collections.length > 0 && web3) {
        setTokensData();
      }
    }
  }, [props.xrc20Collections, props.selectDefaultNetwok, props.publicAddress, web3]);

  useEffect(() => {
    if (props.publicAddress && web3) {
      if (props.stakingList.length > 0) {
        setStakeData();
      }
    } else {
      return;
    }
  }, [props.stakingList, props.selectDefaultNetwok, web3]);

  async function setStakeData() {
    let array1 = [];

    const updatedStakings = await Promise.all(
      props.stakingList.map(async (item) => {
        if (item?.['stakeAddress'] && parseInt(item['network']) == parseInt(props.selectDefaultNetwok)) {
          return { ...item };
        }
        return item;
      })
    );

    console.log("*** 1 updatedStakings", updatedStakings);

    const filteredArray = updatedStakings.flatMap((item) => {
      const filteredStakers = item.stakers.filter((staker) => staker.staker == props.publicAddress.toLowerCase() && staker?.['nfts'].length > 0);
      return filteredStakers.map((staker) => ({
        ...item,
        nfts: staker.nfts
      }));
    });

    console.log("*** 2 filteredArray", filteredArray);

    if (filteredArray && filteredArray.length > 0) {
      filteredArray.filter(async (items) => {
        let tokenAddress = items['nftAddress'];
        let output = tokenAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
        if (output == "xdc") {
          tokenAddress = "0x" + tokenAddress.substring(3);
        } else {
          tokenAddress = tokenAddress;
        }
        let contract = new web3.eth.Contract(XRC721ABI, tokenAddress);
        let tokenName = await contract.methods.name().call();
        let tokenSymbol = await contract.methods.symbol().call();

        console.log("*** 3 tokenSymbol", tokenSymbol);

        items?.['nfts'].map(async (item, index) => {
          let tokenId = item;
          let data = await contract.methods.tokenURI(tokenId).call();
          console.log("*** 4.1 url data", data);

          if (data.split("/")[2] == 'gateway.pinata.cloud') {
            let data = await fetch(`${data}${tokenId}.json`);
            data = await data.json();
            if (!data) {
              const parts = data.split(`${tokenId}.json`);
              const newUrl = parts[0];
              data = newUrl;
            }
          }

          console.log("*** 4 url data", data);

          let response = await axios.post(`${ApiUrl}/v3/getUriStakeData`, { url: data });
          response = response['data']['body'];

          console.log("*** 5 url response", response);

          array1.push({ tokenAddress, tokenId, tokenUri: data, tokenName, tokenSymbol, image: response?.['image'] ? response?.['image'] : "" });
        });
      });

      console.log("*** 6 array1", array1);

      setStakedNFTs(array1);
      setStakedNFTNew(array1);
      props.setLoader({
        message: "Loading Data...",
        status: false,
      });
    } else {
      props.setLoader({
        message: "Loading Data...",
        status: false,
      });
    }
  }

  async function setMarketplaceData() {
    const updatedCollection = await Promise.all(
      props.xrc721Collections.filter(item => item['item']['owner'].toLowerCase() == from.toLowerCase() && parseInt(item['item']['network']) == parseInt(props.selectDefaultNetwok)).map(async (item) => {
        let tokenSymbol = "XDC";
        if (item['item']['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
          let contract = new web3.eth.Contract(XRC20ABI, item['item']['paymentToken']);
          let symbol = await contract.methods.symbol().call();
          tokenSymbol = symbol;
        }
        return { ...item, tokenSymbol };
      })
    );
    setCollections(updatedCollection);
  }

  async function setTokensData() {
    const updatedToken = await Promise.all(
      props.xrc20Collections.filter(item => item['owner'].toLowerCase() == from.toLowerCase() && parseInt(item['network']) == parseInt(props.selectDefaultNetwok)).map(async (item) => {
        let contract = new web3.eth.Contract(XRC20ABI, item['address']);
        let balance = await contract.methods.balanceOf(from).call({ from: props.address });
        balance = web3.utils.fromWei(balance.toString(), "ether");
        return { ...item, balance };
      })
    );
    setTokens(updatedToken);
  }

  async function getOwnedNFTs() {
    try {
      let array1 = [];
      let response = await axios.get(baseURINFT);
      response = response['data'];
      let temp = response.items
      if (temp && temp.length > 0) {
        let list = [];
        for (let items of temp) {
          let tokenAddress = items['token'];
          let output = tokenAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
          if (output == "xdc") {
            tokenAddress = "0x" + tokenAddress.substring(3);
          } else {
            tokenAddress = tokenAddress;
          }
          let tokenId = items['tokenId'];
          let tokenName = items['tokenObj']['name'];
          let tokenSymbol = items['tokenObj']['symbol'];
          let contract = new web3.eth.Contract(XRC721ABI, tokenAddress);
          let data = await contract.methods.tokenURI(tokenId).call();
          // if (data.split("/")[2] == 'gateway.pinata.cloud') {
          //   const parts = data.split(`${items['tokenId']}.json`);
          //   const newUrl = parts[0];
          //   data = newUrl;
          // }
          console.log("*** data 1", data);
          if (data.split("/")[2] == 'gateway.pinata.cloud') {
            console.log("*** data 1", `${data}${items['tokenId']}.json`);
            let data = await fetch(`${data}${items['tokenId']}.json`);
            data = await data.json();
            if (!data) {
              const parts = data.split(`${items['tokenId']}.json`);
              const newUrl = parts[0];
              data = newUrl;
            }
          }
          console.log("*** data 2", data);
          list.push(data);
          array1.push({ tokenAddress, tokenId, tokenName, tokenSymbol });
        };
        props.getUriData({ list });
        setOwnedCollections(array1);
        props.setLoader({
          message: "Loading Data...",
          status: false,
        });
      } else {
        props.setLoader({
          message: "Loading Data...",
          status: false,
        });
      }
    } catch (e) {
      props.setLoader({
        message: "Loading Data...",
        status: false,
      });
    }
  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      if (props.uriData) updateData();
    }
  }, [props.uriData, props.publicAddress, web3]);

  async function updateData() {
    if (props && props.uriData.length > 0) {
      if (ownedCollections.length == props?.uriData?.length) {
        let temp = ownedCollections.map((item, idx) => {
          return { ...item, ...props?.uriData?.[idx] };
        })
        setOwnedCollections(temp);
      }
    }
  }

  async function getOwnedTokens() {
    try {
      let array1 = [];
      let response = await axios.get(baseURIToken);
      response = response['data'];
      let temp = response.items
      if (temp && temp.length > 0) {
        temp.map(async (item, idx) => {
          let tokenAddress = item['token'];
          let tokenHolding = web3.utils.fromWei(item['quantity'].toString(), "ether")
          let tokenName = item['tokenObj']['name'];
          let tokenSymbol = item['tokenObj']['symbol'];
          array1.push({ tokenAddress, tokenHolding, tokenName, tokenSymbol })
        });
        setOwnedTokens(array1);
      }
    } catch (error) {
      props.setLoader({
        message: "Loading Data...",
        status: false,
      });
    }
  }

  const handleCopyText = () => {
    navigator.clipboard.writeText(props.address)
      .then(() => EventBus.publish("success", "Wallet Address Copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address)
      .then(() => EventBus.publish("success", "Contract Address Copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  async function handleChange(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "mintAmount") {
      if (parseFloat(e.target.value) > 0) {
        setMintAmount(parseFloat(e.target.value));
      } else {
        setMintAmount("");
      }
    } else if ([e.target.name] == "walletAddress") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        setWalletAddress(result);
      } else {
        setWalletAddress(e.target.value);
      }
    }
  }

  async function setInstance(item) {
    setMicroeconomyMintModal(true);
    setData(item);
  }

  async function setInstance2(item) {
    setMicroeconomyTransferModal(true);
    setData(item);
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function mintToken(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      if (walletAddress == "") {
        EventBus.publish("error", `Enter wallet address!`);
        return;
      }

      if (!walletAddress.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter wallet address`);
        return;
      }

      if (!walletAddress.match(/^[a-zA-Z0-9]+$/)) {
        EventBus.publish("error", `Invalid Wallet Address`);
        return;
      }

      if (mintAmount == "") {
        EventBus.publish("error", `Enter Token amount to mint!`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      props.setLoader({
        message: "Minting in Progress...",
        status: true,
      });

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let contract = new web3.eth.Contract(XRC20ABI, data['address']);
      let minter = await contract.methods.isMinter(from).call({ from: from });

      mintAmount = web3.utils.toWei(mintAmount.toString(), "ether");

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: data['address'],
          gas: 5000000,
          data: contract.methods
            .mintXRC20Token(walletAddress, mintAmount)
            .encodeABI(),
        })
        .on('transactionHash', hash => {
          console.log(`************** deploy contract hash = ${hash}`);
          setTxHash(hash);
        })
        .on('receipt', async receipt => {
          await refreshBalance();
          await getOwnedTokens();
          setMintAmount("");
          setWalletAddress("");
          setData({});
          props.setLoader({ status: false });
          EventBus.publish("success", `Token(s) Minted Successfully!`);
          setMicroeconomyMintModal(false);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
      setMintAmount("");
      setMicroeconomyMintModal(false);
      EventBus.publish("error", `Mint Failed`);
    }
  };

  async function transferNFT(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (props.kycStatus !== "adminApproved") {
        EventBus.publish("error", `Submit KYC verification to access this function!`);
        return;
      }

      if (walletAddress == "") {
        EventBus.publish("error", `Enter wallet address!`);
        return;
      }

      if (!walletAddress.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter wallet address`);
        return;
      }

      if (!walletAddress.match(/^[a-zA-Z0-9]+$/)) {
        EventBus.publish("error", `Invalid Wallet Address`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      props.setLoader({
        message: "Approval in Progress...",
        status: true,
      });

      let contract = new web3.eth.Contract(XRC721ABI, data['tokenAddress']);
      await contract.methods.approve(walletAddress, parseInt(data['tokenId'])).send({
        from: deployer,
      });

      props.setLoader({
        message: "NFT Transfer in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: data['tokenAddress'],
          gas: 5000000,
          data: contract.methods
            .safeTransferFrom(deployer, walletAddress, parseInt(data['tokenId']))
            .encodeABI(),
        })
        .on('transactionHash', hash => {
          console.log(`************** deploy contract hash = ${hash}`);
          setTxHash(hash);
        })
        .on('receipt', async receipt => {
          await refreshBalance();
          await getOwnedNFTs();
          setWalletAddress("");
          setData({});
          props.setLoader({ status: false });
          EventBus.publish("success", `NFT Transferred Successfully!`);
          setMicroeconomyMintModal(false);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "NFT Transferred Failed...",
        status: false,
      });
      setWalletAddress("");
      setMicroeconomyMintModal(false);
      EventBus.publish("error", `NFT Transferred Failed`);
    }
  };

  const handleImageError = (idx) => {
    // Set the failed image to the fallback image
    ownedCollections[idx]['image'] = alternate;
    setOwnedCollections([...ownedCollections]);
  };

  const handleImageError2 = (idx) => {
    // Set the failed image to the fallback image
    stakedNFTNew[idx]['image'] = alternate;
    setStakedNFTNew([...stakedNFTNew]);
  };

  async function handleImageChange(e) {
    try {

      const file = e.target.files[0];

      let { publicAddress } = props;

      if (file == "") {
        EventBus.publish("error", `Please upload profile picture`);
        return;
      }

      if (file == undefined) {
        EventBus.publish("error", `Please upload profile picture`);
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
        props.setLoader({
          message: "Updating Profile Picture...",
          status: true,
        });
        const formData = new FormData();
        formData.append('publicAddress', publicAddress.toLowerCase());
        formData.append('profilePicture', file);
        await props.updateUserImage(formData);
        props.setLoader({
          message: "Updating Profile Picture...",
          status: false,
        });
      }
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Profile picture update Failed...",
        status: false,
      });
      EventBus.publish("error", `Profile picture update Failed`);
    }
  }

  console.log("*** 7 stakedNFTNew", stakedNFTNew);

  return (
    <>
      <div className="microeconomy-wrapper">
        <div className="microeconomy-head">
          <a className="user-profile-icon" href="#">
          </a>
          {props.isLoader.status == true &&
            <Prompt when={model}
              message="Transaction in progress, leaving page may result in transaction failure!"
              beforeUnload={true}
            />
          }
          {
            (walletAddress !== "" || mintAmount !== "") &&
            <Prompt when={model}
              message="Leaving page will result in losing details entered!"
              beforeUnload={true}
            />
          }
          <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
          <ConnectWallet />
        </div>

        <div className="profile-wrapper">
          <div className="inner">
            <div className="inner-wrap">
              <div className="img-wrap">
                <img src={uploadimg} className="upload-img" alt="upload-img" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />

                <div className="selected-image">
                  <img src={selectedImage} alt="Selected" />
                </div>
              </div>

              <div className="right">
                <div className="content">
                  <h2>{props.kycValue?.firstName ? props.kycValue?.firstName : "Profile"}</h2>

                  <p>This page includes all NFTs and Tokens you have created with your wallet.</p>
                </div>

                <div className="wallet-address-wrap">
                  {
                    props.kycStatus == "adminApproved" &&
                    <img src={approvedkyc} alt="" />
                  }
                  {
                    props.kybStatus == "approved" &&
                    <img src={approvedkyb} alt="" />
                  }
                  {/* {
                    stakings && stakings.length > 0 &&
                    stakings.map((item) => (
                      <>
                        {
                          item?.['nftSymbol'] == "GOV NFT" && item?.['stakedItems'] &&
                          parseInt(item?.['stakedItems']?.['totalStake']) > 0 &&
                          <img src={stakedGov} alt="" />
                        }
                        {
                          item?.['nftSymbol'] == "VIP" && item?.['stakedItems'] &&
                          parseInt(item?.['stakedItems']?.['totalStake']) > 0 &&
                          <img src={stakedVip} alt="" />
                        }
                      </>
                    ))
                  } */}

                  <div className="wallet-address">
                    <span>{props.address && props.address.substring(0, 5) + '...' + props.address.substring(37, props.address.length)}</span>

                    <img src={walletaddresscopyicon} alt="" onClick={() => handleCopyText()} />
                  </div>

                </div>
              </div>
            </div>

            <div className="profile-bottom">
              <ul className="nav nav-tabs" id="tab1" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="nft-created-tab" data-toggle="tab" href="#nft-created" role="tab" aria-controls="nft-created" aria-selected="false">NFT Created</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="token-created-tab" data-toggle="tab" href="#token-created" role="tab" aria-controls="token-created" aria-selected="false">Token Created</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="nft-collected-tab" data-toggle="tab" href="#nft-collected" role="tab" aria-controls="nft-collected" aria-selected="false">NFT Collected</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="nft-staked-tab" data-toggle="tab" href="#nft-staked" role="tab" aria-controls="nft-staked" aria-selected="false">NFT Staked</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="token-collected-tab" data-toggle="tab" href="#token-collected" role="tab" aria-controls="token-collected" aria-selected="false">Token Collected</a>
                </li>

              </ul>
            </div>
          </div>

          <div className="tab-content" id="tab1Content">

            <div className="tab-pane fade active show" id="nft-created" role="tabpanel" aria-labelledby="nft-created-tab">
              <div className="nft-collection">
                <div className="head">
                  <h2>NFT Created</h2>
                </div>

                <div className="collection-wrap">
                  {
                    collections.length > 0 &&
                    collections.map((item) => (
                      <div className="box">
                        <img src={item?.['item']?.['banner']} alt=" " />
                        <div className="content">
                          <div className="info">
                            <div className="profile-info">
                              <div>
                                <h4>{item?.['item']?.['name']}</h4>
                                <p>{item?.['item']?.['symbol']}</p>
                              </div>
                            </div>
                          </div>

                          <div className='wallet-address  mt-3 mb-3'>
                            <p>{item?.['item']?.['address'] && item?.['item']?.['address'].substring(0, 5) + '...' + item?.['item']?.['address'].substring(37, item?.['item']?.['address'].length)}</p>
                            <img src={walletaddresscopyicon} alt="" onClick={() => handleCopyAddress(item?.['item']?.['address'])} />
                          </div>

                          <p>
                            {
                              item['item']?.['paymentToken'] == undefined || item['item']?.['paymentToken'] == "0x0000000000000000000000000000000000000000"
                                ?
                                <span>{item['item']?.['price']} {bSymbol}</span>
                                :
                                <span>{item['item']?.['price']} {item['tokenSymbol']}</span>
                            }
                          </p>
                          <div className='info mt-3'>
                            <Link to={`/project-detail/${item?.['_id']}`}>
                              <button>View Details</button>
                            </Link>

                            <Link to={`/dashboard/microeconomy/project-detail-steps/${item?.['item']?.['address']}`}>
                              <button>Add Details</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>

            </div>

            <div className="tab-pane fade" id="token-created" role="tabpanel" aria-labelledby="token-created-tab">
              <div className="nft-collection">
                <div className="head">
                  <h2>Token Created</h2>
                </div>
                <div className="collection-wrap">
                  {
                    tokens.length > 0 &&
                    tokens.map((item) => (

                      <div className="box">
                        <img src={item?.['banner']} alt=" " />
                        <div className="content">
                          <div className="info">
                            <div className="profile-info">
                              <div>
                                <h4>{item?.['name']}</h4>
                                <p>{item?.['symbol']}</p>
                              </div>
                            </div>

                            <button onClick={() => setInstance(item)}>Mint</button>
                          </div>

                          <div className='wallet-address mt-3 mb-3'>
                            <p>{item?.['address'] && item?.['address'].substring(0, 5) + '...' + item?.['address'].substring(37, item?.['address'].length)}</p>
                            <img src={walletaddresscopyicon} alt="" onClick={() => handleCopyAddress(item?.['address'])} />
                          </div>

                          <p>{`${toFixed(item?.['balance'], 'balance')} ${item?.['symbol']}`}</p>
                          <div className='info mt-3'>
                            <Link to={`/project-detail/${item?.['_id']}`}>
                              <button>View Details</button>
                            </Link>

                            <Link to={`/dashboard/microeconomy/project-detail-steps/${item?.['address']}`}>
                              <button>Add Details</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="nft-collected" role="tabpanel" aria-labelledby="nft-collected-tab">

              <div className="nft-collection">
                <div className="head">
                  <h2>NFT Collected</h2>
                </div>

                <div className="collection-wrap">
                  {
                    ownedCollections.length > 0 &&
                    ownedCollections.map((item, idx) => (
                      <div className="box">
                        <img key={idx} src={item?.['image'] ? item?.['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />

                        <div className="content">
                          <div className="info">
                            <div className="profile-info">
                              <div>
                                <h4>{item?.['tokenName']}</h4>
                                <p>{item?.['tokenSymbol']}</p>
                              </div>
                            </div>
                            <button onClick={() => setInstance2(item)}>Transfer</button>
                          </div>

                          <div className='wallet-address mt-3 mb-3'>
                            <p>{item?.['tokenAddress'] && item?.['tokenAddress'].substring(0, 5) + '...' + item?.['tokenAddress'].substring(37, item?.['tokenAddress'].length)}</p>
                            <img src={walletaddresscopyicon} alt="" onClick={() => handleCopyAddress(item?.['tokenAddress'])} />
                          </div>
                          <p>{`${item?.['tokenSymbol']} #${item?.['tokenId']}`}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="nft-staked" role="tabpanel" aria-labelledby="nft-staked-tab">

              <div className="nft-collection">
                <div className="head">
                  <h2>NFT Staked</h2>
                </div>

                <div className="collection-wrap">
                  {
                    stakedNFTNew.length > 0 &&
                    stakedNFTNew.map((item, idx) => (
                      <div className="box">
                        <img key={idx} src={item?.['image'] ? item?.['image'] : alternate} onError={() => handleImageError2(idx)} alt="Alternate Image" />

                        <div className="content">
                          <div className="info">
                            <div className="profile-info">
                              <div>
                                <h4>{item?.['tokenName']}</h4>
                                <p>{item?.['tokenSymbol']}</p>
                              </div>
                            </div>
                          </div>

                          <div className='wallet-address mt-3 mb-3'>
                            <p>{item?.['tokenAddress'] && item?.['tokenAddress'].substring(0, 5) + '...' + item?.['tokenAddress'].substring(37, item?.['tokenAddress'].length)}</p>
                            <img src={walletaddresscopyicon} alt="" onClick={() => handleCopyAddress(item?.['tokenAddress'])} />
                          </div>
                          <p>{`${item?.['tokenSymbol']} #${item?.['tokenId']}`}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="token-collected" role="tabpanel" aria-labelledby="token-collected-tab">
              <div className="nft-collection">
                <div className="head">
                  <h2>Token Collected</h2>
                </div>

                <div className="collection-wrap">
                  {
                    ownedTokens.length > 0 &&
                    ownedTokens.map((item) => (
                      <div className="box">
                        <div className="content">
                          <div className="info">
                            <div className="profile-info">

                              <div>
                                <h4>{item?.['tokenName']}</h4>

                                <p>{item?.['tokenSymbol']}</p>
                              </div>
                            </div>
                          </div>

                          <div className='wallet-address mt-3 mb-3'>
                            <p>{item?.['tokenAddress'] && item?.['tokenAddress'].substring(0, 5) + '...' + item?.['tokenAddress'].substring(37, item?.['tokenAddress'].length)}</p>
                            <img src={walletaddresscopyicon} alt="" onClick={() => handleCopyAddress(item?.['tokenAddress'])} />
                          </div>

                          <p>{`${toFixed(item?.['tokenHolding'], 'balance')} ${item?.['tokenSymbol']}`}</p>
                        </div>

                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <Modal
        open={microeconomyMintModal}
        onClose={() => setMicroeconomyMintModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal mint-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyMintModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <form onSubmit={mintToken}>
              <div className="form-head">
                <h3>Mint XRC20</h3>
              </div>

              <div className="form-group">
                {
                  walletAddress !== "" ?
                    < input
                      type="text"
                      name='walletAddress'
                      value={walletAddress}
                      maxlength="43"
                      placeholder={walletAddress}
                      onChange={handleChange}
                    /> :
                    <input
                      type="text"
                      name='walletAddress'
                      value={walletAddress}
                      maxlength="43"
                      placeholder="Wallet Address*"
                      onChange={handleChange}
                    />
                }
              </div>

              <div className="form-group">
                {
                  mintAmount !== "" ?
                    <input
                      onWheel={handleOnWheel}
                      type="number"
                      name='mintAmount'
                      value={mintAmount}
                      placeholder={mintAmount}
                      onChange={handleChange}
                    /> :
                    <input
                      onWheel={handleOnWheel}
                      type="number"
                      name='mintAmount'
                      value={mintAmount}
                      placeholder="Mint Amount*"
                      onChange={handleChange}
                    />
                }
              </div>

              <button type="submit">
                Mint
              </button>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={microeconomyTransferModal}
        onClose={() => setMicroeconomyTransferModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal mint-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyTransferModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <form onSubmit={transferNFT}>
              <div className="form-head">
                <h3>Transfer NFT</h3>
              </div>

              <div className="form-group">
                {
                  walletAddress !== "" ?
                    < input
                      type="text"
                      name='walletAddress'
                      value={walletAddress}
                      maxlength="43"
                      placeholder={walletAddress}
                      onChange={handleChange}
                    /> :
                    <input
                      type="text"
                      name='walletAddress'
                      value={walletAddress}
                      maxlength="43"
                      placeholder="Wallet Address*"
                      onChange={handleChange}
                    />
                }
              </div>

              <button type="submit">
                Transfer
              </button>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal1}
        onClose={() => setStepModal1(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal1(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <i>NFT Collected:</i>

            <p>Click <span>NFT collected</span> tab to view individual NFT that you have collected.</p>
          </div>

          <div className="steps-btns">
            <button disabled>Previous</button>

            <button onClick={() => { setStepModal2(true); setStepModal1(false) }}>Next</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal2}
        onClose={() => setStepModal2(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal2(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <i>Token Collected:</i>

            <p>Click <span>Token collected</span> tab to view XRC20 tokens that you have collected.</p>
          </div>

          <div className="steps-btns">
            <button onClick={() => { setStepModal1(true); setStepModal2(false) }}>Previous</button>

            <button onClick={() => { setStepModal3(true); setStepModal2(false) }}>Next</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal3}
        onClose={() => setStepModal3(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal3(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <i>Nft Created:</i>

            <p>Click <span>Nft created</span> tab to view collection that you have created.</p>
          </div>

          <div className="steps-btns">
            <button onClick={() => { setStepModal2(true); setStepModal3(false) }}>Previous</button>

            <button onClick={() => { setStepModal4(true); setStepModal3(false) }}>Next</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal4}
        onClose={() => setStepModal4(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal4(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <i>Token Created:</i>

            <p>Click <span>Token Created</span> tab to view token that you have created. You can mint them as well.</p>
          </div>

          <img src={mintstepinfo} alt="" />

          <div className="steps-btns">
            <button onClick={() => { setStepModal3(true); setStepModal4(false) }}>Previous</button>

            <button onClick={() => { setStepModal5(true); setStepModal4(false) }}>Next</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal5}
        onClose={() => setStepModal5(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal5(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <i>NFT collection details:</i>
            <p>Click <span>View details</span> button of an NFT to view it’s details and buy or sell it.</p>
          </div>

          <img src={nftcollectiondetailsstepinfo} alt="" />

          <div className="steps-btns">
            <button onClick={() => { setStepModal4(true); setStepModal5(false) }}>Previous</button>

            <button disabled>Next</button>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapDispatchToProps = {
  setLoader,
  getXRC20,
  getXRC721,
  getStaking,
  getUriData,
  setBalance,
  setGrootBalance,
  updateUserImage
};

const mapStateToProps = ({ Auth }) => {
  let { address, publicAddress, xrc721Collections, xrc20Collections, stakingList, selectDefaultNetwok, uriData, currentMode, isLoader, kycStatus, kybStatus, kycValue } = Auth;
  return { address, publicAddress, xrc721Collections, xrc20Collections, stakingList, selectDefaultNetwok, uriData, currentMode, isLoader, kycStatus, kybStatus, kycValue };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);