import { PURGE } from "redux-persist";
import { setToken } from "../axios";

var initialState = {
  isLogin: false,
  auth: localStorage.getItem("token"),
  token: localStorage.getItem("token"),
  address: localStorage.getItem("address"),
  loginToken: localStorage.getItem("loginToken"),
  publicAddress: localStorage.getItem("publicAddress"),
  isLoader: { message: "Please Wait...", status: false },
  isInfoModal: false,
  getOrderBook: [],
  nonce: {},
  singlePair: "",
  updateGraph: "",
  volume: {},
  pairAddress: [],
  getUserTrades: [],
  allUserTrades: [],
  allUserOrders: [],
  xrc20Collections: [],
  xrc721Collections: [],
  stakingList: [],
  stakingLPList: [],
  collectionData: [],
  daoList: [],
  proposalList: [],
  daoListCIFI: [],
  proposalListCIFI: [],
  nftData: [],
  uriData: [],
  stakedNFTlist: [],
  stakedLPAmount: 0,
  vestinglist: [],
  ilolist: [],
  selectDefaultNetwok: 50,
  balance: 0,
  grootBalance: 0,
  currentMode: false,
  kycStatus: false,
  kybStatus: "false",
  userSoulBound: "",
  kycGStatus: "",
  kycValue: [],
  xdcPrice: 0,
  kycSubmitted: {},
  icoAddress: "",
  clearProjectDetail: false,
  projectDetails: {},
};

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    /*========== LOGIN REDUCERS ============= */

    case "SET_NONCE":
      return {
        ...state,
        nonce: payload
      };

    case "SET_ALL_USER_TRADES":
      return {
        ...state,
        allUserTrades: payload
      };

    case "SET_ALL_ORDERS":
      return {
        ...state,
        allUserOrders: payload
      };

    case "GET_TRADE_ORDERS":
      return {
        ...state,
        getUserTrades: payload
      }

    case "IS_LOGIN_DISABLED":
      return {
        ...state,
      };

    case "LOGOUT":
      state.address = ""
      localStorage.removeItem("loginToken");
      localStorage.removeItem("address");
      localStorage.removeItem("token");
      localStorage.removeItem("kycStatus");
      localStorage.removeItem("kybStatus");
      setToken();
      return {
        ...state,
        address: null,
        loginToken: "",
        auth: "",
        token: "",
        kycStatus: false,
        kybStatus: "false",
        isLogin: !state.isLogin,
      };

    case "SET_ADDRESS":
      localStorage.setItem("publicAddress", payload);
      localStorage.setItem("address", payload);
      return {
        ...state,
        address: payload,
        publicAddress: payload,
      };

    case "SET_LOGIN_TOKEN":
      localStorage.setItem("loginToken", payload);
      return {
        ...state,
        loginToken: payload,
      };

    case "SET_TOKEN":
      localStorage.setItem("token", payload);
      return {
        ...state,
        token: payload,
      };

    /*========== LOADER REDUCERS ============= */

    case "SET_LOADER":
      return {
        ...state,
        isLoader: payload,
      };

    case "TOGGLE_INFO_MODAL":
      return {
        ...state,
        isInfoModal: payload,
      };

    case "GET_ORDERBOOK":
      return {
        ...state,
        getOrderBook: payload
      }

    case "SET_TOKEN_PAIR":
      return {
        ...state,
        singlePair: payload
      };

    case "UPDATE_GRAPH":
      return {
        ...state,
        updateGraph: payload
      }

    case "SET_24_HOURS_VOLUME":
      return {
        ...state,
        volume: payload
      }

    case "SET_PAIRS_ADDRESS":
      return {
        ...state,
        pairAddress: payload
      }

    case "SELECT_NETWORK":
      return {
        ...state,
        selectDefaultNetwok: payload
      }

    case "SET_BALANCE":
      return {
        ...state,
        balance: payload
      }

    case "SET_GROOT_BALANCE":
      return {
        ...state,
        grootBalance: payload
      }

    /*========== MICRO ECONOMY REDUCERS ============= */

    case "SET_XRC20":
      return {
        ...state,
        xrc20Collections: payload,
      };

    case "SET_ICO":
      return {
        ...state,
        icoAddress: payload,
      };

    case "SET_XRC721":
      return {
        ...state,
        xrc721Collections: payload,
      };

    case "SET_STAKING":
      return {
        ...state,
        stakingList: payload,
      };

      case "SET_LP_STAKING":
      return {
        ...state,
        stakingLPList: payload,
      };

    case "SET_COLLECTION":
      return {
        ...state,
        collectionData: payload,
      };

    case "SET_STAKED_LP":
      return {
        ...state,
        stakedLPAmount: payload,
      };

    case "SET_STAKED_NFT":
      return {
        ...state,
        stakedNFTlist: payload,
      };

    case "SET_VESTING":
      return {
        ...state,
        vestinglist: payload,
      };

    case "SET_ILO":
      return {
        ...state,
        ilolist: payload,
      };

    case "CLEAR_PROJECT_DETAILS":
      return {
        ...state,
        clearProjectDetail: payload,
      };

    case "SET_PROJECT_DETAILS":
      return {
        ...state,
        projectDetails: payload,
      };

    /*========== DAO REDUCERS ============= */

    case "SET_DAO":
      return {
        ...state,
        daoList: payload,
      };

    case "SET_PROPOSALS":
      return {
        ...state,
        proposalList: payload,
      };

    case "SET_CIFI_DAO":
      return {
        ...state,
        daoListCIFI: payload,
      };

    case "SET_CIFI_PROPOSALS":
      return {
        ...state,
        proposalListCIFI: payload,
      };

    /*========== MARKETPLACE REDUCERS ============= */

    case "SET_SINGLE_NFT":
      return {
        ...state,
        nftData: payload,
      };

    case "SET_URI_DATA":
      return {
        ...state,
        uriData: payload,
      };

    case "SET_MODE":
      return {
        ...state,
        currentMode: payload,
      };

    /*========== KYC REDUCERS ============= */

    case "SET_XDC_PRICE":
      return {
        ...state,
        xdcPrice: payload,
      };

    case "SET_KYC_STATUS":
      return {
        ...state,
        kycStatus: payload,
      };

    case "SET_KYB_STATUS":
      return {
        ...state,
        kybStatus: payload,
      };

    case "SET_G_KYC_STATUS":
      return {
        ...state,
        kycGStatus: payload,
      };

    case "SET_KYC":
      return {
        ...state,
        kycValue: payload,
      };

    case "KYC_RESPONSE":
      return {
        ...state,
        kycSubmitted: payload,
      };

    case "SET_ROLE":
      localStorage.setItem("userRole", payload);
      return {
        ...state,
        userRole: payload,
      };

    case "SET_SOUL_BOUND":
      return {
        ...state,
        userSoulBound: payload,
      };


    default:
      return state;
  }
};

export default Auth;