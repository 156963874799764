import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import angleleft from "../../images/angle-left.png";
import angleright from "../../images/angle-right.png";
import DaoGenesislogo from "../../images/dao-genesis.png";

import addmore from "../../images/user-add.png";
import { Link, useParams } from "react-router-dom";
import uploadimg from "../../images/plusicons.svg";
import { networkId, CIFI } from "../../store/config";
import { setLoader, setBalance, getXRC20, getXRC721, addProjectDetails, setGrootBalance, getKybStatus } from "../../store/actions/Auth";


const ProjectDetailSteps = (props, { mode }) => {

  let { tokenAddress } = useParams();

  let [tokens, settokens] = useState([]);

  // let [tokenAddress, settokenAddress] = useState("");
  let [title, settitle] = useState("");
  let [description, setdescription] = useState("");
  let [supply, setsupply] = useState("");
  let [fundSale, setfundSale] = useState("");
  let [vinfo, setvinfo] = useState("");
  let [vstartdate, setvstartdate] = useState("");
  let [venddate, setvenddate] = useState("");
  let [vcliff, setvcliff] = useState("");
  let [vlength, setvlength] = useState("");
  let [whitepaperDoc, setwhitepaperDoc] = useState("");
  let [vestingDoc, setvestingDoc] = useState("");
  let [privacyDoc, setprivacyDoc] = useState("");
  let [website, setwebsite] = useState("");
  let [telegram, settelegram] = useState("");
  let [discord, setdiscord] = useState("");
  let [medium, setmedium] = useState("");
  let [twitter, settwitter] = useState("");
  let [linkedin, setlinkedin] = useState("");

  const [step, setStep] = useState(1);
  const [model, setModel] = useState(true);
  let [formValues, setFormValues] = useState([{ mtitle: "", mname: "", mlinkedin: "", image: "" }]);

  const totalSteps = 6;
  const progress = Math.round((step / totalSteps) * 100);


  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  useEffect(() => {
    props.getXRC20();
    props.getXRC721();
  }, []);

  useEffect(() => {
    let temp = [];
    let temp1 = [];
    if (props.xrc721Collections && props.xrc721Collections.length > 0) {
      props.xrc721Collections.map(items => {
        temp1.push(items.item);
      })
      temp = [...props.xrc20Collections, ...temp1];
    }
    settokens(temp);
  }, [props.xrc20Collections, props.xrc721Collections]);

  useEffect(() => {
    if ((props.publicAddress !== null || props.publicAddress !== undefined) && web3) {
      refreshBalance()
    }
  }, [props.publicAddress, web3])

  useEffect(() => {
    if (props.clearProjectDetail === true) {
      // settokenAddress("");
      settitle("");
      setdescription("");
      setsupply("");
      setfundSale("");
      setvinfo("");
      setvstartdate("");
      setvenddate("");
      setvcliff("");
      setvlength("");
      setwhitepaperDoc("");
      setvestingDoc("");
      setprivacyDoc("");
      setwebsite("");
      settelegram("");
      setdiscord("");
      setmedium("");
      settwitter("");
      setlinkedin("");
      setFormValues([{ mtitle: "", mname: "", mlinkedin: "", image: "" }]);
    }
  }, [props.clearProjectDetail])

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));

    if ([e.target.name] == "title") {
      settitle(e.target.value);
    } else if ([e.target.name] == "description") {
      setdescription(e.target.value);
    } else if ([e.target.name] == "supply") {
      if (parseFloat(e.target.value) > 0) {
        setsupply(parseFloat(e.target.value));
      } else {
        setsupply("");
      }
    } else if ([e.target.name] == "fundSale") {
      if (parseFloat(e.target.value) > 0) {
        setfundSale(parseFloat(e.target.value));
      } else {
        setfundSale("");
      }
    } else if ([e.target.name] == "vinfo") {
      setvinfo(e.target.value);
    } else if ([e.target.name] == "vstartdate") {
      setvstartdate(e.target.value);
    } else if ([e.target.name] == "venddate") {
      setvenddate(e.target.value);
    } else if ([e.target.name] == "vcliff") {
      if (parseInt(e.target.value) > 0) {
        setvcliff(parseInt(e.target.value));
      } else {
        setvcliff("");
      }
    } else if ([e.target.name] == "vlength") {
      if (parseInt(e.target.value) > 0) {
        setvlength(parseInt(e.target.value));
      } else {
        setvlength("");
      }
    } else if ([e.target.name] == "website") {
      let inputValue = e.target.value;
      if (inputValue && !inputValue.startsWith('https://')) {
        inputValue = 'https://' + inputValue;
        setwebsite(inputValue)
      } else {
        setwebsite(e.target.value);
      }
    } else if ([e.target.name] == "telegram") {
      let inputValue = e.target.value;
      if (inputValue && !inputValue.startsWith('https://')) {
        inputValue = 'https://' + inputValue;
        settelegram(inputValue)
      } else {
        settelegram(e.target.value);
      }
    } else if ([e.target.name] == "discord") {
      let inputValue = e.target.value;
      if (inputValue && !inputValue.startsWith('https://')) {
        inputValue = 'https://' + inputValue;
        setdiscord(inputValue)
      } else {
        setdiscord(e.target.value);
      }
    } else if ([e.target.name] == "medium") {
      let inputValue = e.target.value;
      if (inputValue && !inputValue.startsWith('https://')) {
        inputValue = 'https://' + inputValue;
        setmedium(inputValue)
      } else {
        setmedium(e.target.value);
      }
    } else if ([e.target.name] == "linkedin") {
      let inputValue = e.target.value;
      if (inputValue && !inputValue.startsWith('https://')) {
        inputValue = 'https://' + inputValue;
        setlinkedin(inputValue)
      } else {
        setlinkedin(e.target.value);
      }
    } else if ([e.target.name] == "twitter") {
      let inputValue = e.target.value;
      if (inputValue && !inputValue.startsWith('https://')) {
        inputValue = 'https://' + inputValue;
        settwitter(inputValue)
      } else {
        settwitter(e.target.value);
      }
    } else if ([e.target.name] == "whitepaperDoc") {
      setwhitepaperDoc(e.target.files[0]);
    } else if ([e.target.name] == "vestingDoc") {
      setvestingDoc(e.target.files[0]);
    } else if ([e.target.name] == "privacyDoc") {
      setprivacyDoc(e.target.files[0]);
    }
    // else if ([e.target.name] == "tokenAddress") {
    //   let input = e.target.value;
    //   let output = input.substring(0, 3); // checks first three char of address
    //   if (output == "xdc") {
    //     let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
    //     settokenAddress(result);
    //   } else {
    //     settokenAddress(e.target.value);
    //   }
    // }
  }

  async function refreshBalance() {
    // let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
    // let req1 = web3.utils.fromWei(req.toString(), 'ether');
    // setSubPrice(parseFloat(req1));
    let address = (await web3.currentProvider.enable())[0];
    props.getKybStatus({ publicAddress: address });
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function addFormFields() {
    setFormValues([...formValues, { mtitle: "", mname: "", mlinkedin: "", image: "" }]);
  }

  async function handleChange(i, e) {
    if (e.target.name == "image") {
      formValues[i][e.target.name] = e.target.files[0];
    } else {
      formValues[i][e.target.name] = e.target.value;
    }
    setFormValues([...formValues])
  }

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  async function submitProjectDetails(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        publicAddress = "0x" + publicAddress.substring(3);
      } else {
        publicAddress = publicAddress;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      const filtered = await Promise.all(
        tokens.filter(item => item['owner'].toLowerCase() == publicAddress.toLowerCase()).map(async (item) => {
          return item;
        })
      );

      if (filtered.length <= 0) {
        EventBus.publish("error", `Only project owner can add project details`);
        return;
      }

      if (title == "") {
        EventBus.publish("error", `Please enter project title`);
        return;
      }

      if (!title.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter project title`);
        return;
      }

      if (!title.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `project title must contain alphabets`);
        return;
      }

      if (description == "") {
        EventBus.publish("error", `Please enter project description`);
        return;
      }

      if (!description.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter project description`);
        return;
      }

      if (!description.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `project description must contain alphabets`);
        return;
      }

      if (supply == "") {
        EventBus.publish("error", `Please enter token supply`);
        return;
      }

      if (parseFloat(supply) <= 0) {
        EventBus.publish("error", `Please enter token supply`);
        return;
      }

      if (fundSale == "") {
        EventBus.publish("error", `Please enter token sale funds`);
        return;
      }

      if (parseFloat(fundSale) <= 0) {
        EventBus.publish("error", `Please enter token sale funds`);
        return;
      }

      if (vinfo !== "") {
        if (vinfo == "") {
          EventBus.publish("error", `Please enter project vesting information`);
          return;
        }

        if (!vinfo.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter project vesting information`);
          return;
        }

        if (!vinfo.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `project vesting information must contain alphabets`);
          return;
        }

        if (vstartdate == "") {
          EventBus.publish("error", `Enter vesting start date`);
          return;
        }

        if (venddate == "") {
          EventBus.publish("error", `Enter vesting end date`);
          return;
        }

        if (((new Date(vstartdate).getTime()) / 1000) >= ((new Date(venddate).getTime()) / 1000)) {
          EventBus.publish("error", `Vesting starting time must be smaller than ending time`);
          return;
        }

        if (vcliff == "") {
          EventBus.publish("error", `Please enter vesting cliff time`);
          return;
        }

        if (parseInt(vcliff) <= 0) {
          EventBus.publish("error", `Please enter vesting cliff time`);
          return;
        }

        if (vlength == "") {
          EventBus.publish("error", `Please enter vesting cliff time`);
          return;
        }

        if (parseInt(vlength) <= 0) {
          EventBus.publish("error", `Please enter vesting cliff time`);
          return;
        }
      }

      if (whitepaperDoc == "") {
        EventBus.publish("error", `Please upload collection banner`);
        return;
      }

      if (whitepaperDoc == undefined) {
        EventBus.publish("error", `Please upload collection banner`);
        return;
      }

      if (vestingDoc !== "") {
        if (vestingDoc == undefined) {
          EventBus.publish("error", `Please upload collection banner`);
          return;
        }
      }

      if (privacyDoc !== "") {
        if (privacyDoc == undefined) {
          EventBus.publish("error", `Please upload collection banner`);
          return;
        }
      }

      if (website == "") {
        EventBus.publish("error", `Please enter project website`);
        return;
      }

      if (!website.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter project website`);
        return;
      }

      if (!website.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `project website must contain alphabets`);
        return;
      }

      if (telegram !== "") {
        if (!telegram.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter project telegram id`);
          return;
        }

        if (!telegram.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `project telegram id must contain alphabets`);
          return;
        }
      }

      if (discord !== "") {
        if (!discord.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter project discord id`);
          return;
        }

        if (!discord.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `project discord id must contain alphabets`);
          return;
        }
      }

      if (medium !== "") {
        if (!medium.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter project medium handle`);
          return;
        }

        if (!medium.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `project medium handle must contain alphabets`);
          return;
        }
      }

      if (twitter !== "") {
        if (!twitter.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter project twitter handle`);
          return;
        }

        if (!twitter.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `project twitter handle must contain alphabets`);
          return;
        }
      }

      if (linkedin !== "") {
        if (!linkedin.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter project linkedin handle`);
          return;
        }

        if (!linkedin.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `project linkedin handle must contain alphabets`);
          return;
        }
      }

      if (formValues.length > 0) {
        formValues.map((items) => {
          console.log("*** items", items);
          if (items.mtitle == "") {
            EventBus.publish("error", `Please fill all team members designations`);
            return;
          }

          if (!items.mtitle.replace(/\s/g, '').length) {
            EventBus.publish("error", `Please fill all team members designations!`);
            return;
          }

          if (!items.mtitle.match(/[a-zA-Z]/)) {
            EventBus.publish("error", `Team members designations must contain alphabets!`);
            return;
          }

          if (items.mname == "") {
            EventBus.publish("error", `Please fill all team members linkedin handles`);
            return;
          }

          if (!items.mname.replace(/\s/g, '').length) {
            EventBus.publish("error", `Please fill all team members names!`);
            return;
          }

          if (!items.mname.match(/[a-zA-Z]/)) {
            EventBus.publish("error", `Team members names must contain alphabets!`);
            return;
          }

          if (items.mlinkedin == "") {
            EventBus.publish("error", `Please fill all team members linkedin handles`);
            return;
          }

          if (!items.mlinkedin.replace(/\s/g, '').length) {
            EventBus.publish("error", `Please fill all team members linkedin handles!`);
            return;
          }

          if (!items.mlinkedin.match(/[a-zA-Z]/)) {
            EventBus.publish("error", `Team members linkedin handles must contain alphabets!`);
            return;
          }

          if (items.image == "") {
            EventBus.publish("error", `Please upload all team members images`);
            return;
          }

          if (items.image == undefined) {
            EventBus.publish("error", `Please upload all team members images`);
            return;
          }
        })
      }

      props.setLoader({
        message: "Submitting Project Details...",
        status: true,
      });

      let data = new FormData();

      data.append('publicAddress', props.publicAddress);
      data.append('tokenAddress', tokenAddress);
      data.append('title', title);
      data.append('description', description);
      data.append('supply', supply);
      data.append('fundSale', fundSale);
      data.append('vesting', vinfo !== "" ? JSON.stringify({ vinfo, vstartdate, venddate, vcliff, vlength }) : "");
      data.append('whitepaperDoc', whitepaperDoc);
      if (vestingDoc !== "") {
        data.append('vestingDoc', vestingDoc);
      }
      if (privacyDoc !== "") {
        data.append('privacyDoc', privacyDoc);
      }
      data.append('website', website);
      data.append('telegram', telegram);
      data.append('discord', discord);
      data.append('medium', medium);
      data.append('twitter', twitter);
      data.append('linkedin', linkedin);
      data.append('team', formValues.length);
      formValues.forEach((member, index) => {
        data.append(`mtitle${index}`, member.mtitle);
        data.append(`mname${index}`, member.mname);
        data.append(`mlinkedin${index}`, member.mlinkedin);
        data.append(`image${index}`, member.image);
      });

      props.addProjectDetails(data);

    } catch (e) {
      console.log(e);
      props.setLoader({
        status: false,
      });
      EventBus.publish("error", `Unable To add project details!`);
    }
  }


  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <div className="mb-10">
              <div className="progress-head">
                <h3>Add project detail</h3>

                <span>
                  Step {step} of {totalSteps}
                </span>
              </div>

              <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
              </div>
            </div>

            <div className="step">
              <div className="form-wrap full-width">
                <div className="form-group">
                  <input
                    type="text"
                    name='title'
                    value={title}
                    maxlength="30"
                    onChange={handleOnInput}
                    placeholder="Project Title*"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name='tokenAddress'
                    value={tokenAddress}
                    maxlength="43"
                    onChange={handleOnInput}
                    disabled
                    placeholder="Contract Address*"
                  />
                </div>

                <div className="form-group">
                  <textarea
                    maxlength="10000"
                    name='description'
                    value={description}
                    placeholder="Description*"
                    onChange={handleOnInput}
                  />
                </div>
              </div>

              <div className="btn-wrap">
                <div>

                </div>
                <button className="btn next" onClick={handleNextStep}>
                  Next <img src={angleright} alt="" />
                </button>

              </div>
            </div>
          </div>

        );
      case 2:
        return (
          <div>
            <div className="mb-10">
              <div className="progress-head">
                <h3>Token metrics</h3>

                <span>
                  Step {step} of {totalSteps}
                </span>
              </div>

              <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
              </div>
            </div>

            <div className="step">
              <div className="form-wrap dir-col">
                <div className="form-group">
                  <input
                    type="text"
                    name='supply'
                    value={supply}
                    placeholder="Token Supply*"
                    onChange={handleOnInput}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name='fundSale'
                    value={fundSale}
                    placeholder="Funds sale"
                    onChange={handleOnInput}
                  />
                </div>
              </div>

              <div className="btn-wrap">
                <Link className="btn back" onClick={handlePreviousStep}>
                  <img src={angleleft} alt="" /> Back
                </Link>

                <button className="btn next" onClick={handleNextStep}>
                  Next <img src={angleright} alt="" />
                </button>

              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="mb-10">
              <div className="progress-head">
                <h3>Vesting detail</h3>

                <span>
                  Step {step} of {totalSteps}
                </span>
              </div>

              <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
              </div>
            </div>

            <div className="step">
              <div className="form-wrap dir-col no-height">
                <div className="form-group">
                  <input
                    type="text"
                    name='vinfo'
                    value={vinfo}
                    onChange={handleOnInput}
                    placeholder="Info"
                  />
                </div>

                {/* <div className="form-group date-wrap">
                  <label>Initial release date</label>
                  <input
                    type="datetime-local"
                    name='vinfo'
                    value={vinfo}
                    onChange={handleOnInput}
                    placeholder="Initial release date"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Initial release %age"
                  />
                </div> */}

                <div className="form-group date-wrap">
                  <label>Vesting Start date</label>
                  <input
                    type="datetime-local"
                    name='vstartdate'
                    value={vstartdate}
                    onChange={handleOnInput}
                    placeholder="Vesting Start date"
                  />
                </div>

                <div className="form-group date-wrap">
                  <label>Vesting End date</label>
                  <input
                    type="datetime-local"
                    name='venddate'
                    value={venddate}
                    onChange={handleOnInput}
                    placeholder="Vesting End date"
                  />
                </div>

                <div className="swap">
                  <div className="form-group">
                    <div className='input-group'>
                      <input
                        onWheel={handleOnWheel}
                        type="number"
                        name='vcliff'
                        value={vcliff}
                        onChange={handleOnInput}
                        placeholder="Cliff length"
                      />
                      <div className="network-dropdown">
                        <select disabled>
                          <option>month</option>
                          <option>Jan</option>
                          <option>Feb</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>Sep</option>
                          <option>Oct</option>
                          <option>Nov</option>
                          <option>Dec</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className='input-group'>
                      <input
                        onWheel={handleOnWheel}
                        type="number"
                        name='vlength'
                        value={vlength}
                        onChange={handleOnInput}
                        placeholder="Vesting length"
                      />
                      <div className="network-dropdown">
                        <select disabled>
                          <option>Year</option>
                          <option>2024</option>
                          <option>2025</option>
                          <option>2026</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn-wrap">
                <Link className="btn back" onClick={handlePreviousStep}>
                  <img src={angleleft} alt="" /> Back
                </Link>

                <button className="btn next" onClick={handleNextStep}>
                  Next <img src={angleright} alt="" />
                </button>

              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <div className="mb-10">
              <div className="progress-head">
                <h3>Team</h3>

                <span>
                  Step {step} of {totalSteps}
                </span>
              </div>

              <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
              </div>
            </div>

            <div className="step">
              <div className="form-wrap dir-col no-height">
                {formValues.map((element, index) =>
                  <div key={index}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="mtitle"
                        value={element.mtitle || ""}
                        placeholder="Add member designation"
                        maxlength="50"
                        onChange={e => handleChange(index, e)} />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="mname"
                        value={element.mname || ""}
                        placeholder="Add member name"
                        maxlength="50"
                        onChange={e => handleChange(index, e)} />
                    </div>

                    <div className="form-group">
                      <input
                        type="url"
                        name="mlinkedin"
                        value={element.mlinkedin || ""}
                        placeholder="Add Linkedin"
                        maxlength="50"
                        onChange={e => handleChange(index, e)} />
                    </div>

                    <div className="form-group filewrap">
                      <span>{element.image ? (element.image['name'] && element.image['name'].substring(0, 20) + '...') : "Upload Image"}</span>
                      <div className="upload-btn">Choose image</div>
                      <input
                        type="file"
                        name="image"
                        placeholder={element.image ? (element.image['name'] && element.image['name'].substring(0, 20) + '...') : "No image chosen"}
                        accept="image/*"
                        onChange={e => handleChange(index, e)}
                      />
                    </div>
                  </div>
                )}

                <div className="form-group addmore">
                  <button onClick={(event) => {
                    event.preventDefault();
                    addFormFields();
                  }}>
                    Add more
                    <img src={addmore} alt="" />
                  </button>
                </div>
              </div>

              <div className="btn-wrap">
                <Link className="btn back" onClick={handlePreviousStep}>
                  <img src={angleleft} alt="" /> Back
                </Link>

                <button className="btn next" onClick={handleNextStep}>
                  Next <img src={angleright} alt="" />
                </button>

              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <div className="mb-10">
              <div className="progress-head">
                <h3>Documents</h3>

                <span>
                  Step {step} of {totalSteps}
                </span>
              </div>

              <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
              </div>
            </div>

            <div className="step">
              <div className="form-wrap dir-col no-height">
                <div className="form-group filewrap">
                  <span>{whitepaperDoc ? (whitepaperDoc['name'] && whitepaperDoc['name'].substring(0, 20) + '...') : "Upload white paper"}</span>
                  <div className="upload-btn">Choose file</div>

                  <input
                    type="file"
                    name='whitepaperDoc'
                    placeholder={whitepaperDoc ? (whitepaperDoc['name'] && whitepaperDoc['name'].substring(0, 20) + '...') : "No file chosen"}
                    onChange={handleOnInput}
                    accept="application/pdf"
                  />
                </div>

                <div className="form-group filewrap">
                  <span>{vestingDoc ? (vestingDoc['name'] && vestingDoc['name'].substring(0, 20) + '...') : "Upload vesting schedule"}</span>
                  <div className="upload-btn">Choose file</div>
                  <input
                    type="file"
                    name='vestingDoc'
                    placeholder={vestingDoc ? (vestingDoc['name'] && vestingDoc['name'].substring(0, 20) + '...') : "No file chosen"}
                    onChange={handleOnInput}
                    accept="application/pdf"
                  />
                </div>

                <div className="form-group filewrap">
                  <span>{privacyDoc ? (privacyDoc['name'] && privacyDoc['name'].substring(0, 20) + '...') : "Upload privacy policy"}</span>
                  <div className="upload-btn">Choose file</div>
                  <input
                    type="file"
                    name='privacyDoc'
                    placeholder={privacyDoc ? (privacyDoc['name'] && privacyDoc['name'].substring(0, 20) + '...') : "No file chosen"}
                    onChange={handleOnInput}
                    accept="application/pdf"
                  />
                </div>
              </div>

              <div className="btn-wrap">
                <Link className="btn back" onClick={handlePreviousStep}>
                  <img src={angleleft} alt="" /> Back
                </Link>

                <button className="btn next" onClick={handleNextStep}>
                  Next <img src={angleright} alt="" />
                </button>

              </div>
            </div>
          </div>
        );
      case 6:
        return (
          <div>
            <div className="mb-10">
              <div className="progress-head">
                <h3>Social links</h3>

                <span>
                  Step {step} of {totalSteps}
                </span>
              </div>

              <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
              </div>
            </div>

            <div className="step">
              <div className="form-wrap dir-col no-height">
                <div className="form-group">
                  <input
                    type="url"
                    name='website'
                    value={website}
                    onChange={handleOnInput}
                    placeholder="Website"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="url"
                    name='telegram'
                    value={telegram}
                    onChange={handleOnInput}
                    placeholder="Telegram"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="url"
                    name='discord'
                    value={discord}
                    onChange={handleOnInput}
                    placeholder="Discord"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="url"
                    name='medium'
                    value={medium}
                    onChange={handleOnInput}
                    placeholder="Medium"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="url"
                    name='twitter'
                    value={twitter}
                    onChange={handleOnInput}
                    placeholder="Twitter"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="url"
                    name='linkedin'
                    value={linkedin}
                    onChange={handleOnInput}
                    placeholder="Linkedin"
                  />
                </div>
              </div>

              <div className="btn-wrap">
                <Link className="btn back" onClick={handlePreviousStep}>
                  <img src={angleleft} alt="" /> Back
                </Link>

                <button className="btn next" onClick={submitProjectDetails}>
                  Publish
                </button>

              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  console.log("***** kybStatus :: ", props.kybStatus);

  return (
    <div className="microeconomy-wrapper dao-genesis launch-dao">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={DaoGenesislogo} alt="" />
          <span>Project Details</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {/* {
          (title !== "" || description !== "" || tokenAddress !== "") &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        } */}
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />
      </div>

      <div className="steps-form-wrapper">

        {renderStep()}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  getXRC20,
  getXRC721,
  setLoader,
  setBalance,
  getKybStatus,
  setGrootBalance,
  addProjectDetails
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, isLoader, kybStatus, clearProjectDetail, xrc20Collections, xrc721Collections } = Auth;
  return { publicAddress, isLoader, kybStatus, clearProjectDetail, xrc20Collections, xrc721Collections }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailSteps);