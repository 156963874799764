import { PURGE } from "redux-persist";
import { setToken } from "../axios";

var initialState = {
  exhcnageGraph: [],
  pairDetails: {},
  pairBalance: {},
  pairsData: [],
  userOrders: [],
  updateExchange: []
};

const Sockets = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    /*========== LOGIN REDUCERS ============= */

    case "SET_EXCHANGE_GRAPH":
      return {
        ...state,
        exhcnageGraph: payload
      };

    case "SET_EXCHANGE_PAIRS_DETAILS":
      return {
        ...state,
        pairDetails: payload
      }

    case "UPDATE_EXCHANGE_GRAPH":
      return {
        ...state,
        updateExchange: payload
      }

    case "SET_PAIRS_BALANCE":
      return {
        ...state,
        pairBalance: payload
      }

    case "SET_PAIRS_DATA":
      return {
        ...state,
        pairsData: payload
      }

    case "SET_ALL_USER_ORDERS":
      return {
        ...state,
        userOrders: payload
      }

    default:
      return state;
  }
};

export default Sockets;
