import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import Sockets from './Sockets';
import Events from './Events';
export default function* rootSaga() {
  yield all([
    authSagas(),
    Events(),
    Sockets(),
  ]);
}

