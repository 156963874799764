import Prompt from "../use-prompt";
import { Veriff } from '@veriff/js-sdk';
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Form, Button } from 'react-bootstrap';
import { Modal } from "react-responsive-modal";
import goarrow from "../../images/go-arrow.png";
import modalcloseicon from "../../images/close.png";
import uploadicon from "../../images/uploadicon.png";
import infocircle from "../../images/infocircle.svg";
import deploytoken from "../../images/deploytoken.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import microeconomylogo from "../../images/microeconomy-icon.png";
import tokengenerationstepinfo from "../../images/token-generation-step-info.png";
import createnftstepinfo from "../../images/create-nft-step-info.png";
import deployvestingstepinfo from "../../images/deploy-vesting-step-info.png";
import { uploadCollection, setBalance, setGrootBalance, kycSubmit, getKycStatus, setLoader, kybSubmit, getKybStatus } from "../../store/actions/Auth";
import { CiFiAccessNFTAddress, CiFiAccessNFTContract, CiFiAccessNFTABI, AdminWhitelist, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

const Verification = (props) => {

  const [isNFT, setIsNFT] = useState();
  const [isWhitelist, setIsWhitelist] = useState(false);
  const ownerSocialNoRef = useRef(null);
  const businessIdRef = useRef(null);
  const businessEmailRef = useRef(null);
  const businessTitleRef = useRef(null);
  const securityNumberRef = useRef(null);
  const websiteRef = useRef(null);
  const facebookRef = useRef(null);
  const linkedinRef = useRef(null);
  const twitterRef = useRef(null);
  const businessEINRef = useRef(null);
  const businessDocumentRef = useRef(null);

  const navigate = useNavigate();

  setTimeout(() => {
    const modalRoot = document.querySelectorAll('.react-responsive-modal-root');
    if (modalRoot) {
      //Add a custom class to the root element

      modalRoot.forEach((element) => {
        const stepsModal = element.querySelector('.steps-modal');
        const confirmation1Modal = element.querySelector('.confirmation-modal');

        if (stepsModal) {
          // Perform your action here
          element.classList.add('custom-modal-root');
        }

        if (confirmation1Modal) {
          // Perform your action here
          element.classList.add('confirmation-modal-root');
        }
      });

    }
    else {
      console.error("Element not found.");
    }

  }, 1000);

  useEffect(() => {
    if (props.kycStatus == false && props.publicAddress) {
      // if (props.kycStatus == false && props.publicAddress && isWhitelist == true) {
      veriffStart();
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
      var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
      // Check if the element exists before attempting to set its value
      if (veriffVendorDataInput) {
        // Set the value of the input field
        veriffVendorDataInput.value = props.publicAddress.toLowerCase();
      }
    }
    else if (props.kycStatus == "expired" && props.publicAddress) {
      // else if (props.kycStatus == "expired" && props.publicAddress && isWhitelist == true) {
      veriffStart();
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
      var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
      // Check if the element exists before attempting to set its value
      if (veriffVendorDataInput) {
        // Set the value of the input field
        veriffVendorDataInput.value = props.publicAddress.toLowerCase();
      }
    }
    // else if (props.kycStatus == false && props.publicAddress) {
    // // } else if (props.kycStatus == false && props.publicAddress && isNFT && isNFT >= 1) {
    //   veriffStart();
    //   $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
    //   var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
    //   // Check if the element exists before attempting to set its value
    //   if (veriffVendorDataInput) {
    //     // Set the value of the input field
    //     veriffVendorDataInput.value = props.publicAddress.toLowerCase();
    //   }
    // }
    // else if (props.kycStatus == "expired" && props.publicAddress) {
    //   // else if (props.kycStatus == "expired" && props.publicAddress && isNFT && isNFT >= 1) {
    //   veriffStart();
    //   $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
    //   var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
    //   // Check if the element exists before attempting to set its value
    //   if (veriffVendorDataInput) {
    //     // Set the value of the input field
    //     veriffVendorDataInput.value = props.publicAddress.toLowerCase();
    //   }
    // }

  }, [props.kycStatus, props.publicAddress]);

  const getNFT = async () => {
    // if (CiFiAccessNFTABI && CiFiAccessNFTAddress) {
    //   props.setLoader({ message: "Load Microeconomy...", status: true });
    //   let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
    //   if (newCiFiAccessNFTContract) {
    //     let tokenBalance = await newCiFiAccessNFTContract.methods.balanceOf(props.publicAddress).call({ from: props.publicAddress });
    //     // tokenBalance =  1; //tesing purpose
    //     setIsNFT(tokenBalance);
    //     if (Number(tokenBalance) >= 1) {
    //       props.getKybStatus({ publicAddress: props.publicAddress })
    //       props.getKycStatus({ publicAddress: props.publicAddress })
    //     }
    //     else props.setLoader({ message: "Load Microeconomy...", status: false });
    //   }
    // }

    // if (AdminWhitelistABI && AdminWhitelistAddress) {
    //   props.setLoader({ message: "Load Microeconomy...", status: true });
    //   let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
    //   if (newAdminWhitelist) {
    //     let status = await newAdminWhitelist.methods.isWhitelist(props.publicAddress).call({ from: props.publicAddress });
    //     // status =  true; //tesing purpose
    //     setIsWhitelist(status);
    //     if (status == true) {
    //       props.getKybStatus({ publicAddress: props.publicAddress })
    //       props.getKycStatus({ publicAddress: props.publicAddress })
    //     }
    //     else props.setLoader({ message: "Load Microeconomy...", status: false });
    //   }
    // }
    props.setLoader({ message: "Load Microeconomy...", status: true });
    props.getKybStatus({ publicAddress: props.publicAddress });
    props.getKycStatus({ publicAddress: props.publicAddress });
  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      getNFT();
    }
  }, [props.publicAddress, web3]);

  const veriffStart = async () => {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: process.env.REACT_APP_VERIFF,
      parentId: 'veriff-root',
      onSession: async function (err, response) {
        await waitFor(1500);
        let email = document.getElementById('veriff-given-email').value;
        if (!email) return
        let id = response.verification.id;
        let vendorData = response.verification.vendorData;
        let url = response.verification.url;
        let status = response.verification.status;
        let session = response.verification.sessionToken;
        vendorData = vendorData.toLowerCase();
        if (id && vendorData && url && status && session) {
          props.kycSubmit({ id, email, vendorData, url, status, session });
          await waitFor(1500);
          window.location.href = response.verification.url;
        }
      }
    });
    veriff.mount({
      formLabel: {
        givenName: 'First Name',
        lastName: 'Last Name',
        vendorData: 'Metamask Wallet Address'
      },
    });
  }

  const submitKYBForm = (e) => {
    e.preventDefault();

    // Retrieve values using refs
    const businessEmail = businessEmailRef.current.value;
    // const businessId = businessIdRef.current.value;
    const businessTitle = businessTitleRef.current.value;
    const securityNumber = securityNumberRef.current.value;
    const website = websiteRef.current.value;
    const facebook = facebookRef.current.value;
    const linkedin = linkedinRef.current.value;
    const twitter = twitterRef.current.value;
    const ein = businessEINRef.current.value
    const businessDocument = businessDocumentRef.current.files[0];

    if (!businessEmail.trim()) return EventBus.publish("error", "Please enter your business email");
    if (!businessTitle.trim()) return EventBus.publish("error", "Please enter your business title");
    if (!ein.trim()) return EventBus.publish("error", "Please enter your business EIN number");
    // if(!businessId.trim()) return EventBus.publish("error", "Please enter your business id");
    if (!securityNumber.trim()) return EventBus.publish("error", "Please enter your security number");
    if (!businessDocument) return EventBus.publish("error", "Please submit you business document in PDF formate");
    if (!businessDocument.type.includes("pdf")) return EventBus.publish("error", "Please submit you business document in PDF formate");

    props.setLoader({ message: "KYB Submitting...", status: true });
    const formData = new FormData();
    formData.append('document', businessDocument);
    formData.append('email', businessEmail);
    formData.append('publicAddress', props.publicAddress);
    formData.append('ein', ein);
    formData.append('title', businessTitle);
    formData.append('ownerSocialNo', securityNumber);
    formData.append('website', website);
    formData.append('facebook', facebook);
    formData.append('linkedin', linkedin);
    formData.append('twitter', twitter);
    props.kybSubmit(formData)
  }

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }


  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />
      </div>
      {props.publicAddress ?
        // isWhitelist == true ?
        // (isWhitelist == true) || (isNFT && isNFT >= 1) ?
        // props.kycStatus !== "created" && props.kycStatus !== "approved" ?
        <div className="form-wrap min">
          <div className="inner">
            <div className="kyc-kyb-tabs">
              <ul className="nav nav-tabs" id="tab1" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="kyc-tab" data-toggle="tab" href="#kyc" role="tab" aria-controls="kyc" aria-selected="true">KYC</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="kyb-tab" data-toggle="tab" href="#kyb" role="tab" aria-controls="kyb" aria-selected="false">KYB</a>
                </li>
              </ul>
              <div className="tab-pane fade show active" id="kyc" role="tabpanel" aria-labelledby="kyc-tab">
                {/* Kyc code */}
                {props.kycStatus == false &&
                  <>
                    <h2>Verification</h2>
                    <p className="mb-4 left">Verify your Identity to access the features of Microeconomy.</p>
                    <div className="veriff-wrap">
                      <div id='veriff-root' className="edit-add-body col-12"></div>
                    </div>
                  </>
                }

                {props.kycStatus == "expired" &&
                  <>
                    <h2>Verification</h2>
                    <p className="mb-4 left">Verify your Identity to access the features of Microeconomy.</p>
                    <div className="veriff-wrap">
                      <div id='veriff-root' className="edit-add-body col-12"></div>
                    </div>
                  </>
                }

                {props.kycStatus == "created" &&
                  <p className="text-center">Verification Already Submitted, It will take 24Hrs to process your verification.</p>
                }
                {props.kycStatus == "approved" &&
                  <p className="text-center">Verification Already Submitted, It will take 24Hrs to process your verification.</p>
                }
                {props.kycStatus == "adminApproved" &&
                  <p className="text-center">Your KYC is approved</p>
                }
              </div>
              <div className="tab-pane fade" id="kyb" role="tabpanel" aria-labelledby="kyb-tab">
                {props.kybStatus == "false" ?
                  <>
                    <h2>Register your business</h2>
                    <form onSubmit={submitKYBForm}>
                      <div className="form-group">
                        <input
                          type="text"
                          name='businessEmail'
                          // onChange={handleOnInput}
                          ref={businessEmailRef}
                          placeholder="Business email"
                        />

                        <div className="info-wrap">
                          <img src={infocircle} alt="" />

                          <div className="info">

                            <p>Enter Your Business Email Here</p>
                          </div>
                        </div>
                      </div>


                      <div className="form-group">
                        <input
                          type="text"
                          name='businessTitle'
                          // onChange={handleOnInput}
                          ref={businessTitleRef}
                          placeholder="Business title"
                        />

                        <div className="info-wrap">
                          <img src={infocircle} alt="" />

                          <div className="info">

                            <p>Enter Your Business Title Here</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          name='businessEIN'
                          // onChange={handleOnInput}
                          ref={businessEINRef}
                          placeholder="Business EIN number"
                        />

                        <div className="info-wrap">
                          <img src={infocircle} alt="" />

                          <div className="info">

                            <p>Enter Your Business EIN Here</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          type="number"
                          name='securityNumber'
                          ref={securityNumberRef}
                          placeholder="Social security number"
                          onWheel={handleOnWheel}
                        />

                        <div className="info-wrap">
                          <img src={infocircle} alt="" />

                          <div className="info">

                            <p>Enter Business Owner Social Security Number Here</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          type="url"
                          name='website'
                          placeholder="Website"
                          ref={websiteRef}
                        />

                        <div className="info-wrap">
                          <img src={infocircle} alt="" />

                          <div className="info">

                            <p>Insert Your Website Link Here</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          type="url"
                          name='facebook'
                          placeholder="Facebook"
                          ref={facebookRef}
                        />

                        <div className="info-wrap">
                          <img src={infocircle} alt="" />

                          <div className="info">

                            <p>Insert Your Facebook Link Here</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          type="url"
                          name='linkedin'
                          placeholder="Linkedin"
                          ref={linkedinRef}
                        />

                        <div className="info-wrap">
                          <img src={infocircle} alt="" />

                          <div className="info">

                            <p>Insert Your Linkedin Link Here</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          type="url"
                          name='twitter'
                          placeholder="Twitter"
                          ref={twitterRef}
                        />

                        <div className="info-wrap">
                          <img src={infocircle} alt="" />

                          <div className="info">

                            <p>Insert Your Twitter Link Here</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group filewrap">
                        <span>Business document</span>
                        <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload PDF File</div>
                        <input
                          type="file"
                          name='businessDocument'
                          placeholder="Upload PDF File*"
                          accept=".pdf"
                          ref={businessDocumentRef}
                        />

                        <div className="info-wrap">
                          <img src={infocircle} alt="" />

                          <div className="info">

                            <p>Upload Your Business Document Here</p>
                          </div>
                        </div>
                      </div>

                      <button type="submit">
                        Submit
                      </button>
                    </form>
                    {/* <p>Note: You will need 1000 Groot Tokens for one time subscription to add staking to your collection.</p>                 */}
                  </>
                  :
                  props.kybStatus == "approved" ?
                    <p className="text-center">Your KYB is approved</p>
                    :
                    <p className="text-center">Verification Already Submitted, It will take 24Hrs to process your verification.</p>
                }

              </div>
            </div>
          </div>
        </div>
        // :
        // <div className="form-wrap min">
        //   <div className="inner">
        //     <h2 style={{ textAlign: "center" }}>NFT Not Found</h2>
        //     {/* <p className="mb-4 left">Verify your Identity to access the features of Microeconomy.</p> */}
        //   </div>
        // </div>
        :
        <div className="form-wrap min">
          <div className="inner">
            <h2>Wallet Not Connected</h2>
            {/* <p className="mb-4 left">Verify your Identity to access the features of Microeconomy.</p> */}
          </div>
        </div>
      }
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  uploadCollection,
  setBalance,
  setGrootBalance,
  kycSubmit,
  getKycStatus,
  getKybStatus,
  kybSubmit
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, currentMode, isLoader, kycStatus, kycValue, kycSubmitted, kycGStatus, kybStatus } = Auth;
  return { publicAddress, currentMode, isLoader, kycStatus, kycValue, kycSubmitted, kycGStatus, kybStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
