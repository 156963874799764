import React from "react";
import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { web3 } from "../../store/web3";
import copy from "../../images/copy.png";
import BNBLogo from "../../images/bnb.png";
import XDCLogo from "../../images/xdc.png";
import Matic from "../../images/matic.png";
import "react-responsive-modal/styles.css";
import close from "../../images/close.png";
import { useState, useEffect } from "react";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import ethicon from "../../images/eth-icon.svg";
import goarrow from "../../images/go-arrow.png";
import usdcicon from "../../images/usdc-icon.svg";
import { toFixed } from "../../store/numberFormat";
import UnknownLogo from "../../images/bookmark.png";
import arrowswap from "../../images/arrow-swap.svg";
import modalcloseicon from "../../images/close.png";
import settingicon from "../../images/setting-2.png";
import tickcircle from "../../images/tickcircle.png";
import swapicon from "../../images/swap-icon-new.png";
import errorcircle from "../../images/error-icon.svg";
import { setLoader, getKybStatus, getKycStatus, setBalance, setGrootBalance } from "../../store/actions/Auth.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import brownarrow from "../../images/gradient-arrow-right.png";
import { networkId, GrootRouter, GrootRouterAddress, XRC20ABI, GrootPairABI, GrootFactory, CIFI, CiFiAccessNFTAddress, CiFiAccessNFTContract, CiFiAccessNFTABI, AdminWhitelist, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";


function Swap(props) {

  const tokenImages = {
    'XDC': XDCLogo,
    'WXDC': XDCLogo,
    'BNB': BNBLogo,
    'WBNB': BNBLogo,
    'Matic': Matic,
    'WMATIC': Matic,
    'Default': UnknownLogo
    // ... add more tokens as needed
  };

  const [addliquidityModal, setAddliquidityModal] = useState(false);
  const [removeliquidityModal, setRemoveliquidityModal] = useState(false);
  const [microeconomyErrorModal, setMicroeconomyErrorModal] = useState(false);
  const [microeconomySuccessModal, setMicroeconomySuccessModal] = useState(false);

  let [isNFT, setIsNFT] = useState();
  let [isWhitelist, setIsWhitelist] = useState(false);

  const [swapModal1, setSwapModal1] = useState(false);
  const [swapModal2, setSwapModal2] = useState(false);
  const [poolModal1, setPoolModal1] = useState(false);
  const [poolModal2, setPoolModal2] = useState(false);
  const [swapValue1, setSwapValue1] = useState("XDC");
  const [swapValue2, setSwapValue2] = useState("XDC");
  const [poolValue1, setPoolValue1] = useState("XDC");
  const [poolValue2, setPoolValue2] = useState("XDC");

  const [slippageValue, setSlippageValue] = useState(0.3);
  const [iDeadlineValue, setIDeadlineValue] = useState(30);
  const [deadlineValue, setDeadlineValue] = useState(moment(Date.now()).add(30, 'm').toDate());

  let [selectSwapToken1, setSelectSwapToken1] = useState("");
  let [selectSwapToken2, setSelectSwapToken2] = useState("");

  let [selectPoolToken1, setSelectPoolToken1] = useState("");
  let [selectPoolToken2, setSelectPoolToken2] = useState("");

  const [showSettings, setShowSettings] = useState(false);

  const [txHash, setTxHash] = useState("");

  let [primaryToken, setPrimaryToken] = useState("");
  let [secondaryToken, setSecondaryToken] = useState("");
  let [primaryAmount, setPrimaryAmount] = useState("");
  let [secondaryAmount, setSecondaryAmount] = useState("");
  let [primaryAmountP, setPrimaryAmountP] = useState("");
  let [liquidityAmount, setLiquidityAmount] = useState("");
  let [liquidityAmountActual, setLiquidityAmountActual] = useState(0);
  let [secondaryAmountP, setSecondaryAmountP] = useState("");
  let [primaryAmountPMin, setPrimaryAmountPMin] = useState("");
  let [secondaryAmountPMin, setSecondaryAmountPMin] = useState("");
  let [pSymbol, setPSymbol] = useState("");
  let [sSymbol, setSSymbol] = useState("");
  let [token, setToken] = useState("XDC");
  let [wToken, setWToken] = useState("WXDC");

  // START Query for all available pairs  
  const [pairs, setPairs] = useState([]);
  // Storing sepparate tokens on an array
  const [tokenList, setTokenList] = useState([]);
  const [tokenList1, setTokenList1] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const uniqueToken0Symbols = new Set(); // Helper to keep track of unique symbols
  const uniqueToken1Symbols = new Set(); // Helper to keep track of unique symbols

  const getAllUniqueTokens = (tokens) => {
    const tokenMap = new Map();

    tokens.forEach(token => {
      // If the map already has the token address, we skip it, otherwise we add it
      if (!tokenMap.has(token.address)) {
        tokenMap.set(token.address, token);
      }
    });
    // Convert the map values back to an array
    return Array.from(tokenMap.values());
  };

  const uniqueTokens = getAllUniqueTokens(tokenList);

  // Filter pairs based on the search term and removes repeated tokens
  const filteredPairs = tokenList
    .filter(pair => {
      const includesSearchTerm = pair.symbol.includes(primaryToken);
      const isUnique = !uniqueToken0Symbols.has(pair.symbol);
      if (includesSearchTerm && isUnique) {
        // console.log("PAIR= " + pair.symbol)
        uniqueToken0Symbols.add(pair.symbol);
        return true;
      }
      return false;
    });

  // Filter pairs based on the search term and removes repeated tokens
  const filteredPairs2 = tokenList1
    .filter(pair => {
      const includesSearchTerm = pair.symbol.includes(secondaryToken);
      const isUnique = !uniqueToken1Symbols.has(pair.symbol);
      if (includesSearchTerm && isUnique) {
        // console.log("PAIR= " + pair.symbol)
        uniqueToken1Symbols.add(pair.symbol);
        return true;
      }
      return false;
    });

  async function getPairs() {

    // Create a contract instance
    // const factoryContract = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

    try {
      // Get the total number of pairs from the factory contract
      const totalPairs = await GrootFactory.methods.allPairsLength().call();
      // console.log(`Total number of pairs: ${totalPairs}`);

      // Iterate over the pairs and get their addresses
      const pairDetailsPromises = [];
      for (let i = 0; i < totalPairs; i++) {
        const pairAddress = await GrootFactory.methods.allPairs(i).call();
        pairDetailsPromises.push(getPairDetails(pairAddress));
        // console.log(`Pair #${i}: ${getPairDetails(pairAddress)}`);
        // Further calls can be made here to get details about each pair
      }

      const pairsDetails = await Promise.all(pairDetailsPromises);
      pairsDetails.forEach((details, index) => {
        // console.log(`Pair #${index}:`, details);
        const token0 = {
          address: details.token0Address,
          name: details.token0Name,
          symbol: details.token0Symbol,
          reserve: details.reserve0,
        };

        // Create an object for token1
        const token1 = {
          address: details.token1Address,
          name: details.token1Name,
          symbol: details.token1Symbol,
          reserve: details.reserve1,
        };

        // Add the tokens to the array
        tokenList.push(token0, token1);
        tokenList1.push(token0, token1);

        // console.log(`tokenList `, tokenList);

      });
      return pairsDetails;
    } catch (error) {
      console.error('Error fetching pairs:', error);
      return [];
    }
  }

  async function getPairDetails(pairAddress) {
    const pairContract = new web3.eth.Contract(GrootPairABI, pairAddress);

    // Get reserves and other details
    const reserves = await pairContract.methods.getReserves().call();
    const token0Address = await pairContract.methods.token0().call();
    const token1Address = await pairContract.methods.token1().call();

    // Create contract instances for token0 and token1
    const token0Contract = new web3.eth.Contract(XRC20ABI, token0Address);
    const token1Contract = new web3.eth.Contract(XRC20ABI, token1Address);

    // Fetch the names and symbols of token0 and token1
    const token0Name = await token0Contract.methods.name().call();
    const token0Symbol = await token0Contract.methods.symbol().call();
    const token1Name = await token1Contract.methods.name().call();
    const token1Symbol = await token1Contract.methods.symbol().call();

    return {
      pairAddress,
      reserve0: reserves._reserve0,
      reserve1: reserves._reserve1,
      token0Address,
      token1Address,
      token0Name,
      token0Symbol,
      token1Name,
      token1Symbol,
      // ... include other details here
    };
  }

  const renderPairs = () => {
    return uniqueTokens.map((token, index) => {
      // logic to select the correct image based on the symbol
      const imageSrc = getTokenImage(token.symbol);

      return (
        <div key={index} onClick={() => handlePoolValue1(token)} className="input-info">
          <span>
            <img src={imageSrc} className="w-5 h-5" alt={token.symbol} />
          </span>
          <p>{token.name} ({token.symbol})</p>
        </div>
      );
    });
  };

  const getTokenImage = (tokenSymbol) => {
    // Default image if the token symbol is not found in the mapping
    // const defaultImage = XDCLogo;
    const defaultImage = UnknownLogo;

    // Return the image URL from the mapping or the default image if not found
    return tokenImages[tokenSymbol] || defaultImage;
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  }

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  const getNFTs = async () => {
    if (CiFiAccessNFTABI && CiFiAccessNFTAddress) {
      props.setLoader({ message: "Load Microeconomy...", status: true });
      let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
      if (newCiFiAccessNFTContract) {
        let tokenBalance = await newCiFiAccessNFTContract.methods.balanceOf(props.publicAddress).call({ from: props.publicAddress });
        // tokenBalance =  1; //tesing purpose
        setIsNFT(tokenBalance);
        if (Number(tokenBalance) >= 1) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Load Microeconomy...", status: false });
      }
    }

    if (AdminWhitelistABI && AdminWhitelistAddress) {
      props.setLoader({ message: "Loading...", status: true });
      let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
      if (newAdminWhitelist) {
        let status = await newAdminWhitelist.methods.isWhitelist(props.publicAddress).call({ from: props.publicAddress });
        // status =  true; //tesing purpose
        setIsWhitelist(status);
        if (status == true) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Loading...", status: false });
      }
    }

  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      getNFTs();
    }
  }, [props.publicAddress, web3]);

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  useEffect(() => {
    if ((networkId == 80001 || networkId == 137) && web3) {
      getPairs().then(setPairs);
      setToken("MATIC");
      setWToken("WMATIC");
      setPSymbol("MATIC");
      setSwapValue1("MATIC");
      setSwapValue2("MATIC");
      setPoolValue1("MATIC");
      setPoolValue2("MATIC");

      setSelectSwapToken1("MATIC");
      setSelectSwapToken2("");

      setSelectPoolToken1("MATIC");
      setSelectPoolToken2("");
    } else if ((networkId == 97 || networkId == 56) && web3) {
      getPairs().then(setPairs);
      setToken("BNB");
      setWToken("WBNB");
      setPSymbol("BNB");
      setSwapValue1("BNB");
      setSwapValue2("BNB");
      setPoolValue1("BNB");
      setPoolValue2("BNB");

      setSelectSwapToken1("BNB");
      setSelectSwapToken2("");

      setSelectPoolToken1("BNB");
      setSelectPoolToken2("");
    } else if ((networkId == 51 || networkId == 50) && web3) {
      getPairs().then(setPairs);
      setToken("XDC");
      setWToken("WXDC");
      setPSymbol("XDC");
      setSwapValue1("XDC");
      setSwapValue2("XDC");
      setPoolValue1("XDC");
      setPoolValue2("XDC");

      setSelectSwapToken1("XDC");
      setSelectSwapToken2("");

      setSelectPoolToken1("XDC");
      setSelectPoolToken2("");
    }
  }, [networkId, web3]);

  const incrementCount = () => {
    if (parseFloat(primaryAmountPMin) > 0) {
      setPrimaryAmountPMin(parseFloat(primaryAmountPMin) + 1);
    } else {
      setPrimaryAmountPMin("");
    }
  };

  const decrementCount = () => {
    if (parseFloat(primaryAmountPMin) > 0) {
      setPrimaryAmountPMin(parseFloat(primaryAmountPMin) - 1);
    } else {
      setPrimaryAmountPMin("");
    }
  };

  const increment = () => {
    if (parseFloat(secondaryAmountPMin) > 0) {
      setSecondaryAmountPMin(parseFloat(secondaryAmountPMin) - 1);
    } else {
      setSecondaryAmountPMin("");
    }
  };

  const decrement = () => {
    if (parseFloat(secondaryAmountPMin) > 0) {
      setSecondaryAmountPMin(parseFloat(secondaryAmountPMin) - 1);
    } else {
      setSecondaryAmountPMin("");
    }
  };

  async function handleSlippage(e) {
    if ([e.target.name] == "slippageValue") {
      if (Number(e.target.value) > 60) {
        EventBus.publish("error", `Slippage cannot be larger than 60%!`);
        return;
      } else {
        if (e.target.value === "") {
          EventBus.publish("info", `Slippage should be minimum at 0.3%`);
          setSlippageValue(e.target.value);
        } else {
          setSlippageValue(e.target.value);
        }
      }
    }

    if ([e.target.name] == "iDeadlineValue") {
      if (e.target.value === "") {
        setIDeadlineValue(30);
      } else {
        setIDeadlineValue(parseInt(e.target.value));
        let newDateObj = moment(Date.now()).add(parseInt(e.target.value), 'm').toDate();
        setDeadlineValue(parseFloat(newDateObj));
      }
    }
  }

  async function autoSlippage(e) {
    setSlippageValue(parseFloat(0.3));
  }

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "primaryAmountP") {
      if (parseFloat(e.target.value) > 0) {
        setPrimaryAmountP(parseFloat(e.target.value));
      } else {
        setPrimaryAmountP("");
      }
    } else if ([e.target.name] == "secondaryAmountP") {
      if (parseFloat(e.target.value) > 0) {
        setSecondaryAmountP(parseFloat(e.target.value));
      } else {
        setSecondaryAmountP("");
      }
    } else if ([e.target.name] == "liquidityAmount") {
      if ((selectPoolToken1 !== "" || primaryToken !== "") && (selectPoolToken2 !== "" || secondaryToken !== "")) {
        if (parseFloat(e.target.value) > 0) {
          if (parseFloat(e.target.value) < parseFloat(liquidityAmountActual)) {
            setLiquidityAmount(parseFloat(e.target.value));
          } else {
            EventBus.publish("error", `You have not added this amount of liquidity!`);
            return;
          }
        } else {
          setLiquidityAmount("");
        }
      } else {
        EventBus.publish("error", `Please select token pair first!`);
        return;
      }
    } else if ([e.target.name] == "primaryAmountPMin") {
      if (parseFloat(e.target.value) > 0) {
        setPrimaryAmountPMin(parseFloat(e.target.value));
      } else {
        setPrimaryAmountPMin("");
      }
    } else if ([e.target.name] == "secondaryAmountPMin") {
      if (parseFloat(e.target.value) > 0) {
        setSecondaryAmountPMin(parseFloat(e.target.value));
      } else {
        setSecondaryAmountPMin("");
      }
    } else if ([e.target.name] == "primaryToken") {
      if (e.target.value == "") {
        setPrimaryToken("");
      } else if ((e.target.value).length >= 42) {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          setPrimaryToken(result);
          var code = await web3.eth.getCode(result.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setPrimaryToken("");
            return;
          } else {
            setPoolValue1("");
            setSelectPoolToken1("");
            await waitFor(1500);
            let primaryContract = new web3.eth.Contract(XRC20ABI, result);
            let primarySymbol = await primaryContract.methods.symbol().call();
            setPSymbol(primarySymbol);
            if (selectPoolToken2 !== "" || secondaryToken !== "") {
              let { address } = props;

              let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

              if (selectPoolToken2 == "MATIC") {
                secondaryToken = MATIC;
              } else if (selectPoolToken2 == "WMATIC") {
                secondaryToken = WMATIC;
              } else if (selectPoolToken2 == "BNB") {
                secondaryToken = BNB;
              } else if (selectPoolToken2 == "WBNB") {
                secondaryToken = WBNB;
              } else if (selectPoolToken2 == "XDC") {
                secondaryToken = XDC;
              } else if (selectPoolToken2 == "WXDC") {
                secondaryToken = WXDC;
              }

              if (typeof address === "undefined" || address == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
              } else {
                let pair = await GrootFactory.methods.getPair(result.toLowerCase(), secondaryToken.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                  EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                  return;
                } else {
                  let pairContract = new web3.eth.Contract(GrootPairABI, pair);
                  let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
                  balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                  balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
                  setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                  setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
              }
            }
          }
        } else {
          setPrimaryToken(e.target.value);
          var code = await web3.eth.getCode(e.target.value.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setPrimaryToken("");
            return;
          } else {
            setPoolValue1("");
            setSelectPoolToken1("");
            await waitFor(1500);
            let primaryContract = new web3.eth.Contract(XRC20ABI, e.target.value);
            let primarySymbol = await primaryContract.methods.symbol().call();
            setPSymbol(primarySymbol);
            if (selectPoolToken2 !== "" || secondaryToken !== "") {
              let { address } = props;

              let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

              if (selectPoolToken2 == "MATIC") {
                secondaryToken = MATIC;
              } else if (selectPoolToken2 == "WMATIC") {
                secondaryToken = WMATIC;
              } else if (selectPoolToken2 == "BNB") {
                secondaryToken = BNB;
              } else if (selectPoolToken2 == "WBNB") {
                secondaryToken = WBNB;
              } else if (selectPoolToken2 == "XDC") {
                secondaryToken = XDC;
              } else if (selectPoolToken2 == "WXDC") {
                secondaryToken = WXDC;
              }

              if (typeof address === "undefined" || address == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
              } else {
                let pair = await GrootFactory.methods.getPair(e.target.value.toLowerCase(), secondaryToken.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                  EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                  return;
                } else {
                  let pairContract = new web3.eth.Contract(GrootPairABI, pair);
                  let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
                  balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                  balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
                  setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                  setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
              }
            }
          }
        }
      } else {
        setPrimaryToken(e.target.value)
      }
    } else if ([e.target.name] == "secondaryToken") {
      if (e.target.value == "") {
        setSecondaryToken("");
      } else if ((e.target.value).length >= 42) {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          setSecondaryToken(result);
          var code = await web3.eth.getCode(result.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setSecondaryToken("");
            return;
          } else {
            setPoolValue2("");
            setSelectPoolToken2("");
            await waitFor(1500);
            let secondaryContract = new web3.eth.Contract(XRC20ABI, result);
            let secondarySymbol = await secondaryContract.methods.symbol().call();
            setSSymbol(secondarySymbol);
            if (selectPoolToken1 !== "" || primaryToken !== "") {
              let { address } = props;

              let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

              if (selectPoolToken1 == "MATIC") {
                primaryToken = MATIC;
              } else if (selectPoolToken1 == "WMATIC") {
                primaryToken = WMATIC;
              } else if (selectPoolToken1 == "BNB") {
                primaryToken = BNB;
              } else if (selectPoolToken1 == "WBNB") {
                primaryToken = WBNB;
              } else if (selectPoolToken1 == "XDC") {
                primaryToken = XDC;
              } else if (selectPoolToken1 == "WXDC") {
                primaryToken = WXDC;
              }

              if (typeof address === "undefined" || address == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
              } else {
                let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), result.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                  EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                  return;
                } else {
                  let pairContract = new web3.eth.Contract(GrootPairABI, pair);
                  let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
                  balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                  balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
                  setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                  setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
              }
            }
          }
        } else {
          setSecondaryToken(e.target.value);
          var code = await web3.eth.getCode(e.target.value.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setSecondaryToken("");
            return;
          } else {
            setPoolValue2("");
            setSelectPoolToken2("");
            await waitFor(1500);
            let secondaryContract = new web3.eth.Contract(XRC20ABI, e.target.value);
            let secondarySymbol = await secondaryContract.methods.symbol().call();
            setSSymbol(secondarySymbol);
            if (selectPoolToken1 !== "" || primaryToken !== "") {
              let { address } = props;

              let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

              if (selectPoolToken1 == "MATIC") {
                primaryToken = MATIC;
              } else if (selectPoolToken1 == "WMATIC") {
                primaryToken = WMATIC;
              } else if (selectPoolToken1 == "BNB") {
                primaryToken = BNB;
              } else if (selectPoolToken1 == "WBNB") {
                primaryToken = WBNB;
              } else if (selectPoolToken1 == "XDC") {
                primaryToken = XDC;
              } else if (selectPoolToken1 == "WXDC") {
                primaryToken = WXDC;
              }

              if (typeof address === "undefined" || address == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
              } else {
                let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), e.target.value.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                  EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                  return;
                } else {
                  let pairContract = new web3.eth.Contract(GrootPairABI, pair);
                  let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
                  balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                  balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
                  setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                  setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
              }
            }
          }
        }
      } else {
        setSecondaryToken(e.target.value)
      }
    }
  }

  async function handleInput(e) {
    let { address } = props;
    if (address == null) {
      EventBus.publish("error", `Please connect your metamask!`);
      return;
    }
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));

    if ([e.target.name] == "primaryToken") {
      if (e.target.value == "") {
        setPrimaryToken("");
      } else if ((e.target.value).length >= 42) {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          setPrimaryToken(result);
          var code = await web3.eth.getCode(result.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setPrimaryToken("");
            return;
          } else {
            setSwapValue1("");
            setSelectSwapToken1("");
            await waitFor(3000);
            let primaryContract = new web3.eth.Contract(
              XRC20ABI,
              result.toLowerCase()
            );
            let primarySymbol = await primaryContract.methods.symbol().call();
            setPSymbol(primarySymbol);
          }
        } else {
          setPrimaryToken(e.target.value);
          var code = await web3.eth.getCode(e.target.value.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setPrimaryToken("");
            return;
          } else {
            setSwapValue1("");
            setSelectSwapToken1("");
            await waitFor(3000);
            let primaryContract = new web3.eth.Contract(
              XRC20ABI,
              e.target.value.toLowerCase()
            );
            let primarySymbol = await primaryContract.methods.symbol().call();
            setPSymbol(primarySymbol);
          }
        }
      } else {
        setPrimaryToken(e.target.value)
      }
    } else if ([e.target.name] == "secondaryToken") {
      if (e.target.value == "") {
        setSecondaryToken("");
      } else if ((e.target.value).length >= 42) {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          setSecondaryToken(result);
          var code = await web3.eth.getCode(result.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setSecondaryToken("");
            return;
          } else {
            setSwapValue2("");
            setSelectSwapToken2("");
            await waitFor(3000);
            let secondaryContract = new web3.eth.Contract(
              XRC20ABI,
              result.toLowerCase()
            );
            let secondarySymbol = await secondaryContract.methods.symbol().call();
            setSSymbol(secondarySymbol);
          }
        } else {
          setSecondaryToken(e.target.value);
          var code = await web3.eth.getCode(e.target.value.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setSecondaryToken("");
            return;
          } else {
            setSwapValue2("");
            setSelectSwapToken2("");
            await waitFor(3000);
            let secondaryContract = new web3.eth.Contract(
              XRC20ABI,
              e.target.value.toLowerCase()
            );
            let secondarySymbol = await secondaryContract.methods.symbol().call();
            setSSymbol(secondarySymbol);
          }
        }
      } else {
        setSecondaryToken(e.target.value)
      }
    } else if ([e.target.name] == "primaryAmount") {
      if (e.target.value <= 0) {
        setPrimaryAmount(e.target.value);
        setSecondaryAmount(e.target.value);
      } else {
        setPrimaryAmount(parseFloat(e.target.value));
        if (
          parseFloat(e.target.value) >= 0 &&
          primaryToken === "" &&
          selectSwapToken1 === "" &&
          pSymbol === ""
        ) {
          EventBus.publish("error", `Add Token-A To Swap`);
          return;
        }

        if (selectSwapToken1 !== "") {
          if (typeof address === "undefined" || address == null) {
            EventBus.publish("error", `Please connect your metamask!`);
            return;
          } else {
            let primaryBalance = await web3.eth.getBalance(address);
            primaryBalance = web3.utils.fromWei(
              primaryBalance.toString(),
              "ether"
            );
            // if (parseFloat(e.target.value) > primaryBalance) {
            //   EventBus.publish("error", `Insufficient Balance!`);
            //   return;
            // }

            if (selectSwapToken1 !== "" && (secondaryToken !== "" || selectSwapToken2 !== "")) {
              console.log("************************** 3.2");
              let MATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b";
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b";

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198";
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198";

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf";
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf";

              if (selectSwapToken1 == "MATIC") {
                primaryToken = MATIC;
              } else if (selectSwapToken1 == "WMATIC") {
                primaryToken = WMATIC;
              } else if (selectSwapToken1 == "BNB") {
                primaryToken = BNB;
              } else if (selectSwapToken1 == "WBNB") {
                primaryToken = WBNB;
              } else if (selectSwapToken1 == "XDC") {
                primaryToken = XDC;
              } else if (selectSwapToken1 == "WXDC") {
                primaryToken = WXDC;
              }
              if (selectSwapToken2 == "MATIC") {
                secondaryToken = MATIC;
              } else if (selectSwapToken2 == "WMATIC") {
                secondaryToken = WMATIC;
              } else if (selectSwapToken2 == "BNB") {
                secondaryToken = BNB;
              } else if (selectSwapToken2 == "WBNB") {
                secondaryToken = WBNB;
              } else if (selectSwapToken2 == "XDC") {
                secondaryToken = XDC;
              } else if (selectSwapToken2 == "WXDC") {
                secondaryToken = WXDC;
              }

              let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), secondaryToken.toLowerCase()).call();

              if (pair == "0x0000000000000000000000000000000000000000") {
                EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                return;
              } else {
                if (e.target.value === "") {
                  setSecondaryAmount("");
                }
                primaryAmount = web3.utils.toWei(e.target.value.toString(), "ether");
                let expectedAmount = await GrootRouter.methods.getAmountsOut(primaryAmount, [primaryToken.toLowerCase(), secondaryToken.toLowerCase()]).call();
                // let sAmount = parseFloat(expectedAmount[1]);
                let sAmount = web3.utils.fromWei(expectedAmount[1].toString(), "ether");
                setSecondaryAmount(parseFloat(toFixed(sAmount, 'balance')));
              }
            } else {
              EventBus.publish("error", `Token B is missing!`);
              return;
            }
          }
        } else {
          if (typeof address === "undefined" || address == null) {
            EventBus.publish("error", `Please connect your metamask!`);
            return;
          } else {
            let primaryContract = new web3.eth.Contract(XRC20ABI, primaryToken);
            let primaryBalance = await primaryContract.methods
              .balanceOf(address)
              .call();
            primaryBalance = web3.utils.fromWei(
              primaryBalance.toString(),
              "ether"
            );
            if (parseFloat(e.target.value) > primaryBalance) {
              EventBus.publish("error", `Insufficient ${pSymbol} Balance!`);
              return;
            }

            if (selectSwapToken2 !== "") {
              let MATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b";
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b";

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198";
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198";

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf";
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf";

              if (selectSwapToken2 == "MATIC") {
                secondaryToken = MATIC;
              } else if (selectSwapToken2 == "WMATIC") {
                secondaryToken = WMATIC;
              } else if (selectSwapToken2 == "BNB") {
                secondaryToken = BNB;
              } else if (selectSwapToken2 == "WBNB") {
                secondaryToken = WBNB;
              } else if (selectSwapToken2 == "XDC") {
                secondaryToken = XDC;
              } else if (selectSwapToken2 == "WXDC") {
                secondaryToken = WXDC;
              }
              if (selectSwapToken1 == "MATIC") {
                primaryToken = MATIC;
              } else if (selectSwapToken1 == "WMATIC") {
                primaryToken = WMATIC;
              } else if (selectSwapToken1 == "BNB") {
                primaryToken = BNB;
              } else if (selectSwapToken1 == "WBNB") {
                primaryToken = WBNB;
              } else if (selectSwapToken1 == "XDC") {
                primaryToken = XDC;
              } else if (selectSwapToken1 == "WXDC") {
                primaryToken = WXDC;
              }
              let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), secondaryToken.toLowerCase()).call();
              if (pair == "0x0000000000000000000000000000000000000000") {
                EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                return;
              } else {
                if (e.target.value === "") {
                  setSecondaryAmount("");
                }
                primaryAmount = web3.utils.toWei(e.target.value.toString(), "ether");
                let expectedAmount = await GrootRouter.methods.getAmountsOut(primaryAmount, [primaryToken.toLowerCase(), secondaryToken.toLowerCase()]).call();
                // let sAmount = parseFloat(expectedAmount[1]);
                let sAmount = web3.utils.fromWei(expectedAmount[1].toString(), "ether");
                setSecondaryAmount(parseFloat(toFixed(sAmount, 'balance')));
              }
            } else if (secondaryToken !== "") {
              let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), secondaryToken.toLowerCase()).call();
              if (pair == "0x0000000000000000000000000000000000000000") {
                EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                return;
              } else {
                if (e.target.value === "") {
                  setSecondaryAmount("");
                }
                primaryAmount = web3.utils.toWei(e.target.value.toString(), "ether");
                let expectedAmount = await GrootRouter.methods.getAmountsOut(primaryAmount, [primaryToken.toLowerCase(), secondaryToken.toLowerCase()]).call();
                // let sAmount = parseFloat(expectedAmount[1]);
                let sAmount = web3.utils.fromWei(expectedAmount[1].toString(), "ether");
                setSecondaryAmount(parseFloat(toFixed(sAmount, 'balance')));
              }
            } else {
              EventBus.publish("error", `Token B is missing!`);
              return;
            }
          }
        }
      }
    } else if ([e.target.name] == "secondaryAmount") {
      if (e.target.value <= 0) {
        setPrimaryAmount(e.target.value);
        setSecondaryAmount(e.target.value);
      } else {
        setSecondaryAmount(parseFloat(e.target.value));
        if (
          parseFloat(e.target.value) > 0 &&
          secondaryToken === "" &&
          selectSwapToken2 === ""
        ) {
          EventBus.publish("error", `Add Token-B To Swap`);
          return;
        }
      }
    }
  }

  const clearStates = (e) => {
    setPrimaryAmountP("");
    setSecondaryAmountP("");
    setPrimaryAmountPMin("");
    setSecondaryAmountPMin("");
    setPrimaryToken("");
    setSecondaryToken("");
    setPSymbol("");
    setSSymbol("");
    setLiquidityAmount("");
    setLiquidityAmountActual(0);
    setSelectPoolToken1("");
    setSelectPoolToken2("");
  };

  async function handleSwapValue1(data) {
    setSwapValue1(data);
    setSelectSwapToken1(data);
    setPSymbol(data);
    setPrimaryToken("");
    if (
      selectSwapToken2 == "MATIC" ||
      selectSwapToken2 == "WMATIC" ||
      selectSwapToken2 == "BNB" ||
      selectSwapToken2 == "WBNB" ||
      selectSwapToken2 == "XDC" ||
      selectSwapToken2 == "WXDC"
    ) {
      setSecondaryToken("");
      setSelectSwapToken2("");
      setSSymbol("");
    }
    setSwapModal1(false);
  }

  async function handleSwapValue2(data) {
    setSwapValue2(data);
    setSelectSwapToken2(data);
    setSSymbol(data);
    setSecondaryToken("");
    if (
      selectSwapToken1 == "MATIC" ||
      selectSwapToken1 == "WMATIC" ||
      selectSwapToken1 == "BNB" ||
      selectSwapToken1 == "WBNB" ||
      selectSwapToken1 == "XDC" ||
      selectSwapToken1 == "WXDC"
    ) {
      setPrimaryToken("");
      setSelectSwapToken1("");
      setPSymbol("");
    }
    setSwapModal2(false);
  }

  async function handleSwapValue11(data) {

    const selectedToken = tokenList.find(token => token.address === data);

    // If the token is found, use its symbol, otherwise default to an empty string or previous state
    const tokenSymbol = selectedToken ? selectedToken.symbol : '';

    setPrimaryToken(data);
    setSelectSwapToken1(tokenSymbol);
    setPSymbol(tokenSymbol);
    setSwapValue1("");

    if (
      selectSwapToken2 == tokenSymbol
    ) {
      setSecondaryToken("");
      setSelectSwapToken2("");
      setSSymbol("");
    }

    // Create a copy of the tokenList filtering out all tokens that aren't present in a pair with the selected token
    const filteredTokenList = tokenList.filter(token =>
      pairs.some(pair =>
        (pair.token0Address === data && token.address === pair.token1Address) ||
        (pair.token1Address === data && token.address === pair.token0Address)
      )
    );

    setTokenList1(filteredTokenList);
    setSwapModal1(false);
  }

  async function handleSwapValue22(data) {
    const selectedToken = tokenList1.find(token => token.address === data);

    // If the token is found, use its symbol, otherwise default to an empty string or previous state
    const tokenSymbol = selectedToken ? selectedToken.symbol : '';

    setSecondaryToken(data);
    setSelectSwapToken2(tokenSymbol);
    setSSymbol(tokenSymbol);
    setSwapValue2("");
    if (
      selectSwapToken1 == tokenSymbol
    ) {
      setPrimaryToken("");
      setSelectSwapToken1("");
      setPSymbol("");
    }
    setSwapModal2(false);
  }

  async function handlePoolValue1(data) {
    setPoolValue1(data);
    setSelectPoolToken1(data);
    setPSymbol(data);
    setPrimaryToken("");
    if (selectPoolToken2 !== "" || secondaryToken !== "") {
      let { address } = props;

      let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
      let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

      let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
      let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

      let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
      let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

      if (selectPoolToken2 == "MATIC") {
        secondaryToken = MATIC;
      } else if (selectPoolToken2 == "WMATIC") {
        secondaryToken = WMATIC;
      } else if (selectPoolToken2 == "BNB") {
        secondaryToken = BNB;
      } else if (selectPoolToken2 == "WBNB") {
        secondaryToken = WBNB;
      } else if (selectPoolToken2 == "XDC") {
        secondaryToken = XDC;
      } else if (selectPoolToken2 == "WXDC") {
        secondaryToken = WXDC;
      }

      if (typeof address === "undefined" || address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      } else {
        let pair = await GrootFactory.methods.getPair(data.toLowerCase(), secondaryToken.toLowerCase()).call();

        if (pair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish("info", `Pair Does not exist for Token-A & Token-B!`);
          return;
        } else {
          let pairContract = new web3.eth.Contract(GrootPairABI, pair);
          let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
          balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
          balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
          setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
          setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
        }
      }
    }
    if (
      selectPoolToken2 == "MATIC" ||
      selectPoolToken2 == "WMATIC" ||
      selectPoolToken2 == "BNB" ||
      selectPoolToken2 == "WBNB" ||
      selectPoolToken2 == "XDC" ||
      selectPoolToken2 == "WXDC"
    ) {
      setSecondaryToken("");
      setSelectPoolToken2("");
      setSSymbol("");
    }
    setPoolModal1(false);
  }

  async function handlePoolValue11(data) {
    const selectedToken = tokenList.find(token => token.address === data);

    // If the token is found, use its symbol, otherwise default to an empty string or previous state
    const tokenSymbol = selectedToken ? selectedToken.symbol : '';

    setPrimaryToken(data);
    setSelectPoolToken1(tokenSymbol);
    setPSymbol(tokenSymbol);
    setPoolValue1("");
    if (selectPoolToken2 !== "" || secondaryToken !== "") {
      let { address } = props;

      let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
      let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

      let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
      let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

      let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
      let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

      if (selectPoolToken2 == "MATIC") {
        secondaryToken = MATIC;
      } else if (selectPoolToken2 == "WMATIC") {
        secondaryToken = WMATIC;
      } else if (selectPoolToken2 == "BNB") {
        secondaryToken = BNB;
      } else if (selectPoolToken2 == "WBNB") {
        secondaryToken = WBNB;
      } else if (selectPoolToken2 == "XDC") {
        secondaryToken = XDC;
      } else if (selectPoolToken2 == "WXDC") {
        secondaryToken = WXDC;
      }

      if (typeof address === "undefined" || address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      } else {
        let pair = await GrootFactory.methods.getPair(data.toLowerCase(), secondaryToken.toLowerCase()).call();

        if (pair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish("info", `Pair Does not exist for Token-A & Token-B!`);
          return;
        } else {
          let pairContract = new web3.eth.Contract(GrootPairABI, pair);
          let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
          balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
          balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
          setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
          setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
        }
      }
    }
    if (
      selectPoolToken2 == tokenSymbol
    ) {
      setSecondaryToken("");
      setSelectPoolToken2("");
      setSSymbol("");
    }

    setTokenList1(tokenList);
    setPoolModal1(false);
  }

  async function handlePoolValue2(data) {
    setPoolValue2(data);
    setSelectPoolToken2(data);
    setSSymbol(data);
    setSecondaryToken("");
    if (selectPoolToken1 !== "" || primaryToken !== "") {
      let { address } = props;

      let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
      let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

      let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
      let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

      let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
      let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

      if (selectPoolToken1 == "MATIC") {
        primaryToken = MATIC;
      } else if (selectPoolToken1 == "WMATIC") {
        primaryToken = WMATIC;
      } else if (selectPoolToken1 == "BNB") {
        primaryToken = BNB;
      } else if (selectPoolToken1 == "WBNB") {
        primaryToken = WBNB;
      } else if (selectPoolToken1 == "XDC") {
        primaryToken = XDC;
      } else if (selectPoolToken1 == "WXDC") {
        primaryToken = WXDC;
      }

      if (typeof address === "undefined" || address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      } else {
        let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), data.toLowerCase()).call();

        if (pair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish("info", `Pair Does not exist for Token-A & Token-B!`);
          return;
        } else {
          let pairContract = new web3.eth.Contract(GrootPairABI, pair);
          let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
          balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
          balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
          setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
          setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
        }
      }
    }
    if (
      selectPoolToken1 == "MATIC" ||
      selectPoolToken1 == "WMATIC" ||
      selectPoolToken1 == "BNB" ||
      selectPoolToken1 == "WBNB" ||
      selectPoolToken1 == "XDC" ||
      selectPoolToken1 == "WXDC"
    ) {
      setPrimaryToken("");
      setSelectPoolToken1("");
      setPSymbol("");
    }
    setPoolModal2(false);
  }

  async function handlePoolValue22(data) {
    const selectedToken = tokenList1.find(token => token.address === data);

    // If the token is found, use its symbol, otherwise default to an empty string or previous state
    const tokenSymbol2 = selectedToken ? selectedToken.symbol : '';

    setSecondaryToken(data);
    setSelectPoolToken2(tokenSymbol2);
    setSSymbol(tokenSymbol2);
    setPoolValue2("");
    if (selectPoolToken1 !== "" || primaryToken !== "") {
      let { address } = props;

      let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
      let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

      let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
      let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

      let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
      let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

      if (selectPoolToken1 == "MATIC") {
        primaryToken = MATIC;
      } else if (selectPoolToken1 == "WMATIC") {
        primaryToken = WMATIC;
      } else if (selectPoolToken1 == "BNB") {
        primaryToken = BNB;
      } else if (selectPoolToken1 == "WBNB") {
        primaryToken = WBNB;
      } else if (selectPoolToken1 == "XDC") {
        primaryToken = XDC;
      } else if (selectPoolToken1 == "WXDC") {
        primaryToken = WXDC;
      }

      if (typeof address === "undefined" || address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      } else {
        let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), data.toLowerCase()).call();

        if (pair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish("info", `Pair Does not exist for Token-A & Token-B!`);
          return;
        } else {
          let pairContract = new web3.eth.Contract(GrootPairABI, pair);
          let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
          balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
          balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
          setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
          setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
        }
      }
    }
    if (
      selectPoolToken1 == tokenSymbol2
    ) {
      setPrimaryToken("");
      setSelectPoolToken1("");
      setPSymbol("");
    }

    setTokenList1(tokenList);
    setPoolModal2(false);
  }

  async function handleFlip() {
    let arrayP = [];
    let arrayS = [];
    arrayP.push(
      swapValue1,
      selectSwapToken1,
      pSymbol,
      primaryToken,
      primaryAmount
    );
    arrayS.push(
      swapValue2,
      selectSwapToken2,
      sSymbol,
      secondaryToken,
      secondaryAmount
    );
    setSwapValue1(arrayS[0]);
    setSelectSwapToken1(arrayS[1]);
    setPSymbol(arrayS[2]);
    setPrimaryToken(arrayS[3]);
    setPrimaryAmount(arrayS[4]);

    setSwapValue2(arrayP[0]);
    setSelectSwapToken2(arrayP[1]);
    setSSymbol(arrayP[2]);
    setSecondaryToken(arrayP[3]);
    setSecondaryAmount(arrayP[4]);
  }

  async function addLiquidity() {
    try {
      let { address } = props;

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
      let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

      let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
      let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

      let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
      let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

      if (selectPoolToken1 == "MATIC") {
        primaryToken = MATIC;
      } else if (selectPoolToken1 == "WMATIC") {
        primaryToken = WMATIC;
      } else if (selectPoolToken1 == "BNB") {
        primaryToken = BNB;
      } else if (selectPoolToken1 == "WBNB") {
        primaryToken = WBNB;
      } else if (selectPoolToken1 == "XDC") {
        primaryToken = XDC;
      } else if (selectPoolToken1 == "WXDC") {
        primaryToken = WXDC;
      }

      if (selectPoolToken2 == "MATIC") {
        EventBus.publish("error", `Please select MATIC as token A!`);
        return;
      } else if (selectPoolToken2 == "WMATIC") {
        EventBus.publish("error", `Please select MATIC as token A!`);
        return;
      } else if (selectPoolToken2 == "BNB") {
        EventBus.publish("error", `Please select BNB as token A!`);
        return;
      } else if (selectPoolToken2 == "WBNB") {
        EventBus.publish("error", `Please select BNB as token A!`);
        return;
      } else if (selectPoolToken2 == "XDC") {
        EventBus.publish("error", `Please select XDC as token A!`);
        return;
      } else if (selectPoolToken2 == "WXDC") {
        EventBus.publish("error", `Please select XDC as token A!`);
        return;
      }

      if (primaryToken == "") {
        EventBus.publish("error", `Token A is missing!`);
        return;
      }
      if (secondaryToken == "") {
        EventBus.publish("error", `Token B is missing!`);
        return;
      }
      if (primaryToken.toLowerCase() === secondaryToken.toLowerCase()) {
        EventBus.publish(
          "error",
          `Token A cannot have same address as Token B!`
        );
        return;
      }
      if (parseFloat(primaryAmountP) <= 0) {
        EventBus.publish("error", `Enter Token A Amount!`);
        return;
      }
      if (parseFloat(secondaryAmountP) <= 0) {
        EventBus.publish("error", `Enter Token B Amount!`);
        return;
      }
      if (parseFloat(primaryAmountPMin) <= 0) {
        EventBus.publish("error", `Enter Token A Min Amount!`);
        return;
      }
      if (parseFloat(secondaryAmountPMin) <= 0) {
        EventBus.publish("error", `Enter Token B Min Amount!`);
        return;
      }

      primaryToken = primaryToken.toLowerCase();
      secondaryToken = secondaryToken.toLowerCase();
      primaryAmountP = await web3.utils.toWei(
        primaryAmountP.toString(),
        "ether"
      );
      secondaryAmountP = await web3.utils.toWei(
        secondaryAmountP.toString(),
        "ether"
      );
      primaryAmountPMin = await web3.utils.toWei(
        primaryAmountPMin.toString(),
        "ether"
      );
      secondaryAmountPMin = await web3.utils.toWei(
        secondaryAmountPMin.toString(),
        "ether"
      );

      let primaryContract = new web3.eth.Contract(XRC20ABI, primaryToken);
      let secondaryContract = new web3.eth.Contract(XRC20ABI, secondaryToken);

      let primaryBalance = await primaryContract.methods
        .balanceOf(address)
        .call();
      let secondaryBalance = await secondaryContract.methods
        .balanceOf(address)
        .call();

      let userBalance = await web3.eth.getBalance(address);

      if (
        primaryToken.toLowerCase() === "0x0000000000000000000000000000000000001010".toLowerCase() ||
        primaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        primaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() ||
        primaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        if (parseFloat(primaryAmountP) > userBalance) {
          EventBus.publish(
            "error",
            `Token A: You dont have enough balance to deposit!`
          );
          return;
        }
      } else {
        if (parseFloat(primaryAmountP) > primaryBalance) {
          EventBus.publish(
            "error",
            `Token A: You dont have enough balance to deposit!`
          );
          return;
        }
      }

      if (
        secondaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x0000000000000000000000000000000000001010".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase()
      ) {
        if (parseFloat(secondaryAmountP) > userBalance) {
          EventBus.publish(
            "error",
            `Token B: You dont have enough balance to deposit!`
          );
          return;
        }
      } else {
        if (parseFloat(secondaryAmountP) > secondaryBalance) {
          EventBus.publish(
            "error",
            `Token B: You dont have enough balance to deposit!`
          );
          return;
        }
      }

      // Set Amount
      const amountInPrimary = primaryAmountP;
      const amountInP = amountInPrimary.toString();

      const amountInSecondary = secondaryAmountP;
      const amountInS = amountInSecondary.toString();

      /** Add Liquidity **/
      props.setLoader({
        message: "Add Liquidity in Progress...",
        status: true,
      });

      if (
        primaryToken.toLowerCase() !== "0x0000000000000000000000000000000000001010".toLowerCase() &&
        primaryToken.toLowerCase() !== "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() &&
        primaryToken.toLowerCase() !== "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() &&
        primaryToken.toLowerCase() !== "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        console.log("Approving Primary Token ...");
        await primaryContract.methods
          .approve(GrootRouterAddress, amountInP)
          .send({
            from: address,
            gas: 2000000,
          });
      }

      if (
        secondaryToken.toLowerCase() !== "0x0000000000000000000000000000000000001010".toLowerCase() &&
        secondaryToken.toLowerCase() !== "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() &&
        secondaryToken.toLowerCase() !== "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() &&
        secondaryToken.toLowerCase() !== "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        console.log("Approving Secondary Token ...");
        await secondaryContract.methods
          .approve(GrootRouterAddress, amountInS)
          .send({
            from: address,
            gas: 2000000,
          });
      }

      console.log("Adding Liquidity...");
      let result;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (
        primaryToken.toLowerCase() === "0x0000000000000000000000000000000000001010".toLowerCase() ||
        primaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        primaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() ||
        primaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        result = await GrootRouter.methods.addLiquidityXDC(
          secondaryToken,
          secondaryAmountP,
          secondaryAmountPMin,
          primaryAmountPMin,
          address,
          web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20)
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            value: web3.utils.toHex(primaryAmountP),
            data: result.encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            refreshBalance();
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setAddliquidityModal(false);
            getPairs().then(setPairs);
            props.setLoader({ status: false });
            EventBus.publish("success", `Liquidity Added Successfully`);
          });
      } else {
        result = await GrootRouter.methods.addLiquidity(
          primaryToken,
          secondaryToken,
          primaryAmountP,
          secondaryAmountP,
          primaryAmountPMin,
          secondaryAmountPMin,
          address,
          web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20)
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            data: result.encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            refreshBalance();
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setAddliquidityModal(false);
            getPairs().then(setPairs);
            props.setLoader({ status: false });
            EventBus.publish("success", `Liquidity Added Successfully`);
          });
      }
    } catch (e) {
      console.log("********Error = ", e);
      setPSymbol("");
      setSSymbol("");
      setPrimaryToken("");
      setSecondaryToken("");
      setPrimaryAmountP("");
      setSecondaryAmountP("");
      setPrimaryAmountPMin("");
      setSecondaryAmountPMin("");
      setPoolValue1("");
      setPoolValue2("");
      setSelectPoolToken1("");
      setSelectPoolToken2("");
      props.setLoader({
        message: "Add Liquidity Failed!",
        status: false,
      });
      EventBus.publish("error", `Unable To Add Liquidity!`);
    }
  }

  async function removeLiquidity() {
    try {
      let { address } = props;

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (address == null || address == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
      let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

      let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
      let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

      let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
      let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

      if (selectPoolToken1 == "MATIC") {
        primaryToken = MATIC;
      } else if (selectPoolToken1 == "WMATIC") {
        primaryToken = WMATIC;
      } else if (selectPoolToken1 == "BNB") {
        primaryToken = BNB;
      } else if (selectPoolToken1 == "WBNB") {
        primaryToken = WBNB;
      } else if (selectPoolToken1 == "XDC") {
        primaryToken = XDC;
      } else if (selectPoolToken1 == "WXDC") {
        primaryToken = WXDC;
      }

      // if (selectPoolToken2 == "MATIC") {
      //   secondaryToken = MATIC;
      // } else if (selectPoolToken2 == "WMATIC") {
      //   secondaryToken = WMATIC;
      // } else if (selectPoolToken2 == "BNB") {
      //   secondaryToken = BNB;
      // } else if (selectPoolToken2 == "WBNB") {
      //   secondaryToken = WBNB;
      // } else if (selectPoolToken2 == "XDC") {
      //   secondaryToken = XDC;
      // } else if (selectPoolToken2 == "WXDC") {
      //   secondaryToken = WXDC;
      // }
      if (selectPoolToken2 == "MATIC") {
        EventBus.publish("error", `Please select MATIC as token A!`);
        return;
      } else if (selectPoolToken2 == "WMATIC") {
        EventBus.publish("error", `Please select MATIC as token A!`);
        return;
      } else if (selectPoolToken2 == "BNB") {
        EventBus.publish("error", `Please select BNB as token A!`);
        return;
      } else if (selectPoolToken2 == "WBNB") {
        EventBus.publish("error", `Please select BNB as token A!`);
        return;
      } else if (selectPoolToken2 == "XDC") {
        EventBus.publish("error", `Please select XDC as token A!`);
        return;
      } else if (selectPoolToken2 == "WXDC") {
        EventBus.publish("error", `Please select XDC as token A!`);
        return;
      }

      if (primaryToken == "") {
        EventBus.publish("error", `Token A is missing!`);
        return;
      }
      if (secondaryToken == "") {
        EventBus.publish("error", `Token B is missing!`);
        return;
      }
      if (primaryToken.toLowerCase() === secondaryToken.toLowerCase()) {
        EventBus.publish(
          "error",
          `Token A cannot have same address as Token B!`
        );
        return;
      }

      let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), secondaryToken.toLowerCase()).call();

      if (pair == "0x0000000000000000000000000000000000000000") {
        EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
        return;
      } else {
        let pairContract = new web3.eth.Contract(GrootPairABI, pair);
        let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
        liquidityAmount = web3.utils.toWei(liquidityAmount.toString(), "ether");
        if (parseFloat(liquidityAmount) > parseFloat(balanceLiquidity)) {
          EventBus.publish("error", `You have not added this amount of liquidity!`);
        }

        props.setLoader({
          message: "Approval in Progress...",
          status: true,
        });

        await pairContract.methods
          .approve(GrootRouterAddress, liquidityAmount)
          .send({
            from: address,
            gas: 2000000,
          });
      }

      if (parseFloat(liquidityAmount) <= 0) {
        EventBus.publish("error", `Enter Liquidity Amount!`);
        return;
      }

      const balanceWei = await web3.eth.getBalance(publicAddress);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (parseFloat(balanceEther) == 0) return EventBus.publish("error", `Insufficient balance for transaction`);


      liquidityAmount = liquidityAmount.toString();

      /** Remove Liquidity **/
      props.setLoader({
        message: "Remove Liquidity in Progress...",
        status: true,
      });

      console.log("Removing Liquidity...");

      let result;

      if (
        primaryToken.toLowerCase() === "0x0000000000000000000000000000000000001010".toLowerCase() ||
        primaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        primaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() ||
        primaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        result = await GrootRouter.methods.removeLiquidityXDC(
          secondaryToken,
          liquidityAmount,
          1,
          1,
          address,
          web3.utils.toHex(Math.ceil(deadlineValue / 1000))
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            data: result.encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            refreshBalance();
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setLiquidityAmount("");
            setLiquidityAmountActual(0);
            setRemoveliquidityModal(false);
            props.setLoader({ status: false });
            EventBus.publish("success", `Liquidity Redeemed Successfully`);
          });
      } else {
        result = await GrootRouter.methods.removeLiquidity(
          primaryToken,
          secondaryToken,
          liquidityAmount,
          1,
          1,
          address,
          web3.utils.toHex(Math.ceil(deadlineValue / 1000))
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            data: result.encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            refreshBalance();
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setLiquidityAmount("");
            setLiquidityAmountActual(0);
            props.setLoader({ status: false });
            setRemoveliquidityModal(false);
            EventBus.publish("success", `Liquidity Redeemed Successfully`);
          });
      }
    } catch (e) {
      console.log("********Error = ", e);
      setPSymbol("");
      setSSymbol("");
      setPrimaryToken("");
      setSecondaryToken("");
      setPrimaryAmountP("");
      setSecondaryAmountP("");
      setPrimaryAmountPMin("");
      setSecondaryAmountPMin("");
      setPoolValue1("");
      setPoolValue2("");
      setSelectPoolToken1("");
      setSelectPoolToken2("");
      setLiquidityAmount("");
      setLiquidityAmountActual(0);
      props.setLoader({
        message: "Liquidity Redeem Failed!",
        status: false,
      });
      EventBus.publish("error", `Unable To Redeem Liquidity!`);
    }
  }

  async function handleSwap() {
    try {
      let { address } = props;

      if (typeof address === "undefined" || address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      // if (props.kycStatus !== "adminApproved") {
      //   EventBus.publish("error", `Submit KYC verification to access this function!`);
      //   return;
      // }

      let MATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();
      let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

      let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
      let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

      let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
      let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

      if (selectSwapToken1 == "MATIC") {
        primaryToken = MATIC;
      } else if (selectSwapToken1 == "WMATIC") {
        primaryToken = WMATIC;
      } else if (selectSwapToken1 == "BNB") {
        primaryToken = BNB;
      } else if (selectSwapToken1 == "WBNB") {
        primaryToken = WBNB;
      } else if (selectSwapToken1 == "XDC") {
        primaryToken = XDC;
      } else if (selectSwapToken1 == "WXDC") {
        primaryToken = WXDC;
      }

      if (selectSwapToken2 == "MATIC") {
        secondaryToken = MATIC;
      } else if (selectSwapToken2 == "WMATIC") {
        secondaryToken = WMATIC;
      } else if (selectSwapToken2 == "BNB") {
        secondaryToken = BNB;
      } else if (selectSwapToken2 == "WBNB") {
        secondaryToken = WBNB;
      } else if (selectSwapToken2 == "XDC") {
        secondaryToken = XDC;
      } else if (selectSwapToken2 == "WXDC") {
        secondaryToken = WXDC;
      }

      if (primaryToken == "") {
        EventBus.publish("error", `Token A is missing!`);
        return;
      }
      if (secondaryToken == "") {
        EventBus.publish("error", `Token B is missing!`);
        return;
      }
      if (primaryToken.toLowerCase() === secondaryToken.toLowerCase()) {
        EventBus.publish(
          "error",
          `Token A cannot have same address as Token B!`
        );
        return;
      }
      if (parseFloat(primaryAmount) <= 0) {
        EventBus.publish("error", `Enter Token A Amount!`);
        return;
      }

      primaryToken = primaryToken.toLowerCase();
      secondaryToken = secondaryToken.toLowerCase();

      primaryAmount = await web3.utils.toWei(primaryAmount.toString(), "ether");

      if (primaryToken == MATIC || primaryToken == WMATIC) {
        let chechPair = await GrootFactory.methods
          .getPair(WMATIC, secondaryToken)
          .call();
        if (chechPair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish(
            "error",
            `Pair Does not exist for Token-A & Token-B!`
          );
          return;
        }
      } else if (secondaryToken == MATIC || secondaryToken == WMATIC) {
        let chechPair = await GrootFactory.methods
          .getPair(primaryToken, WMATIC)
          .call();
        if (chechPair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish(
            "error",
            `Pair Does not exist for Token-A & Token-B!`
          );
          return;
        }
      } else if (primaryToken == BNB || primaryToken == WBNB) {
        let chechPair = await GrootFactory.methods
          .getPair(WBNB, secondaryToken)
          .call();
        if (chechPair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish(
            "error",
            `Pair Does not exist for Token-A & Token-B!`
          );
          return;
        }
      } else if (secondaryToken == BNB || secondaryToken == WBNB) {
        let chechPair = await GrootFactory.methods
          .getPair(primaryToken, WBNB)
          .call();
        if (chechPair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish(
            "error",
            `Pair Does not exist for Token-A & Token-B!`
          );
          return;
        }
      } else if (primaryToken == XDC || primaryToken == WXDC) {
        let chechPair = await GrootFactory.methods
          .getPair(WXDC, secondaryToken)
          .call();
        if (chechPair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish(
            "error",
            `Pair Does not exist for Token-A & Token-B!`
          );
          return;
        }
      } else if (secondaryToken == XDC || secondaryToken == WXDC) {
        let chechPair = await GrootFactory.methods
          .getPair(primaryToken, WXDC)
          .call();
        if (chechPair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish(
            "error",
            `Pair Does not exist for Token-A & Token-B!`
          );
          return;
        }
      } else {
        let chechPair = await GrootFactory.methods
          .getPair(primaryToken, secondaryToken)
          .call();
        if (chechPair == "0x0000000000000000000000000000000000000000") {
          EventBus.publish(
            "error",
            `Pair Does not exist for Token-A & Token-B!`
          );
          return;
        }
      }

      if (selectSwapToken1 !== "") {
        if (typeof address === "undefined" || address == null) {
          EventBus.publish("error", `Please connect your metamask!`);
          return;
        } else {
          let primaryBalance = await web3.eth.getBalance(address);
          if (parseFloat(primaryAmount) > primaryBalance) {
            EventBus.publish("error", `Insufficient Balance!`);
            return;
          }
        }
      } else {
        if (typeof address === "undefined" || address == null) {
          EventBus.publish("error", `Please connect your metamask!`);
          return;
        } else {
          let primaryContract = new web3.eth.Contract(XRC20ABI, primaryToken);
          let primaryBalance = await primaryContract.methods
            .balanceOf(address)
            .call();
          if (parseFloat(primaryAmount) > primaryBalance) {
            EventBus.publish("error", `Insufficient ${pSymbol} Balance!`);
            return;
          }
        }
      }

      // Perform Swap
      const amountInU = primaryAmount;
      const amountIn = amountInU.toString();

      /** Swap **/
      props.setLoader({
        message: "Swap in Progress...",
        status: true,
      });

      let primaryContract = new web3.eth.Contract(XRC20ABI, primaryToken);

      if (
        primaryToken.toLowerCase() !== "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() &&
        primaryToken.toLowerCase() !== "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() &&
        primaryToken.toLowerCase() !== "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() &&
        primaryToken.toLowerCase() !== "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        console.log("Approving Primary Token ...");
        await primaryContract.methods
          .approve(GrootRouterAddress, amountIn)
          .send({
            from: address,
            gas: 2000000,
          });
      }

      let publicAddress = (await web3.currentProvider.enable())[0];

      let mAmount = (secondaryAmount / 100) * parseFloat(slippageValue);
      let minAmount = secondaryAmount - mAmount;
      minAmount = web3.utils.toWei(minAmount.toString(), "ether");

      if (
        primaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        primaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        primaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() ||
        primaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        let path = [primaryToken.toLowerCase(), secondaryToken.toLowerCase()];
        console.log("Performing Swap...");
        let result = await GrootRouter.methods.swapExactXDCForTokens(
          minAmount,
          path,
          address,
          web3.utils.toHex(Math.ceil(deadlineValue / 1000))
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            value: web3.utils.toHex(amountIn),
            to: GrootRouterAddress,
            gas: 5000000,
            data: result.encodeABI(),
          })
          .on('transactionHash', hash => {
            console.log(`************** hash = ${hash}`);
            setTxHash(hash);
          })
          .on('receipt', async receipt => {
            refreshBalance();
            setPrimaryToken("");
            setSecondaryToken("");
            setSelectSwapToken1("");
            setSelectSwapToken2("");
            setPSymbol("");
            setSSymbol("");
            setMicroeconomySuccessModal(true);
            props.setLoader({ status: false });
            EventBus.publish("success", `Swapped Successfully`);
          });
      } else if (
        secondaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() ||
        secondaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        let path = [primaryToken.toLowerCase(), secondaryToken.toLowerCase()];
        console.log("Performing Swap...");
        let result = await GrootRouter.methods.swapExactTokensForXDC(
          web3.utils.toHex(amountIn),
          minAmount,
          path,
          address,
          web3.utils.toHex(Math.ceil(deadlineValue / 1000))
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            data: result.encodeABI(),
          })
          .on('transactionHash', hash => {
            console.log(`************** hash = ${hash}`);
            setTxHash(hash);
          })
          .on('receipt', async receipt => {
            refreshBalance();
            setPrimaryToken("");
            setSecondaryToken("");
            setSelectSwapToken1("");
            setSelectSwapToken2("");
            setPSymbol("");
            setSSymbol("");
            setMicroeconomySuccessModal(true);
            props.setLoader({ status: false });
            EventBus.publish("success", `Swapped Successfully`);
          });
      } else {
        let path = [primaryToken.toLowerCase(), secondaryToken.toLowerCase()];
        console.log("Performing Swap...");
        let result = await GrootRouter.methods.swapExactTokensForTokens(
          web3.utils.toHex(amountIn),
          minAmount,
          path,
          address,
          web3.utils.toHex(Math.ceil(deadlineValue / 1000))
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            data: result.encodeABI(),
          })
          .on('transactionHash', hash => {
            console.log(`************** hash = ${hash}`);
            setTxHash(hash);
          })
          .on('receipt', async receipt => {
            refreshBalance();
            setPrimaryToken("");
            setSecondaryToken("");
            setSelectSwapToken1("");
            setSelectSwapToken2("");
            setPSymbol("");
            setSSymbol("");
            setMicroeconomySuccessModal(true);
            props.setLoader({ status: false });
            EventBus.publish("success", `Swapped Successfully`);
          });
      }
    } catch (e) {
      console.log("********Error = ", e);
      setPSymbol("");
      setSSymbol("");
      setPrimaryToken("");
      setSecondaryToken("");
      setPrimaryAmount("");
      setSecondaryAmount("");
      setSelectSwapToken1("");
      setSelectSwapToken2("");
      setMicroeconomyErrorModal(true);
      props.setLoader({
        message: "Swap Failed!",
        status: false,
      });
      EventBus.publish("error", `Unable To Swap Tokens!`);
    }
  }

  async function closeSuccessModal() {
    setPSymbol("");
    setSSymbol("");
    setPrimaryToken("");
    setSecondaryToken("");
    setPrimaryAmount("");
    setSecondaryAmount("");
    setMicroeconomySuccessModal(false)
  }

  async function copiedAddress() {
    EventBus.publish("success", "Transaction Hash Copied");
  }

  async function setLiquidityPercent(value) {
    let liquidityValue = (parseFloat(liquidityAmountActual) / 100) * parseInt(value);
    setLiquidityAmount(parseFloat(liquidityValue));
  }

  const handleCopyText = () => {
    navigator.clipboard.writeText(txHash)
      .then(() => EventBus.publish("success", "Transaction Hash Copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  // console.log("*** tokenList", tokenList);
  // console.log("*** tokenList1", tokenList1);
  // console.log("*** filteredPairs", filteredPairs);
  // console.log("*** filteredPairs2", filteredPairs2);
  // console.log("*** primaryToken", primaryToken);
  // console.log("*** secondaryToken", secondaryToken);

  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={swapicon} alt="" />
          <span>Swap</span>
        </a>
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />
      </div>

      <div className="swap">
        <div className="wallet-extension">
          <div className="wallet-extension-wrap">
            <div className="swap-settings-dropdown">
              <h2>Swap</h2>

              <div className="settings-dropdown">
                <div className="header" onClick={toggleSettings}>
                  <img src={settingicon} alt="" />
                </div>
                {showSettings && (
                  <div className="dropdown-wrapper">
                    <div className="dropdown-header">
                      <button className="close-btn" onClick={toggleSettings}>
                        <img src={close} alt="" />
                      </button>
                    </div>
                    <ul className="dropdown-list">
                      <li>
                        <div className="form-group">
                          <label for="tolerance">Slippage tolerance
                          </label>
                          <div>
                            <button onClick={() => autoSlippage()}>Auto</button>
                            <input
                              // onWheel={handleOnWheel}
                              type="text"
                              name="slippageValue"
                              value={slippageValue}
                              // id="exampleText0"
                              placeholder={slippageValue}
                              onInput={handleSlippage}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="form-group">
                          <label for="transaction">Transaction deadline
                          </label>
                          <input
                            onWheel={handleOnWheel}
                            type="number"
                            name="iDeadlineValue"
                            value={iDeadlineValue}
                            className="w-full min-w-0    px-3 py-3 text-base font-medium text-black bg-[#F5F5F5] rounded-[10px]   border     focus:text-gray-700 focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:outline-none "
                            placeholder={iDeadlineValue}
                            onInput={handleSlippage}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="form-wrapper">
              <div className="form-head">
                <button onClick={() => setRemoveliquidityModal(true)} className="add-liquidity">Remove Liquidity</button>
                <button onClick={() => setAddliquidityModal(true)} className="add-liquidity">Add Liquidity</button>
              </div>
              <div className="form-group">
                <div className='input-group'>
                  {primaryAmount ? (
                    <input
                      onWheel={handleOnWheel}
                      type="number"
                      id="price"
                      name="primaryAmount"
                      value={primaryAmount}
                      placeholder={primaryAmount}
                      onInput={handleInput}
                    />
                  ) : (
                    <input
                      onWheel={handleOnWheel}
                      type="number"
                      id="price"
                      name="primaryAmount"
                      value={primaryAmount}
                      placeholder="0"
                      onInput={handleInput}
                    />
                  )}
                  <div className="network-dropdown">
                    <div className="header" onClick={() => setSwapModal1(true)}>
                      <span>
                        {(networkId == 80001 || networkId == 137) &&
                          (pSymbol == "MATIC" || pSymbol == "WMATIC") ? (
                          <img src={Matic} className="w-5 h-5" alt="matic" />
                        ) : (networkId == 97 || networkId == 56) &&
                          (pSymbol == "BNB" || pSymbol == "WBNB") ? (
                          <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                        ) : (networkId == 51 || networkId == 50) &&
                          (pSymbol == "XDC" || pSymbol == "WXDC") ? (
                          <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                        ) : (
                          <></>
                        )}
                      </span>
                      <p>
                        {selectSwapToken1
                          ? selectSwapToken1
                          : pSymbol
                            ? pSymbol
                            : "Select Token"}
                      </p>
                    </div>
                  </div>
                </div>

                <button onClick={() => handleFlip()}><img src={arrowswap} alt="" /></button>

                <div className='input-group'>
                  {secondaryAmount ? (
                    <input
                      onWheel={handleOnWheel}
                      type="number"
                      id="price"
                      name="secondaryAmount"
                      value={secondaryAmount}
                      placeholder={secondaryAmount}
                      onInput={handleInput}
                      disabled
                    />
                  ) : (
                    <input
                      onWheel={handleOnWheel}
                      type="number"
                      id="price"
                      name="secondaryAmount"
                      value={secondaryAmount}
                      placeholder="Expected Amount"
                      onInput={handleInput}
                      disabled
                    />
                  )}
                  <div className="network-dropdown">
                    <div className="header" onClick={() => setSwapModal2(true)}>
                      <span>
                        {(networkId == 80001 || networkId == 137) &&
                          (sSymbol == "MATIC" || sSymbol == "WMATIC") ? (
                          <img src={Matic} className="w-5 h-5" alt="matic" />
                        ) : (networkId == 97 || networkId == 56) &&
                          (sSymbol == "BNB" || sSymbol == "WBNB") ? (
                          <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                        ) : (networkId == 51 || networkId == 50) &&
                          (sSymbol == "XDC" || sSymbol == "WXDC") ? (
                          <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                        ) : (
                          <></>
                        )}
                      </span>
                      <p>
                        {selectSwapToken2
                          ? selectSwapToken2
                          : sSymbol
                            ? sSymbol
                            : "Select Token"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button className="btn" onClick={() => handleSwap()}>Swap</button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={microeconomySuccessModal}
        onClose={() => closeSuccessModal()}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal success-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => closeSuccessModal()}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <img src={tickcircle} alt="Tick Circle" />

            <h3>Success</h3>

            <span>Transaction Submitted</span>
            <div className="transaction-amount">
              <div className="amount">
                {/* <img src={ethicon} alt="" /> */}
                <span>{primaryAmount} {selectSwapToken1 ? selectSwapToken1 : pSymbol} </span>
              </div>
              <img src={brownarrow} alt="" />
              <div className="amount">
                {/* <img src={usdcicon} alt="" /> */}
                <span>{secondaryAmount} {selectSwapToken2 ? selectSwapToken2 : sSymbol}</span>
              </div>
            </div>
            <form>
              <div className="form-group hash">
                <p>
                  <CopyToClipboard
                    text={txHash}
                    onCopy={copiedAddress}
                  >
                    <a>{`Transaction Hash: ${txHash}`}</a>
                  </CopyToClipboard>
                  <img className="copy" src={copy} alt="Tick Circle" onClick={() => handleCopyText()} />
                </p>

                {networkId == 137 ? (
                  <a href={`https://polygonscan.com/tx/k${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 80001 ? (
                  <a href={`https://mumbai.polygonscan.com/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 56 ? (
                  <a href={`https://bscscan.com/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 97 ? (
                  <a href={`https://testnet.bscscan.com/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 50 ? (
                  <a href={`https://xdc.blocksscan.io/txs/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 51 ? (
                  <a href={`https://apothem.blocksscan.io/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : (
                  <></>
                )}

              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={microeconomyErrorModal}
        onClose={() => setMicroeconomyErrorModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal success-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyErrorModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <img src={errorcircle} alt="Tick Circle" />

            <h3>Something Went Wrong</h3>

            <span>Try increasing your slippage tolerance.</span>
            <form>


              <div className="form-group hash">
                <input
                  type="text"
                  name='walletAddress'
                  value={txHash}
                  placeholder={txHash}
                  disabled
                  readonly
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={addliquidityModal}
        onClose={() => setAddliquidityModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal add-liquidity`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setAddliquidityModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="head">
              <h3>Add Liquidity</h3>

              <span onClick={() => clearStates()}>Clear all</span>
            </div>

            {/* <form> */}
            <div className="input-wrapper">
              <label>Select Pair</label>

              <div className="form-group">
                {primaryToken !== "" ? (
                  <input
                    type="text"
                    name="primaryToken"
                    value={primaryToken}
                    placeholder={primaryToken}
                    onInput={handleOnInput}
                  />
                ) : (
                  <input
                    type="text"
                    name="primaryToken"
                    placeholder="0x000"
                    value={primaryToken}
                    onInput={handleOnInput}
                  />
                )}

                <div onClick={() => setPoolModal1(true)} className="input-info">
                  <span>
                    {(networkId == 80001 || networkId == 137) &&
                      (pSymbol == "MATIC" || pSymbol == "WMATIC") ? (
                      <img src={Matic} className="w-5 h-5" alt="matic" />
                    ) : (networkId == 97 || networkId == 56) &&
                      (pSymbol == "BNB" || pSymbol == "WBNB") ? (
                      <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                    ) : (networkId == 51 || networkId == 50) &&
                      (pSymbol == "XDC" || pSymbol == "WXDC") ? (
                      <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <p>
                    {selectPoolToken1
                      ? selectPoolToken1
                      : pSymbol
                        ? pSymbol
                        : "Select Token"}
                  </p>
                </div>

                <p className="fee">{parseFloat(slippageValue)} % Fee Tier</p>
              </div>

              <div className="form-group">
                {secondaryToken !== "" ? (
                  <input
                    type="text"
                    name="secondaryToken"
                    value={secondaryToken}
                    placeholder={secondaryToken}
                    onInput={handleOnInput}
                  />
                ) : (
                  <input
                    type="text"
                    name="secondaryToken"
                    placeholder="0x000"
                    value={secondaryToken}
                    onInput={handleOnInput}
                  />
                )}


                <div onClick={() => setPoolModal2(true)} className="input-info">
                  <span>
                    {(networkId == 80001 || networkId == 137) &&
                      (sSymbol == "MATIC" || sSymbol == "WMATIC") ? (
                      <img src={Matic} className="w-5 h-5" alt="matic" />
                    ) : (networkId == 97 || networkId == 56) &&
                      (sSymbol == "BNB" || sSymbol == "WBNB") ? (
                      <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                    ) : (networkId == 51 || networkId == 50) &&
                      (sSymbol == "XDC" || sSymbol == "WXDC") ? (
                      <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <p>
                    {selectPoolToken2
                      ? selectPoolToken2
                      : sSymbol
                        ? sSymbol
                        : "Select Token"}
                  </p>
                </div>
              </div>
            </div>

            <label>Deposit Amounts</label>

            <div className="form-group label-handle">
              {primaryAmountP ? (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="primaryAmountP"
                  value={primaryAmountP}
                  id="exampleText0"
                  placeholder={primaryAmountP}
                  onInput={handleOnInput}
                />
              ) : (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="primaryAmountP"
                  value={primaryAmountP}
                  id="exampleText0"
                  placeholder="Enter Amount"
                  onInput={handleOnInput}
                />
              )}

              <div className="label">
                <span>{pSymbol}</span>
              </div>
            </div>

            <div className="form-group">
              {secondaryAmountP ? (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="secondaryAmountP"
                  value={secondaryAmountP}
                  id="exampleText0"
                  placeholder={secondaryAmountP}
                  onInput={handleOnInput}
                />
              ) : (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="secondaryAmountP"
                  value={secondaryAmountP}
                  id="exampleText0"
                  placeholder="Enter Amount"
                  onInput={handleOnInput}
                />
              )}

              <div className="label">
                <span>{sSymbol}</span>
              </div>
            </div>

            {primaryAmountP && secondaryAmountP !== "" ? (
              <div>
                <p className="text-[#9E9E9E] text-[14px] mt-2 mb-2">
                  Note: Enter same ratio for Amount A/B and Min-Amount A/B
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className="min-prices-wrapper">

              <div className="form-group-wrapper">
                <label>Min price {pSymbol}</label>
                <div className="form-group total-number">
                  <input
                    onWheel={handleOnWheel}
                    type="number"
                    name="primaryAmountPMin"
                    value={primaryAmountPMin}
                    placeholder={"0.036..."}
                    onInput={handleOnInput}
                  />

                  <div className="count-wrap">
                    <span onClick={(event) => {
                      event.preventDefault();
                      incrementCount();
                    }}>+</span>
                    <span onClick={(event) => {
                      event.preventDefault();
                      decrementCount();
                    }}>-</span>
                  </div>
                  <span>
                    {sSymbol !== "" && pSymbol !== ""
                      ? `${pSymbol} Per ${sSymbol}`
                      : ``}
                  </span>
                </div>
              </div>

              <div className="form-group-wrapper">
                <label>Min price {sSymbol}</label>
                <div className="form-group total-number">
                  <input
                    onWheel={handleOnWheel}
                    type="number"
                    name="secondaryAmountPMin"
                    value={secondaryAmountPMin}
                    placeholder={"0.036..."}
                    onInput={handleOnInput}
                  />

                  <div className="count-wrap">
                    <span onClick={(event) => {
                      event.preventDefault();
                      increment();
                    }}>+</span>
                    <span onClick={(event) => {
                      event.preventDefault();
                      decrement();
                    }}>-</span>
                  </div>
                  <span>
                    {sSymbol !== "" && pSymbol !== ""
                      ? `${sSymbol} Per ${pSymbol}`
                      : ``}
                  </span>
                </div>
              </div>
            </div>

            <button onClick={(event) => {
              event.preventDefault();
              addLiquidity();
            }}>Add</button>
            {/* </form> */}
          </div>
        </div>
      </Modal>

      <Modal
        open={removeliquidityModal}
        onClose={() => setRemoveliquidityModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal add-liquidity`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setRemoveliquidityModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="head">
              <h3>Remove Liquidity</h3>

              <span onClick={() => clearStates()}>Clear all</span>
            </div>

            {/* <form> */}
            <div className="input-wrapper">
              <label>Select Pair</label>

              <div className="form-group">
                {primaryToken !== "" ? (
                  <input
                    type="text"
                    name="primaryToken"
                    value={primaryToken}
                    placeholder={primaryToken}
                    onInput={handleOnInput}
                  />
                ) : (
                  <input
                    type="text"
                    name="primaryToken"
                    placeholder="0x000"
                    value={primaryToken}
                    onInput={handleOnInput}
                  />
                )}

                <div onClick={() => setPoolModal1(true)} className="input-info">
                  <span>
                    {(networkId == 80001 || networkId == 137) &&
                      (pSymbol == "MATIC" || pSymbol == "WMATIC") ? (
                      <img src={Matic} className="w-5 h-5" alt="matic" />
                    ) : (networkId == 97 || networkId == 56) &&
                      (pSymbol == "BNB" || pSymbol == "WBNB") ? (
                      <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                    ) : (networkId == 51 || networkId == 50) &&
                      (pSymbol == "XDC" || pSymbol == "WXDC") ? (
                      <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <p>
                    {selectPoolToken1
                      ? selectPoolToken1
                      : pSymbol
                        ? pSymbol
                        : "Select Token"}
                  </p>
                </div>

                {/* <p className="fee">{parseFloat(slippageValue)} % Fee Tier</p> */}
              </div>

              <div className="form-group">
                {secondaryToken !== "" ? (
                  <input
                    type="text"
                    name="secondaryToken"
                    value={secondaryToken}
                    placeholder={secondaryToken}
                    onInput={handleOnInput}
                  />
                ) : (
                  <input
                    type="text"
                    name="secondaryToken"
                    placeholder="0x000"
                    value={secondaryToken}
                    onInput={handleOnInput}
                  />
                )}


                <div onClick={() => setPoolModal2(true)} className="input-info">
                  <span>
                    {(networkId == 80001 || networkId == 137) &&
                      (sSymbol == "MATIC" || sSymbol == "WMATIC") ? (
                      <img src={Matic} className="w-5 h-5" alt="matic" />
                    ) : (networkId == 97 || networkId == 56) &&
                      (sSymbol == "BNB" || sSymbol == "WBNB") ? (
                      <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                    ) : (networkId == 51 || networkId == 50) &&
                      (sSymbol == "XDC" || sSymbol == "WXDC") ? (
                      <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <p>
                    {selectPoolToken2
                      ? selectPoolToken2
                      : sSymbol
                        ? sSymbol
                        : "Select Token"}
                  </p>
                </div>
              </div>
            </div>

            <label>Liquidity Amounts</label>

            <div className="form-group label-handle">
              {liquidityAmount ? (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="liquidityAmount"
                  value={liquidityAmount}
                  id="liquidityAmount"
                  placeholder={liquidityAmount}
                  onInput={handleOnInput}
                />
              ) : (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="liquidityAmount"
                  value={liquidityAmount}
                  id="liquidityAmount"
                  placeholder="Enter Amount"
                  onInput={handleOnInput}
                />
              )}

              <div className="label">
                <span>CiFi-LP</span>
              </div>
            </div>

            <div className="liquidity-percentage">
              <button onClick={() => setLiquidityPercent(10)}>10%</button>
              <button onClick={() => setLiquidityPercent(25)}>25%</button>
              <button onClick={() => setLiquidityPercent(50)}>50%</button>
              <button onClick={() => setLiquidityPercent(100)}>100%</button>
            </div>

            <button onClick={(event) => {
              event.preventDefault();
              removeLiquidity();
            }}>Remove</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={swapModal1}
        onClose={() => setSwapModal1(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal select-token`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setSwapModal1(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">

            <h3>Select a Token</h3>
            {/* <form> */}
            <label>Search</label>

            <div className="form-group search">
              {primaryToken !== "" ? (
                <input
                  type="text"
                  name="primaryToken"
                  value={primaryToken}
                  placeholder={primaryToken}
                  onChange={handleInput}
                />
              ) : (
                <input
                  type="text"
                  name="primaryToken"
                  value={primaryToken}
                  placeholder="Enter Token Address"
                  onChange={handleInput}
                />
              )}
            </div>

            <div className="btn-wrap">
              <button onClick={(event) => {
                event.preventDefault();
                handleSwapValue1(token);
              }}>
                {networkId == 80001 || networkId == 137 ? (
                  <img src={Matic} className="mr-2" alt="matic" />
                ) : networkId == 97 || networkId == 56 ? (
                  <img src={BNBLogo} className="mr-2" alt="bnb" />
                ) : networkId == 51 || networkId == 50 ? (
                  <img src={XDCLogo} className="mr-2" alt="xdc" />
                ) : (
                  <></>
                )}
                {token}
              </button>

              {/* <button onClick={(event) => {
                event.preventDefault();
                handleSwapValue1(wToken);
              }}>
                {networkId == 80001 || networkId == 137 ? (
                  <img src={Matic} className="mr-2" alt="matic" />
                ) : networkId == 97 || networkId == 56 ? (
                  <img src={BNBLogo} className="mr-2" alt="bnb" />
                ) : networkId == 51 || networkId == 50 ? (
                  <img src={XDCLogo} className="mr-2" alt="xdc" />
                ) : (
                  <></>
                )}
                {wToken}
              </button> */}
            </div>

            <div className="list-wrapper">
              {filteredPairs.map((pair, index) => (
                <div className="list-item">
                  <button className="token-detail"
                    key={index}
                    onClick={(event) => {
                      event.preventDefault();
                      handleSwapValue11(pair.address); // or pair.token1Address
                    }}
                  >
                    <img src={getTokenImage(pair.symbol)} alt={pair.symbol} />

                    <div className="info">
                      <h4>{pair.symbol}</h4>
                    </div>
                  </button>
                </div>
              ))}
            </div>
            {/* </form> */}
          </div>
        </div>
      </Modal>

      <Modal
        open={swapModal2}
        onClose={() => setSwapModal2(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal select-token`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setSwapModal2(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">

            <h3>Select a Token</h3>
            {/* <form> */}
            <label>Search</label>

            <div className="form-group search">
              {secondaryToken !== "" ? (
                <input
                  type="text"
                  name="secondaryToken"
                  value={secondaryToken}
                  placeholder={secondaryToken}
                  onInput={handleInput}
                />
              ) : (
                <input
                  type="text"
                  name="secondaryToken"
                  value={secondaryToken}
                  placeholder="Enter Token Address"
                  onInput={handleInput}
                />
              )}
            </div>

            <div className="btn-wrap">
              <button onClick={(event) => {
                event.preventDefault();
                handleSwapValue2(token);
              }}>
                {networkId == 80001 || networkId == 137 ? (
                  <img src={Matic} className="mr-2" alt="matic" />
                ) : networkId == 97 || networkId == 56 ? (
                  <img src={BNBLogo} className="mr-2" alt="bnb" />
                ) : networkId == 51 || networkId == 50 ? (
                  <img src={XDCLogo} className="mr-2" alt="xdc" />
                ) : (
                  <></>
                )}
                {token}
              </button>

              {/* <button onClick={(event) => {
                event.preventDefault();
                handleSwapValue1(wToken);
              }}>
                {networkId == 80001 || networkId == 137 ? (
                  <img src={Matic} className="mr-2" alt="matic" />
                ) : networkId == 97 || networkId == 56 ? (
                  <img src={BNBLogo} className="mr-2" alt="bnb" />
                ) : networkId == 51 || networkId == 50 ? (
                  <img src={XDCLogo} className="mr-2" alt="xdc" />
                ) : (
                  <></>
                )}
                {wToken}
              </button> */}
            </div>

            <div className="list-wrapper">
              {filteredPairs2.map((pair, index) => (
                <div className="list-item">
                  <button className="token-detail"
                    key={index}
                    onClick={(event) => {
                      event.preventDefault();
                      handleSwapValue22(pair.address); // or pair.token1Address
                    }}
                  >
                    <img src={getTokenImage(pair.symbol)} alt={pair.symbol} />

                    <div className="info">
                      <h4>{pair.symbol}</h4>
                    </div>
                  </button>
                </div>
              ))}
            </div>
            {/* </form> */}
          </div>
        </div>
      </Modal>

      <Modal
        open={poolModal1}
        onClose={() => setPoolModal1(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal select-token`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setPoolModal1(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">

            <h3>Select a Token</h3>
            {/* <form> */}
            <label>Search</label>

            <div className="form-group search">
              {primaryToken !== "" ? (
                <input
                  type="text"
                  name="primaryToken"
                  value={primaryToken}
                  placeholder={primaryToken}
                  onInput={handleOnInput}
                />
              ) : (
                <input
                  type="text"
                  name="primaryToken"
                  value={primaryToken}
                  placeholder="Enter Token Address"
                  onInput={handleOnInput}
                />
              )}
            </div>

            <div className="btn-wrap">
              <button onClick={(event) => {
                event.preventDefault();
                handlePoolValue1(token);
              }}>
                {networkId == 80001 || networkId == 137 ? (
                  <img src={Matic} className="mr-2" alt="matic" />
                ) : networkId == 97 || networkId == 56 ? (
                  <img src={BNBLogo} className="mr-2" alt="bnb" />
                ) : networkId == 51 || networkId == 50 ? (
                  <img src={XDCLogo} className="mr-2" alt="xdc" />
                ) : (
                  <></>
                )}
                {token}
              </button>

              {/* <button onClick={(event) => {
                event.preventDefault();
                handlePoolValue1(wToken);
              }}>
                {networkId == 80001 || networkId == 137 ? (
                  <img src={Matic} className="mr-2" alt="matic" />
                ) : networkId == 97 || networkId == 56 ? (
                  <img src={BNBLogo} className="mr-2" alt="bnb" />
                ) : networkId == 51 || networkId == 50 ? (
                  <img src={XDCLogo} className="mr-2" alt="xdc" />
                ) : (
                  <></>
                )}
                {wToken}
              </button> */}
            </div>

            <div className="list-wrapper">
              {filteredPairs.map((pair, index) => (
                <div className="list-item">
                  <button className="token-detail"
                    key={index}
                    onClick={(event) => {
                      event.preventDefault();
                      handlePoolValue11(pair.address); // or pair.token1Address
                    }}
                  >
                    <img src={getTokenImage(pair.symbol)} alt={pair.symbol} />

                    <div className="info">
                      <h4>{pair.symbol}</h4>
                    </div>
                  </button>
                </div>
              ))}
            </div>
            {/* </form> */}
          </div>
        </div>
      </Modal>

      <Modal
        open={poolModal2}
        onClose={() => setPoolModal2(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal select-token`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setPoolModal2(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">

            <h3>Select a Token</h3>
            {/* <form> */}
            <label>Search</label>

            <div className="form-group search">
              {secondaryToken !== "" ? (
                <input
                  type="text"
                  name="secondaryToken"
                  value={secondaryToken}
                  placeholder={secondaryToken}
                  onInput={handleOnInput}
                />
              ) : (
                <input
                  type="text"
                  name="secondaryToken"
                  value={secondaryToken}
                  placeholder="Enter Token Address"
                  onInput={handleOnInput}
                />
              )}
            </div>

            <div className="btn-wrap">
              <button onClick={(event) => {
                event.preventDefault();
                handlePoolValue2(token);
              }}>
                {networkId == 80001 || networkId == 137 ? (
                  <img src={Matic} className="mr-2" alt="matic" />
                ) : networkId == 97 || networkId == 56 ? (
                  <img src={BNBLogo} className="mr-2" alt="bnb" />
                ) : networkId == 51 || networkId == 50 ? (
                  <img src={XDCLogo} className="mr-2" alt="xdc" />
                ) : (
                  <></>
                )}
                {token}
              </button>

              {/* <button onClick={(event) => {
                event.preventDefault();
                handlePoolValue2(wToken);
              }}>
                {networkId == 80001 || networkId == 137 ? (
                  <img src={Matic} className="mr-2" alt="matic" />
                ) : networkId == 97 || networkId == 56 ? (
                  <img src={BNBLogo} className="mr-2" alt="bnb" />
                ) : networkId == 51 || networkId == 50 ? (
                  <img src={XDCLogo} className="mr-2" alt="xdc" />
                ) : (
                  <></>
                )}
                {wToken}
              </button> */}
            </div>

            <div className="list-wrapper">
              {filteredPairs2.map((pair, index) => (
                <div className="list-item">
                  <button className="token-detail"
                    key={index}
                    onClick={(event) => {
                      event.preventDefault();
                      handlePoolValue22(pair.address); // or pair.token1Address
                    }}
                  >
                    <img src={getTokenImage(pair.symbol)} alt={pair.symbol} />

                    <div className="info">
                      <h4>{pair.symbol}</h4>
                    </div>
                  </button>
                </div>
              ))}
            </div>
            {/* </form> */}
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  getKybStatus,
  getKycStatus,
  setBalance,
  setGrootBalance
};

const mapStateToProps = ({ Auth }) => {
  let { address, publicAddress, currentMode, kybStatus, kycStatus } = Auth;
  return { address, publicAddress, currentMode, kybStatus, kycStatus };
};

export default connect(mapStateToProps, mapDispatchToProps)(Swap);