import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import angleleft from "../../images/angle-left.png";
import subtabicon from "../../images/subtab-icon.png";
import angleright from "../../images/angle-right.png";
import DaoGenesislogo from "../../images/dao-genesis.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import subtabfillicon from "../../images/subtab-fill-icon.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setLoader, deployDAO, uploadCollection, setBalance, setGrootBalance, getKybStatus } from "../../store/actions/Auth";
import { networkId, XRC20ABI, XRC721ABI, XRC20Bytecode, XRC721Bytecode, DaoABI, DaoBytecode, CIFI, GrootSubscription, GrootSubscriptionAddress, ApiUrl } from "../../store/config";


const LaunchDaoSteps = (props, { mode }) => {

    let [toggle, setToggle] = useState(false);
    let [toggle1, setToggle1] = useState(false);
    let [formValues, setFormValues] = useState([""]);
    let [formValues1, setFormValues1] = useState([""]);
    let [selectedOption, setSelectedOption] = useState('option1');
    let [selectedOption1, setSelectedOption1] = useState('option3');

    let [daoTitle, setdaoTitle] = useState("");
    let [stakeholder, setstakeholder] = useState("");
    let [daoDescription, setdaoDescription] = useState("");
    let [proposalOwner, setproposalOwner] = useState(false);
    let [stakeholderEmail, setstakeholderEmail] = useState("");

    let [BToken, setBToken] = useState("XDC");
    let [subPrice, setSubPrice] = useState(50);
    let [BToken1, setBToken1] = useState("XDC");
    let [xrcToken, setxrcToken] = useState("");
    let [tokenUri, settokenUri] = useState("");
    let [tokenName, settokenName] = useState("");
    let [daoAddress, setDaoAddress] = useState("");
    let [tokenPrice, settokenPrice] = useState("");
    let [tokenSupply, settokenSupply] = useState("");
    let [tokenBanner, settokenBanner] = useState("");
    let [tokenSymbol, settokenSymbol] = useState("");
    let [tokenAddress, settokenAddress] = useState("");
    let [collectionName, setcollectionName] = useState("");
    let [tokenDescription, settokenDescription] = useState("");
    let [collectionSupply, setcollectionSupply] = useState("");
    let [collectionBanner, setcollectionBanner] = useState("");
    let [collectionSymbol, setcollectionSymbol] = useState("");
    let [collectionAddress, setcollectionAddress] = useState("");
    let [collectionDescription, setcollectionDescription] = useState("");
    let [tokenWallet, settokenWallet] = useState(localStorage.getItem('publicAddress'));

    let [isChecked, setIsChecked] = useState(false);

    const [model, setModel] = useState(true);

    const handleOnWheel = (event) => {
        const { type } = event.target;
        if (type === 'number') {
            event.preventDefault();
        }
    }

    useEffect(() => {
        document.addEventListener('wheel', handleOnWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleOnWheel);
        };
    }, []);

    useEffect(() => {
        if ((props.publicAddress !== null || props.publicAddress !== undefined) && web3) {
            refreshBalance()
        }
    }, [props.publicAddress, web3])

    useEffect(() => {
        if (networkId == 80001 || networkId == 137) {
            setBToken("MATIC");
        } else if (networkId == 97 || networkId == 56) {
            setBToken("BNB");
        } else if (networkId == 51 || networkId == 50) {
            setBToken("XRC");
            setBToken1("XDC");
        }
    }, [networkId]);

    async function handleOnInput(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "tokenName") {
            settokenName(e.target.value);
        } else if ([e.target.name] == "tokenSymbol") {
            settokenSymbol(e.target.value);
        } else if ([e.target.name] == "tokenUri") {
            let inputValue = e.target.value;
            if (inputValue && !inputValue.startsWith('https://')) {
                inputValue = 'https://' + inputValue;
                settokenUri(inputValue)
            } else {
                settokenUri(e.target.value);
            }
        } else if ([e.target.name] == "tokenDescription") {
            settokenDescription(e.target.value);
        } else if ([e.target.name] == "tokenSupply") {
            if (parseInt(e.target.value) > 0) {
                settokenSupply(parseInt(e.target.value));
            } else {
                settokenSupply("");
            }
        } else if ([e.target.name] == "tokenBanner") {
            settokenBanner(e.target.files[0]);
        } else if ([e.target.name] == "tokenPrice") {
            if (parseFloat(e.target.value) > 0) {
                settokenPrice(parseFloat(e.target.value));
            } else {
                settokenPrice("");
            }
        } else if ([e.target.name] == "tokenWallet") {
            let input = e.target.value;
            let output = input.substring(0, 3); // checks first three char of address
            if (output == "xdc") {
                let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
                settokenWallet(result);
            } else {
                settokenWallet(e.target.value);
            }
        } else if ([e.target.name] == "xrcToken") {
            let input = e.target.value;
            let output = input.substring(0, 3); // checks first three char of address
            if (output == "xdc") {
                let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
                setxrcToken(result);
            } else {
                setxrcToken(e.target.value);
            }
        } else if ([e.target.name] == "collectionAddress") {
            let input = e.target.value;
            let output = input.substring(0, 3); // checks first three char of address
            if (output == "xdc") {
                let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
                setcollectionAddress(result);
            } else {
                setcollectionAddress(e.target.value);
            }
        } else if ([e.target.name] == "tokenAddress") {
            let input = e.target.value;
            let output = input.substring(0, 3); // checks first three char of address
            if (output == "xdc") {
                let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
                settokenAddress(result);
            } else {
                settokenAddress(e.target.value);
            }
        } else if ([e.target.name] == "collectionSymbol") {
            setcollectionSymbol(e.target.value);
        } else if ([e.target.name] == "collectionName") {
            setcollectionName(e.target.value);
        } else if ([e.target.name] == "collectionSupply") {
            if (parseInt(e.target.value) > 0) {
                setcollectionSupply(parseInt(e.target.value));
            } else {
                setcollectionSupply("");
            }
        } else if ([e.target.name] == "collectionBanner") {
            setcollectionBanner(e.target.files[0]);
        } else if ([e.target.name] == "collectionDescription") {
            setcollectionDescription(e.target.value);
        } else if ([e.target.name] == "daoTitle") {
            setdaoTitle(e.target.value);
        } else if ([e.target.name] == "daoDescription") {
            setdaoDescription(e.target.value);
        }
        // else if ([e.target.name] == "totalTokens") {
        //   if (parseFloat(e.target.value) > 0) {
        //     settotalTokens(parseFloat(e.target.value));
        //   } else {
        //     settotalTokens("");
        //   }
        // } else if ([e.target.name] == "stakeholderPercentage") {
        //   if (parseInt(e.target.value) > 0) {
        //     setstakeholderPercentage(parseInt(e.target.value));
        //   } else {
        //     setstakeholderPercentage("");
        //   }
        // } else if ([e.target.name] == "treasuryPercentage") {
        //   if (parseInt(e.target.value) > 0) {
        //     settreasuryPercentage(parseInt(e.target.value));
        //   } else {
        //     settreasuryPercentage("");
        //   }
        // }
        else if ([e.target.name] == "toggle") {
            setToggle(!toggle);
        } else if ([e.target.name] == "toggle1") {
            setToggle1(!toggle1);
        } else if ([e.target.name] == "proposalOwner") {
            setproposalOwner(!proposalOwner);
        } else if ([e.target.name] == "stakeholderEmail") {
            setstakeholderEmail(e.target.value);
        } else if ([e.target.name] == "stakeholder") {
            let input = e.target.value;
            let output = input.substring(0, 3); // checks first three char of address
            if (output == "xdc") {
                let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
                setstakeholder(result);
            } else {
                setstakeholder(e.target.value);
            }
        }
        //  else if ([e.target.name] == "treasuryAddress") {
        //   let input = e.target.value;
        //   let output = input.substring(0, 3); // checks first three char of address
        //   if (output == "xdc") {
        //     let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        //     settreasuryAddress(result);
        //   } else {
        //     settreasuryAddress(e.target.value);
        //   }
        // }
    }

    async function getIPFSData(url) {
        return new Promise(async (resolve, reject) => {
            try {
                if (url && !url.endsWith("/")) url = url + "/";
                
                // First attempt: Check with /1 suffix
                try {
                    let response = await fetch(`${url}/1`, {
                        method: 'HEAD',
                        headers: {
                            'Accept': '*/*',
                        }
                    });
                    if (response.ok) {
                        return resolve(true);
                    }
                } catch (e) {
                    // Continue to next attempt
                }
    
                // Second attempt: Check with /1.json suffix
                try {
                    let response = await fetch(`${url}1.json`, {
                        method: 'HEAD',
                        headers: {
                            'Accept': '*/*',
                        }
                    });
                    if (response.ok) {
                        return resolve(true);
                    }
                } catch (e) {
                    // Continue to next attempt
                }
    
                // Final attempt: Check base URL
                try {
                    let response = await fetch(url, {
                        method: 'HEAD',
                        headers: {
                            'Accept': '*/*',
                        }
                    });
                    if (response.ok) {
                        return resolve(true);
                    }
                } catch (e) {
                    // Continue to final false return
                }
    
                // If all attempts fail
                return resolve(false);
                
            } catch (e) {
                console.error('Error validating IPFS URI:', e);
                return resolve(false);
            }
        });
    }
    
    async function deploy(e) {
        try {

            e.preventDefault();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (props.kybStatus !== "approved") {
                EventBus.publish("error", `Submit KYB verification to access this function!`);
                return;
              }

            if (daoTitle == "") {
                EventBus.publish("error", `Please enter DAO title`);
                return;
            }

            if (!daoTitle.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter DAO title`);
                return;
            }

            if (!daoTitle.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `DAO title must contain alphabets`);
                return;
            }

            if (daoDescription == "") {
                EventBus.publish("error", `Please enter DAO description`);
                return;
            }

            if (!daoDescription.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter DAO description`);
                return;
            }

            if (!daoDescription.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `DAO description must contain alphabets`);
                return;
            }

            if (formValues[0] == "") {
                if (stakeholder == "") {
                    EventBus.publish("error", `Please enter founder address`);
                    return;
                }
            } else if (formValues[0] !== "" || formValues.length > 0) {
                if (formValues.includes("")) {
                    EventBus.publish("error", `Please fill all shareholder address fields`);
                    return;
                }
            }

            // if (treasuryAddress == "") {
            //   EventBus.publish("error", `Please enter treasury address`);
            //   return;
            // }

            // if (!treasuryAddress.replace(/\s/g, '').length) {
            //   EventBus.publish("error", `Please enter treasury address`);
            //   return;
            // }

            // if (!treasuryAddress.match(/^[a-zA-Z0-9]+$/)) {
            //   EventBus.publish("error", `Invalid Treasury Address`);
            //   return;
            // }

            // if (totalTokens == "") {
            //   EventBus.publish("error", `Please enter governance token amount`);
            //   return;
            // }

            // if (stakeholderPercentage == "") {
            //   EventBus.publish("error", `Please enter founder %`);
            //   return;
            // }

            // if (treasuryPercentage == "") {
            //   EventBus.publish("error", `Please enter treasury %`);
            //   return;
            // }

            let tokenType = "";

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            if (collectionAddress == "") {
                let paymentToken = "";

                if (collectionName == "") {
                    EventBus.publish("error", `Please enter collection name`);
                    return;
                }

                if (!collectionName.replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter collection name`);
                    return;
                }

                if (!collectionName.match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Collection name must contain alphabets`);
                    return;
                }

                if (collectionSymbol == "") {
                    EventBus.publish("error", `Please enter collection symbol`);
                    return;
                }

                if (!collectionSymbol.replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter collection symbol`);
                    return;
                }

                if (!collectionSymbol.match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Collection symbol must contain alphabets`);
                    return;
                }

                if (collectionSupply == "") {
                    EventBus.publish("error", `Please enter collection supply`);
                    return;
                }

                if (tokenUri == "") {
                    EventBus.publish("error", `Please enter base URI`);
                    return;
                }

                if (!tokenUri.replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter base URI`);
                    return;
                }

                if (!tokenUri.match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Base URI must contain alphabets`);
                    return;
                }

                let checkUrl = await getIPFSData(tokenUri);
                if (checkUrl == false) return EventBus.publish('error', `Invalid base URI`);

                if (tokenPrice == "") {
                    EventBus.publish("error", `Please enter collection price`);
                    return;
                }

                if (tokenWallet == "") {
                    EventBus.publish("error", `Please enter collection wallet`);
                    return;
                }

                if (!tokenWallet.replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter collection wallet`);
                    return;
                }

                if (!tokenWallet.match(/^[a-zA-Z0-9]+$/)) {
                    EventBus.publish("error", `Invalid Token Wallet`);
                    return;
                }

                if (xrcToken == "") {
                    setxrcToken("0x0000000000000000000000000000000000000000");
                    paymentToken = "0x0000000000000000000000000000000000000000";
                } else {

                    if (!xrcToken.replace(/\s/g, '').length) {
                        EventBus.publish("error", `Please enter Payment Token`);
                        return;
                    }

                    if (!xrcToken.match(/^[a-zA-Z0-9]+$/)) {
                        EventBus.publish("error", `Invalid Payment Token Address`);
                        return;
                    }

                    let code = await web3.eth.getCode(xrcToken.toLowerCase());
                    if (code === "0x") {
                        EventBus.publish("error", `Invalid Payment Token Address!`);
                        return;
                    } else {
                        paymentToken = xrcToken;
                    }
                }

                if (collectionDescription == "") {
                    EventBus.publish("error", `Please enter collection description`);
                    return;
                }

                if (!collectionDescription.replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter collection description`);
                    return;
                }

                if (!collectionDescription.match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Collection description must contain alphabets`);
                    return;
                }

                if (collectionBanner == "") {
                    EventBus.publish("error", `Please upload collection banner`);
                    return;
                }

                if (collectionBanner == undefined) {
                    EventBus.publish("error", `Please upload collection banner`);
                    return;
                }

                if (publicAddress == null || publicAddress == undefined) {
                    EventBus.publish("error", `Please connect your wallet!`);
                    return;
                }

                let from = publicAddress;
                let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
                if (output == "xdc") {
                    from = "0x" + publicAddress.substring(3);
                } else {
                    from = publicAddress;
                }

                let price = await web3.utils.toWei(tokenPrice.toString(), "ether");
                tokenType = "XRC721";

                props.setLoader({
                    message: "NFT Deployment in Progress...",
                    status: true,
                });

                let contract = new web3.eth.Contract(XRC721ABI);
                let deploy = await contract.deploy({ data: XRC721Bytecode, arguments: [collectionName, collectionSymbol, 0, from, collectionSupply, tokenUri, price, tokenWallet, paymentToken] });

                await deploy.send({ from: deployer })
                    .on('receipt', async receipt => {
                        collectionAddress = receipt['contractAddress'];
                        console.log("NFT deployed successfully:", receipt);
                        setcollectionAddress(receipt['contractAddress']);

                        let data = new FormData();
                        data.append('tokenName', collectionName);
                        data.append('tokenSymbol', collectionSymbol);
                        data.append('tokenDescription', collectionDescription);
                        data.append('tokenWallet', tokenWallet);
                        data.append('tokenAddress', receipt['contractAddress']);
                        data.append('tokenSupply', collectionSupply);
                        data.append('tokenPrice', tokenPrice);
                        data.append('tokenType', tokenType);
                        data.append('from', from);
                        data.append('banner', collectionBanner);
                        data.append('paymentToken', paymentToken);
                        data.append('network', parseInt(networkId));

                        props.uploadCollection(data);
                        await waitFor(2500);
                        console.log(`************** deploy xrc721 contract address = `, receipt['contractAddress'])


                        if (tokenAddress == "") {
                            if (tokenName == "") {
                                EventBus.publish("error", `Please enter token name`);
                                return;
                            }

                            if (!tokenName.replace(/\s/g, '').length) {
                                EventBus.publish("error", `Please enter token name`);
                                return;
                            }

                            if (!tokenName.match(/[a-zA-Z]/)) {
                                EventBus.publish("error", `Token name must contain alphabets`);
                                return;
                            }

                            if (tokenSymbol == "") {
                                EventBus.publish("error", `Please enter token symbol`);
                                return;
                            }

                            if (!tokenSymbol.replace(/\s/g, '').length) {
                                EventBus.publish("error", `Please enter token symbol`);
                                return;
                            }

                            if (!tokenSymbol.match(/[a-zA-Z]/)) {
                                EventBus.publish("error", `Token symbol must contain alphabets`);
                                return;
                            }

                            if (tokenSupply == "") {
                                EventBus.publish("error", `Please enter token supply`);
                                return;
                            }

                            if (publicAddress == null || publicAddress == undefined) {
                                EventBus.publish("error", `Please connect your wallet!`);
                                return;
                            }

                            if (tokenDescription == "") {
                                EventBus.publish("error", `Please enter token description`);
                                return;
                            }

                            if (!tokenDescription.replace(/\s/g, '').length) {
                                EventBus.publish("error", `Please enter token description`);
                                return;
                            }

                            if (!tokenDescription.match(/[a-zA-Z]/)) {
                                EventBus.publish("error", `Token description must contain alphabets`);
                                return;
                            }

                            if (tokenBanner == "") {
                                EventBus.publish("error", `Please upload token banner`);
                                return;
                            }

                            if (tokenBanner == undefined) {
                                EventBus.publish("error", `Please upload token banner`);
                                return;
                            }

                            let from = publicAddress;
                            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
                            if (output == "xdc") {
                                from = "0x" + publicAddress.substring(3);
                            } else {
                                from = publicAddress;
                            }

                            tokenType = "XRC20"

                            let supply = await web3.utils.toWei(tokenSupply.toString(), "ether");

                            props.setLoader({
                                message: "Crypto Deployment in Progress...",
                                status: true,
                            });

                            let contract = new web3.eth.Contract(XRC20ABI);
                            let deploy = await contract.deploy({ data: XRC20Bytecode, arguments: [tokenName, tokenSymbol, supply] });

                            await deploy.send({ from: deployer })
                                .on('receipt', async receipt => {
                                    tokenAddress = receipt['contractAddress'];
                                    settokenAddress(receipt['contractAddress']);
                                    let data = new FormData();
                                    data.append('tokenName', tokenName);
                                    data.append('tokenSymbol', tokenSymbol);
                                    data.append('tokenDescription', tokenDescription);
                                    data.append('tokenAddress', receipt['contractAddress']);
                                    data.append('tokenSupply', tokenSupply);
                                    data.append('tokenType', tokenType);
                                    data.append('from', from);
                                    data.append('banner', tokenBanner);
                                    data.append('network', parseInt(networkId));
                                    await props.uploadCollection(data);

                                    EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
                                    await waitFor(2500);
                                    console.log(`************** deploy xrc20 contract address = `, receipt['contractAddress'])


                                    /////////////////////////////////////////////////////////////////////////////////////////////

                                    // let sub = await GrootSubscription.methods.subscribeDaos(deployer).call();
                                    // if (!sub['status']) {
                                    //   props.setLoader({
                                    //     message: "Subscription in Progress...",
                                    //     status: true,
                                    //   });
                                    //   let balance = await CIFI.methods.balanceOf(deployer).call();
                                    //   let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
                                    //   let req1 = web3.utils.fromWei(req.toString(), 'ether');
                                    //   setSubPrice(parseFloat(req1));
                                    //   if (parseInt(balance) < parseInt(req)) {
                                    //     props.setLoader({
                                    //       message: "Subscription Failed...",
                                    //       status: false,
                                    //     });
                                    //     EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
                                    //     return;
                                    //   } else {
                                    //     await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
                                    //     await GrootSubscription.methods.daoSubscription(req).send({ from: deployer });
                                    //   }
                                    // }

                                    props.setLoader({
                                        message: "DAO Deployment in Progress...",
                                        status: true,
                                    });

                                    let contract = new web3.eth.Contract(DaoABI);
                                    let deploy = await contract.deploy({
                                        data: DaoBytecode, arguments: [daoTitle, daoDescription, tokenAddress, collectionAddress, stakeholder !== "" ? [stakeholder] : formValues, proposalOwner]
                                    });

                                    await deploy.send({ from: deployer })
                                        .on('receipt', async receipt => {
                                            setDaoAddress(receipt['contractAddress']);
                                            props.setLoader({
                                                message: "Contract Deploy...",
                                                status: false,
                                            });

                                            let data = new FormData();

                                            data.append('title', daoTitle);
                                            data.append('description', daoDescription);
                                            data.append('tokenAddress', tokenAddress);
                                            data.append('collectionAddress', collectionAddress);
                                            // data.append('treasuryAddress', treasuryAddress);
                                            data.append('stakeholders', stakeholder !== "" ? [stakeholder] : [formValues]);
                                            data.append('stakeholderEmails', stakeholderEmail !== "" ? [stakeholderEmail] : [formValues1]);
                                            // data.append('stakeholderPercentage', stakeholderPercentage);
                                            // data.append('treasuryPercentage', treasuryPercentage);
                                            // data.append('totalTokens', totalTokens);
                                            data.append('network', parseInt(networkId));
                                            data.append('daoAddress', receipt['contractAddress']);
                                            await props.deployDAO(data);
                                            await refreshBalance();
                                            waitFor(2500);

                                            setdaoTitle("");
                                            setstakeholder("");
                                            setstakeholderEmail("");
                                            setToggle1(false);
                                            setToggle(false);
                                            setFormValues([""]);
                                            // settotalTokens("");
                                            setdaoDescription("");
                                            setproposalOwner(false);
                                            // settreasuryAddress("");
                                            // settreasuryPercentage("");
                                            // setstakeholderPercentage("");
                                            setxrcToken("");
                                            settokenUri("");
                                            settokenName("");
                                            settokenPrice("");
                                            settokenSupply("");
                                            settokenBanner("");
                                            settokenSymbol("");
                                            setcollectionName("");
                                            settokenDescription("");
                                            setcollectionSupply("");
                                            setcollectionBanner("");
                                            setcollectionSymbol("");
                                            setcollectionDescription("");
                                            console.log(`************** deploy dao contract address = `, receipt['contractAddress'])
                                        });
                                });
                        } else {

                            // let sub = await GrootSubscription.methods.subscribeDaos(deployer).call();
                            // if (!sub['status']) {
                            //   props.setLoader({
                            //     message: "Subscription in Progress...",
                            //     status: true,
                            //   });
                            //   let balance = await CIFI.methods.balanceOf(deployer).call();
                            //   let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
                            //   let req1 = web3.utils.fromWei(req.toString(), 'ether');
                            //   setSubPrice(parseFloat(req1));
                            //   if (parseInt(balance) < parseInt(req)) {
                            //     props.setLoader({
                            //       message: "Subscription Failed...",
                            //       status: false,
                            //     });
                            //     EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
                            //     return;
                            //   } else {
                            //     await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
                            //     await GrootSubscription.methods.daoSubscription(req).send({ from: deployer });
                            //   }
                            // }

                            props.setLoader({
                                message: "DAO Deployment in Progress...",
                                status: true,
                            });

                            let contract = new web3.eth.Contract(DaoABI);
                            let deploy = await contract.deploy({
                                data: DaoBytecode, arguments: [daoTitle, daoDescription, tokenAddress, collectionAddress, stakeholder !== "" ? [stakeholder] : formValues, proposalOwner]
                            });

                            await deploy.send({ from: deployer })
                                .on('receipt', async receipt => {
                                    setDaoAddress(receipt['contractAddress']);
                                    props.setLoader({
                                        message: "Contract Deploy...",
                                        status: false,
                                    });

                                    let data = new FormData();

                                    data.append('title', daoTitle);
                                    data.append('description', daoDescription);
                                    data.append('tokenAddress', tokenAddress);
                                    data.append('collectionAddress', collectionAddress);
                                    // data.append('treasuryAddress', treasuryAddress);
                                    data.append('stakeholders', stakeholder !== "" ? [stakeholder] : formValues);
                                    data.append('stakeholderEmails', stakeholderEmail !== "" ? [stakeholderEmail] : [formValues1]);
                                    // data.append('stakeholderPercentage', stakeholderPercentage);
                                    // data.append('treasuryPercentage', treasuryPercentage);
                                    // data.append('totalTokens', totalTokens);
                                    data.append('network', parseInt(networkId));
                                    data.append('daoAddress', receipt['contractAddress']);
                                    await props.deployDAO(data);
                                    await refreshBalance();
                                    waitFor(2500);

                                    setdaoTitle("");
                                    setstakeholder("");
                                    setstakeholderEmail("");
                                    setToggle1(false);
                                    setToggle(false);
                                    setFormValues([""]);
                                    // settotalTokens("");
                                    setdaoDescription("");
                                    setproposalOwner(false);
                                    // settreasuryAddress("");
                                    // settreasuryPercentage("");
                                    // setstakeholderPercentage("");
                                    setxrcToken("");
                                    settokenUri("");
                                    settokenName("");
                                    settokenPrice("");
                                    settokenSupply("");
                                    settokenBanner("");
                                    settokenSymbol("");
                                    setcollectionName("");
                                    settokenDescription("");
                                    setcollectionSupply("");
                                    setcollectionBanner("");
                                    setcollectionSymbol("");
                                    setcollectionDescription("");
                                    console.log(`************** deploy dao contract address = `, receipt['contractAddress'])
                                });
                        }
                    }).on('error', (error) => {
                        console.error("NFT deployment failed:", error);
                        EventBus.publish("error", `NFT deployment failed: ${error.message}`);
                    });

            } else if (tokenAddress == "") {
                if (tokenName == "") {
                    EventBus.publish("error", `Please enter token name`);
                    return;
                }

                if (!tokenName.replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter token name`);
                    return;
                }

                if (!tokenName.match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Token name must contain alphabets`);
                    return;
                }

                if (tokenSymbol == "") {
                    EventBus.publish("error", `Please enter token symbol`);
                    return;
                }

                if (!tokenSymbol.replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter token symbol`);
                    return;
                }

                if (!tokenSymbol.match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Token symbol must contain alphabets`);
                    return;
                }

                if (tokenSupply == "") {
                    EventBus.publish("error", `Please enter token supply`);
                    return;
                }

                if (publicAddress == null || publicAddress == undefined) {
                    EventBus.publish("error", `Please connect your wallet!`);
                    return;
                }

                if (tokenDescription == "") {
                    EventBus.publish("error", `Please enter token description`);
                    return;
                }

                if (!tokenDescription.replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter token description`);
                    return;
                }

                if (!tokenDescription.match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Token description must contain alphabets`);
                    return;
                }

                if (tokenBanner == "") {
                    EventBus.publish("error", `Please upload token banner`);
                    return;
                }

                if (tokenBanner == undefined) {
                    EventBus.publish("error", `Please upload token banner`);
                    return;
                }

                let from = publicAddress;
                let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
                if (output == "xdc") {
                    from = "0x" + publicAddress.substring(3);
                } else {
                    from = publicAddress;
                }

                tokenType = "XRC20"

                let supply = await web3.utils.toWei(tokenSupply.toString(), "ether");

                props.setLoader({
                    message: "Crypto Deployment in Progress...",
                    status: true,
                });

                let contract = new web3.eth.Contract(XRC20ABI);
                let deploy = await contract.deploy({ data: XRC20Bytecode, arguments: [tokenName, tokenSymbol, supply] });

                await deploy.send({ from: deployer })
                    .on('receipt', async receipt => {
                        tokenAddress = receipt['contractAddress'];
                        settokenAddress(receipt['contractAddress']);
                        let data = new FormData();
                        data.append('tokenName', tokenName);
                        data.append('tokenSymbol', tokenSymbol);
                        data.append('tokenDescription', tokenDescription);
                        data.append('tokenAddress', receipt['contractAddress']);
                        data.append('tokenSupply', tokenSupply);
                        data.append('tokenType', tokenType);
                        data.append('from', from);
                        data.append('banner', tokenBanner);
                        data.append('network', parseInt(networkId));
                        await props.uploadCollection(data);

                        EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
                        await waitFor(2500);
                        console.log(`************** deploy xrc20 contract address = `, receipt['contractAddress'])


                        /////////////////////////////////////////////////////////////////////////////////////////////

                        // let sub = await GrootSubscription.methods.subscribeDaos(deployer).call();
                        // if (!sub['status']) {
                        //   props.setLoader({
                        //     message: "Subscription in Progress...",
                        //     status: true,
                        //   });
                        //   let balance = await CIFI.methods.balanceOf(deployer).call();
                        //   let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
                        //   let req1 = web3.utils.fromWei(req.toString(), 'ether');
                        //   setSubPrice(parseFloat(req1));
                        //   if (parseInt(balance) < parseInt(req)) {
                        //     props.setLoader({
                        //       message: "Subscription Failed...",
                        //       status: false,
                        //     });
                        //     EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
                        //     return;
                        //   } else {
                        //     await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
                        //     await GrootSubscription.methods.daoSubscription(req).send({ from: deployer });
                        //   }
                        // }

                        props.setLoader({
                            message: "DAO Deployment in Progress...",
                            status: true,
                        });

                        let contract = new web3.eth.Contract(DaoABI);
                        let deploy = await contract.deploy({
                            data: DaoBytecode, arguments: [daoTitle, daoDescription, tokenAddress, collectionAddress, stakeholder !== "" ? [stakeholder] : formValues, proposalOwner]
                        });

                        await deploy.send({ from: deployer })
                            .on('receipt', async receipt => {
                                setDaoAddress(receipt['contractAddress']);
                                props.setLoader({
                                    message: "Contract Deploy...",
                                    status: false,
                                });

                                let data = new FormData();

                                data.append('title', daoTitle);
                                data.append('description', daoDescription);
                                data.append('tokenAddress', tokenAddress);
                                data.append('collectionAddress', collectionAddress);
                                // data.append('treasuryAddress', treasuryAddress);
                                data.append('stakeholders', stakeholder !== "" ? [stakeholder] : [formValues]);
                                data.append('stakeholderEmails', stakeholderEmail !== "" ? [stakeholderEmail] : [formValues1]);
                                // data.append('stakeholderPercentage', stakeholderPercentage);
                                // data.append('treasuryPercentage', treasuryPercentage);
                                // data.append('totalTokens', totalTokens);
                                data.append('network', parseInt(networkId));
                                data.append('daoAddress', receipt['contractAddress']);
                                await props.deployDAO(data);
                                await refreshBalance();
                                waitFor(2500);

                                setdaoTitle("");
                                setstakeholder("");
                                setstakeholderEmail("");
                                setToggle1(false);
                                setToggle(false);
                                setFormValues([""]);
                                // settotalTokens("");
                                setdaoDescription("");
                                setproposalOwner(false);
                                // settreasuryAddress("");
                                // settreasuryPercentage("");
                                // setstakeholderPercentage("");
                                setxrcToken("");
                                settokenUri("");
                                settokenName("");
                                settokenPrice("");
                                settokenSupply("");
                                settokenBanner("");
                                settokenSymbol("");
                                setcollectionName("");
                                settokenDescription("");
                                setcollectionSupply("");
                                setcollectionBanner("");
                                setcollectionSymbol("");
                                setcollectionDescription("");
                                console.log(`************** deploy dao contract address = `, receipt['contractAddress'])
                            });
                    });
            } else {

                // let sub = await GrootSubscription.methods.subscribeDaos(deployer).call();
                // if (!sub['status']) {
                //   props.setLoader({
                //     message: "Subscription in Progress...",
                //     status: true,
                //   });
                //   let balance = await CIFI.methods.balanceOf(deployer).call();
                //   let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
                //   let req1 = web3.utils.fromWei(req.toString(), 'ether');
                //   setSubPrice(parseFloat(req1));
                //   if (parseInt(balance) < parseInt(req)) {
                //     props.setLoader({
                //       message: "Subscription Failed...",
                //       status: false,
                //     });
                //     EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
                //     return;
                //   } else {
                //     await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
                //     await GrootSubscription.methods.daoSubscription(req).send({ from: deployer });
                //   }
                // }

                props.setLoader({
                    message: "DAO Deployment in Progress...",
                    status: true,
                });

                let contract = new web3.eth.Contract(DaoABI);
                let deploy = await contract.deploy({
                    data: DaoBytecode, arguments: [daoTitle, daoDescription, tokenAddress, collectionAddress, stakeholder !== "" ? [stakeholder] : formValues, proposalOwner]
                });

                await deploy.send({ from: deployer })
                    .on('receipt', async receipt => {
                        setDaoAddress(receipt['contractAddress']);
                        props.setLoader({
                            message: "Contract Deploy...",
                            status: false,
                        });

                        let data = new FormData();

                        data.append('title', daoTitle);
                        data.append('description', daoDescription);
                        data.append('tokenAddress', tokenAddress);
                        data.append('collectionAddress', collectionAddress);
                        // data.append('treasuryAddress', treasuryAddress);
                        data.append('stakeholders', stakeholder !== "" ? [stakeholder] : [formValues]);
                        data.append('stakeholderEmails', stakeholderEmail !== "" ? [stakeholderEmail] : [formValues1]);
                        // data.append('stakeholderPercentage', stakeholderPercentage);
                        // data.append('treasuryPercentage', treasuryPercentage);
                        // data.append('totalTokens', totalTokens);
                        data.append('network', parseInt(networkId));
                        data.append('daoAddress', receipt['contractAddress']);
                        await props.deployDAO(data);
                        await refreshBalance();
                        waitFor(2500);

                        setdaoTitle("");
                        setstakeholder("");
                        setstakeholderEmail("");
                        setToggle1(false);
                        setToggle(false);
                        setFormValues([""]);
                        // settotalTokens("");
                        setdaoDescription("");
                        setproposalOwner(false);
                        // settreasuryAddress("");
                        // settreasuryPercentage("");
                        // setstakeholderPercentage("");
                        setxrcToken("");
                        settokenUri("");
                        settokenName("");
                        settokenPrice("");
                        settokenSupply("");
                        settokenBanner("");
                        settokenSymbol("");
                        setcollectionName("");
                        settokenDescription("");
                        setcollectionSupply("");
                        setcollectionBanner("");
                        setcollectionSymbol("");
                        setcollectionDescription("");
                        console.log(`************** deploy dao contract address = `, receipt['contractAddress'])
                    });
            }

        } catch (e) {
            console.log(e);
            props.setLoader({
                status: false,
            });
            EventBus.publish("error", `Unable To Deploy`);
        }
    }

    async function refreshBalance() {
        // let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
        // let req1 = web3.utils.fromWei(req.toString(), 'ether');
        // setSubPrice(parseFloat(req1));
        let address = (await web3.currentProvider.enable())[0];
        let balance = await web3.eth.getBalance(address); //Will give value in.
        let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
        tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
        balance = await web3.utils.fromWei(balance.toString(), "ether");
        let precision = 3;
        let power = Math.pow(10, precision);
        balance = parseFloat(balance);
        balance = Math.trunc(balance * power) / power;
        props.setBalance(balance);
        tokenBalance = parseFloat(tokenBalance);
        tokenBalance = Math.trunc(tokenBalance * power) / power;
        props.setGrootBalance(tokenBalance);
        props.getKybStatus({ publicAddress: address });
    }

    async function addFormFields() {
        setFormValues([...formValues, ""]);
    }

    async function addFormFields1() {
        setFormValues1([...formValues1, ""]);
    }

    async function removeFormFields(i) {
        formValues.splice(i, 1);
        setFormValues(formValues)
    }

    async function handleChange(i, e) {
        formValues[i] = e.target.value;
        setFormValues([...formValues])
    }

    async function handleChange1(i, e) {
        formValues1[i] = e.target.value;
        setFormValues1([...formValues1])
    }

    const handleRadioChange = (event) => {
        setIsChecked(event.target.checked);
    };

    async function copiedAddress() {
        EventBus.publish("success", "Contract Address Copied");
    }

    const handleOptionChange = (event) => {
        const newValue = event.target.value;
        setSelectedOption((prevValue) => (prevValue === newValue ? '' : newValue));
    };

    const handleOptionChange1 = (event) => {
        const newValue1 = event.target.value;
        setSelectedOption1((prevValue1) => (prevValue1 === newValue1 ? '' : newValue1));
    };

    const totalSteps = 6;
    const [step, setStep] = useState(1);

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const progress = Math.round((step / totalSteps) * 100);

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="step">
                        <h2>Describe your DAO</h2>
                        <p>Add DAO details here</p>

                        <div className="form-wrap dir-col">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name='daoTitle'
                                    value={daoTitle}
                                    maxlength="30"
                                    onChange={handleOnInput}
                                    placeholder="DAO Title*"
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    as="textarea"
                                    rows={1}
                                    name='daoDescription'
                                    maxlength="250"
                                    value={daoDescription}
                                    onChange={handleOnInput}
                                    placeholder="DAO Description*  (Max 250 Characters)"
                                />
                            </div>
                        </div>

                        <div className="btn-wrap">
                            <Link className="btn back" to="/dashboard/circularity-dao/launch-dao">
                                <img src={angleleft} alt="" /> Back
                            </Link>

                            <button className="btn next" onClick={handleNextStep}>
                                Next <img src={angleright} alt="" />
                            </button>

                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="step">
                        <h2>Setup shareholders email addresses</h2>
                        <p>Add DAO shareholders emails here</p>

                        <div className="form-wrap dir-col no-height">
                            <div class="form-group checkbox-area">
                                <input type="checkbox" id="multi-gov-email" checked={toggle1} name="toggle1" value={!toggle1} onChange={handleOnInput} />
                                <label for="multi-gov-email">Add multiple shareholders emails</label>
                            </div>


                            {
                                !toggle1 &&
                                <div className="form-group">
                                    <input
                                        type="text"
                                        maxlength="60"
                                        name='stakeholderEmail'
                                        value={stakeholderEmail}
                                        onChange={handleOnInput}
                                        placeholder="Founder email*"
                                    />
                                </div>
                            }

                            {
                                toggle1 &&

                                <>
                                    {formValues1.map((element, index) => (
                                        <>
                                            <div className="form-group">
                                                <input type="text" className="form-control" name={element || ""} value={element || ""} placeholder={`${index == 0 ? "Founder email" : "Shareholder email"}`} maxlength="60" onChange={e => handleChange1(index, e)} />
                                            </div>
                                        </>
                                    ))}
                                    <div className="form-group">
                                        <button onClick={(event) => {
                                            event.preventDefault();
                                            addFormFields1();
                                        }}>
                                            Add shareholders emails
                                        </button>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="btn-wrap">
                            <button className="btn back" onClick={handlePreviousStep}>
                                <img src={angleleft} alt="" /> Back
                            </button>

                            <button className="btn next" onClick={handleNextStep}>
                                Next <img src={angleright} alt="" />
                            </button>

                        </div>

                    </div>
                );
            case 3:
                return (
                    <div className="step">
                        <h2>Configure your NFT collection</h2>
                        <p>Configure your NFT collection with DAO.</p>

                        <div className="radio-wrap">
                            <p>
                                <input type="radio" id="create-nft" name="radio-group1" value="option1" checked={selectedOption === 'option1'}
                                    onChange={handleOptionChange} />
                                <label for="create-nft">
                                    Create NFT
                                </label>
                            </p>

                            <p>
                                <input type="radio" id="own-nft" name="radio-group1" value="option2" checked={selectedOption === 'option2'}
                                    onChange={handleOptionChange} />
                                <label for="own-nft">
                                    Already own NFT
                                </label>
                            </p>
                        </div>

                        {
                            selectedOption === 'option1' &&
                            <div className="form-wrap dir-col">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='collectionName'
                                        value={collectionName}
                                        maxlength="25"
                                        onChange={handleOnInput}
                                        placeholder="Name*"
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='collectionSymbol'
                                        value={collectionSymbol}
                                        maxlength="10"
                                        onChange={handleOnInput}
                                        placeholder="Symbol*"
                                    />
                                </div>

                                {collectionBanner == "" ?
                                    <>
                                        <div className="form-group filewrap">
                                            <span>{collectionBanner ? (collectionBanner['name'] && collectionBanner['name'].substring(0, 20) + '...') : "No file chosen"}</span>
                                            <div className="upload-btn">Choose file</div>
                                            <input
                                                type="file"
                                                name='collectionBanner'
                                                placeholder={collectionBanner ? (collectionBanner['name'] && collectionBanner['name'].substring(0, 20) + '...') : "No file chosen"}
                                                onChange={handleOnInput}
                                                accept="image/*"
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="form-group filewrap">
                                            <span>{collectionBanner ? (collectionBanner['name'] && collectionBanner['name'].substring(0, 20) + '...') : "No file chosen"}</span>
                                            <div className="upload-btn">Choose file</div>
                                            <input
                                                type="file"
                                                name='collectionBanner'
                                                placeholder={collectionBanner ? (collectionBanner['name'] && collectionBanner['name'].substring(0, 20) + '...') : "No file chosen"}
                                                onChange={handleOnInput}
                                                accept="image/*"
                                            />
                                        </div>
                                    </>
                                }

                                <div className="form-group">
                                    <label>
                                        <a href="https://tool.cifiapp.com/" target="_blank">Click here to generate your Base URI</a>
                                    </label>
                                    <input
                                        type="text"
                                        name='tokenUri'
                                        value={tokenUri}
                                        onChange={handleOnInput}
                                        maxlength="200"
                                        placeholder="Base URI*"
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        onWheel={handleOnWheel}
                                        type="number"
                                        name='tokenPrice'
                                        value={tokenPrice}
                                        onChange={handleOnInput}
                                        placeholder="Price in XDC*"
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='tokenWallet'
                                        value={tokenWallet}
                                        onChange={handleOnInput}
                                        maxlength="43"
                                        placeholder="Token Wallet*"
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='xrcToken'
                                        value={xrcToken}
                                        onChange={handleOnInput}
                                        maxlength="43"
                                        placeholder={`Payable Token (Leave empty for ${BToken1})*`}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        onWheel={handleOnWheel}
                                        type="number"
                                        name='collectionSupply'
                                        value={collectionSupply}
                                        onChange={handleOnInput}
                                        placeholder="Total Supply*"
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='collectionDescription'
                                        value={collectionDescription}
                                        onChange={handleOnInput}
                                        maxlength="250"
                                        placeholder="Description* (Max 250 Characters)"
                                    />
                                </div>
                            </div>
                        }
                        {
                            selectedOption === 'option2' &&
                            <div className="form-wrap dir-col">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='collectionAddress'
                                        value={collectionAddress}
                                        onChange={handleOnInput}
                                        maxlength="43"
                                        placeholder="NFT Contract Address*"
                                    />
                                </div>
                            </div>
                        }


                        <div className="btn-wrap">
                            <button className="btn back" onClick={handlePreviousStep}>
                                <img src={angleleft} alt="" /> Back
                            </button>

                            <button className="btn next" onClick={handleNextStep}>
                                Next <img src={angleright} alt="" />
                            </button>

                        </div>

                    </div>
                );

            case 4:
                return (
                    <div className="step">
                        <h2>Setup DAO rules</h2>
                        <p>Set rules to allow shareholders to create proposals or not</p>

                        <div class="radio-select">
                            <p>
                                <input type="radio" id="sh-submit-proposal" name="proposalOwner" checked={!proposalOwner} value={!proposalOwner} onChange={handleOnInput} />
                                <label for="sh-submit-proposal">Allow shareholders to submit proposal</label>
                            </p>

                            <p>
                                <input type="radio" id="owner-submit-proposal" name="proposalOwner" checked={proposalOwner} value={proposalOwner} onChange={handleOnInput} />
                                <label for="owner-submit-proposal">Owner can submit proposal only</label>
                            </p>
                        </div>

                        <div className="btn-wrap">
                            <button className="btn back" onClick={handlePreviousStep}>
                                <img src={angleleft} alt="" /> Back
                            </button>

                            <button className="btn next" onClick={handleNextStep}>
                                Next <img src={angleright} alt="" />
                            </button>
                        </div>
                    </div>
                );

            case 5:
                return (
                    <div className="step">
                        <h2>Setup shareholders wallet addresses</h2>
                        <p>Add DAO shareholders wallet addresses here</p>

                        <div className="form-wrap dir-col no-height">
                            <div class="form-group checkbox-area">
                                <input type="checkbox" id="multi-gov" checked={toggle} name="toggle" value={!toggle} onChange={handleOnInput} />
                                <label for="multi-gov">Add multiple shareholders wallet addresses</label>
                            </div>


                            {
                                !toggle &&
                                <div className="form-group">
                                    <input
                                        type="text"
                                        maxlength="43"
                                        name='stakeholder'
                                        value={stakeholder}
                                        onChange={handleOnInput}
                                        placeholder="Founder Address*"
                                    />
                                </div>
                            }
                            {
                                toggle &&
                                <>
                                    {formValues.map((element, index) => (
                                        <div className="form-group">
                                            <input type="text" className="form-control" name={element || ""} value={element || ""} placeholder={`${index == 0 ? "Founder address" : "Shareholder address"}`} maxlength="43" onChange={e => handleChange(index, e)} />
                                        </div>
                                    ))}
                                    <div className="form-group">
                                        <button onClick={(event) => {
                                            event.preventDefault();
                                            addFormFields();
                                        }}>
                                            Add shareholders
                                        </button>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="btn-wrap">
                            <button className="btn back" onClick={handlePreviousStep}>
                                <img src={angleleft} alt="" /> Back
                            </button>

                            <button className="btn next" onClick={handleNextStep}>
                                Next <img src={angleright} alt="" />
                            </button>
                        </div>
                    </div>
                );

            case 6:
                return (
                    <div className="step">
                        <h2>Configure your {BToken} token</h2>
                        <p>Add your {BToken} 20 token with DAO</p>

                        <div className="radio-wrap">
                            <p>
                                <input type="radio" id="create-token" name="radio-group2" value="option3" checked={selectedOption1 === 'option3'}
                                    onChange={handleOptionChange1} />
                                <label for="create-token">
                                    Create token
                                </label>
                            </p>

                            <p>
                                <input type="radio" id="own-token" name="radio-group2" value="option4" checked={selectedOption1 === 'option4'}
                                    onChange={handleOptionChange1} />
                                <label for="own-token">
                                    Already own token
                                </label>
                            </p>
                        </div>

                        {
                            selectedOption1 === 'option3' &&
                            <div className="form-wrap dir-col">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='tokenName'
                                        value={tokenName}
                                        onChange={handleOnInput}
                                        maxlength="25"
                                        placeholder="Name*"
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='tokenSymbol'
                                        value={tokenSymbol}
                                        onChange={handleOnInput}
                                        maxlength="10"
                                        placeholder="Symbol*"
                                    />
                                </div>

                                {tokenBanner == "" ?
                                    <>
                                        <div className="form-group filewrap">
                                            <span>{tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 20) + '...') : "No file chosen"}</span>
                                            <div className="upload-btn">Choose file</div>
                                            <input
                                                type="file"
                                                name='tokenBanner'
                                                placeholder={tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 20) + '...') : "No file chosen"}
                                                onChange={handleOnInput}
                                                accept="image/*"
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="form-group filewrap">
                                            <span>{tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 20) + '...') : "No file chosen"}</span>
                                            <div className="upload-btn">Choose file</div>
                                            <input
                                                type="file"
                                                name='tokenBanner'
                                                placeholder={tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 20) + '...') : "No file chosen"}
                                                onChange={handleOnInput}
                                                accept="image/*"
                                            />
                                        </div>
                                    </>
                                }

                                <div className="form-group">
                                    <input
                                        onWheel={handleOnWheel}
                                        type="number"
                                        name='tokenSupply'
                                        value={tokenSupply}
                                        onChange={handleOnInput}
                                        placeholder="Total Supply*"
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='tokenDescription'
                                        value={tokenDescription}
                                        onChange={handleOnInput}
                                        maxlength="250"
                                        placeholder="Description* (Max 250 Characters)"
                                    />
                                </div>

                                <div className="h-24">

                                </div>
                            </div>
                        }
                        {
                            selectedOption1 === 'option4' &&
                            <div className="form-wrap dir-col">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name='tokenAddress'
                                        value={tokenAddress}
                                        onChange={handleOnInput}
                                        maxlength="43"
                                        placeholder="Token Contract Address*"
                                    />
                                </div>
                            </div>
                        }

                        <div className="btn-wrap">
                            <button className="btn back" onClick={handlePreviousStep}>
                                <img src={angleleft} alt="" /> Back
                            </button>

                            <button className="btn next" onClick={deploy}>
                                Create DAO <img src={angleright} alt="" />
                            </button>

                        </div>

                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="microeconomy-wrapper dao-genesis launch-dao">
            <div className="microeconomy-head">
                <a href="#" className="logo-wrap">
                    <img src={DaoGenesislogo} alt="" />
                    <span>Circularity DAO</span>
                </a>
                {props.isLoader.status == true &&
                    <Prompt when={model}
                        message="Transaction in progress, leaving page may result in transaction failure!"
                        beforeUnload={true}
                    />
                }
                {
                    (daoTitle !== "" || daoDescription !== "") &&
                    <Prompt when={model}
                        message="Leaving page will result in losing details entered!"
                        beforeUnload={true}
                    />
                }
                <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
                <ConnectWallet />
            </div>

            <div className="steps-form-wrapper">
                <div className="mb-10">
                    <div className="progress-head">
                        <h3>Launch a dao</h3>
                        <div className="dao-address">
                            {
                                daoAddress !== "" &&
                                <>
                                    <p>
                                        <CopyToClipboard
                                            text={daoAddress}
                                            onCopy={copiedAddress}
                                        >
                                            <a>{`DAO Address: ${daoAddress}`}</a>
                                        </CopyToClipboard>
                                    </p>
                                    <p>
                                        <CopyToClipboard
                                            text={tokenAddress}
                                            onCopy={copiedAddress}
                                        >
                                            <a>{`Token Address: ${tokenAddress}`}</a>
                                        </CopyToClipboard>
                                    </p>
                                    <p>
                                        <CopyToClipboard
                                            text={collectionAddress}
                                            onCopy={copiedAddress}
                                        >
                                            <a>{`Collection Address: ${collectionAddress}`}</a>
                                        </CopyToClipboard>
                                    </p>
                                    <p> You can visit View all DAOs to view your DAO</p>
                                </>
                            }
                        </div>

                        <span>
                            Step {step} of {totalSteps}
                        </span>
                    </div>

                    <div className="progress-bar">
                        <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
                    </div>

                </div>
                {renderStep()}
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    setLoader,
    deployDAO,
    getKybStatus,
    uploadCollection,
    setBalance,
    setGrootBalance
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, isLoader, kybStatus } = Auth;
    return { publicAddress, isLoader, kybStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchDaoSteps);