import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import goarrow from "../../images/go-arrow.png";
import modalcloseicon from "../../images/close.png";
import uploadicon from "../../images/uploadicon.png";
import infocircle from "../../images/infocircle.svg";
import deploytoken from "../../images/deploytoken.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import microeconomylogo from "../../images/microeconomy-icon.png";
import tokengenerationstepinfo from "../../images/token-generation-step-info.png";
import createnftstepinfo from "../../images/create-nft-step-info.png";
import deployvestingstepinfo from "../../images/deploy-vesting-step-info.png";
import { setLoader, uploadCollection, setBalance, setGrootBalance } from "../../store/actions/Auth";
import { networkId, XRC20ABI, XRC20Bytecode, ICOXRC20ABI, ICOXRC20Bytecode, CIFI, GrootSubscription, GrootSubscriptionAddress } from "../../store/config";
import { useNavigate } from "react-router-dom";

const DeployToken = (props) => {
  const [subPrice, setSubPrice] = useState(50);
  const [tokenName, settokenName] = useState("");
  const [tokenSymbol, settokenSymbol] = useState("");
  const [tokenSupply, settokenSupply] = useState("");
  const [tokenBanner, settokenBanner] = useState("");
  const [tokenAddress, settokenAddress] = useState("");
  const [tokenDescription, settokenDescription] = useState("");

  const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [model, setModel] = useState(true);
  const [stepModal, setStepModal] = useState(true);

  const [stepModal1, setStepModal1] = useState(false);
  const [stepModal2, setStepModal2] = useState(false);

  const navigate = useNavigate();

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  setTimeout(() => {
    const modalRoot = document.querySelectorAll('.react-responsive-modal-root');
    if (modalRoot) {
      //Add a custom class to the root element

      modalRoot.forEach((element) => {
        const stepsModal = element.querySelector('.steps-modal');
        const confirmation1Modal = element.querySelector('.confirmation-modal');

        if (stepsModal) {
          // Perform your action here
          element.classList.add('custom-modal-root');
        }

        if (confirmation1Modal) {
          // Perform your action here
          element.classList.add('confirmation-modal-root');
        }
      });

    }
    else {
      console.error("Element not found.");
    }

  }, 1000);

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "tokenName") {
      settokenName(e.target.value);
    } else if ([e.target.name] == "tokenSymbol") {
      settokenSymbol(e.target.value);
    } else if ([e.target.name] == "tokenDescription") {
      settokenDescription(e.target.value);
    } else if ([e.target.name] == "tokenSupply") {
      if (parseInt(e.target.value) > 0) {
        settokenSupply(parseInt(e.target.value));
      } else {
        settokenSupply("");
      }
    } else if ([e.target.name] == "tokenBanner") {
      settokenBanner(e.target.files[0]);
    }
  }

  useEffect(() => {
    if (web3 && props.publicAddress) {
      refreshBalance()
    }
  }, [web3, props.publicAddress])

  async function refreshBalance() {
    let req = await GrootSubscription.methods.tokenSubscriptionPrice().call();
    let req1 = web3.utils.fromWei(req.toString(), 'ether');
    setSubPrice(parseFloat(req1));
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function deploy(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      if (tokenName == "") {
        EventBus.publish("error", `Please enter token name`);
        return;
      }

      if (!tokenName.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token name`);
        return;
      }

      if (!tokenName.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token name must contain alphabets`);
        return;
      }

      if (tokenSymbol == "") {
        EventBus.publish("error", `Please enter token symbol`);
        return;
      }

      if (!tokenSymbol.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token symbol must contain alphabets`);
        return;
      }

      if (!tokenSymbol.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token symbol`);
        return;
      }

      if (tokenSupply == "") {
        EventBus.publish("error", `Please enter token supply`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (tokenDescription == "") {
        EventBus.publish("error", `Please enter token description`);
        return;
      }

      if (!tokenDescription.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token description`);
        return;
      }

      if (!tokenDescription.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token description must contain alphabets`);
        return;
      }

      if (tokenBanner == "") {
        EventBus.publish("error", `Please upload token banner`);
        return;
      }

      if (tokenBanner == undefined) {
        EventBus.publish("error", `Please upload token banner`);
        return;
      }

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);
      let sub = await GrootSubscription.methods.subscribeXRC20s(from).call();
      if (!sub['status']) {
        props.setLoader({
          message: "Subscription in Progress...",
          status: true,
        });
        let balance = await CIFI.methods.balanceOf(from).call();
        let req = await GrootSubscription.methods.tokenSubscriptionPrice().call();
        let req1 = web3.utils.fromWei(req.toString(), 'ether');
        setSubPrice(parseFloat(req1));
        if (parseInt(balance) < parseInt(req)) {
          EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
          props.setLoader({
            message: "Subscription Failed...",
            status: false,
          });
          return;
        } else {
          props.setLoader({
            message: "Subscription in Progress...",
            status: true,
          });
          await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
          await GrootSubscription.methods.tokenSubscription(req).send({ from: deployer });
        }
      }

      props.setLoader({
        message: "XRC20 Deployment in Progress...",
        status: true,
      });

      let tokenType = "XRC20"

      let supply = await web3.utils.toWei(tokenSupply.toString(), "ether");

      let contract = new web3.eth.Contract(XRC20ABI);
      let deploy = await contract.deploy({ data: XRC20Bytecode, arguments: [tokenName, tokenSymbol, supply] });

      await deploy.send({ from: deployer })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {

          settokenAddress(receipt['contractAddress']);
          let tokenAddressDeployed = receipt['contractAddress'];
          console.log("*** Deployed XRC20 Token Address :: ", tokenAddressDeployed);

          props.setLoader({
            message: "Airdrop Deployment in Progress...",
            status: true,
          });

          let contractICO = new web3.eth.Contract(ICOXRC20ABI);
          let deployNew = await contractICO.deploy({ data: ICOXRC20Bytecode, arguments: [tokenAddressDeployed] });

          await deployNew.send({ from: deployer })
            .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
            .on('receipt', async receipt => {

              let icoDeployed = receipt['contractAddress'];
              console.log("*** Deployed Airdrop ICO Address :: ", icoDeployed);

              props.setLoader({
                message: "Adding Minter in Progress...",
                status: true,
              });

              let XRC20Contract = new web3.eth.Contract(XRC20ABI, tokenAddressDeployed);

              await web3.eth
                .sendTransaction({
                  from: deployer,
                  value: 0,
                  to: tokenAddressDeployed,
                  gas: 5000000,
                  data: XRC20Contract.methods
                    .addMinter(icoDeployed)
                    .encodeABI(),
                })
                .on('transactionHash', hash => {
                  console.log(`************** deploy contract hash = ${hash}`);
                })
                .on('receipt', async receipt => {

                  props.setLoader({
                    message: "Contract Deploy...",
                    status: false,
                  });

                  let data = new FormData();
                  data.append('tokenName', tokenName);
                  data.append('tokenSymbol', tokenSymbol);
                  data.append('tokenDescription', tokenDescription);
                  data.append('tokenAddress', tokenAddressDeployed);
                  data.append('ico', icoDeployed);
                  data.append('tokenSupply', tokenSupply);
                  data.append('tokenType', tokenType);
                  data.append('from', from);
                  data.append('banner', tokenBanner);
                  data.append('network', parseInt(networkId));
                  await props.uploadCollection(data);

                  EventBus.publish("success", `Deployment Complete`);

                  await refreshBalance();

                  waitFor(1500);
                  setStepModal1(true);
                  settokenName("");
                  settokenSymbol("");
                  settokenDescription("");
                  settokenSupply("");
                  settokenBanner("");
                  console.log(`************** deploy contract address = `, receipt['contractAddress'])
                });
            });
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Deployment Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Deploy`);
    }
  };

  async function copiedAddress() {
    EventBus.publish("success", "Contract Address Copied");
  }

  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {
          (tokenName !== "" || tokenSymbol !== "" || tokenSupply !== "" || tokenBanner !== "" || tokenDescription !== "") &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />
      </div>

      <div className="form-wrap">
        <div className="inner">
          <h2>Token Generation<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>



          {
            tokenAddress !== "" &&
            <>
              <div className="copy-wrap">
                <p>
                  <CopyToClipboard
                    text={tokenAddress}
                    onCopy={copiedAddress}
                  >
                    {networkId == 137 ? (
                      <a href={`https://polygonscan.com/address/k${tokenAddress}`} target="_blank">
                        {`Crypto Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 80001 ? (
                      <a href={`https://mumbai.polygonscan.com/address/${tokenAddress}`} target="_blank">
                        {`Crypto Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 56 ? (
                      <a href={`https://bscscan.com/address/${tokenAddress}`} target="_blank">
                        {`Crypto Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 97 ? (
                      <a href={`https://testnet.bscscan.com/address/${tokenAddress}`} target="_blank">
                        {`Crypto Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 50 ? (
                      <a href={`https://xdc.blocksscan.io/address/${tokenAddress}`} target="_blank">
                        {`Crypto Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 51 ? (
                      <a href={`https://apothem.blocksscan.io/address/${tokenAddress}`} target="_blank">
                        {`Crypto Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : (
                      <></>
                    )}
                  </CopyToClipboard>
                </p>

              </div>
              <p className="mb-4">You can visit profile page to view your token</p>
            </>
          }

          <form onSubmit={deploy}>
            <div className="form-group">
              <input
                type="text"
                name='tokenName'
                value={tokenName}
                onChange={handleOnInput}
                maxlength="42"
                placeholder="Name*"
              />

              <div className="info-wrap name-notice-btn">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Enter Your XRC20 Token Name Here</p>
                </div>
              </div>
            </div>

            <div className="form-group">
              <input
                type="text"
                name='tokenSymbol'
                value={tokenSymbol}
                onChange={handleOnInput}
                maxlength="10"
                placeholder="Symbol*"
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Enter Your XRC20 Token Symbol Here</p>
                </div>
              </div>
            </div>

            <div className="form-group filewrap">
              {tokenBanner == "" ?
                <>
                  <span>Upload Banner*</span>
                  <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload File</div>
                  <input
                    type="file"
                    name='tokenBanner'
                    placeholder="Upload Banner*"
                    accept="image/*"
                    onChange={handleOnInput}
                  />

                  <div className="info-wrap">
                    <img src={infocircle} alt="" />

                    <div className="info">

                      <p>Upload Your XRC20 Token Banner Here</p>
                    </div>
                  </div>
                </>
                :
                <>
                  <span>{tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 10) + '...') : "Upload Banner*"}</span>
                  <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload File</div>
                  <input
                    type="file"
                    name='tokenBanner'
                    placeholder={tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 10) + '...') : "Upload Banner*"}
                    accept="image/*"
                    onChange={handleOnInput}
                  />

                  <div className="info-wrap">
                    <img src={infocircle} alt="" />

                    <div className="info">

                      <p>Upload Your XRC20 Token Banner Here</p>
                    </div>
                  </div>
                </>
              }
            </div>

            <div className="form-group">
              <input
                onWheel={handleOnWheel}
                type="number"
                name='tokenSupply'
                value={tokenSupply}
                onChange={handleOnInput}
                placeholder="Total Supply*"
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Enter Your XRC20 Token Supply Here</p>
                </div>
              </div>
            </div>

            <div className="form-group">
              <textarea
                as="textarea"
                rows={3}
                name='tokenDescription'
                value={tokenDescription}
                onChange={handleOnInput}
                maxlength="250"
                placeholder="Description* (Max 250 Characters)"
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Enter Your XRC20 Token Description Here</p>
                </div>
              </div>
            </div>

            <button type="submit">
              Generate Crypto Token
            </button>
          </form>

          <p>Note: You will need {subPrice} CIFI Tokens for one time fee to deploy your token.</p>
        </div>
      </div>

      <Modal
        open={microeconomyToolModal}
        onClose={() => setMicroeconomyToolModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyToolModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={deploytoken} alt="" />

          <h3>Deploy Your Token</h3>

          <p>Create and launch your custom tokens with ease. Our user-friendly module supports XRC20, XRC721, and reward tokens, perfect for fostering community engagement, incentivizing participation, or crowdfunding your projects.</p>
        </div>
      </Modal>

      <Modal
        open={stepModal}
        onClose={() => setStepModal(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <p>Visit your <span>Profile</span> under <span>Micro economy</span> to view your deployed token.</p>
          </div>

          <img src={tokengenerationstepinfo} alt="" />

        </div>
      </Modal>

      <Modal
        open={stepModal1}
        onClose={() => setStepModal1(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal1(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 1/2</h3>
            <p>You can attach your Token to NFT as payment method in <span>Create NFT Collection</span></p>
          </div>

          <img src={createnftstepinfo} alt="" />

          <div className="steps-btns">
            <button disabled>Previous step</button>

            <button onClick={() => { setStepModal2(true); setStepModal1(false) }}>Next step</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal2}
        onClose={() => setStepModal2(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal2(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 2/2</h3>
            <p>You can create a vesting mechanism for your token in <span>Deploy Vesting</span></p>
          </div>

          <img src={deployvestingstepinfo} alt="" />

          <div className="steps-btns">
            <button onClick={() => { setStepModal1(true); setStepModal2(false) }}>Previous step</button>

            <button disabled>Next step</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        classNames={{
          //overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal confirmation-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setConfirmationModal(false)}
          />
        </button>

        <div className="modal-body">
          <h3>Are you Sure?</h3>

          <div className="confirmation-btns">
            <button>Yes</button>

            <button>No</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  uploadCollection,
  setBalance,
  setGrootBalance
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, currentMode, isLoader, kycStatus, kybStatus } = Auth;
  return { publicAddress, currentMode, isLoader, kycStatus, kybStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeployToken);
