import Logo from "../../images/Grootlogo.png";
import tradeicons from "../../images/tradeicon.png";
import handicon from "../../images/hand-icon.svg";
import poolicon from "../../images/pool-icon.png";
import stakeicon from "../../images/stake-icon.png";
import tradeicon from "../../images/trade-icon.svg";
import historyicon from "../../images/history-icon.svg";
import offeringicon from "../../images/offering-icon.svg";
import swapicon from "../../images/swapicon.png";
import github from "../../images/github-icon.svg";
import discord from "../../images/discord.svg";
import twitter from "../../images/twitter.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function Hidding() {
    const [open , setopen] = useState(false)
  const getPaths = useLocation();
  return (
    <div className="font-medium pb-8 backdrop-blur-[30px] min-w-[340px] shadow-2xl rounded-[26px] text-center xl:block hidden">
      <div className="text-xl text-gray-100">
        <div class="mt-1 flex justify-center items-center">
          <img src={Logo} alt="logo" className="" />
        </div>
        <div class="my-1 bg-[#02163812] h-[1px]"></div>
      </div>

      <ul className="mt-14">
        <li>
          <Link to="/dashboard">
            <div
              className={`${
                getPaths.pathname === "/dashboard" && "bg-[#B99237]"
              } h-[70px] flex items-center pl-8  duration-300 cursor-pointer hover:bg-[#B99237] text-white`}
            >
              <img src={tradeicons} alt="" className="w-6 h-6" />
            </div>
          </Link>
        </li>

        <li>
          <Link to="/dashboard/borrow">
            <div
              class={`${
                getPaths.pathname === "/dashboard/borrow" && "bg-[#B99237]"
              } h-[70px] flex items-center pl-8  duration-300 cursor-pointer hover:bg-[#B99237] text-white`}
            >
              <img src={handicon} alt="" className="w-6 h-6" />
            </div>
          </Link>
        </li>

        <li>
          <Link to="/dashboard/stability">
            <div
              class={`${
                getPaths.pathname === "/dashboard/stability" && "bg-[#B99237]"
              } h-[70px] flex items-center pl-8  duration-300 cursor-pointer hover:bg-[#B99237] text-white `}
            >
              <img src={poolicon} alt="" className="w-6 h-6" />
            </div>
          </Link>
        </li>

        <li>
          <Link to="/dashboard/stake">
            <div
              class={`${
                getPaths.pathname === "/dashboard/stake" && "bg-[#B99237]"
              } h-[70px] flex items-center pl-8  duration-300 cursor-pointer hover:bg-[#B99237] text-white`}
            >
              <img src={stakeicon} alt="" className="w-6 h-6" />
            </div>
          </Link>
        </li>

        <li>
          <Link to="/dashboard/trades">
            <div
              class={`${
                getPaths.pathname === "/dashboard/trades" && "bg-[#B99237]"
              } h-[70px] flex items-center pl-8  duration-300 cursor-pointer hover:bg-[#B99237] text-white`}
            >
              <img src={tradeicon} alt="" className="w-6 h-6" />
            </div>
          </Link>
        </li>

        <li>
          <Link to="/dashboard/history">
            <div
              class={`${
                getPaths.pathname === "/dashboard/history" && "bg-[#B99237]"
              } h-[70px] flex items-center pl-8  duration-300 cursor-pointer hover:bg-[#B99237] text-white`}
            >
              <img src={historyicon} alt="" className="w-6 h-6" />
            </div>
          </Link>
        </li>

        <li>
          <Link to="/dashboard/initial-offer">
            <div
              class={`${
                getPaths.pathname === "/dashboard/initial-offer" &&
                "bg-[#B99237]"
              } h-[70px] flex items-center pl-8  duration-300 cursor-pointer hover:bg-[#B99237] text-white`}
            >
              <img src={offeringicon} alt="" className="w-6 h-6" />
            </div>
          </Link>
        </li>
        <li>
          <Link to="/dashboard/swap">
            <div
              class={`${
                getPaths.pathname === "/dashboard/swap" && "bg-[#B99237]"
              } h-[70px] flex items-center pl-8  duration-300 cursor-pointer hover:bg-[#B99237] text-white`}
            >
              <img src={swapicon} alt="" className="w-6 h-6" />
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
}
