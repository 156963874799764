import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import modalcloseicon from "../../images/close.png";
import infocircle from "../../images/infocircle.svg";
import subtabicon from "../../images/subtab-icon.png";
import DaoGenesislogo from "../../images/dao-genesis.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import subtabfillicon from "../../images/subtab-fill-icon.png";
import microeconomyicon from "../../images/microeconomy-icon.png";
import { setLoader, deployDAO, uploadCollection, setBalance, setGrootBalance } from "../../store/actions/Auth";
import { networkId, XRC20ABI, XRC721ABI, XRC20Bytecode, XRC721Bytecode, DaoABI, DaoBytecode, Groot, CIFI, GrootSubscription, GrootSubscriptionAddress, ApiUrl } from "../../store/config";


const LaunchaDao = (props, { mode }) => {

  let [toggle, setToggle] = useState(false);
  let [toggle1, setToggle1] = useState(false);
  let [formValues, setFormValues] = useState([""]);
  let [formValues1, setFormValues1] = useState([""]);
  let [selectedOption, setSelectedOption] = useState('option1');
  let [selectedOption1, setSelectedOption1] = useState('option3');

  let [daoTitle, setdaoTitle] = useState("");
  let [stakeholder, setstakeholder] = useState("");
  // let [totalTokens, settotalTokens] = useState("");
  let [daoDescription, setdaoDescription] = useState("");
  let [proposalOwner, setproposalOwner] = useState(false);
  let [stakeholderEmail, setstakeholderEmail] = useState("");
  // let [treasuryAddress, settreasuryAddress] = useState("");
  // let [treasuryPercentage, settreasuryPercentage] = useState("");
  // let [stakeholderPercentage, setstakeholderPercentage] = useState("");

  let [BToken, setBToken] = useState("XDC");
  let [subPrice, setSubPrice] = useState(50);
  let [BToken1, setBToken1] = useState("XDC");
  let [xrcToken, setxrcToken] = useState("");
  let [tokenUri, settokenUri] = useState("");
  let [tokenName, settokenName] = useState("");
  let [daoAddress, setDaoAddress] = useState("");
  let [tokenPrice, settokenPrice] = useState("");
  let [tokenSupply, settokenSupply] = useState("");
  let [tokenBanner, settokenBanner] = useState("");
  let [tokenSymbol, settokenSymbol] = useState("");
  let [tokenAddress, settokenAddress] = useState("");
  let [collectionName, setcollectionName] = useState("");
  let [tokenDescription, settokenDescription] = useState("");
  let [collectionSupply, setcollectionSupply] = useState("");
  let [collectionBanner, setcollectionBanner] = useState("");
  let [collectionSymbol, setcollectionSymbol] = useState("");
  let [collectionAddress, setcollectionAddress] = useState("");
  let [collectionDescription, setcollectionDescription] = useState("");
  let [tokenWallet, settokenWallet] = useState(localStorage.getItem('publicAddress'));

  let [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  let [isChecked, setIsChecked] = useState(false);

  const [model, setModel] = useState(true);

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  useEffect(() => {
    if (web3 && props.publicAddress) {
      refreshBalance()
    }
  }, [web3, props.publicAddress])

  useEffect(() => {
    if (networkId == 80001 || networkId == 137) {
      setBToken("MATIC");
    } else if (networkId == 97 || networkId == 56) {
      setBToken("BNB");
    } else if (networkId == 51 || networkId == 50) {
      setBToken("XRC");
      setBToken1("XDC");
    }
  }, [networkId]);

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "tokenName") {
      settokenName(e.target.value);
    } else if ([e.target.name] == "tokenSymbol") {
      settokenSymbol(e.target.value);
    } else if ([e.target.name] == "tokenUri") {
      let inputValue = e.target.value;
      if (inputValue && !inputValue.startsWith('https://')) {
        inputValue = 'https://' + inputValue;
        settokenUri(inputValue)
      } else {
        settokenUri(e.target.value);
      }
    } else if ([e.target.name] == "tokenDescription") {
      settokenDescription(e.target.value);
    } else if ([e.target.name] == "tokenSupply") {
      if (parseInt(e.target.value) > 0) {
        settokenSupply(parseInt(e.target.value));
      } else {
        settokenSupply("");
      }
    } else if ([e.target.name] == "tokenBanner") {
      settokenBanner(e.target.files[0]);
    } else if ([e.target.name] == "tokenPrice") {
      if (parseFloat(e.target.value) > 0) {
        settokenPrice(parseFloat(e.target.value));
      } else {
        settokenPrice("");
      }
    } else if ([e.target.name] == "tokenWallet") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        settokenWallet(result);
      } else {
        settokenWallet(e.target.value);
      }
    } else if ([e.target.name] == "xrcToken") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        setxrcToken(result);
      } else {
        setxrcToken(e.target.value);
      }
    } else if ([e.target.name] == "collectionAddress") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        setcollectionAddress(result);
      } else {
        setcollectionAddress(e.target.value);
      }
    } else if ([e.target.name] == "tokenAddress") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        settokenAddress(result);
      } else {
        settokenAddress(e.target.value);
      }
    } else if ([e.target.name] == "collectionSymbol") {
      setcollectionSymbol(e.target.value);
    } else if ([e.target.name] == "collectionName") {
      setcollectionName(e.target.value);
    } else if ([e.target.name] == "collectionSupply") {
      if (parseInt(e.target.value) > 0) {
        setcollectionSupply(parseInt(e.target.value));
      } else {
        setcollectionSupply("");
      }
    } else if ([e.target.name] == "collectionBanner") {
      setcollectionBanner(e.target.files[0]);
    } else if ([e.target.name] == "collectionDescription") {
      setcollectionDescription(e.target.value);
    } else if ([e.target.name] == "daoTitle") {
      setdaoTitle(e.target.value);
    } else if ([e.target.name] == "daoDescription") {
      setdaoDescription(e.target.value);
    }
    // else if ([e.target.name] == "totalTokens") {
    //   if (parseFloat(e.target.value) > 0) {
    //     settotalTokens(parseFloat(e.target.value));
    //   } else {
    //     settotalTokens("");
    //   }
    // } else if ([e.target.name] == "stakeholderPercentage") {
    //   if (parseInt(e.target.value) > 0) {
    //     setstakeholderPercentage(parseInt(e.target.value));
    //   } else {
    //     setstakeholderPercentage("");
    //   }
    // } else if ([e.target.name] == "treasuryPercentage") {
    //   if (parseInt(e.target.value) > 0) {
    //     settreasuryPercentage(parseInt(e.target.value));
    //   } else {
    //     settreasuryPercentage("");
    //   }
    // }
    else if ([e.target.name] == "toggle") {
      setToggle(!toggle);
    } else if ([e.target.name] == "toggle1") {
      setToggle1(!toggle1);
    } else if ([e.target.name] == "proposalOwner") {
      setproposalOwner(!proposalOwner);
    } else if ([e.target.name] == "stakeholderEmail") {
      setstakeholderEmail(e.target.value);
    } else if ([e.target.name] == "stakeholder") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        setstakeholder(result);
      } else {
        setstakeholder(e.target.value);
      }
    }
    //  else if ([e.target.name] == "treasuryAddress") {
    //   let input = e.target.value;
    //   let output = input.substring(0, 3); // checks first three char of address
    //   if (output == "xdc") {
    //     let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
    //     settreasuryAddress(result);
    //   } else {
    //     settreasuryAddress(e.target.value);
    //   }
    // }
  }

  async function getIPFSData(url) {
    return new Promise(async (resolve, reject) => {
      try {
        if (url && !url.endsWith("/")) url = url + "/";
        let data = await fetch(`${ApiUrl}/v3/validateURI`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
          },
          body: JSON.stringify({ url: `${url}1` }), // Convert your data to JSON format if needed
        });
        if (parseInt(data.status) == 200) {
          data = await data.json();
          return resolve(true);
        }
        if (parseInt(data.status) == 400) {
          let data = await fetch(`${ApiUrl}/v3/validateURI`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
            },
            body: JSON.stringify({ url: `${url}1.json` }), // Convert your data to JSON format if needed
          });
          if (parseInt(data.status) == 200) {
            return resolve(true);
          } else if (parseInt(data.status) == 400) {
            let data = await fetch(`${ApiUrl}/v3/validateURI`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
              },
              body: JSON.stringify({ url }), // Convert your data to JSON format if needed
            });
            if (parseInt(data.status) == 200) {
              return resolve(true);
            }
            else return resolve(false);
          }
        }
      } catch (e) {
        return resolve(false);
      }
    });
  }

  async function deploy(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (daoTitle == "") {
        EventBus.publish("error", `Please enter DAO title`);
        return;
      }

      if (!daoTitle.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter DAO title`);
        return;
      }

      if (!daoTitle.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `DAO title must contain alphabets`);
        return;
      }

      if (daoDescription == "") {
        EventBus.publish("error", `Please enter DAO description`);
        return;
      }

      if (!daoDescription.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter DAO description`);
        return;
      }

      if (!daoDescription.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `DAO description must contain alphabets`);
        return;
      }

      if (formValues[0] == "") {
        if (stakeholder == "") {
          EventBus.publish("error", `Please enter founder address`);
          return;
        }
      } else if (formValues[0] !== "" || formValues.length > 0) {
        if (formValues.includes("")) {
          EventBus.publish("error", `Please fill all shareholder address fields`);
          return;
        }
      }

      // if (treasuryAddress == "") {
      //   EventBus.publish("error", `Please enter treasury address`);
      //   return;
      // }

      // if (!treasuryAddress.replace(/\s/g, '').length) {
      //   EventBus.publish("error", `Please enter treasury address`);
      //   return;
      // }

      // if (!treasuryAddress.match(/^[a-zA-Z0-9]+$/)) {
      //   EventBus.publish("error", `Invalid Treasury Address`);
      //   return;
      // }

      // if (totalTokens == "") {
      //   EventBus.publish("error", `Please enter governance token amount`);
      //   return;
      // }

      // if (stakeholderPercentage == "") {
      //   EventBus.publish("error", `Please enter founder %`);
      //   return;
      // }

      // if (treasuryPercentage == "") {
      //   EventBus.publish("error", `Please enter treasury %`);
      //   return;
      // }

      let tokenType = "";

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      if (collectionAddress == "") {
        let paymentToken = "";

        if (collectionName == "") {
          EventBus.publish("error", `Please enter collection name`);
          return;
        }

        if (!collectionName.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter collection name`);
          return;
        }

        if (!collectionName.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Collection name must contain alphabets`);
          return;
        }

        if (collectionSymbol == "") {
          EventBus.publish("error", `Please enter collection symbol`);
          return;
        }

        if (!collectionSymbol.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter collection symbol`);
          return;
        }

        if (!collectionSymbol.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Collection symbol must contain alphabets`);
          return;
        }

        if (collectionSupply == "") {
          EventBus.publish("error", `Please enter collection supply`);
          return;
        }

        if (tokenUri == "") {
          EventBus.publish("error", `Please enter base URI`);
          return;
        }

        if (!tokenUri.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter base URI`);
          return;
        }

        if (!tokenUri.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Base URI must contain alphabets`);
          return;
        }

        let checkUrl = await getIPFSData(tokenUri);
        if (checkUrl == false) return EventBus.publish('error', `Invalid base URI`);

        if (tokenPrice == "") {
          EventBus.publish("error", `Please enter collection price`);
          return;
        }

        if (tokenWallet == "") {
          EventBus.publish("error", `Please enter collection wallet`);
          return;
        }

        if (!tokenWallet.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter collection wallet`);
          return;
        }

        if (!tokenWallet.match(/^[a-zA-Z0-9]+$/)) {
          EventBus.publish("error", `Invalid Token Wallet`);
          return;
        }

        if (xrcToken == "") {
          setxrcToken("0x0000000000000000000000000000000000000000");
          paymentToken = "0x0000000000000000000000000000000000000000";
        } else {

          if (!xrcToken.replace(/\s/g, '').length) {
            EventBus.publish("error", `Please enter Payment Token`);
            return;
          }

          if (!xrcToken.match(/^[a-zA-Z0-9]+$/)) {
            EventBus.publish("error", `Invalid Payment Token Address`);
            return;
          }

          let code = await web3.eth.getCode(xrcToken.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Payment Token Address!`);
            return;
          } else {
            paymentToken = xrcToken;
          }
        }

        if (collectionDescription == "") {
          EventBus.publish("error", `Please enter collection description`);
          return;
        }

        if (!collectionDescription.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter collection description`);
          return;
        }

        if (!collectionDescription.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Collection description must contain alphabets`);
          return;
        }

        if (collectionBanner == "") {
          EventBus.publish("error", `Please upload collection banner`);
          return;
        }

        if (collectionBanner == undefined) {
          EventBus.publish("error", `Please upload collection banner`);
          return;
        }

        if (publicAddress == null || publicAddress == undefined) {
          EventBus.publish("error", `Please connect your wallet!`);
          return;
        }

        let from = publicAddress;
        let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
        if (output == "xdc") {
          from = "0x" + publicAddress.substring(3);
        } else {
          from = publicAddress;
        }

        let price = await web3.utils.toWei(tokenPrice.toString(), "ether");
        tokenType = "XRC721";

        props.setLoader({
          message: "NFT Deployment in Progress...",
          status: true,
        });

        let contract = new web3.eth.Contract(XRC721ABI);
        let deploy = await contract.deploy({ data: XRC721Bytecode, arguments: [collectionName, collectionSymbol, 0, from, collectionSupply, tokenUri, price, tokenWallet, paymentToken] });

        await deploy.send({ from: deployer })
          .on('receipt', async receipt => {
            collectionAddress = receipt['contractAddress'];
            setcollectionAddress(receipt['contractAddress']);

            let data = new FormData();
            data.append('tokenName', collectionName);
            data.append('tokenSymbol', collectionSymbol);
            data.append('tokenDescription', collectionDescription);
            data.append('tokenWallet', tokenWallet);
            data.append('tokenAddress', receipt['contractAddress']);
            data.append('tokenSupply', collectionSupply);
            data.append('tokenPrice', tokenPrice);
            data.append('tokenType', tokenType);
            data.append('from', from);
            data.append('banner', collectionBanner);
            data.append('paymentToken', paymentToken);
            data.append('network', parseInt(networkId));

            props.uploadCollection(data);
            await waitFor(2500);
            console.log(`************** deploy xrc721 contract address = `, receipt['contractAddress'])


            if (tokenAddress == "") {
              if (tokenName == "") {
                EventBus.publish("error", `Please enter token name`);
                return;
              }

              if (!tokenName.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter token name`);
                return;
              }

              if (!tokenName.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Token name must contain alphabets`);
                return;
              }

              if (tokenSymbol == "") {
                EventBus.publish("error", `Please enter token symbol`);
                return;
              }

              if (!tokenSymbol.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter token symbol`);
                return;
              }

              if (!tokenSymbol.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Token symbol must contain alphabets`);
                return;
              }

              if (tokenSupply == "") {
                EventBus.publish("error", `Please enter token supply`);
                return;
              }

              if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
              }

              if (tokenDescription == "") {
                EventBus.publish("error", `Please enter token description`);
                return;
              }

              if (!tokenDescription.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter token description`);
                return;
              }

              if (!tokenDescription.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Token description must contain alphabets`);
                return;
              }

              if (tokenBanner == "") {
                EventBus.publish("error", `Please upload token banner`);
                return;
              }

              if (tokenBanner == undefined) {
                EventBus.publish("error", `Please upload token banner`);
                return;
              }

              let from = publicAddress;
              let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
              if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
              } else {
                from = publicAddress;
              }

              tokenType = "XRC20"

              let supply = await web3.utils.toWei(tokenSupply.toString(), "ether");

              props.setLoader({
                message: "Crypto Deployment in Progress...",
                status: true,
              });

              let contract = new web3.eth.Contract(XRC20ABI);
              let deploy = await contract.deploy({ data: XRC20Bytecode, arguments: [tokenName, tokenSymbol, supply] });

              await deploy.send({ from: deployer })
                .on('receipt', async receipt => {
                  tokenAddress = receipt['contractAddress'];
                  settokenAddress(receipt['contractAddress']);
                  let data = new FormData();
                  data.append('tokenName', tokenName);
                  data.append('tokenSymbol', tokenSymbol);
                  data.append('tokenDescription', tokenDescription);
                  data.append('tokenAddress', receipt['contractAddress']);
                  data.append('tokenSupply', tokenSupply);
                  data.append('tokenType', tokenType);
                  data.append('from', from);
                  data.append('banner', tokenBanner);
                  data.append('network', parseInt(networkId));
                  await props.uploadCollection(data);

                  EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
                  await waitFor(2500);
                  console.log(`************** deploy xrc20 contract address = `, receipt['contractAddress'])


                  /////////////////////////////////////////////////////////////////////////////////////////////

                  // let sub = await GrootSubscription.methods.subscribeDaos(deployer).call();
                  // if (!sub['status']) {
                  //   props.setLoader({
                  //     message: "Subscription in Progress...",
                  //     status: true,
                  //   });
                  //   let balance = await CIFI.methods.balanceOf(deployer).call();
                  //   let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
                  //   let req1 = web3.utils.fromWei(req.toString(), 'ether');
                  //   setSubPrice(parseFloat(req1));
                  //   if (parseInt(balance) < parseInt(req)) {
                  //     props.setLoader({
                  //       message: "Subscription Failed...",
                  //       status: false,
                  //     });
                  //     EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
                  //     return;
                  //   } else {
                  //     await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
                  //     await GrootSubscription.methods.daoSubscription(req).send({ from: deployer });
                  //   }
                  // }

                  props.setLoader({
                    message: "DAO Deployment in Progress...",
                    status: true,
                  });

                  let contract = new web3.eth.Contract(DaoABI);
                  let deploy = await contract.deploy({
                    data: DaoBytecode, arguments: [daoTitle, daoDescription, tokenAddress, collectionAddress, stakeholder !== "" ? [stakeholder] : formValues, proposalOwner]
                  });

                  await deploy.send({ from: deployer })
                    .on('receipt', async receipt => {
                      setDaoAddress(receipt['contractAddress']);
                      props.setLoader({
                        message: "Contract Deploy...",
                        status: false,
                      });

                      let data = new FormData();

                      data.append('title', daoTitle);
                      data.append('description', daoDescription);
                      data.append('tokenAddress', tokenAddress);
                      data.append('collectionAddress', collectionAddress);
                      // data.append('treasuryAddress', treasuryAddress);
                      data.append('stakeholders', stakeholder !== "" ? [stakeholder] : [formValues]);
                      data.append('stakeholderEmails', stakeholderEmail !== "" ? [stakeholderEmail] : [formValues1]);
                      // data.append('stakeholderPercentage', stakeholderPercentage);
                      // data.append('treasuryPercentage', treasuryPercentage);
                      // data.append('totalTokens', totalTokens);
                      data.append('network', parseInt(networkId));
                      data.append('daoAddress', receipt['contractAddress']);
                      await props.deployDAO(data);
                      await refreshBalance();
                      waitFor(2500);

                      setdaoTitle("");
                      setstakeholder("");
                      setstakeholderEmail("");
                      setToggle1(false);
                      setToggle(false);
                      setFormValues([""]);
                      // settotalTokens("");
                      setdaoDescription("");
                      setproposalOwner(false);
                      // settreasuryAddress("");
                      // settreasuryPercentage("");
                      // setstakeholderPercentage("");
                      setxrcToken("");
                      settokenUri("");
                      settokenName("");
                      settokenPrice("");
                      settokenSupply("");
                      settokenBanner("");
                      settokenSymbol("");
                      setcollectionName("");
                      settokenDescription("");
                      setcollectionSupply("");
                      setcollectionBanner("");
                      setcollectionSymbol("");
                      setcollectionDescription("");
                      console.log(`************** deploy dao contract address = `, receipt['contractAddress'])
                    });
                });
            } else {

              // let sub = await GrootSubscription.methods.subscribeDaos(deployer).call();
              // if (!sub['status']) {
              //   props.setLoader({
              //     message: "Subscription in Progress...",
              //     status: true,
              //   });
              //   let balance = await CIFI.methods.balanceOf(deployer).call();
              //   let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
              //   let req1 = web3.utils.fromWei(req.toString(), 'ether');
              //   setSubPrice(parseFloat(req1));
              //   if (parseInt(balance) < parseInt(req)) {
              //     props.setLoader({
              //       message: "Subscription Failed...",
              //       status: false,
              //     });
              //     EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
              //     return;
              //   } else {
              //     await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
              //     await GrootSubscription.methods.daoSubscription(req).send({ from: deployer });
              //   }
              // }

              props.setLoader({
                message: "DAO Deployment in Progress...",
                status: true,
              });

              let contract = new web3.eth.Contract(DaoABI);
              let deploy = await contract.deploy({
                data: DaoBytecode, arguments: [daoTitle, daoDescription, tokenAddress, collectionAddress, stakeholder !== "" ? [stakeholder] : formValues, proposalOwner]
              });

              await deploy.send({ from: deployer })
                .on('receipt', async receipt => {
                  setDaoAddress(receipt['contractAddress']);
                  props.setLoader({
                    message: "Contract Deploy...",
                    status: false,
                  });

                  let data = new FormData();

                  data.append('title', daoTitle);
                  data.append('description', daoDescription);
                  data.append('tokenAddress', tokenAddress);
                  data.append('collectionAddress', collectionAddress);
                  // data.append('treasuryAddress', treasuryAddress);
                  data.append('stakeholders', stakeholder !== "" ? [stakeholder] : formValues);
                  data.append('stakeholderEmails', stakeholderEmail !== "" ? [stakeholderEmail] : [formValues1]);
                  // data.append('stakeholderPercentage', stakeholderPercentage);
                  // data.append('treasuryPercentage', treasuryPercentage);
                  // data.append('totalTokens', totalTokens);
                  data.append('network', parseInt(networkId));
                  data.append('daoAddress', receipt['contractAddress']);
                  await props.deployDAO(data);
                  await refreshBalance();
                  waitFor(2500);

                  setdaoTitle("");
                  setstakeholder("");
                  setstakeholderEmail("");
                  setToggle1(false);
                  setToggle(false);
                  setFormValues([""]);
                  // settotalTokens("");
                  setdaoDescription("");
                  setproposalOwner(false);
                  // settreasuryAddress("");
                  // settreasuryPercentage("");
                  // setstakeholderPercentage("");
                  setxrcToken("");
                  settokenUri("");
                  settokenName("");
                  settokenPrice("");
                  settokenSupply("");
                  settokenBanner("");
                  settokenSymbol("");
                  setcollectionName("");
                  settokenDescription("");
                  setcollectionSupply("");
                  setcollectionBanner("");
                  setcollectionSymbol("");
                  setcollectionDescription("");
                  console.log(`************** deploy dao contract address = `, receipt['contractAddress'])
                });
            }
          });

      } else if (tokenAddress == "") {
        if (tokenName == "") {
          EventBus.publish("error", `Please enter token name`);
          return;
        }

        if (!tokenName.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter token name`);
          return;
        }

        if (!tokenName.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Token name must contain alphabets`);
          return;
        }

        if (tokenSymbol == "") {
          EventBus.publish("error", `Please enter token symbol`);
          return;
        }

        if (!tokenSymbol.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter token symbol`);
          return;
        }

        if (!tokenSymbol.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Token symbol must contain alphabets`);
          return;
        }

        if (tokenSupply == "") {
          EventBus.publish("error", `Please enter token supply`);
          return;
        }

        if (publicAddress == null || publicAddress == undefined) {
          EventBus.publish("error", `Please connect your wallet!`);
          return;
        }

        if (tokenDescription == "") {
          EventBus.publish("error", `Please enter token description`);
          return;
        }

        if (!tokenDescription.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter token description`);
          return;
        }

        if (!tokenDescription.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Token description must contain alphabets`);
          return;
        }

        if (tokenBanner == "") {
          EventBus.publish("error", `Please upload token banner`);
          return;
        }

        if (tokenBanner == undefined) {
          EventBus.publish("error", `Please upload token banner`);
          return;
        }

        let from = publicAddress;
        let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
        if (output == "xdc") {
          from = "0x" + publicAddress.substring(3);
        } else {
          from = publicAddress;
        }

        tokenType = "XRC20"

        let supply = await web3.utils.toWei(tokenSupply.toString(), "ether");

        props.setLoader({
          message: "Crypto Deployment in Progress...",
          status: true,
        });

        let contract = new web3.eth.Contract(XRC20ABI);
        let deploy = await contract.deploy({ data: XRC20Bytecode, arguments: [tokenName, tokenSymbol, supply] });

        await deploy.send({ from: deployer })
          .on('receipt', async receipt => {
            tokenAddress = receipt['contractAddress'];
            settokenAddress(receipt['contractAddress']);
            let data = new FormData();
            data.append('tokenName', tokenName);
            data.append('tokenSymbol', tokenSymbol);
            data.append('tokenDescription', tokenDescription);
            data.append('tokenAddress', receipt['contractAddress']);
            data.append('tokenSupply', tokenSupply);
            data.append('tokenType', tokenType);
            data.append('from', from);
            data.append('banner', tokenBanner);
            data.append('network', parseInt(networkId));
            await props.uploadCollection(data);

            EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
            await waitFor(2500);
            console.log(`************** deploy xrc20 contract address = `, receipt['contractAddress'])


            /////////////////////////////////////////////////////////////////////////////////////////////

            // let sub = await GrootSubscription.methods.subscribeDaos(deployer).call();
            // if (!sub['status']) {
            //   props.setLoader({
            //     message: "Subscription in Progress...",
            //     status: true,
            //   });
            //   let balance = await CIFI.methods.balanceOf(deployer).call();
            //   let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
            //   let req1 = web3.utils.fromWei(req.toString(), 'ether');
            //   setSubPrice(parseFloat(req1));
            //   if (parseInt(balance) < parseInt(req)) {
            //     props.setLoader({
            //       message: "Subscription Failed...",
            //       status: false,
            //     });
            //     EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
            //     return;
            //   } else {
            //     await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
            //     await GrootSubscription.methods.daoSubscription(req).send({ from: deployer });
            //   }
            // }

            props.setLoader({
              message: "DAO Deployment in Progress...",
              status: true,
            });

            let contract = new web3.eth.Contract(DaoABI);
            let deploy = await contract.deploy({
              data: DaoBytecode, arguments: [daoTitle, daoDescription, tokenAddress, collectionAddress, stakeholder !== "" ? [stakeholder] : formValues, proposalOwner]
            });

            await deploy.send({ from: deployer })
              .on('receipt', async receipt => {
                setDaoAddress(receipt['contractAddress']);
                props.setLoader({
                  message: "Contract Deploy...",
                  status: false,
                });

                let data = new FormData();

                data.append('title', daoTitle);
                data.append('description', daoDescription);
                data.append('tokenAddress', tokenAddress);
                data.append('collectionAddress', collectionAddress);
                // data.append('treasuryAddress', treasuryAddress);
                data.append('stakeholders', stakeholder !== "" ? [stakeholder] : [formValues]);
                data.append('stakeholderEmails', stakeholderEmail !== "" ? [stakeholderEmail] : [formValues1]);
                // data.append('stakeholderPercentage', stakeholderPercentage);
                // data.append('treasuryPercentage', treasuryPercentage);
                // data.append('totalTokens', totalTokens);
                data.append('network', parseInt(networkId));
                data.append('daoAddress', receipt['contractAddress']);
                await props.deployDAO(data);
                await refreshBalance();
                waitFor(2500);

                setdaoTitle("");
                setstakeholder("");
                setstakeholderEmail("");
                setToggle1(false);
                setToggle(false);
                setFormValues([""]);
                // settotalTokens("");
                setdaoDescription("");
                setproposalOwner(false);
                // settreasuryAddress("");
                // settreasuryPercentage("");
                // setstakeholderPercentage("");
                setxrcToken("");
                settokenUri("");
                settokenName("");
                settokenPrice("");
                settokenSupply("");
                settokenBanner("");
                settokenSymbol("");
                setcollectionName("");
                settokenDescription("");
                setcollectionSupply("");
                setcollectionBanner("");
                setcollectionSymbol("");
                setcollectionDescription("");
                console.log(`************** deploy dao contract address = `, receipt['contractAddress'])
              });
          });
      } else {

        // let sub = await GrootSubscription.methods.subscribeDaos(deployer).call();
        // if (!sub['status']) {
        //   props.setLoader({
        //     message: "Subscription in Progress...",
        //     status: true,
        //   });
        //   let balance = await CIFI.methods.balanceOf(deployer).call();
        //   let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
        //   let req1 = web3.utils.fromWei(req.toString(), 'ether');
        //   setSubPrice(parseFloat(req1));
        //   if (parseInt(balance) < parseInt(req)) {
        //     props.setLoader({
        //       message: "Subscription Failed...",
        //       status: false,
        //     });
        //     EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
        //     return;
        //   } else {
        //     await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
        //     await GrootSubscription.methods.daoSubscription(req).send({ from: deployer });
        //   }
        // }

        props.setLoader({
          message: "DAO Deployment in Progress...",
          status: true,
        });

        let contract = new web3.eth.Contract(DaoABI);
        let deploy = await contract.deploy({
          data: DaoBytecode, arguments: [daoTitle, daoDescription, tokenAddress, collectionAddress, stakeholder !== "" ? [stakeholder] : formValues, proposalOwner]
        });

        await deploy.send({ from: deployer })
          .on('receipt', async receipt => {
            setDaoAddress(receipt['contractAddress']);
            props.setLoader({
              message: "Contract Deploy...",
              status: false,
            });

            let data = new FormData();

            data.append('title', daoTitle);
            data.append('description', daoDescription);
            data.append('tokenAddress', tokenAddress);
            data.append('collectionAddress', collectionAddress);
            // data.append('treasuryAddress', treasuryAddress);
            data.append('stakeholders', stakeholder !== "" ? [stakeholder] : [formValues]);
            data.append('stakeholderEmails', stakeholderEmail !== "" ? [stakeholderEmail] : [formValues1]);
            // data.append('stakeholderPercentage', stakeholderPercentage);
            // data.append('treasuryPercentage', treasuryPercentage);
            // data.append('totalTokens', totalTokens);
            data.append('network', parseInt(networkId));
            data.append('daoAddress', receipt['contractAddress']);
            await props.deployDAO(data);
            await refreshBalance();
            waitFor(2500);

            setdaoTitle("");
            setstakeholder("");
            setstakeholderEmail("");
            setToggle1(false);
            setToggle(false);
            setFormValues([""]);
            // settotalTokens("");
            setdaoDescription("");
            setproposalOwner(false);
            // settreasuryAddress("");
            // settreasuryPercentage("");
            // setstakeholderPercentage("");
            setxrcToken("");
            settokenUri("");
            settokenName("");
            settokenPrice("");
            settokenSupply("");
            settokenBanner("");
            settokenSymbol("");
            setcollectionName("");
            settokenDescription("");
            setcollectionSupply("");
            setcollectionBanner("");
            setcollectionSymbol("");
            setcollectionDescription("");
            console.log(`************** deploy dao contract address = `, receipt['contractAddress'])
          });
      }

    } catch (e) {
      console.log(e);
      props.setLoader({
        status: false,
      });
      EventBus.publish("error", `Unable To Deploy`);
    }
  }

  async function refreshBalance() {
    let req = await GrootSubscription.methods.daoSubscriptionPrice().call();
    let req1 = web3.utils.fromWei(req.toString(), 'ether');
    setSubPrice(parseFloat(req1));
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function addFormFields() {
    setFormValues([...formValues, ""]);
  }

  async function addFormFields1() {
    setFormValues1([...formValues1, ""]);
  }

  async function removeFormFields(i) {
    formValues.splice(i, 1);
    setFormValues(formValues)
  }

  async function handleChange(i, e) {
    formValues[i] = e.target.value;
    setFormValues([...formValues])
  }

  async function handleChange1(i, e) {
    formValues1[i] = e.target.value;
    setFormValues1([...formValues1])
  }

  const handleRadioChange = (event) => {
    setIsChecked(event.target.checked);
  };

  async function copiedAddress() {
    EventBus.publish("success", "Contract Address Copied");
  }

  const handleOptionChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption((prevValue) => (prevValue === newValue ? '' : newValue));
  };

  const handleOptionChange1 = (event) => {
    const newValue1 = event.target.value;
    setSelectedOption1((prevValue1) => (prevValue1 === newValue1 ? '' : newValue1));
  };

  return (
    <div className="microeconomy-wrapper dao-genesis launch-dao">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={DaoGenesislogo} alt="" />
          <span>DAO Genesis</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {
          (daoTitle !== "" || daoDescription !== "") &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />
      </div>

      <div className="microeconomy-tool">
        <h2>Launch a DAO<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

        <div className="dao-address">
          {
            daoAddress !== "" &&
            <>
              <p>
                <CopyToClipboard
                  text={daoAddress}
                  onCopy={copiedAddress}
                >
                  <a>{`DAO Address: ${daoAddress}`}</a>
                </CopyToClipboard>
              </p>
              <p>
                <CopyToClipboard
                  text={tokenAddress}
                  onCopy={copiedAddress}
                >
                  <a>{`Token Address: ${tokenAddress}`}</a>
                </CopyToClipboard>
              </p>
              <p>
                <CopyToClipboard
                  text={collectionAddress}
                  onCopy={copiedAddress}
                >
                  <a>{`Collection Address: ${collectionAddress}`}</a>
                </CopyToClipboard>
              </p>
              <p> You can visit View all DAOs to view your DAO</p>
            </>
          }
        </div>


        <div className="detail-row">
          <form onSubmit={deploy}>
            <div className="dao-info">
              <div className="dao-card">
                <h3>Create a DAO</h3>
                <p>Add DAO details here.</p>
                <input
                  type="text"
                  name='daoTitle'
                  value={daoTitle}
                  maxlength="30"
                  onChange={handleOnInput}
                  placeholder="DAO Title*"
                />
                <input
                  type="text"
                  name='daoDescription'
                  maxlength="250"
                  value={daoDescription}
                  onChange={handleOnInput}
                  placeholder="DAO Description*  (Max 250 Characters)"
                />
              </div>

              {/* <div className="dao-card">
                <h3>Token distribution</h3>
                <p>Add token distribution in percentage.</p>
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name='stakeholderPercentage'
                  value={stakeholderPercentage}
                  onChange={handleOnInput}
                  placeholder="Founder Distribution*"
                />
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name='treasuryPercentage'
                  value={treasuryPercentage}
                  onChange={handleOnInput}
                  placeholder="Treasury Distribution*"
                />
              </div> */}

              <div className="dao-card">
                <h3>Setup DAO rules</h3>
                <p>Set rules to allow shareholders to vote or not.</p>

                <div class="checkbox-wrap">
                  <input type="checkbox" id="checkbox1" checked={!proposalOwner} name="proposalOwner" value={!proposalOwner} onChange={handleOnInput} />
                  <img src={subtabicon} alt="" className="" onClick={handleOnInput} />
                  <img src={subtabfillicon} className="fill" alt="" onClick={handleOnInput} />
                  <label for="checkbox1">Allow shareholders to submit proposal</label>

                </div>

                <div class="checkbox-wrap">
                  <input type="checkbox" id="checkbox2" checked={proposalOwner} name="proposalOwner" value={proposalOwner} onChange={handleOnInput} />
                  <img src={subtabicon} alt="" className="" onClick={handleOnInput} />
                  <img src={subtabfillicon} className="fill" alt="" onClick={handleOnInput} />
                  <label for="checkbox2">Owner can submit proposal only</label>
                </div>
              </div>

              <div className="dao-card">
                <h3>Setting up shareholders email addresses</h3>
                <p>Add DAO shareholders emails.</p>
                <div class="checkbox-wrap">
                  <input type="checkbox" id="multi-gov-email" checked={toggle1} name="toggle1" value={!toggle1} onChange={handleOnInput} />
                  <img src={subtabicon} alt="" className="" onClick={handleOnInput} />
                  <img src={subtabfillicon} className="fill" alt="" onClick={handleOnInput} />
                  <label for="multi-gov-email">Add multiple shareholders emails</label>
                </div>
                {
                  !toggle1 &&
                  <input
                    type="text"
                    maxlength="60"
                    name='stakeholderEmail'
                    value={stakeholderEmail}
                    onChange={handleOnInput}
                    placeholder="Founder email*"
                  />
                }
                {
                  toggle1 &&
                  <>
                    {formValues1.map((element, index) => (
                      <div className="dao-card" key={index}>

                        <div>
                          <input type="text" className="form-control" name={element || ""} value={element || ""} placeholder={`${index == 0 ? "Founder email" : "Shareholder email"}`} maxlength="60" onChange={e => handleChange1(index, e)} />
                        </div>

                      </div>
                    ))}
                    <button onClick={(event) => {
                      event.preventDefault();
                      addFormFields1();
                    }}>
                      Add shareholders emails
                    </button>
                  </>
                }
              </div>

              <div className="dao-card">
                <h3>Setting up shareholders wallet addresses</h3>
                <p>Add DAO shareholders wallet addresses.</p>
                <div class="checkbox-wrap">
                  <input type="checkbox" id="multi-gov" checked={toggle} name="toggle" value={!toggle} onChange={handleOnInput} />
                  <img src={subtabicon} alt="" className="" onClick={handleOnInput} />
                  <img src={subtabfillicon} className="fill" alt="" onClick={handleOnInput} />
                  <label for="multi-gov">Add multiple shareholders wallet addresses</label>
                </div>
                {
                  !toggle &&
                  <input
                    type="text"
                    maxlength="43"
                    name='stakeholder'
                    value={stakeholder}
                    onChange={handleOnInput}
                    placeholder="Founder Address*"
                  />
                }
                {
                  toggle &&
                  <>
                    {formValues.map((element, index) => (
                      <div className="dao-card" key={index}>

                        <div>
                          <input type="text" className="form-control" name={element || ""} value={element || ""} placeholder={`${index == 0 ? "Founder address" : "Shareholder address"}`} maxlength="43" onChange={e => handleChange(index, e)} />
                        </div>

                      </div>
                    ))}
                    <button onClick={(event) => {
                      event.preventDefault();
                      addFormFields();
                    }}>
                      Add shareholders
                    </button>
                  </>
                }

                {/* <input
                  type="text"
                  name='treasuryAddress'
                  value={treasuryAddress}
                  onChange={handleOnInput}
                  maxlength="43"
                  placeholder="Treasury Address*"
                />
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name='totalTokens'
                  value={totalTokens}
                  onChange={handleOnInput}
                  placeholder="Governance Token Amount*"
                /> */}


              </div>

              <div className="dao-card nft-crypto-card">
                <h3>NFT</h3>
                <p>Configure your NFT collection with DAO.</p>
                <div className="radio-wrap">
                  <p>
                    <input type="radio" id="create-nft" name="radio-group1" value="option1" checked={selectedOption === 'option1'}
                      onChange={handleOptionChange} /*onClick={() => setMode(true)}*/ />
                    <label for="create-nft">
                      Create NFT
                    </label>
                  </p>

                  <p>
                    <input type="radio" id="own-nft" name="radio-group1" value="option2" checked={selectedOption === 'option2'}
                      onChange={handleOptionChange} /*onClick={() => setMode(false)}*/ />
                    <label for="own-nft">
                      Already own NFT
                    </label>
                  </p>
                </div>

                <div className="nft-collection">
                  <div className="collection-filters">

                    <div className={selectedOption === 'option1' ? 'nft-wrap create selected' : 'nft-wrap create'}>
                      <input
                        type="text"
                        name='collectionName'
                        value={collectionName}
                        maxlength="25"
                        onChange={handleOnInput}
                        placeholder="Name*"
                      />

                      <input
                        type="text"
                        name='collectionSymbol'
                        value={collectionSymbol}
                        maxlength="10"
                        onChange={handleOnInput}
                        placeholder="Symbol*"
                      />

                      {collectionBanner == "" ?
                        <>
                          <input
                            type="file"
                            name='collectionBanner'
                            placeholder="Upload Banner*"
                            onChange={handleOnInput}
                            accept="image/*"
                          />
                        </>
                        :
                        <>
                          <input
                            type="file"
                            name='collectionBanner'
                            placeholder={collectionBanner ? (collectionBanner['name'] && collectionBanner['name'].substring(0, 10) + '...') : "Upload Banner*"}
                            onChange={handleOnInput}
                            accept="image/*"
                          />
                        </>
                      }

                      <input
                        type="text"
                        name='tokenUri'
                        value={tokenUri}
                        onChange={handleOnInput}
                        maxlength="200"
                        placeholder="Base URI*"
                      />

                      <input
                        onWheel={handleOnWheel}
                        type="number"
                        name='tokenPrice'
                        value={tokenPrice}
                        onChange={handleOnInput}
                        placeholder="Price in XDC*"
                      />

                      <input
                        type="text"
                        name='tokenWallet'
                        value={tokenWallet}
                        onChange={handleOnInput}
                        maxlength="43"
                        placeholder="Token Wallet*"
                      />

                      <input
                        type="text"
                        name='xrcToken'
                        value={xrcToken}
                        onChange={handleOnInput}
                        maxlength="43"
                        placeholder={`Payable Token (Leave empty for ${BToken1})*`}
                      />

                      <input
                        onWheel={handleOnWheel}
                        type="number"
                        name='collectionSupply'
                        value={collectionSupply}
                        onChange={handleOnInput}
                        placeholder="Total Supply*"
                      />

                      <input
                        type="text"
                        name='collectionDescription'
                        value={collectionDescription}
                        onChange={handleOnInput}
                        maxlength="250"
                        placeholder="Description* (Max 250 Characters)"
                      />
                    </div>

                    <div className={selectedOption === 'option2' ? 'already-owned nft-wrap selected' : 'nft-wrap already-owned'}>
                      <input
                        type="text"
                        name='collectionAddress'
                        value={collectionAddress}
                        onChange={handleOnInput}
                        maxlength="43"
                        placeholder="NFT Contract Address*"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dao-card nft-crypto-card">
                <h3>Crypto</h3>
                <p>Configure your {BToken} 20 token with DAO.</p>
                <div className="radio-wrap">
                  <p>
                    <input type="radio" id="create-token" name="radio-group2" value="option3" checked={selectedOption1 === 'option3'} onChange={handleOptionChange1} />
                    <label for="create-token">
                      Create Token
                    </label>
                  </p>

                  <p>
                    <input type="radio" id="own-token" name="radio-group2" value="option4" checked={selectedOption1 === 'option4'} onChange={handleOptionChange1} />
                    <label for="own-token">
                      Already own Token
                    </label>
                  </p>
                </div>

                <div className="nft-collection">
                  <div className="collection-filters">

                    <div className={isChecked ? 'active nft-crypto' : 'nft-crypto'}>
                      <div className={selectedOption1 === 'option3' ? 'create crypto-wrap selected' : 'crypto-wrap create'}>
                        <input
                          type="text"
                          name='tokenName'
                          value={tokenName}
                          onChange={handleOnInput}
                          maxlength="25"
                          placeholder="Name*"
                        />

                        <input
                          type="text"
                          name='tokenSymbol'
                          value={tokenSymbol}
                          onChange={handleOnInput}
                          maxlength="10"
                          placeholder="Symbol*"
                        />

                        {tokenBanner == "" ?
                          <>
                            <input
                              type="file"
                              name='tokenBanner'
                              placeholder="Upload Banner*"
                              onChange={handleOnInput}
                              accept="image/*"
                            />
                          </>
                          :
                          <>
                            <input
                              type="file"
                              name='tokenBanner'
                              placeholder={tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 10) + '...') : "Upload Banner*"}
                              onChange={handleOnInput}
                              accept="image/*"
                            />
                          </>
                        }

                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          name='tokenSupply'
                          value={tokenSupply}
                          onChange={handleOnInput}
                          placeholder="Total Supply*"
                        />

                        <input
                          type="text"
                          name='tokenDescription'
                          value={tokenDescription}
                          onChange={handleOnInput}
                          maxlength="250"
                          placeholder="Description* (Max 250 Characters)"
                        />
                      </div>

                      <div className={selectedOption1 === 'option4' ? 'already-owned crypto-wrap selected' : 'crypto-wrap already-owned'}>
                        <input
                          type="text"
                          name='tokenAddress'
                          value={tokenAddress}
                          onChange={handleOnInput}
                          maxlength="43"
                          placeholder="Token Contract Address*"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btns dao-btn">
              <button type="submit">Create DAO</button>
            </div>
          </form>
        </div>
      </div>

      <Modal
        open={microeconomyToolModal}
        onClose={() => setMicroeconomyToolModal(false)}
        classNames={{
          modal: `${mode ? 'light' : 'dark'} microeconomy-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyToolModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={microeconomyicon} alt="" />

          <h3> Tool</h3>

          <p>Empower your project with our revolutionary platform, designed to navigate the blockchain world effortlessly. Deploy NFTs, airdrops, tokens, DAOs, and smart contracts even with limited resources or expertise.</p>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  deployDAO,
  uploadCollection,
  setBalance,
  setGrootBalance
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, isLoader } = Auth;
  return { publicAddress, isLoader }
};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchaDao);