
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import EventBus from "eventing-bus";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { toFixed } from "../../store/numberFormat";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { setLoader, getProjectDetails } from "../../store/actions/Auth";

import Roadmap from "../../images/roadmap.png";
import Logo from "../../images/groot-logo.svg";
import pdficon from "../../images/pdf-icon.png";
import Activity from "../../images/activity.png";
import Ecosystem from "../../images/ecsystm.png";
import Github from "../../images/github-gold.svg";
import xdclarge from "../../images/xdc-large.png";
import copy from "../../images/document-copy.png";
import timeicon from "../../images/time-icon.png";
import alternate from "../../images/alternate.jpg";
import teamimg1 from "../../images/team-img-1.png";
import teamimg2 from "../../images/team-img-2.png";
import teamimg3 from "../../images/team-img-3.png";
import teamimg4 from "../../images/team-img-4.png";
import testvideo from "../../images/video-test.webm";
import Twitter from "../../images/twitter-gold.svg";
import Discord from "../../images/discord-gold.svg";
import linkedin from "../../images/linkedin-icon.png";
import bggradient from "../../images/bg-gradient.png";
import metamask from "../../images/metamask-icon.png";
import exporticon from "../../images/export-icon.png";
import DataEconomy from "../../images/data-economy.png";
import websiteicon from "../../images/website-icon.png";
import telegramicon from "../../images/telegram-icon.png";
import discordicon from "../../images/discord-icon.png";
import mediumicon from "../../images/medium-icon.png";
import twittericon from "../../images/twitter-icon.png";
import linkedinicon from "../../images/linkedin-icon-gr.png";


const ProjectDetails = (props) => {

  let { token } = useParams();

  let [details, setdetails] = useState({});
  let [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

  useEffect(() => {
    props.setLoader({
      message: "Loading Details...",
      status: true,
    });
    props.getProjectDetails({ token: token });
  }, []);

  useEffect(() => {
    if (props.projectDetails && Object.keys(props.projectDetails).length > 0) {
      setdetails(props.projectDetails);
    }
  }, [props.projectDetails]);

  window.addEventListener("load", function () {
    AOS.init();
  });

  const series1 = [details && details?.fundSale ? details?.fundSale : 0, details && details?.supply ? details?.supply : 0];

  const options1 = {
    chart: {
      type: 'donut',
      width: 133
    },

    labels: ["", `Funds Sale <span>${details && details?.fundSale ? details?.fundSale : 0} (${details && details?.fundSale && details?.supply ? (details?.fundSale / details?.supply) * 100 : 0}%)</span>`],

    colors: ['#6EB3D6', '#3BE3B6'],

    dataLabels: {
      enabled: false,
    },

    stroke: {
      width: 0
    },

    plotOptions: {
      pie: {
        donut: {
          size: "82%"
        },

        expandOnClick: false
      }
    },
  }

  const handleImageError = (idx) => {
    details['team'][idx]['image'] = alternate;
    setdetails(...details);
  };

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address)
      .then(() => EventBus.publish("success", "Contract Address Copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  console.log("*** details", details);

  return (

    <>
      {
        details && Object.keys(props.projectDetails).length > 0 ?
          <div class="data">
            <header className="project-detail-header">
              <div className="container">
                <a className="logo" href="/">
                  <img
                    src={Logo}
                  />
                  <span>Circularity Finance</span>
                </a>
                {/* 
          <ul>
            <li>
              <a href="#">Home</a>
            </li>

            <li>
              <a href="#">Private Equity</a>
            </li>

            <li>
              <a href="#">Donations</a>
            </li>

            <li>
              <a href="#">Launchpad</a>
            </li>

            <li>
              <a href="#">Contact</a>
            </li>
          </ul> */}

                <a className="launch-app" href="/">
                  Launch App
                </a>
              </div>
            </header>

            <section className="project-detail-content">
              <div className="container">
                <h1>{details?.['title']}</h1>

                {/* <div className="video-wrapper">
            <video width="320" height="240" controls>
              <source src={testvideo} type="video/webm" />
            </video>

          </div> */}

                {/* <div className="intro">
            <h3>Introduction</h3>

            <p>Xyz is a collaborative data marketplace and the infrastructure provider for data ecosystems. It brings together the power of community-driven data analysis with the datasets of some of the most successful modern businesses. The marketplace allows both grassroots data enthusiasts, SMEs and institutional partners to access new data sources as well as ways to put your own untapped data to use. This allows them to gain new insights, find new revenue streams within the boundaries of regulations as well as stay within the framework of their own data governance.</p>

            <p>Our vision is to fuel the new data economy. We provide an easy-to-use platform for sharing, requesting and understanding data. This uncovers key insights, improves processes, and opens up new business opportunities while supporting next-generation Large Language Models and AI. All supported by a fair system that rewards those who publish or contribute data while charging those who consume data</p>

            <p>Bringing together the power of artificial intelligence and blockchain, Nuklai will accelerate the tokenization of real-world assets (RWA). It will facilitate new avenues for the vast amounts of data these assets will rely on for their valuation and provide the infrastructure for this data to be utilized, monetized, and enhanced by other players.</p>

            <p>By developing the Nuklai data ecosystem on its own public blockchain, along with the use of subnet technology for creating private data consortiums Nuklai is able to provide the benefits of trustless access and sharing of data to the fragmented data landscape. At the same time, by empowering Decentralized Physical Infrastructure Network (DePIN) with standardized data sources Nuklai will enable companies to tap into the data ecosystem produced by the emerging Economy of Things (EoT) industry.</p>

            <p>Nuklai, as its name suggests, is the nucleus, the core of the new data economy. It will unify the scattered segments of the data landscape, further enhancing and advancing the capabilities of next-generation large language models (LLMs) and artificial intelligence (AI).</p>

            <img
              src={DataEconomy}
            />

            <h3>What problem or opportunity is xyz addressing?</h3>

            <p>Xyz addresses the challenges of a fragmented data landscape caused by technical complexity and increased costs that resulted in the underutilization of data by businesses. Nuklai provides a solution to efficiently publish, standardize, analyze and manage large volumes of data that enterprises often have access to but that remain locked away in silos.</p>

            <p>As traditional industries and business models are faced with disruption by rapidly evolving startups that use the latest technologies such as artificial intelligence (AI) and large language models (LLMs), businesses are forced to protect their existing business models and explore new ones. Nuklai helps businesses to gain a competitive advantage and innovate faster through the usage of underutelized data and normally unavailable data while turning this data into a profitable asset to train advanced artificial intelligence (AI) models such as large language models (LLMs) via our distributed computational network.</p>

            <h3>How does xyz take advantage of this?</h3>

            <p>Xyz is a data ecosystem that allows businesses to manage and use their data to tackle challenges and explore new business models by introducing a modular decentralized network that facilitates collaboration and exchange of data via data consortiums. The decentralized approach enhances the security and control over data and minimizes the trust among network participants that is normally required in traditional data management and exchange, while retaining full control over their data, even within their own framework of data governance.</p>

            <p>Xyz simplifies the complex world of data by allowing users to connect, store and structure various data sets into a unified format. This uniform format allows for easier manipulation and analysis of the data, allowing users to seamlessly access multiple data sources and extract new insights and trends.</p>

            <p>Central to Xyz ecosystem are the contributors who turn raw data into a valuable asset. These contributions play a central role in enhancing the metadata which add extra depth and context to the datasets within the Xyz ecosystem. By doing so, they enhance the interpretability and accessibility of the data, which increases its quality. For example, something as simple as a country can be matched in various ways. Countries have ISO country codes but also different ways of naming the country in different languages, by describing the data in more details it is easier to match the data in different silos. Furthermore, it allows hidden insights to be revealed and new correlations to be found by combining different datasets. All contributors within the Nuklai ecosystem are fairly and transparently rewarded for their effort.</p>

            <p>Xyz makes it easy to integrate established or custom large language models (LLMs) and allows these models to be trained by high quality structured data, enabling these models to significantly improve their accuracy and particularly the provision of fact based information. This is especially important in sectors where precision and reliability are a must, like for example in finance and healthcare.</p>

            <p>While training and running custom models can be prohibitively expensive, Nuklai democratizes the computational power by a distributed network of compute nodes, allowing business to use this distributed resources to train and run these models. This levels the playing field and allows even smaller businesses to access advanced AI capabilities and only pay for the computational power that they use.</p>

            <img
              src={Ecosystem}
            />

            <h3>Participation restrictions</h3>

            <p>To ensure compliance with regulatory standards, our platform adheres to the following Know Your Customer (KYC) restrictions:</p>


            <p><b>Residency Requirement:</b> You must not be a resident or citizen of the United States to use our services. This restriction is in place to comply with specific legal and regulatory requirements.</p>

            <p><b>PEP Screening:</b> We conduct thorough screenings for Politically Exposed Persons (PEPs). If you have been identified as a PEP, additional compliance checks will be required before you can engage with our services.</p>

            <p><b>Age Verification:</b> You must be at least 18 years old.</p>

            <p><b>Sanctions Check:</b> Your country of residence must not be on any sanctioned list. This is to ensure that we are not conducting business with individuals from countries that are subject to restrictions by international regulatory bodies.</p>

            <p>We appreciate your understanding and cooperation in adhering to these requirements, which are in place to protect all stakeholders and comply with legal obligations.</p>
          </div> */}

                <div className="intro">
                  <h3>Description</h3>
                  <p>{details?.['description']}</p>
                </div>

                <div class="project-detail-info-box">
                  <div className="box token-metrics">
                    <h3>Token Metrics</h3>

                    <div className="inner">
                      <Chart options={options1} series={series1} type="donut" height={133} />
                    </div>
                  </div>

                  <div className="box">
                    <h3>Network</h3>

                    <img
                      src={xdclarge}
                    />

                    <div className="input-wrap">
                      <span className="xdc">
                        <img src={xdclarge} /> XDC
                      </span>
                      <input
                        type="text"
                        disabled
                        placeholder={details?.token?.address && details?.token?.address.substring(0, 5) + '...' + details?.token?.address.substring(37, details?.token?.address.length)}
                      />

                      {/* <img className="meta-mask" src={metamask} /> */}

                      <img className="copy" src={copy} onClick={() => handleCopyAddress(details?.token?.address)} />

                      {props.selectDefaultNetwok == 50 ? (
                        <a className="link" href={`https://xdc.blocksscan.io/address/${details?.token?.address}`} target="_blank">
                          <img src={exporticon} />
                        </a>
                      ) : props.selectDefaultNetwok == 51 ? (
                        <a className="link" href={`https://apothem.blocksscan.io/address/${details?.token?.address}`} target="_blank">
                          <img src={exporticon} />
                        </a>
                      ) : <></>
                      }
                    </div>
                  </div>

                  {
                    details && details?.vesting &&
                    <div className="box vesting-ingo-box">
                      <h3>Vesting information</h3>

                      <p>{details?.vesting?.vinfo}</p>

                      <div className="vesting-info">
                        <div className="date-wrap">
                          <span>{String((new Date(details?.vesting?.vstartdate)).getMonth() + 1).padStart(2, '0')} {(new Date(details?.vesting?.vstartdate)).getFullYear()}</span>
                          <div className="date">{String((new Date(details?.vesting?.vstartdate)).getDate()).padStart(2, '0')}</div>
                        </div>

                        <div className="right-info">
                          <div className="head">
                            <div className="cifi-length">
                              <h6>Cliff length: {details?.vesting?.vcliff} month(s)</h6>

                              <div className="cifi-length-box">
                                {/* <div>
                          <span>Initial release:</span>

                          <span><img src={timeicon} /> Mar 16, 2024, 05:00 PM</span>
                        </div> */}

                                <div>
                                  <span>Start vesting:</span>

                                  <span><img src={timeicon} /> {moment(details?.vesting?.vstartdate).local().format('MMM DD, YYYY, hh:mm A')}</span>
                                </div>
                              </div>
                            </div>

                            <div className="vesting-length">
                              <h6>Vesting length: {details?.vesting?.vlength} year(s)</h6>

                              <span>End vesting:</span>

                              <span><img src={timeicon} /> {moment(details?.vesting?.venddate).local().format('MMM DD, YYYY, hh:mm A')}</span>
                            </div>
                          </div>

                          <div className="progress-bar-wrap">
                            <ProgressBar completed={details?.vesting && details?.vesting?.vstartdate && details?.vesting?.venddate ?
                              toFixed((
                                ((currentTime - Math.floor(new Date(details?.vesting?.vstartdate).getTime() / 1000)) /
                                  (Math.floor(new Date(details?.vesting?.venddate).getTime() / 1000) - Math.floor(new Date(details?.vesting?.vstartdate).getTime() / 1000)))
                                * 100)
                                , 'price') :
                              0
                            } />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <div className="team">
                  <img className="bg-gradient"
                    src={bggradient}
                  />

                  <h2>Team</h2>

                  <div className="inner">
                    {
                      details && Object.keys(details).length > 0 && details?.team &&
                      details?.team.length > 0 && details.team.map((item, idx) => (

                        <div className="box">
                          <div className="img-wrap">
                            <img key={idx} src={item?.imageUrl ? item?.imageUrl : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />

                            <a href={item?.linkedin} target="_blank">
                              <img className="linkedin" src={linkedin} />
                            </a>
                          </div>

                          <h3>{item?.name}</h3>
                          <span>{item?.title}</span>
                        </div>

                      ))
                    }
                  </div>
                </div>

                <div className="links">
                  <h2>Links</h2>

                  <div className="inner">
                    {
                      details && Object.keys(details).length > 0 && details?.website !== "" &&
                      <a href={details?.website} className="link" target="_blank">
                        <img src={websiteicon} />
                      </a>
                    }

                    {
                      details && Object.keys(details).length > 0 && details?.telegram !== "" &&
                      <a href={details?.telegram} className="link" target="_blank">
                        <img src={telegramicon} />
                      </a>
                    }

                    {
                      details && Object.keys(details).length > 0 && details?.discord !== "" &&
                      <a href={details?.discord} className="link" target="_blank">
                        <img src={discordicon} />
                      </a>
                    }

                    {
                      details && Object.keys(details).length > 0 && details?.medium !== "" &&
                      <a href={details?.medium} className="link" target="_blank">
                        <img src={mediumicon} />
                      </a>
                    }

                    {
                      details && Object.keys(details).length > 0 && details?.twitter !== "" &&
                      <a href={details?.twitter} className="link" target="_blank">
                        <img src={twittericon} />
                      </a>
                    }

                    {
                      details && Object.keys(details).length > 0 && details?.linkedin !== "" &&
                      <a href={details?.linkedin} className="link" target="_blank">
                        <img src={linkedinicon} />
                      </a>
                    }
                  </div>
                </div>

                <div className="document">
                  <img className="bg-gradient"
                    src={bggradient}
                  />

                  <h2>Documents</h2>

                  <div className="inner">
                    {
                      details && Object.keys(details).length > 0 && details?.whitepaperDoc !== "" &&
                      <a className="box" href={details?.whitepaperDoc} target="_blank">
                        <div className="img-wrap">
                          <img
                            src={pdficon}
                          />
                        </div>

                        <h3>White paper</h3>
                      </a>
                    }

                    {
                      details && Object.keys(details).length > 0 && details?.vestingDoc !== "" &&
                      <a className="box" href={details?.vestingDoc} target="_blank">
                        <div className="img-wrap">
                          <img
                            src={pdficon}
                          />
                        </div>

                        <h3>Vesting schedule</h3>
                      </a>
                    }

                    {
                      details && Object.keys(details).length > 0 && details?.privacyDoc !== "" &&
                      <a className="box" href={details?.privacyDoc} target="_blank">
                        <div className="img-wrap">
                          <img
                            src={pdficon}
                          />
                        </div>

                        <h3>Privacy policy</h3>
                      </a>
                    }
                  </div>
                </div>
              </div>

            </section>
          </div>
          :
          <>
            <div class="data">
              <header className="project-detail-header">
                <div className="container">
                  <a className="logo" href="/">
                    <img
                      src={Logo}
                    />
                    <span>Circularity Finance</span>
                  </a>
                  {/* 
          <ul>
            <li>
              <a href="#">Home</a>
            </li>

            <li>
              <a href="#">Private Equity</a>
            </li>

            <li>
              <a href="#">Donations</a>
            </li>

            <li>
              <a href="#">Launchpad</a>
            </li>

            <li>
              <a href="#">Contact</a>
            </li>
          </ul> */}

                  <a className="launch-app" href="/">
                    Launch App
                  </a>
                </div>
              </header>
            </div>
            <div className="no-data">
              Project details are not available
            </div>
          </>
      }
    </>
  );
}

const mapDispatchToProps = {
  setLoader,
  getProjectDetails
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, isLoader, projectDetails, selectDefaultNetwok } = Auth;
  return { publicAddress, isLoader, projectDetails, selectDefaultNetwok }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);