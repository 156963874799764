import moment from "moment";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import modalcloseicon from "../../images/close.png";
import infocircle from "../../images/infocircle.svg";
import BlockchainIcon from "../../images/blockchain.svg";
import DaoGenesislogo from "../../images/dao-genesis.png";
import { setLoader, getDAO } from "../../store/actions/Auth";
import microeconomyicon from "../../images/microeconomy-icon.png";


const ViewAllDaos = (props, { mode }) => {

  const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  const [daos, setDaos] = useState([]);

  useEffect(() => {
    props.getDAO();
  }, []);

  async function setDaoData() {
    const updatedDAOs = await Promise.all(
      props.daoList.map(async (item) => {
        if (parseInt(item['network']) == parseInt(props.selectDefaultNetwok)) {
          return { ...item };
        }
        return item;
      })
    );
    setDaos(updatedDAOs);
  }

  useEffect(() => {
    if (props.daoList.length > 0) {
      setDaoData();
    }
  }, [props.daoList, props.selectDefaultNetwok]);

  return (
    <div className="microeconomy-wrapper dao-genesis view-all">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={DaoGenesislogo} alt="" />

          <span>DAO Genesis</span>
        </a>
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />

      </div>

      <div className="microeconomy-tool">
        <h2>View all DAOs<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

        <div className="detail-row">
          <ul className="detail-list">
            <li>
              <div>
                <h2>{daos.length ? daos.length : 0}</h2>
                <p>Total DAOs</p>
              </div>
              <img src={BlockchainIcon} alt="" />
            </li>
          </ul>

          <h2>All DAOs</h2>

          <div className="table-wrapper">
            <table responsive="md">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Date created</th>
                  <th>Status</th>
                  <th>Options</th>
                </tr>
              </thead>
              {
                daos?.length > 0 &&
                daos?.map((item) => (
                  <tbody>
                    <tr>
                      <td>{item?.title}</td>
                      <td>{moment(item.createdAt).format('L hh:mm:ss A')}</td>
                      <td className="status green">Active</td>
                      <td className="options">
                        <Link className="btns" to={`/dashboard/dao-genesis/submit-proposal/${item['daoAddress']}`}>Proposals</Link>
                      </td>
                    </tr>
                  </tbody>
                ))
              }
            </table>
          </div>
        </div>
      </div>

      <Modal
        open={microeconomyToolModal}
        onClose={() => setMicroeconomyToolModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyToolModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={microeconomyicon} alt="" />

          <h3>Micro Economy Tool</h3>

          <p>Empower your project with our revolutionary platform, designed to navigate the blockchain world effortlessly. Deploy NFTs, airdrops, tokens, and smart contracts even with limited resources or expertise.</p>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  getDAO
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, daoList, selectDefaultNetwok, currentMode } = Auth;
  return { publicAddress, daoList, selectDefaultNetwok, currentMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllDaos);