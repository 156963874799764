import React from "react";
import { useState } from "react";
import { Modal } from "react-responsive-modal";
import modalcloseicon from "../../images/close.png";
import infocircle from "../../images/infocircle.svg";
import DaoGenesislogo from "../../images/dao-genesis.svg";
import microeconomyicon from "../../images/microeconomy-icon.png";


export default function Modules({mode}) {

  const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);

  return (
    <div className="microeconomy-wrapper dao-genesis modules">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
            <img src={DaoGenesislogo} alt="" />

            <span>DAO Genesis</span>
        </a>
        
        <div className="btns">
          <button>Add CIFI to metamask</button>
          <button>Buy CIFI</button>
        </div>
        <div className="btn-wrap">
        <select>
            <option value="51">XDC</option>
            <option value="97">BSC</option>
            <option value="80001">Matic</option>
        </select>
      
        <button>1,581 BNB | 0xBBB6A7...6hn9</button>
      </div>
      </div>

      <div className="microeconomy-tool">
        <h2>Modules<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

        <div className="detail-row">
            <ul className="modules-list">
              <li>
                <h3>Private exit</h3>
                <p>Get a private offer for exit from DAO</p>
              </li>
              <li>
                <h3>Dividends</h3>
                <p>Pay dividends by distributing tokens from DAO treasury</p>
              </li>
              <li>
                <h3>Private exit</h3>
                <p>Start a token sale of LP token of your DAO</p>
              </li>
              <li>
                <h3>Payroll</h3>
                <p>Pay salaries to employees or other regular payments</p>
              </li>
              <li>
                <h3>Document sign</h3>
                <p>Create & sign your legal documents, saved in IPFS</p>
              </li>
              <li>
                <h3>Vestingt</h3>
                <p>Lock up LP tokens for the investors & distribute them in designated cycles</p>
              </li>
              <li>
                <h3>Compensation</h3>
                <p>Reimburse team members for TX generated on Chain</p>
              </li>
              <li>
                <h3>Governance delegation</h3>
                <p>Allow a second wallet to manage your voting rights while “away”</p>
              </li>
              <li>
                <h3>Transaction Invoice</h3>
                <p>Send a receipt of all TX to designated email assigned with id/note</p>
              </li>
            </ul>
          </div>
      </div>

        <Modal
          open={microeconomyToolModal}
          onClose={() => setMicroeconomyToolModal(false)}
          classNames={{
            modal: `${mode ? 'light' : 'dark'} microeconomy-modal`
          }}
          center
        >
          <button className="absolute right-4 top-6">
            <img 
            src={modalcloseicon}
              onClick={() => setMicroeconomyToolModal(false)}
            />
          </button>

          <div className="modal-body">
              <img src={microeconomyicon} alt="" />

              <h3>Micro Economy Tool</h3>

              <p>Empower your project with our revolutionary platform, designed to navigate the blockchain world effortlessly. Deploy NFTs, airdrops, tokens, and smart contracts even with limited resources or expertise.</p>
          </div>  
        </Modal>
    </div>
  );
}
