import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { setHeader } from "../axios";
import { all, takeEvery, call, put, take } from 'redux-saga/effects';

import { socket } from '../config';
import { setAllUsersOrder, setExchangeGraph, updateExchangeGraph } from '../actions/Events';


function* getExchangeGraph({ payload }) {
  const { error, response } = yield call(getCall, `/getGraphData/${payload['pairs']}/${payload['networkId']}`);
  if (response) { }
  yield put(setExchangeGraph(response["data"]["body"]));
  yield put(updateExchangeGraph([]));
};

function* getExchangePairsDetails({ payload }) {
  socket.emit("exchange:pair:detail", payload);
};

function* getBalancesPairs({ payload }) {
  socket.emit("address:pairA:pairB", payload);
};

function* getPairsData({ payload }) {
  socket.emit("pairA:pairB", payload);
};

function* actionWatcher() {
  yield takeEvery('GET_EXCHANGE_GRAPH', getExchangeGraph);
  yield takeEvery('GET_EXCHANGE_PAIRS_DETAILS', getExchangePairsDetails);
  yield takeEvery('GET_PAIRS_BALANCE', getBalancesPairs);
  yield takeEvery('GET_PAIRS_DATA', getPairsData);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
