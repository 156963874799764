import React from "react";
import etherumicon from "../../images/etherumicon.svg";
import btc from "../../images/btc.svg";
import { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts'
import HeaderDash from "./HeaderDash";
import { Modal } from "react-responsive-modal";
import ConnectWallet from "../ConnectWallet";
import bscicon from "../../images/bsc-icon.png";
import microeconomylogo from "../../images/borrow-icon.png";
import ethicon from "../../images/eth-icon.svg";
import usdcicon from "../../images/usdc-icon.svg"; 
import modalcloseicon from "../../images/close.png";
import infocircle from "../../images/infocircle.svg";
import goarrow from "../../images/go-arrow.png";
import copy from "../../images/copy.png";
import tickcircle from "../../images/tickcircle.png";
import { Link } from "react-router-dom";
import  {web3}  from '../../store/web3';
import { BORROW,DE_FRANC,TROVE_HELPERS,SORTEDTROVES,HINT_HELPERS,TROVE_MANAGER } from "../../store/config";

function Borrow(props) {
  const [borrowModal, setBorrowModal] = useState(false);
  const [manageModal, setManageModal] = useState(false);
  const [microeconomySuccessModal, setMicroeconomySuccessModal] = useState(false);
  const [textColor, setTextColor] = useState({
    value1: "text-black",
    value2: "text-black"
  });
  const handleOnWheel = (event) => {
    const { type } = event.target;
    if(type === 'number'){
      event.preventDefault();
    }
  }
  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  function mySubmit(e) { 
    e.preventDefault(); 
    return false;
  }

  const series = [
    {
      name: " ", //will be displayed on the y-axis
      data: [1400, 1700, 1200, 1900, 3300, 2000, 2700, 3200, 2500, 3300, 1200, 4700]
    }
  ];

  const options = {
    chart: {
      id: "simple-bar"
    },

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

      axisBorder: {
        color: '#282C35',
        height: 2,
        width: '100%'
      },

      labels: {
        style: {
          colors: '#A9A9A9',
          fontSize: '12px',
          fontFamily: 'Quicksand-Medium',
          fontWeight: 400,
        },
      }
    },

    yaxis:{
      categories: [1000, 3000, 9000, 11000],

      labels: {
        style: {
          colors: '#A9A9A9',
          fontSize: '12px',
          fontFamily: 'Quicksand-Medium',
          fontWeight: 400,
        },

        formatter: function (value) {
          return "$" + value;
        }
      }
    },
    fill: {
      colors: ['#B7933D'],
      opacity: 1,
      width:8
    },

    grid: {
      show: true,
      borderColor: '#282C35',
      strokeDashArray: 6,
      xaxis: {
          lines: {
              show: false
          }
      },    
    },

    plotOptions: {
      bar: {
        columnWidth: '8',
      },
    }
  };

  const series1 = [55, 45];

  const options1 = {
    chart: {
      type: 'donut',
      width: 300
    },

    labels: ["Bitcoin <span>BTC</span>", "Binance Coin <span>BNB</span>"],

    colors: ['#6EB3D6', '#3BE3B6'],

    dataLabels: {
      enabled: false,
    },

    stroke: {
      width:0
    },

    plotOptions: {
      pie: {
        donut: {
          size: "82%"
        },

        expandOnClick: false
      }
    },
  }

  const series2 = [55, 45];

  const options2 = {
    chart: {
      type: 'donut',
      width: 300
    },

    labels: ["Your Collateral (MATIC) <span>0.00 CiFi</span>", "Your Debt (CiFi) <span>0.00 CiFi Stable Coin</span>"],

    colors: ['#6EB3D6', '#3BE3B6'],

    dataLabels: {
      enabled: false,
    },

    stroke: {
      width:0
    },

    plotOptions: {
      pie: {
        donut: {
          size: "82%"
        },

        expandOnClick: false
      }
    },
  }

  const [dataBorrow, setDataBorrow] = useState([
    {
      coin: "eth",
      deposit: "",
      Debt: "",
      tvl: "0.00",
    },
    {
      coin: "btc",
      deposit: "0 DCHF",
      Debt: "",
      tvl: "0.00",
    },
    {
      coin: "eth",
      deposit: "",
      arp: "0%",
      tvl: "0.00",
    },
    {
      coin: "btc",
      deposit: "",
      Debt: "",
      tvl: "0.00",
    },
  ]);

  const [error, setError] = useState({
    ratioError: false,
    borrowError: false,
    depositError: false,
  });

  const [borrow, setBorrow] = useState({
    collateral: "",
    borrowUSDd: "",
  });

  const [barrowCardValue1, setBarrowCardValue1] = useState({
    value1: "MATIC",
    value2: "0.00",
  });

  const [barrowCardValue2, setBarrowCardValue2] = useState({
    value1: "0.00%",
    value2: "0.00%",
  });

  const [barrowCardValue3, setBarrowCardValue3] = useState({
    value1: "0.00 MATIC",
    value2: "0.00 CiFi",
    value3: "200.00 CiFi",
    value4: "0.00 CiFi",
    value5: "200.00 CiFi",
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd"
        );
        const { binancecoin } = await res.json();
        setBarrowCardValue1({
          ...barrowCardValue1,
          // value1: `${binancecoin.usd} MATIC`,
          value1: `1522.60 MATIC`,
        });
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {};
  }, []);

  useEffect(() => {
    const cloneBarrowCardValue1 = { ...barrowCardValue1 };
    if (borrow.borrowUSDd && borrow.collateral) {
      const liquidation = (
        ((borrow.borrowUSDd / borrow.collateral) * 110) /
        (10 * 10)
      ).toFixed(2);

      cloneBarrowCardValue1.value2 = `${liquidation} USD`;
    } else {
      cloneBarrowCardValue1.value2 = "0.00 USD";
    }
    setBarrowCardValue1(cloneBarrowCardValue1);
    return () => {};
  }, [borrow]);

 useEffect(() => {
    collateralCalculation();
  }, [borrow.collateral]);

  const collateralCalculation = useCallback(() => {
    const cloneBarrowCardValue3 = { ...barrowCardValue3 };
    const cloneBarrowCardValue2 = { ...barrowCardValue2 };
    const errorClone = { ...error };
    if (borrow.collateral) {
      cloneBarrowCardValue3.value1 = `${(borrow.collateral * 1).toFixed(
        2
      )} Matic`;
      const ratio =
        ((parseFloat(borrow.collateral) * parseFloat(barrowCardValue1.value1)) /
          cloneBarrowCardValue3.value5.replace("CiFi", "")) *
        100;
      cloneBarrowCardValue2.value2 = `${ratio.toFixed(2)} %`;
      // if (borrow.collateral * 1 > balance) {
      //   errorClone.depositError = true;
      // }
      if (parseInt(ratio) < 110) {
        errorClone.ratioError = true;
      } else {
        errorClone.ratioError = false;
      }
      handleTextColor(parseInt(ratio));
    } else {
      cloneBarrowCardValue3.value1 = `0.00 MATIC`;
      cloneBarrowCardValue2.value2 = `0.00 %`;
      errorClone.depositError = false;
      handleTextColor(0);
    }
    setBarrowCardValue3(cloneBarrowCardValue3);
    setBarrowCardValue2(cloneBarrowCardValue2);
    setError(errorClone);
  }, [borrow.collateral]);

  useEffect(() => {
    borrowUSDdCalculation();
    return () => {};
  }, [borrow.borrowUSDd]);

  const borrowUSDdCalculation = useCallback(() => {
    const cloneBarrowCardValue3 = { ...barrowCardValue3 };
    const cloneBarrowCardValue2 = { ...barrowCardValue2 };
    const errorClone = { ...error };
    if (borrow.borrowUSDd) {
      const cValue2 = borrow.borrowUSDd * 1;
      const cValue4 = (borrow.borrowUSDd * 0.5) / 100;
      const cValue5 = 200 + cValue2 + cValue4;
      const ratio =
        ((parseFloat(borrow.collateral) * parseFloat(barrowCardValue1.value1)) /
          cValue5) *
        100;
      cloneBarrowCardValue3.value2 = `${cValue2.toFixed(2)} CiFi`;
      cloneBarrowCardValue3.value4 = `${cValue4.toFixed(2)} CiFi`;
      cloneBarrowCardValue3.value5 = `${cValue5.toFixed(2)} CiFi`;
      if (borrow.collateral) {
        cloneBarrowCardValue2.value2 = `${ratio.toFixed(2)} %`;
      }
      if (borrow.borrowUSDd * 1 < 200) {
        errorClone.borrowError = true;
      } else {
        errorClone.borrowError = false;
      }
      if (parseInt(ratio) < 110) {
        errorClone.ratioError = true;
      } else {
        errorClone.ratioError = false;
      }
      handleTextColor(parseInt(ratio));
    } else {
      cloneBarrowCardValue3.value2 = `0.00 CiFi`;
      cloneBarrowCardValue3.value4 = `0.00 CiFi`;
      cloneBarrowCardValue3.value5 = `200.00 CiFi`;
      errorClone.borrowError = false;
      handleTextColor(0);
    }
    setBarrowCardValue3(cloneBarrowCardValue3);
    setBarrowCardValue2(cloneBarrowCardValue2);
    setError(errorClone);
  }, [borrow.borrowUSDd]);

  const handleTextColor = (ratio) => {
    const cloneColor = { ...textColor };
    if (ratio > 0 && ratio < 150) {
      cloneColor.value2 = "text-red";
    } else if (ratio > 150 && ratio < 200) {
      cloneColor.value2 = "text-purple ";
    } else if (ratio > 200) {
      cloneColor.value2 = "text-green";
    }else {
      cloneColor.value2 = "text-white";
    }
    setTextColor(cloneColor);
  };

  const handleField = (e) => {
    const { name, value, validity } = e.target;
    setBorrow({ ...borrow, [name]: validity.valid ? value : borrow[name] });
  };

  const getGasAmount = async (fromAddress, toAddress, amount) => {
    const gasAmount = await web3.eth.estimateGas({
      to: toAddress,
      from: fromAddress,
      value: web3.utils.toWei(`${amount}`, 'ether'),
    });
    return gasAmount
  }
  
  const handleCloseTrove = async() => {
    try {
      let publicAddress = (await web3.currentProvider.enable())[0];
      const gasPrice = await web3.eth.getGasPrice();
      console.log("gasprice",gasPrice);
      const data = await TROVE_MANAGER.methods.liquidate(
        "0x0000000000000000000000000000000000000000",

      ).send({
        from: publicAddress});
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  } 

  const handleBorrow = async (e) => {
  
  try {
    e.preventDefault()
    let publicAddress = (await web3.currentProvider.enable())[0];

    const maxFee = "5".concat("0".repeat(16)); // Slippage protection: 5%
    const value = await web3.utils.toWei(borrow?.collateral,"ether");
    // const value = await web3.utils.toBN(Ethereum)
    const USDdBorrow = await  web3.utils.toWei(borrow?.borrowUSDd,"ether");
    // const USDdBorrow = await web3.utils.toBN(USDETH)
    console.log("DE_FRANC ",DE_FRANC.methods);

    const deadAddress= "0x0000000000000000000000000000000000000000";


    const liquidationReserve = await DE_FRANC.methods.DCHF_GAS_COMPENSATION(
      deadAddress
    ).call();
    // const liquidationReserve = await web3.utils.toBN(liquidation)

    const expectedFee = await TROVE_HELPERS.methods.getBorrowingFeeWithDecay(
      deadAddress,
      USDdBorrow
    ).call();


    console.log("expectedFee",expectedFee,"liquidationReserve",liquidationReserve);


    const expectedDebt = parseFloat(USDdBorrow)+parseFloat(expectedFee)+parseFloat(liquidationReserve);
    console.log("expectedDebt",expectedDebt);


    // Get the nominal NICR of the new trove
    const _1e20 = await web3.utils.toWei("100").toString();
    console.log("_1e20", _1e20);
    let NICR = parseFloat(value)*parseFloat(_1e20)/(expectedDebt);
    // let NICR = await TROVE_HELPERS.methods.getNominalICR(deadAddress,"0x9412659141C4aA42a02dAe8791502d4451b20669").call();
    console.log("NICR", NICR);

    // Get an approximate address hint from the deployed HintHelper contract. Use (15 * number of troves) trials
    // to get an approx. hint that is close to the right position.
    let numTroves = await SORTEDTROVES.methods.getSize(deadAddress).call();
    console.log(numTroves);

    let numTrials = numTroves* parseFloat("15");

    let { 0: approxHint } = await HINT_HELPERS.methods.getApproxHint(
      deadAddress,
      NICR.toString(),
      numTrials,
      42
    ).call(); // random seed of 42

    // Use the approximate hint to get the exact upper and lower hints from the deployed SortedTroves contract
    let { 0: upperHint, 1: lowerHint } =
      await SORTEDTROVES.methods.findInsertPosition(
        deadAddress,
        NICR.toString(),
        approxHint,
        approxHint
      ).call();
      
      console.log("upperhint",upperHint, "lowerhint:",lowerHint);
      const res = await  BORROW.methods.openTrove(
          deadAddress,
          0,
          maxFee.toString(),
          USDdBorrow.toString(),
          upperHint,
          lowerHint
        ) .send({
          from: publicAddress,
          value: value.toString(),
        });
        console.log("res",res);
        setMicroeconomySuccessModal(true); 
        setBorrowModal(false)
      } catch (error) {
      console.log(error);
      }
  }


  return (
    <>
    {/* <div className="px-4 xl:px-0">
      <HeaderDash  Title="Borrow" />
      
    </div> */}

    <div className="microeconomy-wrapper borrow">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
            <img src={microeconomylogo} alt="" />

            <span>Borrow</span>
        </a>
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
       <ConnectWallet />
      </div>

      <div className="chart-wrapper">
        <div class="radial-chart-wrapper">
          <div className="bar-chart-head">
            <div>
                <h2>Total Borrowed</h2>
            </div>
          </div>

          <div className="inner"> 
            <Chart options={options1} series={series1} type="donut" height={300} />
          </div>
        </div>
      </div>

      <div className="nft-collection">
        <div className="head">
          <h2>Borrow</h2>
        </div>

        <div className="borrow-table-wrap">
        <div className="overflow-y-auto">
          <table class="table-fixed  w-full borrow-table">
          
            <tbody className="">
            {dataBorrow.map((value) => {
               return (
                  <tr className="bg-[#32353D] flex items-center justify-between px-2 md:px-5 py-5 mb-2 font-medium border-[#D9D9D9]">
                
                  <td className="flex items-center col-span-2 font-medium text-lg w-[30%]">
                    <img src={value.coin == "eth" ? etherumicon : btc} className="w-6 mr-4 md:w-10"></img>
                    {/* <img src={ethicon} className="w-6 mr-4 md:w-10"></img> */}
                    <span className="text-[#ffffff] text-sm md:text-lg">
                    {/* LP-WETH */}
                    {value.coin == "eth"
                          ? "Ethereum ETH"
                          : value.coin == "btc"
                          ? "Wrapped Bitcoin wBTC"
                          : ""}
                    </span>
                  </td>
  
                  <td className="w-[17.5%]">
                    <p className="text-[#858585] text-[14px]">
                      Deposit
                    </p>
                    <p className="text-[#ffffff] mt-2 text-sm md:text-lg">{value.deposit || "-"}</p>
                  </td>
  
                  <td className="w-[17.5%]">
                    <p className="text-[#858585] text-[14px]">TLV</p>
                    <p className="text-[#ffffff] mt-2 text-lg">{value.tvl} DCHF</p>
                  </td>{" "}
  
                  <td className="w-[17.5%]">
                    <p className="text-[#858585] text-[14px]"> {value.arp != null ? "ARP" : "Debt"}</p>
                    <p className="text-[#ffffff] mt-2 text-sm md:text-lg"> {value.debt || value.arp || "-"}</p>
                  </td>
                  <td className="text-right w-[17.5%] borrow-btns">
                  {/* <Link to="/dashboard/baroowEth"> */}
                    <button onClick={() => setBorrowModal(true)} className=" text-[#0A0A0A] bg-[#B7933D] px-8 h-11 rounded-[100px]">
                      Borrow
                    </button>
                  {/* </Link> */}
                    {/* <button onClick={() => setManageModal(true)}  className=" text-[#0A0A0A] px-8 h-11 manage-position">
                      Manage Position
                    </button> */}
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>

    <Modal
      open={borrowModal}
      onClose={() => setBorrowModal(false)}
      classNames={{
        modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal borrow-manage-eth-modal`
      }}
      center
    >
      <button className="absolute right-4 top-6">
        <img 
        src={modalcloseicon}
          onClick={() => setBorrowModal(false)}
        />
      </button>

      <div className="modal-body">
          <h3>Borrow ETH<button><img src={infocircle} alt="" /></button></h3>

          <div className="borrow-manage-wrap">
              <div className="left">
                  <div className="info-head">
                    <h4>Price</h4>
                  </div>

                  <div className="info-box">
                      <div className="info">
                        <label>Matic Price</label>

                        <span>{barrowCardValue1.value1}</span>
                      </div>

                      <div className="info">
                        <label>Your Liquidation Price</label>

                        <span>{barrowCardValue1.value2}</span>
                      </div>
                  </div>

                  <div className="info-head">
                    <h4>Collateral Section</h4>

                    {/* <span>0.00%</span> */}
                  </div>

                  <div className="info-box">
                      <div className="info">
                        <label>Total Collateral Ratio MATIC</label>

                        <span> {barrowCardValue2.value1} </span>
                      </div>

                      <div className="info">
                        <label>Your Collateral Ratio MATIC</label>
                        <span>{barrowCardValue2.value2} </span>
                      </div>
                  </div>

                  <div className="info-head">
                    <h4>Position</h4>
                  </div>

                  <div className="info-box">
                      <div className="info">
                        <label>Your Collateral</label>

                        <span>  {barrowCardValue3.value1} </span>
                      </div>

                      <div className="info">
                        <label>You Recieve</label>

                        <span> {barrowCardValue3.value2}</span>
                      </div>

                      <div className="info">
                        <label>Liquidation Reserve Fee</label>

                        <span> {barrowCardValue3.value3}</span>
                      </div>

                      <div className="info">
                        <label>Borrowing Fee (0.5%)</label>

                        <span> {barrowCardValue3.value4}</span>
                      </div>

                      <div className="info">
                        <label>Total Debt</label>

                        <span>{barrowCardValue3.value5}</span>
                      </div>
                  </div>
              </div>

              <div className="right">
                <div className="chart-wrapper">
                  <div className="info-head">
                    <h4></h4>
                  </div>
                  
                  <div class="radial-chart-wrapper">
                    <div className="inner"> 
                      <Chart options={options2} series={series2} type="donut" height={300} />
                    </div>
                  </div>
                </div>

                <div className="borrow-manage-form">
                  <div className="form-wrap">
                    <form onSubmit={handleBorrow}>
                      <div className="form-group">
                        <label>Deposit Collateral</label>

                        <input
                            type="text"
                            name="collateral"
                            pattern="[0.0-9]{0,9}"
                            value={borrow?.collateral}
                            onChange={handleField}
                            // value={barrowfrometh}
                        />

                        <div className="input-info">
                           <img src={ethicon} alt="" />
                          <span>Matic</span>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Borrow USDG</label>

                        <input
                            type="text"
                            name="borrowUSDd"
                            value={borrow?.borrowUSDd}
                            pattern="[0.0-9]{0,9}"
                            onChange={handleField}
                        />

                        <div className="input-info">
                            <img src={usdcicon} alt="" />
                            <span>USDG</span>
                        </div>
                      </div>

                      <button type="submit" >
                        Deposit
                      </button>
                    </form> 
                  </div>
                </div>
              </div>
          </div>
      </div>  
    </Modal>

    <Modal
      open={manageModal}
      onClose={() => setManageModal(false)}
      classNames={{
        modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal borrow-manage-eth-modal`
      }}
      center
    >
      <button className="absolute right-4 top-6">
        <img 
        src={modalcloseicon}
          onClick={() => setManageModal(false)}
        />
      </button>

      <div className="modal-body">
          <h3>Manage Your ETH Position<button><img src={infocircle} alt="" /></button></h3>

          <div className="borrow-manage-wrap">
              <div className="left">
                  <div className="info-head">
                    <h4>Price</h4>
                  </div>

                  <div className="info-box">
                      <div className="info">
                        <label>Matic Price</label>

                        <span>1522.60 MATIC</span>
                      </div>

                      <div className="info">
                        <label>Your Liquidation Price</label>

                        <span>0.00</span>
                      </div>
                  </div>

                  <div className="info-head">
                    <h4>Collateral Section</h4>

                    <span>0.00%</span>
                  </div>

                  <div className="info-box">
                      <div className="info">
                        <label>Total Collateral Ratio MATIC</label>

                        <span>0.00%</span>
                      </div>

                      <div className="info">
                        <label>Your Collateral Ratio MATIC</label>
                      </div>
                  </div>

                  <div className="info-head">
                    <h4>Position</h4>
                  </div>

                  <div className="info-box">
                      <div className="info">
                        <label>Your Collateral</label>

                        <span>0.00 MATIC</span>
                      </div>

                      <div className="info">
                        <label>You Recieve</label>

                        <span>0.00 CiFi</span>
                      </div>

                      <div className="info">
                        <label>Liquidation Reserve Fee</label>

                        <span>200.00 CiFi</span>
                      </div>

                      <div className="info">
                        <label>Borrowing Fee (0.5%)</label>

                        <span>0.00 CiFi</span>
                      </div>

                      <div className="info">
                        <label>Total Debt</label>

                        <span>200.00 CiFi</span>
                      </div>
                  </div>
              </div>

              <div className="right">
                <div className="chart-wrapper">
                  <div className="info-head">
                    <h4>Risk Ratio</h4>
                  </div>
                  
                  <div class="radial-chart-wrapper">
                    <div className="inner"> 
                      <Chart options={options2} series={series2} type="donut" height={300} />
                    </div>
                  </div>
                </div>

                <div className="borrow-manage-form">
                  <div className="form-wrap">
                    <h4>Your Position</h4>

                    <form onSubmit={ mySubmit}>
                      <div className="form-group">
                        <label>Deposit Collateral</label>

                        <input
                        onWheel={handleOnWheel}
                            type="number"
                            name='depositCollateral'
                            placeholder="0"
                        />

                        <div className="input-info">
                           <img src={ethicon} alt="" />
                          <span>ETH</span>
                        </div>
                      </div>

                      <button type="submit" onClick={() => {setMicroeconomySuccessModal(true); setManageModal(false)}}>
                        Liquidate
                      </button>
                    </form> 
                  </div>
                </div>
              </div>
          </div>
      </div>  
    </Modal>

    <Modal
          open={microeconomySuccessModal}
          onClose={() => setMicroeconomySuccessModal(false)}
          classNames={{
            modal: `${props.currentMode ? 'light' : 'dark'} success-modal`
          }}
          center
        >
          <button className="absolute right-4 top-6">
            <img 
            src={modalcloseicon}
              onClick={() => setMicroeconomySuccessModal(false)}
            />
          </button>

          <div className="modal-body">
            <div className="form-wrap">
              <img src={tickcircle} alt="Tick Circle"/>

              <h3>Success</h3>

              <span>4.44 MATIC BORROWED</span>
              <form>
                

                <div className="form-group hash">
                    <input
                        type="text"
                        name='walletAddress'
                        placeholder="Hash | 0xBBB6A7...6hn9"
                    />

                    <img className="copy" src={copy} alt="Tick Circle"/>

                    <img className="go-arrow" src={goarrow} alt="Go Arrow"/>
                </div>
              </form>
            </div> 
          </div>  
      </Modal>
    </>

  );
}

const mapDispatchToProps = {
  
};

const mapStateToProps = ({ Auth }) => {
  let { currentMode } = Auth;
  return { currentMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(Borrow);