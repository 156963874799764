import moment from "moment";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import viewpool from "../../images/view-pool-new.png";
import React, { useState, useEffect } from "react";
import modalcloseicon from "../../images/close.png";
import infocircle from "../../images/infocircle.svg";
import SwapLogo from "../../images/swap-icon-new.png";
import BlockchainIcon from "../../images/blockchain.svg";
import walletaddresscopyicon from "../../images/wallet-address-copy-icon.png";
import { networkId, GrootRouter, GrootRouterAddress, XRC20ABI, GrootPairABI, GrootFactory, GrootFactoryABI, GrootFactoryAddress, CIFI } from "../../store/config";
import { setLoader, getDAO, getXdcPrice, setBalance, setGrootBalance } from "../../store/actions/Auth";
import { web3 } from "../../store/web3";
import axios from 'axios';
import EventBus from "eventing-bus";
import { toFixed } from "../../store/numberFormat";
import BNBLogo from "../../images/bnb.png";
import XDCLogo from "../../images/xdc.png";
import Matic from "../../images/matic.png";

const ViewPools = (props, { mode }) => {

  const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  const [pairs, setPairs] = useState([]);
  const [slippageValue, setSlippageValue] = useState(0.3);

  const [poolModal1, setPoolModal1] = useState(false);
  const [poolModal2, setPoolModal2] = useState(false);
  const [poolValue1, setPoolValue1] = useState("XDC");
  const [poolValue2, setPoolValue2] = useState("XDC");
  const [addliquidityModal, setAddliquidityModal] = useState(false);
  const [removeliquidityModal, setRemoveliquidityModal] = useState(false);
  // Storing sepparate tokens on an array
  const [tokenList, setTokenList] = useState([]);
  const [tokenList1, setTokenList1] = useState([]);

  const [deadlineValue, setDeadlineValue] = useState(moment(Date.now()).add(30, 'm').toDate());

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  let [primaryToken, setPrimaryToken] = useState("");
  let [secondaryToken, setSecondaryToken] = useState("");
  let [primaryAmount, setPrimaryAmount] = useState("");
  let [secondaryAmount, setSecondaryAmount] = useState("");
  let [primaryAmountP, setPrimaryAmountP] = useState("");
  let [liquidityAmount, setLiquidityAmount] = useState("");
  let [liquidityAmountActual, setLiquidityAmountActual] = useState(0);
  let [secondaryAmountP, setSecondaryAmountP] = useState("");
  let [primaryAmountPMin, setPrimaryAmountPMin] = useState("");
  let [secondaryAmountPMin, setSecondaryAmountPMin] = useState("");
  let [pSymbol, setPSymbol] = useState("");
  let [sSymbol, setSSymbol] = useState("");
  let [selectPoolToken1, setSelectPoolToken1] = useState("");
  let [selectPoolToken2, setSelectPoolToken2] = useState("");

  useEffect(() => {
    setSlippageValue(parseFloat(0.3));
    // setXdcPrice('-');
  }, []);

  const fetchCryptoPrice = async (crypto, currency) => {
    const pairPrice = `${crypto}-${currency}-price`;
    const cachedData = localStorage.getItem(pairPrice);

    if (cachedData) {
      const { price, timestamp } = JSON.parse(cachedData);
      const hoursPassed = (new Date().getTime() - new Date(timestamp).getTime()) / (1000 * 60 * 60);

      if (hoursPassed < 24) {
        return price;
      }
    }

    try {
      const response = await fetch(`https://api.coinbase.com/v2/prices/${crypto}-${currency}/spot`);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      const newPrice = data.data.amount;

      localStorage.setItem(pairPrice, JSON.stringify({ price: newPrice, timestamp: new Date().toISOString() }));
      console.log("Fetched new data:", newPrice);
      return newPrice;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  async function setPairData() {
    const updatedPairs = await getPairs();
    setPairs([...updatedPairs].sort((a, b) => b.tvl - a.tvl));
  }

  useEffect(() => {
    if ((networkId == 80001 || networkId == 137) && web3) {
      setPairData();
      props.getXdcPrice();
    } else if ((networkId == 97 || networkId == 56) && web3) {
      setPairData();
      props.getXdcPrice();
    } else if ((networkId == 51 || networkId == 50) && web3) {
      setPairData();
      props.getXdcPrice();
    }
  }, [networkId, GrootFactoryABI, GrootFactoryAddress, web3]);

  async function getPairs() {
    try {
      props.setLoader({ message: "Loading Pools...", status: true });
      // Get the total number of pairs from the factory contract

      let GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

      const totalPairs = await GrootFactory.methods.allPairsLength().call();

      console.log(`Total number of pairs: ${totalPairs}`);

      // Iterate over the pairs and get their addresses
      const pairDetailsPromises = [];
      for (let i = 0; i < totalPairs; i++) {
        const pairAddress = await GrootFactory.methods.allPairs(i).call();

        // Push a promise with internal error handling
        pairDetailsPromises.push(
          getPairDetails(pairAddress).catch(error => {
            console.error(`Error fetching details for pair at index ${i}:`, error);
            return null; // or any other error representation
          })
        );
      }

      // Await all promises (both resolved and rejected)
      const pairsDetails = await Promise.all(pairDetailsPromises);

      // Filter out null values if you don't want failed results
      return pairsDetails.filter(details => details !== null);
    } catch (error) {
      props.setLoader({ message: "Loading Pools...", status: false });
      console.log('Error fetching pairs:', error);
      return [];
    }
  }

  async function getPairDetails(pairAddress) {
    try {
      pairAddress = replaceXdcWith0x(pairAddress);
      let volume = 0;
      let fees = 0;
      const pairContract = new web3.eth.Contract(GrootPairABI, pairAddress);
      await calculateVolume(pairContract, 30).then(data => { volume = data.volume; fees = data.fees });

      // Get reserves and other details
      const reserves = await pairContract.methods.getReserves().call();

      const token0Address = await pairContract.methods.token0().call();
      const token1Address = await pairContract.methods.token1().call();

      // Create contract instances for token0 and token1
      const token0Contract = new web3.eth.Contract(XRC20ABI, token0Address);
      const token1Contract = new web3.eth.Contract(XRC20ABI, token1Address);

      // Fetch the names and symbols of token0 and token1
      const token0Name = await token0Contract.methods.name().call();
      const token0Symbol = await token0Contract.methods.symbol().call();
      const token1Name = await token1Contract.methods.name().call();
      const token1Symbol = await token1Contract.methods.symbol().call();

      let price = await fetchCryptoPrice(token0Symbol, token1Symbol);

      let tvl = 0;
      if(price){
        tvl = await calculateTVL(token0Symbol, reserves._reserve0, token1Symbol, reserves._reserve1, price);
      } else {
        tvl = await calculateTVL(token0Symbol, reserves._reserve0, token1Symbol, reserves._reserve1, props.xdcPrice);
      }

      let amountToken0 = web3.utils.toWei("1", "ether");
      let expectedAmount = await GrootRouter.methods.getAmountsOut(amountToken0, [token0Address.toLowerCase(), token1Address.toLowerCase()]).call();
      let sAmount = parseFloat(expectedAmount[1]);
      sAmount = web3.utils.fromWei(sAmount.toString(), "ether");
      let amountToken1 = parseFloat(toFixed(sAmount, 'balance'));

      amountToken0 = web3.utils.fromWei(amountToken0.toString(), "ether");
      amountToken0 = parseFloat(toFixed(amountToken0, 'balance'));

      props.setLoader({ message: "Loading Pools...", status: false });
      return {
        pairAddress,
        reserve0: reserves._reserve0,
        reserve1: reserves._reserve1,
        token0Address,
        token1Address,
        amountToken0,
        amountToken1,
        token0Name,
        token0Symbol,
        token1Name,
        token1Symbol,
        tvl,
        volume,
        fees

        // ... include other details here
      };
    } catch (e) {
      props.setLoader({ message: "Loading Pools...", status: false });
      console.log(e);
    }
  }

  function replaceXdcWith0x(inputString) {
    if (inputString.startsWith('xdc')) {
      // Replace 'xdc' with '0x'
      return '0x' + inputString.slice(3);
    } else {
      // Return the original string if it doesn't start with 'xdc'
      return inputString;
    }
  }

  async function calculateVolume(contract, feeRate) {
    const oneDayAgo = Math.floor((Date.now() / 1000) - (24 * 60 * 60));
    const events = await contract.getPastEvents('Swap', {
      fromBlock: Math.max(0, await web3.eth.getBlockNumber() - 43271), // Approx blocks in 24hrs
      toBlock: 'latest'
    });

    let volume = 0;
    let fees = 0;

    events.forEach(event => {
      // Assuming the event returns the timestamp and the amounts swapped
      const eventTimestamp = event.returnValues.timestamp;
      const amount0In = web3.utils.toBN(event.returnValues.amount0In);
      const amount1In = web3.utils.toBN(event.returnValues.amount1In);

      if (eventTimestamp >= oneDayAgo) {
        const totalAmountIn = amount0In.add(amount1In);
        volume += parseFloat(web3.utils.fromWei(totalAmountIn, 'ether'));

        // Calculate fees for each swap
        const feeForThisSwap = totalAmountIn.mul(web3.utils.toBN(feeRate)).div(web3.utils.toBN(10000)); // Assuming feeRate is in basis points (bps)
        fees += parseFloat(web3.utils.fromWei(feeForThisSwap, 'ether'));
      }
    });

    return { volume, fees };
  }

  async function calculateTVL(token0symbol, reserve0, token1symbol, reserve1, pairPrice) {
    // Normalize reserves
    const normalizedReserve0 = parseFloat(reserve0) / 1e18; // Assuming 18 decimals for token0
    const normalizedReserve1 = parseFloat(reserve1) / 1e18; // Assuming 18 decimals for token1

    let valueOfReserve0InUSD = 0;
    let valueOfReserve1InUSD = 0;

    // Check if token0 is XDC or WXDC
    if (token0symbol === 'XDC' || token0symbol === 'WXDC') {
      valueOfReserve0InUSD = normalizedReserve0 * pairPrice;
      const priceOfToken1InUSD = pairPrice * (normalizedReserve0 / normalizedReserve1);
      valueOfReserve1InUSD = normalizedReserve1 * priceOfToken1InUSD;
    }
    // Check if token1 is XDC or WXDC
    else if (token1symbol === 'XDC' || token1symbol === 'WXDC') {
      valueOfReserve1InUSD = normalizedReserve1 * pairPrice;
      const priceOfToken0InUSD = pairPrice * (normalizedReserve1 / normalizedReserve0);
      valueOfReserve0InUSD = normalizedReserve0 * priceOfToken0InUSD;
    } else {
      // Handle case where neither token is XDC or WXDC
      // Additional logic needed here to determine prices
      return "-";
    }

    // Calculate TVL
    // Calculate TVL and limit to 3 decimal places
    const tvl = valueOfReserve0InUSD + valueOfReserve1InUSD;
    return parseFloat(tvl.toFixed(3));
  }

  // Add Liquidity / Remove Liquidity
  const clearStates = (e) => {
    setPrimaryAmountP("");
    setSecondaryAmountP("");
    setPrimaryAmountPMin("");
    setSecondaryAmountPMin("");
    setLiquidityAmount("");
    setLiquidityAmountActual(0);
    setSelectPoolToken1("");
    setSelectPoolToken2("");
  };

  const incrementCount = () => {
    if (parseFloat(primaryAmountPMin) > 0) {
      setPrimaryAmountPMin(parseFloat(primaryAmountPMin) + 1);
    } else {
      setPrimaryAmountPMin("");
    }
  };

  const decrementCount = () => {
    if (parseFloat(primaryAmountPMin) > 0) {
      setPrimaryAmountPMin(parseFloat(primaryAmountPMin) - 1);
    } else {
      setPrimaryAmountPMin("");
    }
  };

  const increment = () => {
    if (parseFloat(secondaryAmountPMin) > 0) {
      setSecondaryAmountPMin(parseFloat(secondaryAmountPMin) - 1);
    } else {
      setSecondaryAmountPMin("");
    }
  };

  const decrement = () => {
    if (parseFloat(secondaryAmountPMin) > 0) {
      setSecondaryAmountPMin(parseFloat(secondaryAmountPMin) - 1);
    } else {
      setSecondaryAmountPMin("");
    }
  };

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function setLiquidityPercent(value) {
    let liquidityValue = (parseFloat(liquidityAmountActual) / 100) * parseInt(value);
    setLiquidityAmount(parseFloat(liquidityValue));
  }

  async function addLiquidity() {
    try {
      let address = props.publicAddress;

      // if (props.kybStatus !== "approved") {
      //   EventBus.publish("error", `Submit KYB verification to access this function!`);
      //   return;
      // }

      let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
      let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

      let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
      let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

      let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
      let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

      if (selectPoolToken1 == "MATIC") {
        primaryToken = MATIC;
      } else if (selectPoolToken1 == "WMATIC") {
        primaryToken = WMATIC;
      } else if (selectPoolToken1 == "BNB") {
        primaryToken = BNB;
      } else if (selectPoolToken1 == "WBNB") {
        primaryToken = WBNB;
      } else if (selectPoolToken1 == "XDC") {
        primaryToken = XDC;
      } else if (selectPoolToken1 == "WXDC") {
        primaryToken = WXDC;
      }

      if (selectPoolToken2 == "MATIC") {
        EventBus.publish("error", `Please select MATIC as token A!`);
        return;
      } else if (selectPoolToken2 == "WMATIC") {
        EventBus.publish("error", `Please select MATIC as token A!`);
        return;
      } else if (selectPoolToken2 == "BNB") {
        EventBus.publish("error", `Please select BNB as token A!`);
        return;
      } else if (selectPoolToken2 == "WBNB") {
        EventBus.publish("error", `Please select BNB as token A!`);
        return;
      } else if (selectPoolToken2 == "XDC") {
        EventBus.publish("error", `Please select XDC as token A!`);
        return;
      } else if (selectPoolToken2 == "WXDC") {
        EventBus.publish("error", `Please select XDC as token A!`);
        return;
      }

      if (primaryToken == "") {
        EventBus.publish("error", `Token A is missing!`);
        return;
      }
      if (secondaryToken == "") {
        EventBus.publish("error", `Token B is missing!`);
        return;
      }
      if (primaryToken.toLowerCase() === secondaryToken.toLowerCase()) {
        EventBus.publish(
          "error",
          `Token A cannot have same address as Token B!`
        );
        return;
      }
      if (parseFloat(primaryAmountP) <= 0) {
        EventBus.publish("error", `Enter Token A Amount!`);
        return;
      }
      if (parseFloat(secondaryAmountP) <= 0) {
        EventBus.publish("error", `Enter Token B Amount!`);
        return;
      }
      if (parseFloat(primaryAmountPMin) <= 0) {
        EventBus.publish("error", `Enter Token A Min Amount!`);
        return;
      }
      if (parseFloat(secondaryAmountPMin) <= 0) {
        EventBus.publish("error", `Enter Token B Min Amount!`);
        return;
      }

      primaryToken = primaryToken.toLowerCase();
      secondaryToken = secondaryToken.toLowerCase();
      primaryAmountP = await web3.utils.toWei(
        primaryAmountP.toString(),
        "ether"
      );
      secondaryAmountP = await web3.utils.toWei(
        secondaryAmountP.toString(),
        "ether"
      );
      primaryAmountPMin = await web3.utils.toWei(
        primaryAmountPMin.toString(),
        "ether"
      );
      secondaryAmountPMin = await web3.utils.toWei(
        secondaryAmountPMin.toString(),
        "ether"
      );

      let primaryContract = new web3.eth.Contract(XRC20ABI, primaryToken);
      let secondaryContract = new web3.eth.Contract(XRC20ABI, secondaryToken);

      let primaryBalance = await primaryContract.methods
        .balanceOf(address)
        .call();
      let secondaryBalance = await secondaryContract.methods
        .balanceOf(address)
        .call();

      let userBalance = await web3.eth.getBalance(address);

      if (
        primaryToken.toLowerCase() === "0x0000000000000000000000000000000000001010".toLowerCase() ||
        primaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        primaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() ||
        primaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        if (parseFloat(primaryAmountP) > userBalance) {
          EventBus.publish(
            "error",
            `Token A: You dont have enough balance to deposit!`
          );
          return;
        }
      } else {
        if (parseFloat(primaryAmountP) > primaryBalance) {
          EventBus.publish(
            "error",
            `Token A: You dont have enough balance to deposit!`
          );
          return;
        }
      }

      if (
        secondaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x0000000000000000000000000000000000001010".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase()
      ) {
        if (parseFloat(secondaryAmountP) > userBalance) {
          EventBus.publish(
            "error",
            `Token B: You dont have enough balance to deposit!`
          );
          return;
        }
      } else {
        if (parseFloat(secondaryAmountP) > secondaryBalance) {
          EventBus.publish(
            "error",
            `Token B: You dont have enough balance to deposit!`
          );
          return;
        }
      }

      // Set Amount
      const amountInPrimary = primaryAmountP;
      const amountInP = amountInPrimary.toString();

      const amountInSecondary = secondaryAmountP;
      const amountInS = amountInSecondary.toString();

      /** Add Liquidity **/
      props.setLoader({
        message: "Add Liquidity in Progress...",
        status: true,
      });

      if (
        primaryToken.toLowerCase() !== "0x0000000000000000000000000000000000001010".toLowerCase() &&
        primaryToken.toLowerCase() !== "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() &&
        primaryToken.toLowerCase() !== "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() &&
        primaryToken.toLowerCase() !== "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        console.log("Approving Primary Token ...");
        await primaryContract.methods
          .approve(GrootRouterAddress, amountInP)
          .send({
            from: address,
            gas: 2000000,
          });
      }

      if (
        secondaryToken.toLowerCase() !== "0x0000000000000000000000000000000000001010".toLowerCase() &&
        secondaryToken.toLowerCase() !== "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() &&
        secondaryToken.toLowerCase() !== "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() &&
        secondaryToken.toLowerCase() !== "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        console.log("Approving Secondary Token ...");
        await secondaryContract.methods
          .approve(GrootRouterAddress, amountInS)
          .send({
            from: address,
            gas: 2000000,
          });
      }

      console.log("Adding Liquidity...");
      let result;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (
        primaryToken.toLowerCase() === "0x0000000000000000000000000000000000001010".toLowerCase() ||
        primaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        primaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() ||
        primaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        result = await GrootRouter.methods.addLiquidityXDC(
          secondaryToken,
          secondaryAmountP,
          secondaryAmountPMin,
          primaryAmountPMin,
          address,
          web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20)
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            value: web3.utils.toHex(primaryAmountP),
            data: result.encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            refreshBalance();
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setAddliquidityModal(false);
            getPairs().then(setPairs);
            props.setLoader({ status: false });
            EventBus.publish("success", `Liquidity Added Successfully`);
          });
      } else if (
        secondaryToken.toLowerCase() === "0x0000000000000000000000000000000000001010".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        secondaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() ||
        secondaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        result = await GrootRouter.methods.addLiquidityXDC(
          primaryToken,
          primaryAmountP,
          primaryAmountPMin,
          secondaryAmountPMin,
          address,
          web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20)
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            value: web3.utils.toHex(secondaryAmountP),
            data: result.encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            refreshBalance();
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setAddliquidityModal(false);
            getPairs().then(setPairs);
            props.setLoader({ status: false });
            EventBus.publish("success", `Liquidity Added Successfully`);
          });
      } else {
        result = await GrootRouter.methods.addLiquidity(
          primaryToken,
          secondaryToken,
          primaryAmountP,
          secondaryAmountP,
          primaryAmountPMin,
          secondaryAmountPMin,
          address,
          web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20)
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            data: result.encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            refreshBalance();
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setAddliquidityModal(false);
            getPairs().then(setPairs);
            props.setLoader({ status: false });
            EventBus.publish("success", `Liquidity Added Successfully`);
          });
      }
    } catch (e) {
      console.log("********Error = ", e);
      // setPSymbol("");
      // setSSymbol("");
      // setPrimaryToken("");
      // setSecondaryToken("");
      // setPrimaryAmountP("");
      // setSecondaryAmountP("");
      // setPrimaryAmountPMin("");
      // setSecondaryAmountPMin("");
      // setPoolValue1("");
      // setPoolValue2("");
      // setSelectPoolToken1("");
      // setSelectPoolToken2("");
      props.setLoader({
        message: "Add Liquidity Failed!",
        status: false,
      });
      EventBus.publish("error", `Unable To Add Liquidity!`);
    }
  }

  async function removeLiquidity() {
    try {
      let address = props.publicAddress;

      // if (props.kybStatus !== "approved") {
      //   EventBus.publish("error", `Submit KYB verification to access this function!`);
      //   return;
      // }

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (address == null || address == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
      let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

      let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
      let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

      let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
      let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

      if (selectPoolToken1 == "MATIC") {
        primaryToken = MATIC;
      } else if (selectPoolToken1 == "WMATIC") {
        primaryToken = WMATIC;
      } else if (selectPoolToken1 == "BNB") {
        primaryToken = BNB;
      } else if (selectPoolToken1 == "WBNB") {
        primaryToken = WBNB;
      } else if (selectPoolToken1 == "XDC") {
        primaryToken = XDC;
      } else if (selectPoolToken1 == "WXDC") {
        primaryToken = WXDC;
      }

      // if (selectPoolToken2 == "MATIC") {
      //   secondaryToken = MATIC;
      // } else if (selectPoolToken2 == "WMATIC") {
      //   secondaryToken = WMATIC;
      // } else if (selectPoolToken2 == "BNB") {
      //   secondaryToken = BNB;
      // } else if (selectPoolToken2 == "WBNB") {
      //   secondaryToken = WBNB;
      // } else if (selectPoolToken2 == "XDC") {
      //   secondaryToken = XDC;
      // } else if (selectPoolToken2 == "WXDC") {
      //   secondaryToken = WXDC;
      // }
      if (selectPoolToken2 == "MATIC") {
        EventBus.publish("error", `Please select MATIC as token A!`);
        return;
      } else if (selectPoolToken2 == "WMATIC") {
        EventBus.publish("error", `Please select MATIC as token A!`);
        return;
      } else if (selectPoolToken2 == "BNB") {
        EventBus.publish("error", `Please select BNB as token A!`);
        return;
      } else if (selectPoolToken2 == "WBNB") {
        EventBus.publish("error", `Please select BNB as token A!`);
        return;
      } else if (selectPoolToken2 == "XDC") {
        EventBus.publish("error", `Please select XDC as token A!`);
        return;
      } else if (selectPoolToken2 == "WXDC") {
        EventBus.publish("error", `Please select XDC as token A!`);
        return;
      }

      if (primaryToken == "") {
        EventBus.publish("error", `Token A is missing!`);
        return;
      }
      if (secondaryToken == "") {
        EventBus.publish("error", `Token B is missing!`);
        return;
      }
      if (primaryToken.toLowerCase() === secondaryToken.toLowerCase()) {
        EventBus.publish(
          "error",
          `Token A cannot have same address as Token B!`
        );
        return;
      }

      let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), secondaryToken.toLowerCase()).call();

      if (pair == "0x0000000000000000000000000000000000000000") {
        EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
        return;
      } else {
        let pairContract = new web3.eth.Contract(GrootPairABI, pair);
        let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
        liquidityAmount = web3.utils.toWei(liquidityAmount.toString(), "ether");
        if (parseFloat(liquidityAmount) > parseFloat(balanceLiquidity)) {
          EventBus.publish("error", `You have not added this amount of liquidity!`);
        }

        props.setLoader({
          message: "Approval in Progress...",
          status: true,
        });

        await pairContract.methods
          .approve(GrootRouterAddress, liquidityAmount)
          .send({
            from: address,
            gas: 2000000,
          });
      }

      if (parseFloat(liquidityAmount) <= 0) {
        EventBus.publish("error", `Enter Liquidity Amount!`);
        return;
      }

      const balanceWei = await web3.eth.getBalance(publicAddress);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (parseFloat(balanceEther) == 0) return EventBus.publish("error", `Insufficient balance for transaction`);


      liquidityAmount = liquidityAmount.toString();

      /** Remove Liquidity **/
      props.setLoader({
        message: "Remove Liquidity in Progress...",
        status: true,
      });

      console.log("Removing Liquidity...");

      let result;

      if (
        primaryToken.toLowerCase() === "0x0000000000000000000000000000000000001010".toLowerCase() ||
        primaryToken.toLowerCase() === "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase() ||
        primaryToken.toLowerCase() === "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase() ||
        primaryToken.toLowerCase() === "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase()
      ) {
        result = await GrootRouter.methods.removeLiquidityXDC(
          secondaryToken,
          liquidityAmount,
          1,
          1,
          address,
          web3.utils.toHex(Math.ceil(deadlineValue / 1000))
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            data: result.encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            refreshBalance();
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setLiquidityAmount("");
            setLiquidityAmountActual(0);
            setRemoveliquidityModal(false);
            props.setLoader({ status: false });
            EventBus.publish("success", `Liquidity Redeemed Successfully`);
          });
      } else {
        result = await GrootRouter.methods.removeLiquidity(
          primaryToken,
          secondaryToken,
          liquidityAmount,
          1,
          1,
          address,
          web3.utils.toHex(Math.ceil(deadlineValue / 1000))
        );

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            to: GrootRouterAddress,
            gas: 5000000,
            data: result.encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            refreshBalance();
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setLiquidityAmount("");
            setLiquidityAmountActual(0);
            props.setLoader({ status: false });
            setRemoveliquidityModal(false);
            EventBus.publish("success", `Liquidity Redeemed Successfully`);
          });
      }
    } catch (e) {
      console.log("********Error = ", e);
      setPSymbol("");
      setSSymbol("");
      setPrimaryToken("");
      setSecondaryToken("");
      setPrimaryAmountP("");
      setSecondaryAmountP("");
      setPrimaryAmountPMin("");
      setSecondaryAmountPMin("");
      setPoolValue1("");
      setPoolValue2("");
      setSelectPoolToken1("");
      setSelectPoolToken2("");
      setLiquidityAmount("");
      setLiquidityAmountActual(0);
      props.setLoader({
        message: "Add Redeem Failed!",
        status: false,
      });
      EventBus.publish("error", `Unable To Redeem Liquidity!`);
    }
  }

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "primaryAmountP") {
      if (parseFloat(e.target.value) > 0) {
        setPrimaryAmountP(parseFloat(e.target.value));
      } else {
        setPrimaryAmountP("");
      }
    } else if ([e.target.name] == "secondaryAmountP") {
      if (parseFloat(e.target.value) > 0) {
        setSecondaryAmountP(parseFloat(e.target.value));
      } else {
        setSecondaryAmountP("");
      }
    } else if ([e.target.name] == "liquidityAmount") {
      if ((selectPoolToken1 !== "" || primaryToken !== "") && (selectPoolToken2 !== "" || secondaryToken !== "")) {
        if (parseFloat(e.target.value) > 0) {
          if (parseFloat(e.target.value) < parseFloat(liquidityAmountActual)) {
            setLiquidityAmount(parseFloat(e.target.value));
          } else {
            EventBus.publish("error", `You have not added this amount of liquidity!`);
            return;
          }
        } else {
          setLiquidityAmount("");
        }
      } else {
        EventBus.publish("error", `Please select token pair first!`);
        return;
      }
    } else if ([e.target.name] == "primaryAmountPMin") {
      if (parseFloat(e.target.value) > 0) {
        setPrimaryAmountPMin(parseFloat(e.target.value));
      } else {
        setPrimaryAmountPMin("");
      }
    } else if ([e.target.name] == "secondaryAmountPMin") {
      if (parseFloat(e.target.value) > 0) {
        setSecondaryAmountPMin(parseFloat(e.target.value));
      } else {
        setSecondaryAmountPMin("");
      }
    } else if ([e.target.name] == "primaryToken") {
      if (e.target.value == "") {
        setPrimaryToken("");
      } else if ((e.target.value).length >= 42) {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          setPrimaryToken(result);
          var code = await web3.eth.getCode(result.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setPrimaryToken("");
            return;
          } else {
            setPoolValue1("");
            setSelectPoolToken1("");
            await waitFor(1500);
            let primaryContract = new web3.eth.Contract(XRC20ABI, result);
            let primarySymbol = await primaryContract.methods.symbol().call();
            setPSymbol(primarySymbol);
            if (selectPoolToken2 !== "" || secondaryToken !== "") {
              let { address } = props;

              let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

              if (selectPoolToken2 == "MATIC") {
                secondaryToken = MATIC;
              } else if (selectPoolToken2 == "WMATIC") {
                secondaryToken = WMATIC;
              } else if (selectPoolToken2 == "BNB") {
                secondaryToken = BNB;
              } else if (selectPoolToken2 == "WBNB") {
                secondaryToken = WBNB;
              } else if (selectPoolToken2 == "XDC") {
                secondaryToken = XDC;
              } else if (selectPoolToken2 == "WXDC") {
                secondaryToken = WXDC;
              }

              if (typeof address === "undefined" || address == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
              } else {
                let pair = await GrootFactory.methods.getPair(result.toLowerCase(), secondaryToken.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                  EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                  return;
                } else {
                  let pairContract = new web3.eth.Contract(GrootPairABI, pair);
                  let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
                  balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                  balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
                  setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                  setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
              }
            }
          }
        } else {
          setPrimaryToken(e.target.value);
          var code = await web3.eth.getCode(e.target.value.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setPrimaryToken("");
            return;
          } else {
            setPoolValue1("");
            setSelectPoolToken1("");
            await waitFor(1500);
            let primaryContract = new web3.eth.Contract(XRC20ABI, e.target.value);
            let primarySymbol = await primaryContract.methods.symbol().call();
            setPSymbol(primarySymbol);
            if (selectPoolToken2 !== "" || secondaryToken !== "") {
              let { address } = props;

              let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

              if (selectPoolToken2 == "MATIC") {
                secondaryToken = MATIC;
              } else if (selectPoolToken2 == "WMATIC") {
                secondaryToken = WMATIC;
              } else if (selectPoolToken2 == "BNB") {
                secondaryToken = BNB;
              } else if (selectPoolToken2 == "WBNB") {
                secondaryToken = WBNB;
              } else if (selectPoolToken2 == "XDC") {
                secondaryToken = XDC;
              } else if (selectPoolToken2 == "WXDC") {
                secondaryToken = WXDC;
              }

              if (typeof address === "undefined" || address == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
              } else {
                let pair = await GrootFactory.methods.getPair(e.target.value.toLowerCase(), secondaryToken.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                  EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                  return;
                } else {
                  let pairContract = new web3.eth.Contract(GrootPairABI, pair);
                  let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
                  balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                  balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
                  setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                  setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
              }
            }
          }
        }
      } else {
        setPrimaryToken(e.target.value)
      }
    } else if ([e.target.name] == "secondaryToken") {
      console.log("****");
      if (e.target.value == "") {
        setSecondaryToken("");
      } else if ((e.target.value).length >= 42) {
        let input = e.target.value;
        let output = input.substring(0, 3); // checks first three char of address
        if (output == "xdc") {
          let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
          setSecondaryToken(result);
          var code = await web3.eth.getCode(result.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setSecondaryToken("");
            return;
          } else {
            setPoolValue2("");
            setSelectPoolToken2("");
            await waitFor(1500);
            let secondaryContract = new web3.eth.Contract(XRC20ABI, result);
            let secondarySymbol = await secondaryContract.methods.symbol().call();
            setSSymbol(secondarySymbol);

            if (selectPoolToken1 !== "" || primaryToken !== "") {
              let { address } = props;

              let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

              if (selectPoolToken1 == "MATIC") {
                primaryToken = MATIC;
              } else if (selectPoolToken1 == "WMATIC") {
                primaryToken = WMATIC;
              } else if (selectPoolToken1 == "BNB") {
                primaryToken = BNB;
              } else if (selectPoolToken1 == "WBNB") {
                primaryToken = WBNB;
              } else if (selectPoolToken1 == "XDC") {
                primaryToken = XDC;
              } else if (selectPoolToken1 == "WXDC") {
                primaryToken = WXDC;
              }

              if (typeof address === "undefined" || address == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
              } else {
                let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), result.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                  EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                  return;
                } else {
                  let pairContract = new web3.eth.Contract(GrootPairABI, pair);
                  let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
                  balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                  balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
                  setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                  setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
              }
            }
          }
        } else {
          setSecondaryToken(e.target.value);
          var code = await web3.eth.getCode(e.target.value.toLowerCase());
          if (code === "0x") {
            EventBus.publish("error", `Invalid Token Address!`);
            setSecondaryToken("");
            return;
          } else {
            setPoolValue2("");
            setSelectPoolToken2("");
            await waitFor(1500);
            let secondaryContract = new web3.eth.Contract(XRC20ABI, e.target.value);
            let secondarySymbol = await secondaryContract.methods.symbol().call();
            setSSymbol(secondarySymbol);

            if (selectPoolToken1 !== "" || primaryToken !== "") {
              let { address } = props;

              let MATIC = "0x0000000000000000000000000000000000001010".toLowerCase();
              let WMATIC = "0x4fa1f87e3831335f00fa4ec41ecb8f50f5acea6b".toLowerCase();

              let BNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();
              let WBNB = "0x153f54def755c589c6607adeb889d9b4340c6198".toLowerCase();

              let XDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();
              let WXDC = "0xd667E515833a5285FDDeF96e8C6E02370A74eadf".toLowerCase();

              if (selectPoolToken1 == "MATIC") {
                primaryToken = MATIC;
              } else if (selectPoolToken1 == "WMATIC") {
                primaryToken = WMATIC;
              } else if (selectPoolToken1 == "BNB") {
                primaryToken = BNB;
              } else if (selectPoolToken1 == "WBNB") {
                primaryToken = WBNB;
              } else if (selectPoolToken1 == "XDC") {
                primaryToken = XDC;
              } else if (selectPoolToken1 == "WXDC") {
                primaryToken = WXDC;
              }

              if (typeof address === "undefined" || address == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
              } else {
                let pair = await GrootFactory.methods.getPair(primaryToken.toLowerCase(), e.target.value.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                  EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                  return;
                } else {
                  let pairContract = new web3.eth.Contract(GrootPairABI, pair);
                  let balanceLiquidity = await pairContract.methods.balanceOf(address).call();
                  balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                  balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
                  setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                  setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
              }
            }
          }
        }
      } else {
        setSecondaryToken(e.target.value)
      }
    }
  }

  async function openRemoveLiq(item) {
    try {
      let { publicAddress } = props;
      setPrimaryToken(item?.token0Address);
      setPSymbol(item?.token0Symbol);
      setSecondaryToken(item?.token1Address);
      setSSymbol(item?.token1Symbol);
      let pairContract = new web3.eth.Contract(GrootPairABI, item?.pairAddress);
      let balanceLiquidity = await pairContract.methods.balanceOf(publicAddress).call();
      balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
      balanceLiquidity = (parseFloat(balanceLiquidity) / 100) * 99.6;
      setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
      setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
      setRemoveliquidityModal(true)
    } catch (e) {
      console.log("*** error :: ", e);
    }
  }

  async function closeRemoveLiq() {
    setPrimaryToken("");
    setPSymbol("");
    setSecondaryToken("");
    setSSymbol("");
    setLiquidityAmount("");
    setLiquidityAmountActual(0);
    setRemoveliquidityModal(false)
  }

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address)
      .then(() => EventBus.publish("success", "LP Token Address Copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  console.log("**** pairs :: ", pairs);

  return (
    <div className="microeconomy-wrapper dao-genesis view-all">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={viewpool} alt="" />

          <span>Pools</span>
        </a>
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />

      </div>

      <div className="microeconomy-tool view-pool">
        <h2>Become a liquidity provider and earn tokens.<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

        <div className="detail-row">
          <ul className="detail-list">
            <li>
              <div>
                <h2>{pairs.length ? pairs.length : 0}</h2>
                <p>Total Pools</p>
              </div>
              <img src={BlockchainIcon} alt="" />
            </li>
            <li>
              <div>
                <h2>{!isNaN(parseFloat(props.xdcPrice)) ? parseFloat(props.xdcPrice).toFixed(3) : props.xdcPrice}</h2>
                <p>XDC Price</p>
              </div>
              <img src={BlockchainIcon} alt="" />
            </li>
          </ul>

          <h2 className="mt-10">All Pools</h2>

          <div className="table-wrapper view-pools-table">
            <table responsive="md">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>LP Token</th>
                  <th>TVL</th>
                  <th>Liquidity Ratio</th>
                  <th>Volume (24h)</th>
                  <th>Fees (24h)</th>
                  <th>Status</th>
                  <th>+ Liquidity</th>
                  <th>- Liquidity</th>
                </tr>
              </thead>
              {
                pairs?.length > 0 &&
                pairs.map((item, index) => (
                  <tbody key={index}> {/* Make sure to add a key for each item */}
                    <tr style={{ backgroundColor: index % 2 !== 0 ? "#635e5e" : "none" }}>
                      <td>{item?.token0Symbol} / {item?.token1Symbol}</td>
                      <td>
                        <div className="wallet-address">
                          <span>{item?.pairAddress && item?.pairAddress.substring(0, 5) + '...' + item?.pairAddress.substring(37, item?.pairAddress.length)}</span>
                          <img src={walletaddresscopyicon} alt="" onClick={() => handleCopyAddress(item?.pairAddress)} />
                        </div>
                      </td>
                      <td>${item?.tvl}</td>
                      <td>{item?.amountToken0} / {item?.amountToken1}</td>
                      <td>${item?.volume}</td>
                      <td>${item?.fees}</td>
                      <td className="status green">Active</td>
                      <td>
                        {/* Link or button for adding liquidity */}
                        <button onClick={() => {
                          setPrimaryToken(item?.token0Address);
                          setPSymbol(item?.token0Symbol);
                          setSecondaryToken(item?.token1Address);
                          setSSymbol(item?.token1Symbol);
                          setAddliquidityModal(true);
                        }} className="add-liquidity">Add Liquidity</button>
                      </td>
                      <td>
                        {/* Button for removing liquidity */}
                        <button onClick={() => openRemoveLiq(item)} className="add-liquidity">Remove Liquidity</button>
                      </td>
                    </tr>
                  </tbody>
                ))
              }
            </table>

          </div>
        </div>
      </div>

      <Modal
        open={addliquidityModal}
        onClose={() => setAddliquidityModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal add-liquidity`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setAddliquidityModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="head">
              <h3>Add Liquidity</h3>

              <span onClick={() => clearStates()}>Clear all</span>
            </div>

            {/* <form> */}
            <div className="input-wrapper">
              <label>Select Pair</label>

              <div className="form-group">
                {primaryToken !== "" ? (
                  <input
                    type="text"
                    name="primaryToken"
                    value={primaryToken}
                    placeholder={primaryToken}
                    onInput={handleOnInput}
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    name="primaryToken"
                    placeholder="0x000"
                    value={primaryToken}
                    onInput={handleOnInput}
                    disabled
                  />
                )}

                <div onClick={() => setPoolModal1(true)} className="input-info">
                  <span>
                    {(networkId == 80001 || networkId == 137) &&
                      (pSymbol == "MATIC" || pSymbol == "WMATIC") ? (
                      <img src={Matic} className="w-5 h-5" alt="matic" />
                    ) : (networkId == 97 || networkId == 56) &&
                      (pSymbol == "BNB" || pSymbol == "WBNB") ? (
                      <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                    ) : (networkId == 51 || networkId == 50) &&
                      (pSymbol == "XDC" || pSymbol == "WXDC") ? (
                      <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <p>
                    {selectPoolToken1
                      ? selectPoolToken1
                      : pSymbol
                        ? pSymbol
                        : "Select Token"}
                  </p>
                </div>

                <p className="fee">{parseFloat(slippageValue)} % Fee Tier</p>
              </div>

              <div className="form-group">
                {secondaryToken !== "" ? (
                  <input
                    type="text"
                    name="secondaryToken"
                    value={secondaryToken}
                    placeholder={secondaryToken}
                    onInput={handleOnInput}
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    name="secondaryToken"
                    placeholder="0x000"
                    value={secondaryToken}
                    onInput={handleOnInput}
                    disabled
                  />
                )}


                <div onClick={() => setPoolModal2(true)} className="input-info">
                  <span>
                    {(networkId == 80001 || networkId == 137) &&
                      (sSymbol == "MATIC" || sSymbol == "WMATIC") ? (
                      <img src={Matic} className="w-5 h-5" alt="matic" />
                    ) : (networkId == 97 || networkId == 56) &&
                      (sSymbol == "BNB" || sSymbol == "WBNB") ? (
                      <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                    ) : (networkId == 51 || networkId == 50) &&
                      (sSymbol == "XDC" || sSymbol == "WXDC") ? (
                      <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <p>
                    {selectPoolToken2
                      ? selectPoolToken2
                      : sSymbol
                        ? sSymbol
                        : "Select Token"}
                  </p>
                </div>
              </div>
            </div>

            <label>Deposit Amounts</label>

            <div className="form-group label-handle">
              {primaryAmountP ? (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="primaryAmountP"
                  value={primaryAmountP}
                  id="exampleText0"
                  placeholder={primaryAmountP}
                  onInput={handleOnInput}
                />
              ) : (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="primaryAmountP"
                  value={primaryAmountP}
                  id="exampleText0"
                  placeholder="Enter Amount"
                  onInput={handleOnInput}
                />
              )}

              <div className="label">
                <span>{pSymbol}</span>
              </div>
            </div>

            <div className="form-group">
              {secondaryAmountP ? (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="secondaryAmountP"
                  value={secondaryAmountP}
                  id="exampleText0"
                  placeholder={secondaryAmountP}
                  onInput={handleOnInput}
                />
              ) : (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="secondaryAmountP"
                  value={secondaryAmountP}
                  id="exampleText0"
                  placeholder="Enter Amount"
                  onInput={handleOnInput}
                />
              )}

              <div className="label">
                <span>{sSymbol}</span>
              </div>
            </div>

            {primaryAmountP && secondaryAmountP !== "" ? (
              <div>
                <p className="text-[#9E9E9E] text-[14px] mt-2 mb-2">
                  Note: Enter same ratio for Amount A/B and Min-Amount A/B
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className="min-prices-wrapper">

              <div className="form-group-wrapper">
                <label>Min price {pSymbol}</label>
                <div className="form-group total-number">
                  <input
                    onWheel={handleOnWheel}
                    type="number"
                    name="primaryAmountPMin"
                    value={primaryAmountPMin}
                    placeholder={"0.036..."}
                    onInput={handleOnInput}
                  />

                  <div className="count-wrap">
                    <span onClick={(event) => {
                      event.preventDefault();
                      incrementCount();
                    }}>+</span>
                    <span onClick={(event) => {
                      event.preventDefault();
                      decrementCount();
                    }}>-</span>
                  </div>
                  <span>
                    {sSymbol !== "" && pSymbol !== ""
                      ? `${pSymbol} Per ${sSymbol}`
                      : ``}
                  </span>
                </div>
              </div>

              <div className="form-group-wrapper">
                <label>Min price {sSymbol}</label>
                <div className="form-group total-number">
                  <input
                    onWheel={handleOnWheel}
                    type="number"
                    name="secondaryAmountPMin"
                    value={secondaryAmountPMin}
                    placeholder={"0.036..."}
                    onInput={handleOnInput}
                  />

                  <div className="count-wrap">
                    <span onClick={(event) => {
                      event.preventDefault();
                      increment();
                    }}>+</span>
                    <span onClick={(event) => {
                      event.preventDefault();
                      decrement();
                    }}>-</span>
                  </div>
                  <span>
                    {sSymbol !== "" && pSymbol !== ""
                      ? `${sSymbol} Per ${pSymbol}`
                      : ``}
                  </span>
                </div>
              </div>
            </div>

            <button onClick={(event) => {
              event.preventDefault();
              addLiquidity();
            }}>Add</button>
            {/* </form> */}
          </div>
        </div>
      </Modal>

      <Modal
        open={removeliquidityModal}
        onClose={() => closeRemoveLiq()}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal add-liquidity`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => closeRemoveLiq()}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="head">
              <h3>Remove Liquidity</h3>

              <span onClick={() => clearStates()}>Clear all</span>
            </div>

            <div className="input-wrapper">
              <label>Select Pair</label>

              <div className="form-group">
                {primaryToken !== "" ? (
                  <input
                    type="text"
                    name="primaryToken"
                    value={primaryToken}
                    placeholder={primaryToken}
                    onInput={handleOnInput}
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    name="primaryToken"
                    placeholder="0x000"
                    value={primaryToken}
                    onInput={handleOnInput}
                    disabled
                  />
                )}

                <div onClick={() => setPoolModal1(true)} className="input-info">
                  <span>
                    {(networkId == 80001 || networkId == 137) &&
                      (pSymbol == "MATIC" || pSymbol == "WMATIC") ? (
                      <img src={Matic} className="w-5 h-5" alt="matic" />
                    ) : (networkId == 97 || networkId == 56) &&
                      (pSymbol == "BNB" || pSymbol == "WBNB") ? (
                      <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                    ) : (networkId == 51 || networkId == 50) &&
                      (pSymbol == "XDC" || pSymbol == "WXDC") ? (
                      <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <p>
                    {selectPoolToken1
                      ? selectPoolToken1
                      : pSymbol
                        ? pSymbol
                        : "Select Token"}
                  </p>
                </div>

              </div>

              <div className="form-group">
                {secondaryToken !== "" ? (
                  <input
                    type="text"
                    name="secondaryToken"
                    value={secondaryToken}
                    placeholder={secondaryToken}
                    onInput={handleOnInput}
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    name="secondaryToken"
                    placeholder="0x000"
                    value={secondaryToken}
                    onInput={handleOnInput}
                    disabled
                  />
                )}


                <div onClick={() => setPoolModal2(true)} className="input-info">
                  <span>
                    {(networkId == 80001 || networkId == 137) &&
                      (sSymbol == "MATIC" || sSymbol == "WMATIC") ? (
                      <img src={Matic} className="w-5 h-5" alt="matic" />
                    ) : (networkId == 97 || networkId == 56) &&
                      (sSymbol == "BNB" || sSymbol == "WBNB") ? (
                      <img src={BNBLogo} className="w-5 h-5" alt="bnb" />
                    ) : (networkId == 51 || networkId == 50) &&
                      (sSymbol == "XDC" || sSymbol == "WXDC") ? (
                      <img src={XDCLogo} className="w-5 h-5" alt="xdc" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <p>
                    {selectPoolToken2
                      ? selectPoolToken2
                      : sSymbol
                        ? sSymbol
                        : "Select Token"}
                  </p>
                </div>
              </div>
            </div>

            <label>Liquidity Amounts</label>

            <div className="form-group label-handle">
              {liquidityAmount ? (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="liquidityAmount"
                  value={liquidityAmount}
                  id="liquidityAmount"
                  placeholder={liquidityAmount}
                  onInput={handleOnInput}
                />
              ) : (
                <input
                  onWheel={handleOnWheel}
                  type="number"
                  name="liquidityAmount"
                  value={liquidityAmount}
                  id="liquidityAmount"
                  placeholder="Enter Amount"
                  onInput={handleOnInput}
                />
              )}

              <div className="label">
                <span>CiFi-LP</span>
              </div>
            </div>

            <div className="liquidity-percentage">
              <button onClick={() => setLiquidityPercent(10)}>10%</button>
              <button onClick={() => setLiquidityPercent(25)}>25%</button>
              <button onClick={() => setLiquidityPercent(50)}>50%</button>
              <button onClick={() => setLiquidityPercent(100)}>100%</button>
            </div>
            <button onClick={(event) => {
              event.preventDefault();
              removeLiquidity();
            }}>Remove</button>
          </div>
        </div>
      </Modal>

    </div>
  );
}

const mapDispatchToProps = {
  getDAO,
  setLoader,
  setBalance,
  getXdcPrice,
  setGrootBalance
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, daoList, selectDefaultNetwok, currentMode, xdcPrice } = Auth;
  return { publicAddress, daoList, selectDefaultNetwok, currentMode, xdcPrice }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPools);