import moment from "moment";
import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { web3 } from '../../store/web3';
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { toFixed } from "../../store/numberFormat";
import React, { useState, useEffect } from "react";
import { validate } from "wallet-address-validator";
import modalcloseicon from "../../images/close.png";
import daogenesisicon from "../../images/dao-genesis.png";
import circularitydaostakenftimg from "../../images/circularitydaostakenft-img.png";
import circularitydaostakenftimg2 from "../../images/circularitydaostakenft-img2.png";
import { setLoader, getXRC721, getXRC20, getStaking, setBalance, setGrootBalance, stakeNFT, unStakeNFT, getStakedNFT, getKybStatus, getKycStatus } from "../../store/actions/Auth";
import { XRC721ABI, CiFiStakeABI, CIFI, XRC20ABI, CiFiAccessNFTAddress, CiFiAccessNFTABI, CiFiDAONFTAddress, CiFiDAONFTABI, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";


const CircularityDaoStakeNft = (props, { mode }) => {

  let [data, setData] = useState("");
  let [nftSymbol, setnftSymbol] = useState("");
  let [from, setFrom] = useState("");
  let [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));
  let [nftAddressGOV, setnftAddressGOV] = useState("0xce7f1f4b168bbbe9c3120c706d6edc5baa1486f8");
  let [tokenAddressGOV, settokenAddressGOV] = useState("0xe5f9ae9d32d93d3934007568b30b7a7ca489c486");
  let [stakeAddressGOV, setstakeAddressGOV] = useState("0x452b5083a4af6613fa318a7ae96c3ec4ff80cfd2");
  let [nftAddressVIP, setnftAddressVIP] = useState("0xdf669e841aac3051321656fb451b06073c58b81c");
  let [tokenAddressVIP, settokenAddressVIP] = useState("0xbc24f5f3f09ced3c12322db67effb55297816ef6");
  let [stakeAddressVIP, setstakeAddressVIP] = useState("0x35b6c916cd03f58b8c8c9546f09073e231627882");
  let [stakings, setStakings] = useState([]);
  let [tokenIds, setTokenIds] = useState([]);
  let [tokenIds2, setTokenIds2] = useState([]);
  let [tokenId, setTokenId] = useState("");
  let [tokenId2, setTokenId2] = useState("");

  let [isNFT, setIsNFT] = useState();
  let [isWhitelist, setIsWhitelist] = useState(false);

  let [microeconomyStakeModal, setMicroeconomyStakeModal] = useState(false);
  let [microeconomyUnStakeModal, setMicroeconomyUnStakeModal] = useState(false);

  const navigate = useNavigate()

  const [model, setModel] = useState(true);


  setTimeout(() => {
    const modalRoot = document.querySelector('.react-responsive-modal-root');

    if (modalRoot) {
      //Add a custom class to the root element
      const stepsModal = modalRoot.querySelector('.steps-modal');

      if (stepsModal) {
        // Perform your action here
        modalRoot.classList.add('custom-modal-root');
      }

    } else {
      console.error("Element not found.");
    }
  }, 1000);

  const getNFTs = async () => {
    if (CiFiAccessNFTABI && CiFiAccessNFTAddress) {
      props.setLoader({ message: "Load Microeconomy...", status: true });
      let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
      if (newCiFiAccessNFTContract) {
        let tokenBalance = await newCiFiAccessNFTContract.methods.balanceOf(props.publicAddress).call({ from: props.publicAddress });
        // tokenBalance =  1; //tesing purpose
        setIsNFT(tokenBalance);
        if (Number(tokenBalance) >= 1) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Load Microeconomy...", status: false });
      }
    }

    if (AdminWhitelistABI && AdminWhitelistAddress) {
      props.setLoader({ message: "Loading...", status: true });
      let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
      if (newAdminWhitelist) {
        let status = await newAdminWhitelist.methods.isWhitelist(props.publicAddress).call({ from: props.publicAddress });
        // status =  true; //tesing purpose
        setIsWhitelist(status);
        if (status == true) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Loading...", status: false });
      }
    }

  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      getNFTs();
    }
  }, [props.publicAddress, web3]);

  useEffect(() => {
    props.getXRC20();
    props.getXRC721();
    props.getStaking();
    if (props.publicAddress == null || props.publicAddress == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    } else {
      let address = props.publicAddress;
      let output = props.publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        address = "0x" + props.publicAddress.substring(3);
        setFrom(address);
      } else {
        address = props.publicAddress;
        setFrom(address);
      }
    }
  }, []);

  async function setStakeData() {
    const updatedStakings = await Promise.all(
      props.stakingList.map(async (item) => {
        if (parseInt(item['network']) == parseInt(props.selectDefaultNetwok)) {
          let contract = new web3.eth.Contract(CiFiStakeABI, item['stakeAddress']);
          let staked = await contract.methods.totalStaked().call();
          return { ...item, stakedItems: staked };
        }
        return item;
      })
    );
    setStakings(updatedStakings);
  }

  useEffect(() => {
    if (props.stakingList.length > 0 && web3) {
      setStakeData();
    }
  }, [props.stakingList, props.selectDefaultNetwok, web3]);

  async function setVIPStake() {
    setData({ nftAddress: nftAddressVIP, tokenAddress: tokenAddressVIP, stakeAddress: stakeAddressVIP });
    setnftSymbol("VIP");
    setMicroeconomyStakeModal(true);
    let address = (await web3.currentProvider.enable())[0];
    props.getStakedNFT({ stakeAddress: stakeAddressVIP, staker: address });
    let contract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
    let holdings = await contract.methods.walletOfOwner(from).call();
    if (holdings.length == 0) {
      setTokenIds([]);
    } else {
      setTokenIds(holdings);
    }
  }

  async function setVIPUnstake(data) {
    setData({ nftAddress: nftAddressVIP, tokenAddress: tokenAddressVIP, stakeAddress: stakeAddressVIP });
    setnftSymbol("VIP");
    setMicroeconomyUnStakeModal(true);
    let address = (await web3.currentProvider.enable())[0];
    let contract = new web3.eth.Contract(CiFiStakeABI, data['stakeAddress']);
    let available = await contract.methods.totalStake(address).call({ from: address });
    console.log("*** available :: ", available);
    props.getStakedNFT({ stakeAddress: stakeAddressVIP, staker: address });
  }

  async function setVIPClaim(data) {
    setData({ nftAddress: nftAddressVIP, tokenAddress: tokenAddressVIP, stakeAddress: stakeAddressVIP });
    setnftSymbol("VIP");
    let address = (await web3.currentProvider.enable())[0];
    props.getStakedNFT({ stakeAddress: stakeAddressVIP, staker: address });
    await claim(data);
  }

  async function setDAOStake() {
    setData({ nftAddress: nftAddressGOV, tokenAddress: tokenAddressGOV, stakeAddress: stakeAddressGOV });
    setnftSymbol("CDN");
    setMicroeconomyStakeModal(true);
    let address = (await web3.currentProvider.enable())[0];
    props.getStakedNFT({ stakeAddress: stakeAddressGOV, staker: address });
    let contract = new web3.eth.Contract(CiFiDAONFTABI, CiFiDAONFTAddress);
    let holdings = await contract.methods.walletOfOwner(from).call();
    if (holdings.length == 0) {
      setTokenIds([]);
    } else {
      setTokenIds(holdings);
    }
  }

  async function setDAOUnstake(data) {
    setData({ nftAddress: nftAddressGOV, tokenAddress: tokenAddressGOV, stakeAddress: stakeAddressGOV });
    setnftSymbol("CDN");
    setMicroeconomyUnStakeModal(true);
    let address = (await web3.currentProvider.enable())[0];
    props.getStakedNFT({ stakeAddress: stakeAddressGOV, staker: address });
  }

  async function setDAOClaim(data) {
    setData({ nftAddress: nftAddressGOV, tokenAddress: tokenAddressGOV, stakeAddress: stakeAddressGOV });
    setnftSymbol("CDN");
    let address = (await web3.currentProvider.enable())[0];
    props.getStakedNFT({ stakeAddress: stakeAddressGOV, staker: address });
    await claim(data);
  }

  useEffect(() => {
    if (props.stakedNFTlist.length > 0) {
      setTokenIds2(props.stakedNFTlist[0]['nfts']);
    }
  }, [props.stakedNFTlist, props.selectDefaultNetwok]);


  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "tokenId") {
      setTokenId(parseInt(e.target.value));
    } else if ([e.target.name] == "tokenId2") {
      setTokenId2(parseInt(e.target.value));
    }
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function stake(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (props.kycStatus !== "adminApproved") {
        EventBus.publish("error", `Submit KYC verification to access this function!`);
        return;
      }

      if (tokenIds.length == 0) {
        EventBus.publish("error", `You don't own NFT from this collection!`);
        return;
      }

      if (tokenId == undefined) {
        setTokenId(parseInt(tokenIds[0]));
      }

      let deployer = (await web3.currentProvider.enable())[0];
      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      props.setLoader({
        message: "Approval in Progress...",
        status: true,
      });


      let contract = new web3.eth.Contract(XRC721ABI, data['nftAddress']);
      let contract1 = new web3.eth.Contract(CiFiStakeABI, data['stakeAddress']);

      await contract.methods.approve(data['stakeAddress'], tokenId ? tokenId : parseInt(tokenIds[0])).send({
        from: deployer,
      });

      tokenId = tokenId ? tokenId : parseInt(tokenIds[0]);

      if (data['stakeAddress'].toLowerCase() == stakeAddressVIP.toLowerCase()) {

        let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
        let status = await newAdminWhitelist.methods.isWhitelist(deployer).call({ from: deployer });
        if (!status) {
          props.setLoader({
            message: "Whitelist in Progress...",
            status: true,
          });

          await web3.eth.sendTransaction({
            from: deployer,
            value: 0,
            to: AdminWhitelistAddress,
            gas: 5000000,
            data: newAdminWhitelist.methods
              .addWhitelist(deployer)
              .encodeABI(),
          })
            .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
            .on('receipt', async receipt => {

              props.setLoader({
                message: "Stake in Progress...",
                status: true,
              });
              await web3.eth.sendTransaction({
                from: deployer,
                value: 0,
                to: data['stakeAddress'],
                gas: 5000000,
                data: contract1.methods
                  .stake(tokenId ? tokenId : parseInt(tokenIds[0]))
                  .encodeABI(),
              })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                  await props.stakeNFT({ stakeAddress: data['stakeAddress'], staker: deployer, tokenId });
                  await refreshBalance();
                  setMicroeconomyStakeModal(false);
                  setTokenId("");
                  props.setLoader({ status: false });
                  EventBus.publish("success", `NFT Staked Successfully!`);
                });
            });
        } else {
          props.setLoader({
            message: "Stake in Progress...",
            status: true,
          });

          await web3.eth.sendTransaction({
            from: deployer,
            value: 0,
            to: data['stakeAddress'],
            gas: 5000000,
            data: contract1.methods
              .stake(tokenId ? tokenId : parseInt(tokenIds[0]))
              .encodeABI(),
          })
            .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
            .on('receipt', async receipt => {
              await props.stakeNFT({ stakeAddress: data['stakeAddress'], staker: deployer, tokenId });
              await refreshBalance();
              setMicroeconomyStakeModal(false);
              setTokenId("");
              props.setLoader({ status: false });
              EventBus.publish("success", `NFT Staked Successfully!`);
            });
        }
      } else {
        props.setLoader({
          message: "Stake in Progress...",
          status: true,
        });

        await web3.eth.sendTransaction({
          from: deployer,
          value: 0,
          to: data['stakeAddress'],
          gas: 5000000,
          data: contract1.methods
            .stake(tokenId ? tokenId : parseInt(tokenIds[0]))
            .encodeABI(),
        })
          .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
          .on('receipt', async receipt => {
            await props.stakeNFT({ stakeAddress: data['stakeAddress'], staker: deployer, tokenId });
            await refreshBalance();
            setMicroeconomyStakeModal(false);
            setTokenId("");
            props.setLoader({ status: false });
            EventBus.publish("success", `NFT Staked Successfully!`);
          });
      }
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Stake Not Completed...",
        status: false,
      });
      setData({});
      setMicroeconomyStakeModal(false);
      setTokenId("");
      EventBus.publish("error", `Staking Failed`);
    }
  };

  async function unStake(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (props.kycStatus !== "adminApproved") {
        EventBus.publish("error", `Submit KYC verification to access this function!`);
        return;
      }

      if (tokenIds2.length == 0) {
        EventBus.publish("error", `You have no NFTs staked in this collection yet!`);
        return;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);

      let contract1 = new web3.eth.Contract(XRC20ABI, data['tokenAddress']);
      let availableBalance = await contract1.methods.balanceOf(data['stakeAddress']).call({ from: deployer });

      if (availableBalance <= 0) {
        EventBus.publish("info", `Insufficient contract balance to withdraw rewards, NFT will be withdrawn!`);
        // return;
      }

      if (tokenId2 == undefined) {
        setTokenId(parseInt(tokenIds2[0]));
      }

      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let contract = new web3.eth.Contract(CiFiStakeABI, data['stakeAddress']);

      let available = await contract.methods.totalStake(deployer).call({ from: deployer });

      if (parseInt(available['totalStake']) == 0) {
        props.setLoader({ status: false });
        EventBus.publish("error", `You have no NFTs staked in this collection yet!`);
        return;
      }

      tokenId2 = tokenId2 ? tokenId2 : parseInt(tokenIds2[0]);

      if (data['stakeAddress'].toLowerCase() == stakeAddressVIP.toLowerCase()) {
        let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
        let status = await newAdminWhitelist.methods.isWhitelist(deployer).call({ from: deployer });
        if (status == true) {
          props.setLoader({
            message: "Un-staking in Progress...",
            status: true,
          });

          await web3.eth.sendTransaction({
            from: deployer,
            value: 0,
            to: data['stakeAddress'],
            gas: 5000000,
            data: contract.methods
              .withdraw(parseInt(tokenId2))
              .encodeABI(),
          })
            .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
            .on('receipt', async receipt => {
              await props.unStakeNFT({ stakeAddress: data['stakeAddress'], staker: deployer, tokenId: tokenId2 });
              
              props.setLoader({
                message: "Removing Whitelist...",
                status: true,
              });

              await web3.eth.sendTransaction({
                from: deployer,
                value: 0,
                to: AdminWhitelistAddress,
                gas: 5000000,
                data: newAdminWhitelist.methods
                  .removeWhitelist(deployer)
                  .encodeABI(),
              })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                  await props.unStakeNFT({ stakeAddress: data['stakeAddress'], staker: deployer, tokenId: tokenId2 });
                  await refreshBalance();
                  setMicroeconomyUnStakeModal(false);
                  props.setLoader({ status: false });
                  setTokenId2("");
                  EventBus.publish("success", `Un-staked Successfully!`);
                });
            });
        } else {
          props.setLoader({
            message: "Un-staking in Progress...",
            status: true,
          });

          await web3.eth.sendTransaction({
            from: deployer,
            value: 0,
            to: data['stakeAddress'],
            gas: 5000000,
            data: contract.methods
              .withdraw(parseInt(tokenId2))
              .encodeABI(),
          })
            .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
            .on('receipt', async receipt => {
              await props.unStakeNFT({ stakeAddress: data['stakeAddress'], staker: deployer, tokenId: tokenId2 });
              await refreshBalance();
              setMicroeconomyUnStakeModal(false);
              props.setLoader({ status: false });
              setTokenId2("");
              EventBus.publish("success", `Un-staked Successfully!`);
            });
        }
      } else {
        props.setLoader({
          message: "Un-staking in Progress...",
          status: true,
        });

        await web3.eth.sendTransaction({
          from: deployer,
          value: 0,
          to: data['stakeAddress'],
          gas: 5000000,
          data: contract.methods
            .withdraw(parseInt(tokenId2))
            .encodeABI(),
        })
          .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
          .on('receipt', async receipt => {
            await props.unStakeNFT({ stakeAddress: data['stakeAddress'], staker: deployer, tokenId: tokenId2 });
            await refreshBalance();
            setMicroeconomyUnStakeModal(false);
            props.setLoader({ status: false });
            setTokenId2("");
            EventBus.publish("success", `Un-staked Successfully!`);
          });
      }
    } catch (e) {
      console.log(e);
      setMicroeconomyUnStakeModal(false);
      props.setLoader({
        message: "Un-stake Not Completed...",
        status: false,
      });
      setData({});
      EventBus.publish("error", `Un-staking Failed`);
    }
  };

  async function claim(data) {
    try {

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (props.kycStatus !== "adminApproved") {
        EventBus.publish("error", `Submit KYC verification to access this function!`);
        return;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let contract = new web3.eth.Contract(CiFiStakeABI, data['stakeAddress']);

      let available = await contract.methods.totalStake(deployer).call({ from: deployer });

      if (parseInt(available['totalStake']) == 0) {
        EventBus.publish("error", `You have no NFTs staked in this collection yet!!`);
        return;
      }

      if ((parseInt(available['startTime']) + 2592000) >= parseInt(Math.floor(Date.now() / 1000))) {
        EventBus.publish("error", `Can claim rewards once every 30 days, next reward at: ${moment.utc((parseInt(available['startTime']) + 2592000) * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}!!`);
        return;
      }

      let contract1 = new web3.eth.Contract(XRC20ABI, data['tokenAddress']);
      let availableBalance = await contract1.methods.balanceOf(data['stakeAddress']).call({ from: deployer });

      if (availableBalance <= 0) {
        EventBus.publish("error", `Insufficient contract balance to withdraw rewards!`);
        return;
      }

      props.setLoader({
        message: "Claim in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: data['stakeAddress'],
          gas: 5000000,
          data: contract.methods
            .claimReward()
            .encodeABI(),
        })
        .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          await refreshBalance();
          props.setLoader({ status: false });
          EventBus.publish("success", `Claimed Successfully!`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Un-stake Not Completed...",
        status: false,
      });
      setData({});
      EventBus.publish("error", `Claim Failed`);
    }
  };


  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={daogenesisicon} alt="" />
          <span>Circularity DAO</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />
      </div>

      <div className="microeconomy-tool">
        <h2>Stake NFT</h2>

        <div class="nft-collection circularitydao-stakenft">
          <div class="collection-wrap">
            <div class="box">
              <img src={circularitydaostakenftimg2} alt=" " />

              <div class="btn-wrap">
                <button onClick={() => setVIPStake()}>Stake</button>
                <button onClick={() => {
                  let data = { nftAddress: nftAddressVIP, tokenAddress: tokenAddressVIP, stakeAddress: stakeAddressVIP };
                  setVIPUnstake(data);
                }
                }>Unstake</button>
                <button onClick={() => {
                  let data = { nftAddress: nftAddressVIP, tokenAddress: tokenAddressVIP, stakeAddress: stakeAddressVIP };
                  setVIPClaim(data)
                }
                }>Claim</button>
              </div>

              <div className="info-box">
                <div>
                  <p>APY percentage</p>
                  <p>6%</p>
                </div>

                <div>
                  <p>Monthly reward</p>
                  <p>50 REFI</p>
                </div>
              </div>
            </div>

            <div class="box">
              <img src={circularitydaostakenftimg} alt=" " />

              <div class="btn-wrap">
                <button onClick={() => setDAOStake()}>Stake</button>
                <button onClick={() => {
                  let data = { nftAddress: nftAddressGOV, tokenAddress: tokenAddressGOV, stakeAddress: stakeAddressGOV };
                  setDAOUnstake(data);
                }
                }>Unstake</button>
                <button onClick={() => {
                  let data = { nftAddress: nftAddressGOV, tokenAddress: tokenAddressGOV, stakeAddress: stakeAddressGOV };
                  setDAOClaim(data)
                }
                }>Claim</button>
              </div>

              <div className="info-box">
                <div>
                  <p>APY percentage</p>
                  <p>10%</p>
                </div>

                <div>
                  <p>Monthly reward</p>
                  <p>833 CIFI</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={microeconomyStakeModal}
        onClose={() => setMicroeconomyStakeModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal stake-unstake-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyStakeModal(false)}
          />
        </button>

        <div className="modal-body nft-collection">
          <div className="collection-wrap">
            <h3 className="text-center">Stake Your NFT</h3>
            <div className="box">
              <div className="content form-wrap">
                <Form onSubmit={stake}>
                  <Form.Group>
                    <Form.Select
                      name="tokenId"
                      value={tokenId !== "" ? tokenId : tokenIds[0]}
                      onChange={handleOnInput}
                    >
                      {
                        tokenIds.length > 0 &&
                        tokenIds.map((item) => (
                          <option value={item}>
                            {`#${item} - ${nftSymbol}`}
                          </option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>

                  <div>
                    <Button type="submit">
                      Confirm
                    </Button>

                    <Button onClick={() => setMicroeconomyStakeModal(false)}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

          </div>
        </div>
      </Modal>

      <Modal
        open={microeconomyUnStakeModal}
        onClose={() => setMicroeconomyUnStakeModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal stake-unstake-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyUnStakeModal(false)}
          />
        </button>

        <div className="modal-body nft-collection">
          <div className="collection-wrap">
            <h3 className="text-center">Unstake Your NFT</h3>
            <div className="box">
              <div className="content form-wrap">
                <Form onSubmit={unStake}>
                  <Form.Group>
                    <Form.Select
                      name="tokenId2"
                      value={tokenId2 !== "" ? tokenId2 : tokenIds2[0]}
                      onChange={handleOnInput}
                    >
                      {
                        tokenIds2.length > 0 &&
                        tokenIds2.map((item) => (
                          <option value={item}>
                            {`#${item} - ${nftSymbol}`}
                          </option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>

                  <div>
                    <Button type="submit">
                      Confirm
                    </Button>

                    <Button onClick={() => setMicroeconomyUnStakeModal(false)}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  getXRC20,
  stakeNFT,
  setLoader,
  getXRC721,
  getStaking,
  setBalance,
  unStakeNFT,
  getStakedNFT,
  setGrootBalance,
  getKybStatus,
  getKycStatus
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, xrc20Collections, xrc721Collections, stakingList, stakedNFTlist, currentMode, isLoader, kycStatus } = Auth;
  return { publicAddress, xrc20Collections, xrc721Collections, stakingList, stakedNFTlist, currentMode, isLoader, kycStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(CircularityDaoStakeNft);