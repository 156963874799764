import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DaoGenesislogo from "../../images/dao-genesis.png";
import membership from "../../images/membership.png";
import shwallet from "../../images/sh-wallet.png";
import settingsicon from "../../images/settings-icon.png";
import configurexdc from "../../images/configure-xdc.png";
import rules from "../../images/rules.png";
import writedesc from "../../images/write-desc.png";
import arrowlink from "../../images/arrow-link.png";
import { setLoader, deployDAO, uploadCollection, setBalance, setGrootBalance } from "../../store/actions/Auth";
import { networkId, XRC20ABI, XRC721ABI, XRC20Bytecode, XRC721Bytecode, DaoABI, DaoBytecode, Groot, CIFI, GrootSubscription, GrootSubscriptionAddress, ApiUrl } from "../../store/config";


const LaunchDao = (props, { mode }) => {

    const [step, setStep] = useState(1);
    const [model, setModel] = useState(true);

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="microeconomy-wrapper dao-genesis launch-dao">
            <div className="microeconomy-head">
                <a href="#" className="logo-wrap">
                    <img src={DaoGenesislogo} alt="" />
                    <span>Circularity DAO</span>
                </a>
                {props.isLoader.status == true &&
                    <Prompt when={model}
                        message="Transaction in progress, leaving page may result in transaction failure!"
                        beforeUnload={true}
                    />
                }
                <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
                <ConnectWallet />
            </div>

            <div className="launchdao-head">
                <div className="left">
                    <h2>Launch a DAO</h2>

                    <p>Start simple and learn as you go. You can always evolve your DAO in the future.</p>
                </div>

                <Link to="/dashboard/dao-genesis/launch-dao/steps">
                    <button>Launch a DAO <img src={arrowlink} alt="" /></button>
                </Link>

            </div>

            <div className="launchdao-steps-boxes-wrap">
                <div className="step-box">
                    <div className="icon-wrap">
                        <img src={writedesc} alt="" />
                    </div>

                    <div class="step-box-info">
                        <span>Step 1</span>

                        <h3>Describe your DAO</h3>
                    </div>
                </div>

                <div className="step-box">
                    <div className="icon-wrap">
                        <img src={membership} alt="" />
                    </div>

                    <div class="step-box-info">
                        <span>Step 2</span>

                        <h3>Setup shareholders email addresses</h3>
                    </div>
                </div>

                <div className="step-box">
                    <div className="icon-wrap">
                        <img src={settingsicon} alt="" />
                    </div>

                    <div class="step-box-info">
                        <span>Step 3</span>

                        <h3>Configure your NFT collection</h3>
                    </div>
                </div>

                <div className="step-box">
                    <div className="icon-wrap">
                        <img src={rules} alt="" />
                    </div>

                    <div class="step-box-info">
                        <span>Step 4</span>

                        <h3>Setup DAO rules</h3>
                    </div>
                </div>

                <div className="step-box">
                    <div className="icon-wrap">
                        <img src={shwallet} alt="" />
                    </div>

                    <div class="step-box-info">
                        <span>Step 5</span>

                        <h3>Setup shareholders wallet addresses</h3>
                    </div>
                </div>

                <div className="step-box">
                    <div className="icon-wrap">
                        <img src={configurexdc} alt="" />
                    </div>

                    <div class="step-box-info">
                        <span>Step 6</span>

                        <h3>Configure your XRC 20 token</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    setLoader,
    deployDAO,
    uploadCollection,
    setBalance,
    setGrootBalance
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, isLoader } = Auth;
    return { publicAddress, isLoader }
};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchDao);