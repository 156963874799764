import { eventChannel } from 'redux-saga';
import { take, call, put, } from 'redux-saga/effects';

import { socket } from '../config';

// import { setExchangeGraph, setExchangePairsDetails, setPairsBalance, setAllUsersOrder, setPairsData } from '../actions/Events'


const createSocket = socket => eventChannel((emit) => {

    /************** EXCHANGE DATA **************/
    // socket.on(`exchange:pair:graph`, data => emit(setExchangeGraph(data)));
    // socket.on(`exchange:pair:detail`, data => emit(setExchangePairsDetails(data)));
    // socket.on(`address:pairA:pairB`, data => emit(setPairsBalance(data)));
    // socket.on(`orders:address:pairA:pairB`, data => emit(setAllUsersOrder(data)));
    // socket.on(`pairA:pairB`, data => emit(setPairsData(data)));


    return () => {
        // socket.off('collections:listing', () => emit());
        // socket.off('collections:trades', () => emit());
    }
});

export default function* Sockets() {
    const socketChannel = yield call(createSocket, socket);
    while (true) {
        let action = yield take(socketChannel);
        if (action) yield put(action);
    }
};

