import moment from "moment";
import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import copy from "../../images/copy.png";
import "react-responsive-modal/styles.css";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import { validate } from "wallet-address-validator";
import modalcloseicon from "../../images/close.png";
import infocircle from "../../images/infocircle.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import offeringiconnew from "../../images/offering-icon-new.png";
import collectionprofile from "../../images/collection-profile.png";
import offeringiconnewgradient from "../../images/offering-icon-new-gradient.png";
import { setLoader, setBalance, setGrootBalance, getVesting, getILO, deployILO, updateILOTime, getKybStatus, getKycStatus } from "../../store/actions/Auth.js";
import { GrootFactory, GrootILO, GrootILOAddress, GrootILOABI, XRC20ABI, GrootPresaleABI, networkId, Groot, CIFI, GrootSubscription, GrootSubscriptionAddress, CiFiAccessNFTAddress, CiFiAccessNFTContract, CiFiAccessNFTABI, AdminWhitelist, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";
import {web3} from "../../store/web3";
import { useNavigate } from "react-router-dom";

function Initailoffering(props) {

  const [ILOModal, setILOModal] = useState(true);
  const [investListdetailModal, setInvestListdetailModal] = useState(false);

  let [pEnd, setPEnd] = useState("");
  let [pLock, setPLock] = useState("");
  let [pOwner, setPOwner] = useState("");
  let [pToken, setPToken] = useState("");
  let [bToken, setBToken] = useState("");
  let [amount, setAmount] = useState("");
  let [tPrice, setTPrice] = useState("");
  let [pStart, setPStart] = useState("");
  let [bSymbol, setBSymbol] = useState("");
  let [tName, setTName] = useState("Token");
  let [tSymbol, setTSymbol] = useState("Token");
  let [tListRate, TListRate] = useState("");
  let [pSoftcap, setPSoftcap] = useState("");
  let [presaleData, setPresaleData] = useState([]);
  let [userHardcap, setUserHardcap] = useState("");
  let [pLiqPercentage, setPLiqPercentage] = useState("");

  let [isNFT, setIsNFT] = useState();
  let [isWhitelist, setIsWhitelist] = useState(false);

  let [endTime, setEndTime] = useState("");
  let [tabData, setTabData] = useState(null);
  let [startTime, setStartTime] = useState("");
  let [currentTime, setCurrentTime] = useState(0);
  let [amountInvest, setAmountInvest] = useState("");
  let [presaleStatus, setPresaleStatus] = useState("");
  let [pStatus, setPStatus] = useState(0);
  let [publicAddress, setPublicAddress] = useState(localStorage.getItem('publicAddress'));

  const navigate = useNavigate()


  const [model, setModel] = useState(true);
  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }
  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  useEffect(() => {
    if (networkId == 80001 || networkId == 137) {
      setBToken("0x4fA1f87E3831335f00Fa4ec41ecb8f50F5acEa6b");
      setBSymbol("MATIC");
    } else if (networkId == 97 || networkId == 56) {
      setBToken("0x153F54dEf755C589c6607adEB889D9B4340c6198");
      setBSymbol("BNB");
    } else if (networkId == 51 || networkId == 50) {
      setBToken("0xd667E515833a5285FDDeF96e8C6E02370A74eadf");
      setBSymbol("XDC");
    }
  }, [networkId]);

  useEffect(() => {
    // Update timestamp every second
    const intervalId = setInterval(() => {
      setCurrentTime(Math.floor(Date.now() / 1000));
    }, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    props.getILO();
    props.getVesting();
  }, []);

  async function setVestingData() {
    const updatedVestings = await Promise.all(
      props.vestinglist.map(async (item) => {
        if (item['tokenAddress'].toLowerCase() == pToken.toLowerCase()) {
          // let amountPresaleTokens = web3.utils.fromWei((item['presaleAmount']).toString(), "ether");
          setAmount(parseFloat(item['presaleAmount']));
          return { ...item };
        }
        return item;
      })
    );
  }

  async function setILOData() {
    let presaleDataNew = [];
    const updatedPresales = await Promise.all(
      props.ilolist.map(async (item) => {
        if (parseInt(item['network']) == parseInt(networkId)) {
          presaleDataNew.push(item);
          return { ...item };
        }
        return item;
      })
    );
    if (presaleDataNew.length > 0) setPresaleData(presaleDataNew);
  }

  const getNFTs = async () => {
    if (CiFiAccessNFTABI && CiFiAccessNFTAddress) {
      props.setLoader({ message: "Load Microeconomy...", status: true });
      let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
      if (newCiFiAccessNFTContract) {
        let tokenBalance = await newCiFiAccessNFTContract.methods.balanceOf(props.publicAddress).call({ from: props.publicAddress });
        // tokenBalance =  1; //tesing purpose
        setIsNFT(tokenBalance);
        if (Number(tokenBalance) >= 1) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Load Microeconomy...", status: false });
      }
    }

    if (AdminWhitelistABI && AdminWhitelistAddress) {
      props.setLoader({ message: "Loading...", status: true });
      let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
      if (newAdminWhitelist) {
        let status = await newAdminWhitelist.methods.isWhitelist(props.publicAddress).call({ from: props.publicAddress });
        // status =  true; //tesing purpose
        setIsWhitelist(status);
        if (status == true) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Loading...", status: false });
      }
    }

  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      getNFTs();
    }
  }, [props.publicAddress, web3]);

  useEffect(() => {
    if (props.vestinglist.length > 0 && pToken !== "" && web3) {
      setVestingData();
    }
  }, [props.vestinglist, props.selectDefaultNetwok, pToken, web3]);

  useEffect(() => {
    if (props.ilolist.length > 0 && web3) {
      setILOData();
    }
  }, [props.ilolist, props.selectDefaultNetwok, web3]);

  // useEffect(() => {
  //   if (Object.keys(GrootILO).length > 0) registeredPresales();
  // }, [Object.keys(GrootILO).length > 0])

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function closeModal() {
    setPresaleStatus("");
    setInvestListdetailModal(false)
  }

  //-------------------------------- Action Functions --------------------------------//
  async function toggleModal(value) {
    if (Object.keys(GrootPresaleABI).length > 0 && props.address !== null) {
      props.setLoader({
        message: "Loading Data",
        status: true,
      });
      let { address } = props;
      let presaleDataNew = [];
      let primaryContract = new web3.eth.Contract(GrootPresaleABI, value.pAddress);
      let presaleInfo = await primaryContract.methods.PRESALE_INFO().call();
      let presaleStatus = await primaryContract.methods.STATUS().call();
      let buyerStatus = await primaryContract.methods.BUYERS(address).call();
      let pSupply = web3.utils.fromWei(presaleInfo[5].toString(), "ether");
      let pPrice = web3.utils.fromWei(presaleInfo[3].toString(), "ether");
      let lPrice = web3.utils.fromWei(presaleInfo[9].toString(), "ether");
      let uCap = web3.utils.fromWei(presaleInfo[4].toString(), "ether");
      let hCap = web3.utils.fromWei(presaleInfo[6].toString(), "ether");
      let sCap = web3.utils.fromWei(presaleInfo[7].toString(), "ether");
      let lAdded = presaleStatus[0];
      let bCollected = web3.utils.fromWei(presaleStatus[2].toString(), "ether");
      let pSold = web3.utils.fromWei(presaleStatus[3].toString(), "ether");
      let lper = presaleInfo[8] / 10;
      let lTime = presaleInfo[12] / 86400;
      let saleStatus = presaleStatus;

      let data = {
        id: value['_id'],
        owner: presaleInfo[0].toLowerCase(),
        pAddress: value.pAddress,
        tAddress: value.tAddress,
        symbol: value.symbol,
        tokenName: value.tokenName,
        sTime: value.sTime,
        eTime: value.eTime,
        lTime,
        pSupply,
        pPrice,
        lPrice,
        uCap,
        hCap,
        sCap,
        lper,
        lAdded,
        bCollected,
        pSold,
        saleStatus,
        buyerStatus
      }
      presaleDataNew.push(data);
      if (presaleDataNew.length > 0) {
        props.setLoader({ status: false });
        setTabData(presaleDataNew[0]);
        setInvestListdetailModal(true);
      } else {
        props.setLoader({ status: false });
      }
    } else {
      EventBus.publish("error", `Please connect metamask!`);
      return;
    }
  }

  async function refreshData(pAddress) {
    let { address } = props;
    let presaleDataNew = [];
    let primaryContract = new web3.eth.Contract(GrootPresaleABI, pAddress);
    let presaleInfo = await primaryContract.methods.PRESALE_INFO().call();
    let presaleStatus = await primaryContract.methods.STATUS().call();
    let buyerStatus = await primaryContract.methods.BUYERS(address).call();
    let pContract = new web3.eth.Contract(XRC20ABI, presaleInfo[1]);
    let symbol = await pContract.methods.symbol().call();
    let tokenName = await pContract.methods.name().call();
    let pSupply = web3.utils.fromWei(presaleInfo[5].toString(), "ether");
    let pPrice = web3.utils.fromWei(presaleInfo[3].toString(), "ether");
    let lPrice = web3.utils.fromWei(presaleInfo[9].toString(), "ether");
    let uCap = web3.utils.fromWei(presaleInfo[4].toString(), "ether");
    let hCap = web3.utils.fromWei(presaleInfo[6].toString(), "ether");
    let sCap = web3.utils.fromWei(presaleInfo[7].toString(), "ether");
    let lAdded = presaleStatus[0];
    let bCollected = web3.utils.fromWei(presaleStatus[2].toString(), "ether");
    let pSold = web3.utils.fromWei(presaleStatus[3].toString(), "ether");
    let lper = presaleInfo[8] / 10;
    let lTime = presaleInfo[12] / 86400;
    let saleStatus = presaleStatus;

    let data = {
      owner: presaleInfo[0].toLowerCase(),
      pAddress: pAddress,
      tAddress: presaleInfo[1],
      symbol,
      tokenName,
      sTime: presaleInfo[10],
      eTime: presaleInfo[11],
      lTime,
      pSupply,
      pPrice,
      lPrice,
      uCap,
      hCap,
      sCap,
      lper,
      lAdded,
      bCollected,
      pSold,
      saleStatus,
      buyerStatus
    }
    presaleDataNew.push(data);
    if (presaleDataNew.length > 0) setTabData(presaleDataNew[0]);
  }

  async function handleOnInput(e) {
    if (e.target.name === "amountInvest") {
      if (parseFloat(e.target.value) > 0) {
        setAmountInvest(e.target.value);
      } else {
        setAmountInvest("");
      }
    } else if (e.target.name === "startTime") {
      setStartTime(e.target.value);
    } else if (e.target.name === "endTime") {
      setEndTime(e.target.value);
    } else if (e.target.name === "userHardcap") {
      if (parseInt(e.target.value) > 0) {
        setUserHardcap(e.target.value);
      } else {
        setUserHardcap("")
      }
    } else {
      return;
    }
  }

  async function submitInvestment(pAddress) {
    try {
      let { address } = props;

      if (address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      if (amountInvest == "") {
        EventBus.publish("error", `Enter amount to invest`);
        return;
      }

      if (((parseFloat(amountInvest) * (parseFloat(tabData.pPrice))) + parseFloat(web3.utils.fromWei((tabData.buyerStatus[1]).toString(), "ether"))) > (parseFloat(tabData.uCap))) {
        EventBus.publish("error", `Cannot buy more than ${parseFloat(tabData.uCap) - parseFloat(web3.utils.fromWei((tabData.buyerStatus[1]).toString(), "ether"))} ${tabData.symbol} tokens!`);
        return;
      }

      amountInvest = web3.utils.toWei(amountInvest.toString(), "ether");

      let primaryContract = new web3.eth.Contract(GrootPresaleABI, pAddress);

      if (props.kycStatus !== "adminApproved") {
        EventBus.publish("error", `Submit KYC verification to access this function!`);
        return;
      }

      let publicAddress = (await web3.currentProvider.enable())[0];

      let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
      let isNFT = await newCiFiAccessNFTContract.methods.balanceOf(publicAddress).call({ from: publicAddress });

      let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
      let isWhitelist = await newAdminWhitelist.methods.isWhitelist(publicAddress).call({ from: publicAddress });

      console.log("*** publicAddress :: ", publicAddress);
      console.log("*** isNFT :: ", isNFT);
      console.log("*** isWhitelist :: ", isWhitelist);

      if (isWhitelist && isWhitelist == false) {
        if (isNFT && isNFT <= 0) {
          EventBus.publish("error", `Buy VIP NFT or get whitelisted by admin to access this function!`);
          return;
        }
      }

      props.setLoader({
        message: "Presale deposit in progress",
        status: true,
      });

      let result = await primaryContract.methods.userDeposit(amountInvest);
      await web3.eth
        .sendTransaction({
          from: address,
          to: pAddress,
          value: web3.utils.toHex(amountInvest),
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          // registeredPresales();
          refreshData(pAddress);
          setAmountInvest("");
          props.setLoader({ status: false });
          EventBus.publish("success", `Transaction Successfull`);
        });
    } catch (e) {
      setAmountInvest("");
      props.setLoader({
        message: "Presale deposit!",
        status: false,
      });
      EventBus.publish("error", `Presale deposit failed!`);
    }
  }

  async function updateTime(tabData) {
    try {
      let { address } = props;

      if (address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      if (startTime == "") {
        EventBus.publish("error", `Select start time`);
        return;
      }

      if (endTime == "") {
        EventBus.publish("error", `Select end time`);
        return;
      }

      if (tabData['owner'].toLowerCase() !== address.toLowerCase()) {
        EventBus.publish("error", `Only owner can perform this action`);
        return;
      }

      let primaryContract = new web3.eth.Contract(GrootPresaleABI, tabData.pAddress);

      props.setLoader({
        message: "Updating presale time",
        status: true,
      });

      let result = await primaryContract.methods.updateBlocks(parseInt((new Date(startTime).getTime()) / 1000), parseInt((new Date(endTime).getTime()) / 1000));
      await web3.eth
        .sendTransaction({
          from: address,
          to: tabData.pAddress,
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          // registeredPresales();
          await props.updateILOTime({ _id: tabData['id'], sTime: parseInt((new Date(startTime).getTime()) / 1000), eTime: parseInt((new Date(endTime).getTime()) / 1000) })
          refreshData(tabData.pAddress);
          setStartTime("");
          setEndTime("");
          props.setLoader({ status: false });
          EventBus.publish("success", `Presale time updated`);
        });
    } catch (e) {
      setStartTime("");
      setEndTime("");
      props.setLoader({
        message: "Updating presale time failed!",
        status: false,
      });
      EventBus.publish("error", `Presale time update failed!`);
    }
  }

  async function updateHardcap(pAddress) {
    try {
      let { address } = props;

      if (address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      if (userHardcap == "") {
        EventBus.publish("error", `Please enter user hardcap!`);
        return;
      }

      if (tabData['owner'].toLowerCase() !== address.toLowerCase()) {
        EventBus.publish("error", `Only owner can perform this action`);
        return;
      }

      userHardcap = web3.utils.toWei(userHardcap.toString(), "ether");

      let primaryContract = new web3.eth.Contract(GrootPresaleABI, pAddress);
      props.setLoader({
        message: "Updating user hardcap",
        status: true,
      });

      let result = await primaryContract.methods.updateMaxSpendLimit(userHardcap);
      await web3.eth
        .sendTransaction({
          from: address,
          to: pAddress,
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          // registeredPresales();
          refreshData(pAddress);
          setUserHardcap("");
          props.setLoader({ status: false });
          EventBus.publish("success", `Presale user hardcap updated`);
        });
    } catch (e) {
      setUserHardcap("");
      props.setLoader({
        message: "Updating user hardcap failed!",
        status: false,
      });
      EventBus.publish("error", `Updating user hardcap failed!`);
    }
  }

  async function statusCall(pAddress) {
    try {
      let { address } = props;

      if (address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      let primaryContract = new web3.eth.Contract(GrootPresaleABI, pAddress);

      let result = await primaryContract.methods.presaleStatus().call({ from: address });
      setPStatus(result);
      if (result == 3) {
        setPresaleStatus("Failed");
      } else if (result == 2) {
        setPresaleStatus("Success");
      } else if (result == 1) {
        setPresaleStatus("Live");
      } else {
        setPresaleStatus("Qued");
      }
    } catch (e) {
      EventBus.publish("error", `Cannot call presale status!`);
    }
  }

  async function withdrawOwnerTokens(pAddress) {
    try {
      let { address } = props;

      if (address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      if (tabData['owner'].toLowerCase() !== address.toLowerCase()) {
        EventBus.publish("error", `Only owner can perform this action`);
        return;
      }

      let primaryContract = new web3.eth.Contract(GrootPresaleABI, pAddress);
      props.setLoader({
        message: "Withdrawing presale tokens",
        status: true,
      });

      let result = await primaryContract.methods.ownerWithdrawTokens();
      await web3.eth
        .sendTransaction({
          from: address,
          to: pAddress,
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          // registeredPresales();
          refreshData(pAddress);
          props.setLoader({ status: false });
          EventBus.publish("success", `Presale tokens withdrawn!`);
        });
    } catch (e) {
      props.setLoader({
        message: "Withdraw presale tokens failed!",
        status: false,
      });
      EventBus.publish("error", `Withdraw presale tokens failed!`);
    }
  }

  async function addLiquidity(pAddress) {
    try {
      let { address } = props;

      if (address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      if (tabData['owner'].toLowerCase() !== address.toLowerCase()) {
        EventBus.publish("error", `Only owner can perform this action`);
        return;
      }

      let primaryContract = new web3.eth.Contract(GrootPresaleABI, pAddress);
      props.setLoader({
        message: "Adding liquidity",
        status: true,
      });

      let result = await primaryContract.methods.addLiquidity();
      await web3.eth
        .sendTransaction({
          from: address,
          to: pAddress,
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          // registeredPresales();
          refreshData(pAddress);
          props.setLoader({ status: false });
          EventBus.publish("success", `Liquidity Added!`);
        });
    } catch (e) {
      props.setLoader({
        message: "Add Liquidity failed!",
        status: false,
      });
      EventBus.publish("error", `Add Liquidity failed!`);
    }
  }

  async function userWithdraw(pAddress) {
    try {
      let { address } = props;

      if (address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      if (props.kycStatus !== "adminApproved") {
        EventBus.publish("error", `Submit KYC verification to access this function!`);
        return;
      }

      let publicAddress = (await web3.currentProvider.enable())[0];

      let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
      let isNFT = await newCiFiAccessNFTContract.methods.balanceOf(publicAddress).call({ from: publicAddress });

      let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
      let isWhitelist = await newAdminWhitelist.methods.isWhitelist(publicAddress).call({ from: publicAddress });

      if (isWhitelist && isWhitelist == false) {
        if (isNFT && isNFT <= 0) {
          EventBus.publish("error", `Buy VIP NFT or get whitelisted by admin to access this function!`);
          return;
        }
      }

      let primaryContract = new web3.eth.Contract(GrootPresaleABI, pAddress);
      props.setLoader({
        message: "Withdrawing investment amount",
        status: true,
      });

      let result = await primaryContract.methods.userWithdrawBaseTokens();
      await web3.eth
        .sendTransaction({
          from: address,
          to: pAddress,
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          // registeredPresales();
          refreshData(pAddress);
          props.setLoader({ status: false });
          EventBus.publish("success", `Investment withdrawn successfully!`);
        });
    } catch (e) {
      props.setLoader({
        message: "Investment withdraw failed!",
        status: false,
      });
      EventBus.publish("error", `Investment withdraw failed!`);
    }
  }

  async function userGetTokens(pAddress) {
    try {
      let { address } = props;

      if (address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      let primaryContract = new web3.eth.Contract(GrootPresaleABI, pAddress);
      props.setLoader({
        message: "Withdrawing tokens",
        status: true,
      });

      let result = await primaryContract.methods.userWithdrawTokens();
      await web3.eth
        .sendTransaction({
          from: address,
          to: pAddress,
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          // registeredPresales();
          refreshData(pAddress);
          props.setLoader({ status: false });
          EventBus.publish("success", `Tokens withdrawn successfully!`);
        });
    } catch (e) {
      props.setLoader({
        message: "Tokens withdraw failed!",
        status: false,
      });
      EventBus.publish("error", `Tokens withdraw failed!`);
    }
  }

  const handleCopyText = () => {
    navigator.clipboard.writeText(tabData.tAddress)
      .then(() => EventBus.publish("success", "Token Address Copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  function copiedAddress(address) {
    EventBus.publish("success", "Token Address Copied");
  }

  //-------------------------------- List Functions --------------------------------//

  async function handleInput(e) {
    let { address } = props;
    if (address == null) {
      EventBus.publish("error", `Please connect your metamask!`);
      return;
    }
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));

    if (e.target.name === "pOwner") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        setPOwner(result);
      } else {
        setPOwner(e.target.value);
      }
    } else if (e.target.name === "pToken") {
      console.log("*** 1");
      if (e.target.value === "") {
        setPToken("")
      } else {
        await waitFor(3000);
        console.log("*** 2");
        if ((e.target.value).replace(/\s/g, '').length) {
          let input = e.target.value;
          let output = input.substring(0, 3); // checks first three char of address
          if (output == "xdc") {
            let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
            setPToken(result);
            if (validate(result, "ETH")) {
              var code = await web3.eth.getCode(result.toLowerCase());
              if (code === "0x") {
                EventBus.publish("error", `Invalid Token Address!`);
                setPToken("");
                return;
              } else {
                let pContract = new web3.eth.Contract(XRC20ABI, result);
                let name = await pContract.methods.name().call();
                let symbol = await pContract.methods.symbol().call();
                setTName(name);
                setTSymbol(symbol);
                setPToken(result);
              }
            } else {
              EventBus.publish("error", `Invalid Token Address`);
              setPToken("");
              return;
            }
          } else {
            console.log("*** 3");
            setPToken(e.target.value);
            if (validate(e.target.value, "ETH")) {
              var code = await web3.eth.getCode(e.target.value.toLowerCase());
              if (code === "0x") {
                EventBus.publish("error", `Invalid Token Address!`);
                setPToken("");
                return;
              } else {
                console.log("*** 4");
                let pContract = new web3.eth.Contract(XRC20ABI, e.target.value);
                console.log("*** 5");
                let name = await pContract.methods.name().call();
                let symbol = await pContract.methods.symbol().call();
                setTName(name);
                setTSymbol(symbol);
                setPToken(e.target.value);
              }
            } else {
              EventBus.publish("error", `Invalid Token Address!`);
              setPToken("");
              return;
            }
          }
        }
      }
    } else if (e.target.name === "bToken") {
      setBToken(e.target.value);
    } else if (e.target.name === "amount") {
      if (parseFloat(e.target.value) > 0) {
        setAmount(parseFloat(e.target.value));
      } else {
        setAmount("");
      }
    } else if (e.target.name === "tPrice") {
      if (parseFloat(e.target.value) > 0) {
        setTPrice(parseFloat(e.target.value));
      } else {
        setTPrice("");
      }
    } else if (e.target.name === "userHardcap") {
      if (parseFloat(e.target.value) > 0) {
        setUserHardcap(parseFloat(e.target.value));
      } else {
        setUserHardcap("");
      }
    } else if (e.target.name === "pSoftcap") {
      if (parseFloat(e.target.value) > 0) {
        setPSoftcap(parseFloat(e.target.value));
      } else {
        setPSoftcap("");
      }
    } else if (e.target.name === "pLiqPercentage") {
      if (parseFloat(e.target.value) > 0) {
        setPLiqPercentage(parseFloat(e.target.value));
      } else {
        setPLiqPercentage("");
      }
    } else if (e.target.name === "tListRate") {
      if (parseFloat(e.target.value) > 0) {
        TListRate(parseFloat(e.target.value));
      } else {
        TListRate("");
      }
    } else if (e.target.name === "pLock") {
      if (parseInt(e.target.value) <= 28) {
        EventBus.publish("info", `Lock period must be greater than 28 days!`);
        setPLock(parseInt(e.target.value));
      } else if (parseInt(e.target.value) > 0) {
        setPLock(parseInt(e.target.value));
      } else {
        setPLock("");
      }
    } else if (e.target.name === "pStart") {
      setPStart(e.target.value);
    } else if (e.target.name === "pEnd") {
      setPEnd(e.target.value);
    } else {
      return;
    }
  }

  async function submitPresale() {
    try {
      let { address } = props;

      if (address == null) {
        EventBus.publish("error", `Please connect your metamask!`);
        return;
      }

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      if (pOwner === "") {
        EventBus.publish("error", `Enter presale owner`);
        return;
      }
      if (!validate(pOwner, "ETH")) {
        EventBus.publish("error", `Please provide valid presale owner`);
        return;
      }
      if (!pOwner.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter presale owner`);
        return;
      }
      if (!pOwner.match(/^[a-zA-Z0-9]+$/)) {
        EventBus.publish("error", `Invalid presale owner address`);
        return;
      }
      if (pToken === "") {
        EventBus.publish("error", `Enter presale token`);
        return;
      }
      if (!pToken.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter presale token`);
        return;
      }
      if (!pToken.match(/^[a-zA-Z0-9]+$/)) {
        EventBus.publish("error", `Invalid presale token address`);
        return;
      }
      let code = await web3.eth.getCode(pToken.toLowerCase());
      if (code === "0x") {
        EventBus.publish("error", `Presale Token Address is not a contract!`);
        return;
      }
      if (bToken === "") {
        EventBus.publish("error", `Enter base token`);
        return;
      }
      if (pToken.toLowerCase() === bToken.toLowerCase()) {
        EventBus.publish("error", `Presale token cannot have same address as base token`);
        return;
      }
      let chechPair = await GrootFactory.methods
        .getPair(pToken, bToken)
        .call();
      if (chechPair !== "0x0000000000000000000000000000000000000000") {
        EventBus.publish("error", `Token already listed on DEX!`);
        return;
      }
      if (amount === "") {
        EventBus.publish("error", `Enter presale token amount`);
        return;
      }
      if (tPrice === "") {
        EventBus.publish("error", `Enter presale price`);
        return;
      }
      if (userHardcap === "") {
        EventBus.publish("error", `Enter user hardcap`);
        return;
      }
      if (pSoftcap === "") {
        EventBus.publish("error", `Enter presale softcap`);
        return;
      }
      if (pLiqPercentage === "") {
        EventBus.publish("error", `Enter presale liquidity percentage`);
        return;
      }
      if (parseInt(pLiqPercentage) <= 30) {
        EventBus.publish("error", `Liquidity must be greater than 30%`);
        return;
      }
      if (tListRate === "") {
        EventBus.publish("error", `Enter token public list rate`);
        return;
      }
      if (parseFloat(tListRate) > parseFloat(tPrice)) {
        EventBus.publish("error", `DEX price must be greater than presale price`);
        return;
      }
      if (pLock === "") {
        EventBus.publish("error", `Enter preasle tokens lock period`);
        return;
      }
      if (parseInt(pLock) <= 28) {
        EventBus.publish("error", `Lock period must be greater than 28 days`);
        return;
      }
      if (pStart === "") {
        EventBus.publish("error", `Enter presale start date`);
        return;
      }
      if (pEnd === "") {
        EventBus.publish("error", `Enter presale end date`);
        return;
      }
      if (((new Date(pStart).getTime()) / 1000) >= ((new Date(pEnd).getTime()) / 1000)) {
        EventBus.publish("error", `Starting time must be smaller than ending time`);
        return;
      }

      if (((new Date(pEnd).getTime()) / 1000) - ((new Date(pStart).getTime()) / 1000) > 15552000) {
        EventBus.publish("error", `Presale time cannot be more than 6 months(180 days)`);
        return;
      }
      let presaleHardcap = amount / tPrice;
      if (presaleHardcap <= 0) {
        EventBus.publish("error", `Invalid presale hardcap`);
        return;
      }

      amount = web3.utils.toWei(amount.toString(), "ether");
      tPrice = web3.utils.toWei(tPrice.toString(), "ether");
      userHardcap = web3.utils.toWei(userHardcap.toString(), "ether");
      presaleHardcap = web3.utils.toWei(presaleHardcap.toString(), "ether");
      pSoftcap = web3.utils.toWei(pSoftcap.toString(), "ether");
      tListRate = web3.utils.toWei(tListRate.toString(), "ether");

      let contractB = new web3.eth.Contract(XRC20ABI, pToken.toLowerCase());
      let pBalance = contractB.methods.balanceOf(address);
      if (parseInt(amount) > parseInt(pBalance)) {
        EventBus.publish("error", `Insufficient Presale Token Balance!`);
        return;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      // props.setLoader({
      //   message: "Platform Fee Transfer in Progress...",
      //   status: true,
      // });

      // let balance = await CIFI.methods.balanceOf(address).call();
      // let req = web3.utils.toWei("50", "ether");
      // if (parseInt(balance) < parseInt(req)) {
      //   props.setLoader({
      //     message: "Platform Fee Transfer Failed...",
      //     status: false,
      //   });
      //   EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
      //   return;
      // } else {
      //   await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
      //   await GrootSubscription.methods.iloPlatformFee(req).send({ from: deployer });
      // }

      let primaryContract = new web3.eth.Contract(XRC20ABI, pToken);
      let GrootILOContract = new web3.eth.Contract(GrootILOABI, GrootILOAddress);
      let primaryBalance = await primaryContract.methods.balanceOf(address).call();
      let requiredAmount = await GrootILOContract.methods.calculateAmountRequired(amount, tPrice, tListRate, parseInt(pLiqPercentage * 10)).call();
      let requiredAmountEth = web3.utils.fromWei(requiredAmount.toString(), "ether");
      let primaryBalanceEth = web3.utils.fromWei(primaryBalance.toString(), "ether");
      if (parseFloat(primaryBalanceEth) < parseFloat(requiredAmountEth)) {
        EventBus.publish("error", `Insufficient Presale Token Balance, You Need ${requiredAmountEth} ${tSymbol}!`);
        return;
      }
      props.setLoader({
        message: `Approving ${requiredAmountEth} Presale Tokens`,
        status: true,
      });
      await primaryContract.methods
        .approve(GrootILOAddress, requiredAmount)
        .send({
          from: address,
          gas: 2000000,
        });

      props.setLoader({
        message: "Registering Presale...",
        status: true,
      });

      let result = await GrootILOContract.methods.createPresale(
        pOwner,
        pToken,
        bToken,
        [
          amount,
          tPrice,
          userHardcap,
          presaleHardcap,
          pSoftcap,
          parseInt(pLiqPercentage * 10),
          tListRate,
          parseInt((new Date(pStart).getTime()) / 1000),
          parseInt((new Date(pEnd).getTime()) / 1000),
          parseInt(pLock * 86400)
        ]
      );

      await web3.eth
        .sendTransaction({
          from: address,
          to: GrootILOAddress,
          value: web3.utils.toHex(2000000),
          gas: 5000000,
          data: result.encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          await props.deployILO({ pAddress: ("0x" + receipt.logs[2].data.slice(26)), tokenName: tName, symbol: tSymbol, tAddress: pToken, sTime: parseInt((new Date(pStart).getTime()) / 1000), eTime: parseInt((new Date(pEnd).getTime()) / 1000), network: parseInt(networkId) })
          // await registeredPresales();
          await refreshBalance();
          setPEnd("");
          setPLock("");
          setPOwner("");
          setPToken("");
          setAmount("");
          setTPrice("");
          setPStart("");
          TListRate("");
          setPSoftcap("");
          setUserHardcap("");
          setPLiqPercentage("");
          props.setLoader({ status: false });
          EventBus.publish("success", `Presale Registered Successfully`);
        });
    } catch (e) {
      props.setLoader({
        message: "Presale Registration Failed!",
        status: false,
      });
      EventBus.publish("error", `Unable To Register Presale!`);
    }
  }

  return (
    <>
      <div className="microeconomy-wrapper">
        <div className="microeconomy-head">
          <a href="#" className="logo-wrap">
            <img src={offeringiconnew} alt="" />
            <span>Initial Liquidity Offering</span>
          </a>
          {props.isLoader.status == true &&
            <Prompt when={model}
              message="Transaction in progress, leaving page may result in transaction failure!"
              beforeUnload={true}
            />
          }
          {(pEnd !== "" || pOwner !== "" || pLock !== "" || pToken !== "" || amount !== "" || tPrice !== "" ||
            pStart !== "" || tListRate !== "" || pSoftcap !== "" || userHardcap !== "" || pLiqPercentage !== "") &&
            <Prompt when={model}
              message="Leaving page will result in losing details entered!"
              beforeUnload={true}
            />
          }
          <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
          <ConnectWallet />
        </div>

        <div className="initial-offer-wrap">
          <div className="form-wrap">
            <div className="inner">
              <h2>Create Your Presale
              </h2>

              <form>
                <div className="form-inner">
                  <div className="form-group">
                    {
                      pOwner !== "" ?
                        <input
                          type="text"
                          name="pOwner"
                          value={pOwner}
                          placeholder={pOwner}
                          maxlength="43"
                          onInput={handleInput}
                        />
                        :
                        <input
                          type="text"
                          name="pOwner"
                          value={pOwner}
                          placeholder="Presale Owner"
                          maxlength="43"
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Enter Your Wallet Address Here</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    {
                      amount !== "" ?
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder={amount}
                          name="amount"
                          value={amount}
                          onInput={handleInput}
                        />
                        :
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder="Amount"
                          name="amount"
                          value={amount}
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Enter Your Presale Token Deposit Here</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    {
                      tPrice !== "" ?
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder={tPrice}
                          name="tPrice"
                          value={tPrice}
                          onInput={handleInput}
                        />
                        :
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder="Token presale price"
                          name="tPrice"
                          value={tPrice}
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Enter Your Token Presale Price Here, Enter amount of {tSymbol} to bought from 1 {bSymbol}</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    {
                      pLock !== "" ?
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder={pLock}
                          name="pLock"
                          value={pLock}
                          onInput={handleInput}
                        />
                        :
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder="Lock Period"
                          name="pLock"
                          value={pLock}
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Enter Your LP Tokens Lock Period In Days</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group date-wrap">
                    {
                      pStart !== "" ?
                        <input
                          type="datetime-local"
                          placeholder=""
                          name="pStart"
                          value={pStart}
                          onInput={handleInput}
                        />
                        :
                        <input
                          type="datetime-local"
                          placeholder=""
                          name="pStart"
                          value={pStart}
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Select Your Presale Start Date</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group date-wrap">
                    {
                      pEnd !== "" ?
                        <input
                          type="datetime-local"
                          placeholder="End date"
                          name="pEnd"
                          value={pEnd}
                          onInput={handleInput}
                        />
                        :
                        <input
                          type="datetime-local"
                          placeholder="End date"
                          name="pEnd"
                          value={pEnd}
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Select Your Presale End Date</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-inner">
                  <div className="form-group">
                    {
                      pToken !== "" ?
                        <input
                          type="text"
                          placeholder={pToken}
                          name="pToken"
                          value={pToken}
                          maxlength="43"
                          onInput={handleInput}
                        />
                        :
                        <input
                          type="text"
                          placeholder="Presale token"
                          name="pToken"
                          defaultValue={pToken}
                          maxlength="43"
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Enter Your Presale Token Address Here</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    {
                      pSoftcap !== "" ?
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder={pSoftcap}
                          name="pSoftcap"
                          value={pSoftcap}
                          onInput={handleInput}
                        />
                        :
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder="Soft cap"
                          name="pSoftcap"
                          value={pSoftcap}
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Enter Your Presale Softcap Here, Min {bSymbol} Collected</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    {
                      tListRate !== "" ?
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder={tListRate}
                          name="tListRate"
                          value={tListRate}
                          onInput={handleInput}
                        />
                        :
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder="Token public sale price"
                          name="tListRate"
                          value={tListRate}
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Enter Your DEX Listing Rate Here, Enter amount of {tSymbol} to bought from 1 {bSymbol}</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    {
                      userHardcap !== "" ?
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder={userHardcap}
                          name="userHardcap"
                          value={userHardcap}
                          onInput={handleInput}
                        />
                        :
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder="User hard cap"
                          name="userHardcap"
                          value={userHardcap}
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Enter Your Buyers Hardcap Here, Max Tokens Each Buyer can Buy</p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    {
                      pLiqPercentage !== "" ?
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder={pLiqPercentage}
                          name="pLiqPercentage"
                          value={pLiqPercentage}
                          onInput={handleInput}
                        />
                        :
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          placeholder="Liquidity percent"
                          name="pLiqPercentage"
                          value={pLiqPercentage}
                          onInput={handleInput}
                        />
                    }

                    <div className="info-wrap name-notice-btn">
                      <img src={infocircle} alt="" />

                      <div className="info">

                        <p>Enter Percentage of {bSymbol} That Will Be Invested In DEX Pool</p>
                      </div>
                    </div>
                  </div>
                </div>

                <button onClick={(event) => {
                  event.preventDefault();
                  submitPresale();
                }}>
                  Launch Presale
                </button>
              </form>

              <div className="ilo-invest">
                <h2>Invest in ILO</h2>

                <div className="tabs-wrapper-ilo">
                  <ul className="nav nav-tabs" id="tab1" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="upcoming-presale-tab" data-toggle="tab" href="#upcoming-presale" role="tab" aria-controls="upcoming-presale" aria-selected="true">Upcoming presale</a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" id="live-presale-tab" data-toggle="tab" href="#live-presale" role="tab" aria-controls="live-presale" aria-selected="false">Live presale</a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" id="past-presale-tab" data-toggle="tab" href="#past-presale" role="tab" aria-controls="past-presale" aria-selected="false">Past presale</a>
                    </li>
                  </ul>

                  <div className="tab-content" id="tab1Content">
                    <div className="tab-pane fade show active" id="upcoming-presale" role="tabpanel" aria-labelledby="upcoming-presale-tab">
                      <div className="token-list">
                        <ul>
                          {
                            presaleData?.map((value) => (
                              <>
                                {currentTime < value.sTime && (
                                  <>
                                    <li onClick={() => toggleModal(value)}>
                                      <div className="left">
                                        {/* <img src={collectionprofile} alt="" /> */}

                                        <div clasName="info">
                                          <h3>{value.symbol}</h3>

                                          <p>{value.tokenName}</p>
                                        </div>
                                      </div>

                                      <div className="right">
                                        <span>
                                          Starting on:
                                        </span>

                                        <p>{moment.utc(value.sTime * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                                      </div>
                                    </li>
                                  </>
                                )}
                              </>
                            ))
                          }
                        </ul >
                      </div>
                    </div>

                    <div className="tab-pane fade" id="live-presale" role="tabpanel" aria-labelledby="live-presale-tab">
                      <div className="token-list">
                        <ul>
                          {
                            presaleData?.map((value) => (
                              <>
                                {currentTime > value.sTime && currentTime < value.eTime && (
                                  <>
                                    <li onClick={() => toggleModal(value)}>
                                      <div className="left">
                                        {/* <img src={collectionprofile} alt="" /> */}

                                        <div className="info">
                                          <h3>{value.symbol}</h3>

                                          <p>{value.tokenName}</p>
                                        </div>
                                      </div>

                                      <div className="right">
                                        <span>
                                          Ending on:
                                        </span>

                                        <p>{moment.utc(value.eTime * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                                      </div>
                                    </li>
                                  </>
                                )}
                              </>
                            ))
                          }
                        </ul >
                      </div>
                    </div>

                    <div className="tab-pane fade" id="past-presale" role="tabpanel" aria-labelledby="past-presale-tab">
                      <div className="token-list">
                        <ul>
                          {
                            presaleData?.map((value) => (
                              <>
                                {currentTime > value.eTime && (
                                  <>
                                    <li onClick={() => toggleModal(value)}>
                                      <div className="left">
                                        {/* <img src={collectionprofile} alt="" /> */}

                                        <div className="info">
                                          <h3>{value.symbol}</h3>

                                          <p>{value.tokenName}</p>
                                        </div>
                                      </div>

                                      <div className="right">
                                        <span>
                                          Ended on:
                                        </span>

                                        <p>{moment.utc(value.eTime * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                                      </div>
                                    </li>
                                  </>
                                )}
                              </>
                            ))
                          }
                        </ul >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={ILOModal}
        onClose={() => setILOModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal ilo-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setILOModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={offeringiconnewgradient} alt="" />

          <h3>Launchpad</h3>

          <p className="mb-5">Run a decentralised Initial Liquidity Offering (ILO) to raise funds and liquidity for your project with our trusted decentalised launchpad.</p>

          <p>Is your project stellar!? Apply to be incubated by circularity finance by sending us a mail at:</p>
          <p>info@circularity.finance</p>

          <p className="mb-6">If you would like to be incubated do not create a presale yet, we'll help with marketing, KYC, Audits, Hardcaps and presale parameters.</p>

          <h4 className="mb-6">Presale best practices</h4>

          <p>The best presales raise less. Raise so there is room for your market cap to grow.</p>

          <p>Use token vesting to lock as many of your team tokens as you can to increase trust in your project and your tokenomics score in the presale.</p>

          <p>Use token vesting to send tokens to marketers if you need to give tokens to anyone before a presale concludes. This ensures no one can create a pair on an exchange with liquidity before a presale concludes and set the initial price (this will cause the presale to fail).</p>

          <p>Build trust by applying for Audits and KYC . Alternatively use our ENMT safe-token minter to mint a pre-audited token.</p>
        </div>
      </Modal>

      <Modal
        open={investListdetailModal}
        onClose={() => closeModal()}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal invest-list-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => closeModal()}
          />
        </button>

        <div className="modal-body">
          <div className="ilo-detail-tabs-wrapper">
            <ul className="nav nav-tabs" id="tab1" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">Info</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" id="actions-tab" data-toggle="tab" href="#actions" role="tab" aria-controls="actions" aria-selected="false">Actions</a>
              </li>
            </ul>

            <div className="tab-content" id="tab1Content">
              {tabData &&
                <>
                  <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                    <ul>
                      <li>
                        <h4>Token address :</h4>

                        <p className="token-address">
                          <a>
                            {`${tabData.tAddress.substring(0, 6) +
                              "....." +
                              tabData.tAddress.substring(
                                34,
                                tabData.tAddress.length
                              )
                              }`}
                          </a>
                          <img src={copy} alt="copy-btn" className="copy" onClick={() => handleCopyText()} />
                        </p>
                      </li>

                      <li>
                        <h4>Presale start time :{" "}</h4>
                        <p>Approx:{" "}{moment.utc(tabData?.sTime * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}{" "}</p>
                      </li>

                      <li>
                        <h4>Presale end time :{" "}</h4>
                        <p>Approx:{" "}{moment.utc(tabData?.eTime * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}{" "}</p>
                      </li>

                      <li>
                        <h4>{tabData?.lper}% raised liquidity lock :{" "}</h4>

                        <p>{tabData?.lTime} days lock duration</p>
                      </li>

                      <li>
                        <h4>Softcap :{" "}</h4>

                        <p>{tabData?.sCap} {bSymbol}</p>
                      </li>

                      <li>
                        <h4>Hardcap :{" "}</h4>

                        <p>{tabData?.hCap} {bSymbol}</p>
                      </li>

                      <li>
                        <h4>Presale Tokens :{" "}</h4>

                        <p>{tabData?.pSupply} {tabData.symbol}</p>
                      </li>

                      <li>
                        <h4>Max tokens per account :{" "}</h4>

                        <p>{tabData?.uCap} {tabData.symbol}</p>
                      </li>

                      <li>
                        {networkId == 80001 || networkId == 137 ? (
                          <h4>
                            MATIC Collected : {" "}
                          </h4>
                        ) : networkId == 97 || networkId == 56 ? (
                          <h4>
                            BNB Collected : {" "}
                          </h4>
                        ) : networkId == 51 || networkId == 50 ? (
                          <h4>
                            XDC Collected : {" "}
                          </h4>
                        ) : (<></>)}

                        <p>{tabData?.bCollected}</p>
                      </li>

                      <li>
                        <h4>{tabData?.tokenName} Sold :{" "}</h4>

                        <p>{tabData?.pSold} {tabData.symbol}</p>
                      </li>

                      <li>
                        <h4>Presale Price :{" "}</h4>
                        {networkId == 80001 || networkId == 137 ? (
                          <p>
                            {tabData?.pPrice} {tabData?.symbol} / per MATIC
                          </p>
                        ) : networkId == 97 || networkId == 56 ? (
                          <p>
                            {tabData?.pPrice} {tabData?.symbol} / per BNB
                          </p>
                        ) : networkId == 51 || networkId == 50 ? (
                          <p>
                            {tabData?.pPrice} {tabData?.symbol} / per XDC
                          </p>
                        ) : (<></>)}
                      </li>

                      <li>
                        <h4>DEX Price :{" "}</h4>
                        {networkId == 80001 || networkId == 137 ? (
                          <p>
                            {tabData?.lPrice} {tabData?.symbol} / per MATIC
                          </p>
                        ) : networkId == 97 || networkId == 56 ? (
                          <p>
                            {tabData?.lPrice} {tabData?.symbol} / per BNB
                          </p>
                        ) : networkId == 51 || networkId == 50 ? (
                          <p>
                            {tabData?.lPrice} {tabData?.symbol} / per XDC
                          </p>
                        ) : (<></>)}
                      </li>
                    </ul>
                  </div>
                  <div className="tab-pane fade" id="actions" role="tabpanel" aria-labelledby="actions-tab">
                    <div className="action-content">
                      {currentTime > tabData.sTime && currentTime < tabData.eTime && (parseFloat(tabData.pSold) / parseFloat(tabData.pPrice) < tabData.hCap) ? (
                        <div className="form-wrap">
                          <form>
                            <div className="presale-status">
                              <h2>Invest In Presale:{" "}</h2>
                              {
                                amountInvest !== "" ?
                                  <input
                                    onWheel={handleOnWheel}
                                    type="number"
                                    placeholder={amountInvest}
                                    name="amountInvest"
                                    value={amountInvest}
                                    onInput={handleOnInput}
                                  />
                                  :
                                  <input
                                    onWheel={handleOnWheel}
                                    type="number"
                                    placeholder={`Investment Amount in ${bSymbol}`}
                                    name="amountInvest"
                                    value={amountInvest}
                                    onInput={handleOnInput}
                                  />
                              }
                              <br />
                              <br />
                              <p>You will receive {(parseFloat(amountInvest) > 0) ? (parseFloat(amountInvest) * parseFloat(tabData.pPrice)) : 0} {tabData.symbol ? tabData.symbol : ""} Tokens</p>
                              <br />
                              <button onClick={(event) => {
                                event.preventDefault();
                                submitInvestment(tabData.pAddress);
                              }}>
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                      {currentTime > tabData.sTime && currentTime < tabData.eTime && (parseFloat(tabData.pSold) / parseFloat(tabData.pPrice) >= tabData.hCap) ? (
                        <div className="form-wrap">
                          <form>
                            <div className="presale-status">
                              <h2>Presale Concluded Successfully!</h2>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                      {tabData.owner == publicAddress && currentTime < tabData.sTime ? (
                        <div className="form-wrap">
                          <form>
                            <div className="presale-status">
                              <h2>Update Presale Time:{" "}</h2>
                              {
                                startTime !== "" ?
                                  <input
                                    type="datetime-local"
                                    placeholder="Start Time"
                                    name="startTime"
                                    value={startTime}
                                    onInput={handleOnInput}
                                  />
                                  :
                                  <input
                                    type="datetime-local"
                                    placeholder="Start Time"
                                    name="startTime"
                                    value={startTime}
                                    onInput={handleOnInput}
                                  />
                              }
                              <br />
                              <br />
                              {
                                endTime !== "" ?
                                  <input
                                    type="datetime-local"
                                    placeholder="End Time"
                                    name="endTime"
                                    value={endTime}
                                    onInput={handleOnInput}
                                  />
                                  :
                                  <input
                                    type="datetime-local"
                                    placeholder="End Time"
                                    name="endTime"
                                    value={endTime}
                                    onInput={handleOnInput}
                                  />
                              }
                              <br />
                              <br />
                              <button onClick={(event) => {
                                event.preventDefault();
                                updateTime(tabData);
                              }}>
                                Submit
                              </button>
                            </div>
                            <br />
                            <br />
                            <div className="presale-status">
                              <h2>Update User Hardcap:{" "}</h2>
                              {
                                userHardcap !== "" ?
                                  <input
                                    type="text"
                                    placeholder="User Hardcap"
                                    name="userHardcap"
                                    value={userHardcap}
                                    onInput={handleOnInput}
                                  />
                                  :
                                  <input
                                    type="text"
                                    placeholder={userHardcap}
                                    name="userHardcap"
                                    value={userHardcap}
                                    onInput={handleOnInput}
                                  />
                              }
                              <br />
                              <br />
                              <button onClick={(event) => {
                                event.preventDefault();
                                updateHardcap(tabData.pAddress);
                              }}>
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                      {currentTime > tabData.eTime ? (
                        <div className="form-wrap">
                          <form>
                            <div className="presale-status">
                              <h2>Presale Status :</h2>
                              <button onClick={(event) => {
                                event.preventDefault();
                                statusCall(tabData.pAddress);
                              }}>
                                Submit
                              </button>
                            </div>

                            <div className="status">
                              <h2>Status :{" "}{presaleStatus}</h2>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                      {tabData.owner == publicAddress && presaleStatus == "Failed" && currentTime > tabData.eTime ? (
                        <div className="form-wrap">
                          <form>
                            <div className="presale-status">
                              <h2>Withdraw presale tokens:{" "}</h2>
                              <button onClick={(event) => {
                                event.preventDefault();
                                withdrawOwnerTokens(tabData.pAddress);
                              }}>
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                      {presaleStatus == "Failed" && currentTime > tabData.eTime && parseInt(tabData.buyerStatus[0]) > 0 ? (
                        <div className="form-wrap">
                          <form>
                            <div className="presale-status">
                              <h2>Withdraw user investment:{" "}</h2>
                              <button onClick={(event) => {
                                event.preventDefault();
                                userWithdraw(tabData.pAddress);
                              }}>
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                      {tabData.lAdded == true && presaleStatus == "Success" && currentTime > tabData.eTime && parseInt(tabData.buyerStatus[1]) > 0 ? (
                        <div className="form-wrap">
                          <form>
                            <div className="presale-status">
                              <h2>Withdraw user tokens:{" "}</h2>
                              <button onClick={(event) => {
                                event.preventDefault();
                                userGetTokens(tabData.pAddress);
                              }}>
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                      {tabData.lAdded == false && presaleStatus == "Success" && currentTime > tabData.eTime && parseInt(tabData.buyerStatus[1]) > 0 ? (
                        <div className="form-wrap">
                          <form>
                            <div className="presale-status">
                              <h2>Withdraw user tokens:{" "}</h2>
                              <button onClick={(event) => {
                                event.preventDefault();
                                EventBus.publish("info", "Cannot withdraw tokens before owner has added liquidity in DEX");
                              }}>
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                      {tabData.owner == publicAddress && tabData.lAdded == false && presaleStatus == "Success" && currentTime > tabData.eTime ? (
                        <div className="form-wrap">
                          <form>
                            <div className="presale-status">
                              <h2>Add liquidity:{" "}</h2>
                              <button onClick={(event) => {
                                event.preventDefault();
                                addLiquidity(tabData.pAddress);
                              }}>
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapDispatchToProps = {
  getILO,
  setLoader,
  deployILO,
  updateILOTime,
  setBalance,
  getVesting,
  setGrootBalance,
  getKybStatus,
  getKycStatus
};

const mapStateToProps = ({ Auth }) => {
  let { address, publicAddress, currentMode, isLoader, vestinglist, ilolist, kycStatus, kybStatus } = Auth;
  return { address, publicAddress, currentMode, isLoader, vestinglist, ilolist, kycStatus, kybStatus };
};
export default connect(mapStateToProps, mapDispatchToProps)(Initailoffering);