import { init } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import infinityWalletModule from '@web3-onboard/infinity-wallet'
import sequenceModule from '@web3-onboard/sequence'
// import keepkeyModule from '@web3-onboard/keepkey'
import safeModule from '@web3-onboard/gnosis'
// import keystoneModule from '@web3-onboard/keystone'
// import ledgerModule from '@web3-onboard/ledger'
// import walletConnectModule from '@web3-onboard/walletconnect'
import coinbaseModule from '@web3-onboard/coinbase'
import dcentModule from '@web3-onboard/dcent'
import tahoModule from '@web3-onboard/taho'
import trustModule from '@web3-onboard/trust'
import frontierModule from '@web3-onboard/frontier'

const injected = injectedModule()
// const keepkey = keepkeyModule()
const infinityWallet = infinityWalletModule()
const sequence = sequenceModule()
const coinbase = coinbaseModule()
const dcent = dcentModule()
// const walletConnect = walletConnectModule()

// const ledger = ledgerModule()
const safe = safeModule()
const taho = tahoModule() // Previously named Tally Ho wallet
const trust = trustModule()
const frontier = frontierModule()

const wallets = [
  // infinityWallet,
  // sequence,
  injected,
  trust,
  frontier,
  // taho,
//   ledger,
  coinbase,
  // dcent,
//   walletConnect,
  safe, 
]

const chains = [
    // {
    //   id: '0x33',
    //   token: 'TXDC',
    //   label: 'XDC Testnet',
    //   rpcUrl: `https://erpc.apothem.network`
    // }, 
    {
      id: '0x32',
      token: 'XDC',
      label: 'XDC Mainnet',
      rpcUrl: `https://earpc.xinfin.network`
    }, 
    // {
    //   id: '0x32',
    //   token: 'XDC',
    //   label: 'XDC Mainnet',
    //   rpcUrl: [`https://erpc.xinfin.network`,`https://earpc.xinfin.network`]
    // }
]
  
  const appMetadata = {
    name: 'CIFI',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
    <path d="M36.045 28.2037H34.3112C33.9939 28.2037 33.6001 28.4212 33.4179 28.6739C30.303 33.0582 25.0254 35.797 19.1541 35.3268C11.8371 34.7332 5.19009 28.0509 4.63764 20.7339C3.93238 11.4245 11.3082 3.6256 20.4647 3.6256C25.807 3.6256 30.5381 6.27619 33.4179 10.3314C33.6001 10.59 33.9997 10.8074 34.3112 10.8074H36.0567C37.1322 10.8074 37.5554 10.0552 36.9853 9.14422C33.318 3.31999 26.6592 -0.435487 19.1835 0.0405599C9.56854 0.657658 1.76373 8.36256 1.02321 17.9658C0.141641 29.4027 9.19828 39 20.4588 39C27.4056 39 33.5178 35.3503 36.9736 29.867C37.432 29.1441 36.8913 28.1978 36.045 28.1978V28.2037Z" fill="url(#paint0_linear_84_552)"/>
    <path d="M38.5548 19.5056C38.5548 20.8339 37.4793 21.9094 36.157 21.9153H36.1452C34.8111 21.9153 33.7356 20.8339 33.7356 19.5056C33.7356 18.1774 34.8111 17.0901 36.1452 17.0901H36.157C37.4793 17.096 38.5548 18.1715 38.5548 19.5056Z" fill="url(#paint1_linear_84_552)"/>
    <defs>
      <linearGradient id="paint0_linear_84_552" x1="-2.23859" y1="12.0358" x2="40.4293" y2="26.0703" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3BE3B6"/>
        <stop offset="1" stop-color="#6EB3D6"/>
      </linearGradient>
      <linearGradient id="paint1_linear_84_552" x1="-0.710218" y1="7.38114" x2="41.9636" y2="21.4157" gradientUnits="userSpaceOnUse">
        <stop offset="0.713542" stop-color="#3BE3B6"/>
        <stop offset="1" stop-color="#6EB3D6"/>
      </linearGradient>
    </defs>
  </svg>`,
    description: 'Circularity Finance.',
    recommendedInjectedWallets: [
      { name: 'MetaMask', url: 'https://metamask.io' },
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
    ]
  }

  // initialize and export Onboard
  export default init({
    wallets,
    chains,
    appMetadata
  })
  