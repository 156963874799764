import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { useEffect } from "react";
import { useConnectWallet } from '@web3-onboard/react'
import 'react-toastify/dist/ReactToastify.css';
import { web3, setProvider } from './store/web3';
import Zendesk, { ZendeskAPI } from "./ZendeskConfig";
import { ToastContainer, toast } from 'react-toastify';
import { logout, setAddress, selectNetwork, setBalance, setGrootBalance } from './store/actions/Auth';

// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes,
  RouterProvider,
} from "react-router-dom";

import ProjectDetail from "./components/landing/ProjectDetail";
import Dashboard from "./components/dashboard/index";
import {networkId} from "./store/config"

function App(props) {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

    useEffect(()=>{
        localStorage.removeItem("address");
        setProvider("");
        calllogout();

        EventBus.on('info', (e) => toast.info(e));
        EventBus.on('error', (e) => toast.error(e));
        EventBus.on('success', (e) => toast.success(e));
        EventBus.on("tokenExpired", () => props.logout());
        
    },[]);

    async function calllogout() {
        props.setBalance(0);
        props.setGrootBalance(0);
        props.setAddress("");
        props.logout();
        if(wallet && wallet.label){
            disconnect({ label: wallet.label });
            window.location.reload();
        }
    }
    const reloadPage = async() => {
      localStorage.removeItem("address");
      // addCustomNetwork();
      logout();
  };

  const eventBus = async () => {
   
  }

  useEffect(()=>{  
    
    if (web3 && window.ethereum) {
      // addCustomNetwork();
      window.ethereum.on('networkChanged', reloadPage);
      web3.eth.net.getId(async (err, netId)=>{
        if(netId !== networkId){
          reloadPage()
        }
      })
    }
})

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/project-detail/:token" element={<ProjectDetail />} />
            <Route path="dashboard/*" element={<Dashboard />}>
            </Route>
      </>
    )
  );

  const handleLoaded = () => {
    ZendeskAPI("messenger", "open");
  };

  return (
    <div>
      <ToastContainer />
      <Zendesk defer zendeskKey={"ecd6bada-48e8-45e4-b39b-a1d643467d44"} onLoaded={handleLoaded} />
      <RouterProvider router={router} />
      {/* <Router>
        <div>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="dashboard/*" element={<Dashboard />}>
            </Route>
          </Routes>
        </div>
      </Router> */}
    </div>
  );
}

const mapDispatchToProps = { setAddress, selectNetwork, setBalance, setGrootBalance, logout};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);