import Papa from "papaparse";
import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import { validate } from "wallet-address-validator";
import tokenairdrop from "../../images/airdrop.png";
import modalcloseicon from "../../images/close.png";
import uploadicon from "../../images/uploadicon.png";
import infocircle from "../../images/infocircle.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import microeconomylogo from "../../images/microeconomy-icon.png";
import deploytokenstepinfo from "../../images/deploy-token-step-info.png";
import uploadfilestepinfo from "../../images/upload-file-step-info.png";
import { setLoader, setBalance, setGrootBalance, getICO } from "../../store/actions/Auth";
import { ICOXRC20ABI, Groot, CIFI, GrootSubscription, GrootSubscriptionAddress, TokenAirDropContract, TokenAirDropAddress, XRC20ABI } from "../../store/config";
import { useNavigate } from "react-router-dom";

const TokenAirdrop = (props) => {

  const [txhash, settxhash] = useState("");
  const [subPrice, setSubPrice] = useState(50);
  const [addressFile, setaddressFile] = useState("");
  const [tokenAddress, settokenAddress] = useState("");
  const [addressArray, setaddressArray] = useState([]);
  const [amountArray, setamountArray] = useState([]);
  const [newaddressArray, setnewaddressArray] = useState([]);
  const [newamountArray, setnewamountArray] = useState([]);

  const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  const [stepModal1, setStepModal1] = useState(true);
  const [stepModal2, setStepModal2] = useState(false);

  const [contractAirdrop, setContractAirdrop] = useState(false);
  const [walletAirdrop, setWalletAirdrop] = useState(false);


  const [model, setModel] = useState(true);

  const navigate = useNavigate()



  setTimeout(() => {
    const modalRoot = document.querySelector('.react-responsive-modal-root');
    if (modalRoot) {
      //Add a custom class to the root element
      const stepsModal = modalRoot.querySelector('.steps-modal');

      if (stepsModal) {
        // Perform your action here
        modalRoot.classList.add('custom-modal-root');
      }

    } else {
      console.error("Element not found.");
    }
  }, 1000);

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    setaddressFile("");
    if ([e.target.name] == "tokenAddress") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        settokenAddress(result);

        waitFor(1500);
        props.getICO({ tokenAddress: result });
      } else {
        settokenAddress(e.target.value);

        waitFor(1500);
        props.getICO({ tokenAddress: e.target.value });
      }
    } else if ([e.target.name] == "addressFile") {
      if (e.target.files[0] == undefined) {
        setaddressArray([]);
        setamountArray([]);
        setaddressFile("");
      } else {
        Papa.parse(e.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            let valuesArray = [];
            let amountsArray = [];

            // Iterating data to get column name and their values
            results.data.map((item) => {
              if (item['Address'] !== "") {
                valuesArray.push(item['Address']);
              }
              if (item['Amount'] !== "") {
                amountsArray.push(item['Amount']);
              }
            });

            // Parsed Data Response in array format
            setaddressArray(valuesArray);
            setamountArray(amountsArray);
          },
        });
        setaddressFile(e.target.files[0]);
      }
    }
  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      refreshBalance()
    }
  }, [props.publicAddress, web3])

  async function refreshBalance() {
    let req = await GrootSubscription.methods.airdropSubscriptionPrice().call();
    let req1 = web3.utils.fromWei(req.toString(), 'ether');
    setSubPrice(parseFloat(req1));
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  function checkForDuplicates(array) {
    // Sort the array
    array.sort();

    // Iterate through the sorted array
    for (let i = 0; i < array.length - 1; i++) {
      // If adjacent elements are equal, return true (duplicate found)
      if (array[i].toLowerCase() === array[i + 1].toLowerCase()) {
        return true;
      }
    }

    // If no duplicates found, return false
    return false;
  }

  async function airdrop(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (props.kybStatus !== "approved") {
        EventBus.publish("error", `Submit KYB verification to access this function!`);
        return;
      }

      if (tokenAddress == "") {
        EventBus.publish("error", `Please enter token address!`);
        return;
      }

      if (!tokenAddress.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token address`);
        return;
      }

      if (!tokenAddress.match(/^[a-zA-Z0-9]+$/)) {
        EventBus.publish("error", `Invalid Token Address`);
        return;
      }

      let code = await web3.eth.getCode(tokenAddress.toLowerCase());
      if (code === "0x") {
        EventBus.publish("error", `XRC20 Token Address is not a contract!`);
        return;
      }

      if (!validate(tokenAddress, "ETH")) {
        EventBus.publish("error", `Please provide valid token address`);
        return;
      }

      await props.getICO({ tokenAddress });

      if (addressFile == "") {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (addressArray == "") {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (addressArray == undefined) {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (addressArray.length > 100) {
        EventBus.publish("error", `Cannot airdrop to more than 100 address!`);
        return;
      }

      if (amountArray == "") {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (amountArray == undefined) {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (amountArray.length > 100) {
        EventBus.publish("error", `Cannot airdrop to more than 100 address!`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let from = publicAddress;

      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let sub = await GrootSubscription.methods.subscribeAirdrops(from).call();
      if (!sub['status']) {
        props.setLoader({
          message: "Subscription in Progress...",
          status: true,
        });
        let balance = await CIFI.methods.balanceOf(from).call();
        let req = await GrootSubscription.methods.airdropSubscriptionPrice().call();
        let req1 = web3.utils.fromWei(req.toString(), 'ether');
        setSubPrice(parseFloat(req1));
        if (parseInt(balance) < parseInt(req)) {
          EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
          props.setLoader({
            message: "Subscription failed...",
            status: false,
          });
          return;
        } else {
          props.setLoader({
            message: "Subscription in Progress...",
            status: true,
          });
          await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
          await GrootSubscription.methods.airdropSubscription(req).send({ from: deployer });
        }
      }

      let { icoAddress } = props;

      console.log("*** icoAddress", icoAddress);

      if (icoAddress == "" || icoAddress == null || icoAddress == undefined) {
        EventBus.publish("error", `Airdrop contract not deployed for this token!`);
        return;
      }

      let contract = new web3.eth.Contract(ICOXRC20ABI, icoAddress);

      let owner = await contract.methods.owner().call();
      let methods = await contract.methods;

      if (!methods?.['airdropBatch']) {
        EventBus.publish("error", `Airdrop function not found!`);
        return;
      }

      if (owner.toLowerCase() !== from.toLowerCase()) {
        EventBus.publish("error", `You are not owner of contract!`);
        return;
      }

      let newAddress = [];
      addressArray.map(item => {
        let output = item.substring(0, 3); // removes "xdc" and adds "0x" to the beginning

        if (output == "xdc") {
          EventBus.publish("error", `Cannot airdrop for an address with xdc prefix!`);
          return;
        }

        if (!validate(item, "ETH")) {
          EventBus.publish("error", `Invalid address detected in csv file!`);
          return;
        }

        newAddress.push(item);
        newaddressArray.push(item);
      });
      let newAmount = []
      amountArray.map(item => {
        let amount = item;
        if (parseFloat(amount) <= 0) {
          EventBus.publish("error", `Airdrop amount cannot be zero for an address!`);
          return;
        }
        amount = web3.utils.toWei(amount.toString(), "ether");
        newamountArray.push(amount);
        newAmount.push(amount);
      });

      if (checkForDuplicates(newAddress)) {
        EventBus.publish("error", `Dublicate Address Found!`);
        props.setLoader({
          status: false,
        });
        return
      }

      props.setLoader({
        message: "Airdrop in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: icoAddress,
          gas: 5000000,
          data: contract.methods
            .airdropBatch(addressArray, newAmount)
            .encodeABI(),
        })
        .on('transactionHash', hash => {
          console.log(`************** deploy contract hash = ${hash}`);
          settxhash(hash);
        })
        .on('receipt', async receipt => {

          await refreshBalance();

          setaddressFile("");
          settokenAddress("");
          setaddressArray([]);
          setamountArray([]);
          setnewaddressArray([]);
          setnewamountArray([]);
          props.setLoader({ status: false });
          EventBus.publish("success", `XRC20 Token(s) Airdropped Successfully!`);
        });
    } catch (e) {
      console.log(e);
      setaddressFile("");
      setaddressArray([]);
      setamountArray([]);
      setnewaddressArray([]);
      setnewamountArray([]);
      props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Airdrop Failed`);
    }
  };

  async function airdropByWallet(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      // if (props.kybStatus !== "approved") {
      //   EventBus.publish("error", `Submit KYB verification to access this function!`);
      //   return;
      // }

      if (tokenAddress == "") {
        EventBus.publish("error", `Please enter token address!`);
        return;
      }

      if (!tokenAddress.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token address`);
        return;
      }

      if (!tokenAddress.match(/^[a-zA-Z0-9]+$/)) {
        EventBus.publish("error", `Invalid Token Address`);
        return;
      }

      let code = await web3.eth.getCode(tokenAddress.toLowerCase());
      if (code === "0x") {
        EventBus.publish("error", `XRC20 Token Address is not a contract!`);
        return;
      }

      if (!validate(tokenAddress, "ETH")) {
        EventBus.publish("error", `Please provide valid token address`);
        return;
      }

      await props.getICO({ tokenAddress });

      if (addressFile == "") {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (addressArray == "") {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (addressArray == undefined) {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (addressArray.length > 100) {
        EventBus.publish("error", `Cannot airdrop to more than 100 address!`);
        return;
      }

      if (amountArray == "") {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (amountArray == undefined) {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (amountArray.length > 100) {
        EventBus.publish("error", `Cannot airdrop to more than 100 address!`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let from = publicAddress;

      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let sub = await GrootSubscription.methods.subscribeAirdrops(from).call();
      if (!sub['status']) {
        props.setLoader({
          message: "Subscription in Progress...",
          status: true,
        });
        let balance = await CIFI.methods.balanceOf(from).call();
        let req = await GrootSubscription.methods.airdropSubscriptionPrice().call();
        let req1 = web3.utils.fromWei(req.toString(), 'ether');
        setSubPrice(parseFloat(req1));
        if (parseInt(balance) < parseInt(req)) {
          EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
          props.setLoader({
            message: "Subscription failed...",
            status: false,
          });
          return;
        } else {
          props.setLoader({
            message: "Subscription in Progress...",
            status: true,
          });
          await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
          await GrootSubscription.methods.airdropSubscription(req).send({ from: deployer });
        }
      }

      let methods = await TokenAirDropContract.methods;

      if (!methods?.['airdropBatch']) {
        EventBus.publish("error", `Airdrop function not found!`);
        return;
      }

      let newAddress = [];
      
      addressArray.map(item => {
        let output = item.substring(0, 3); // removes "xdc" and adds "0x" to the beginning

        if (output == "xdc") {
          EventBus.publish("error", `Cannot airdrop for an address with xdc prefix!`);
          return;
        }

        if (!validate(item, "ETH")) {
          EventBus.publish("error", `Invalid address detected in csv file!`);
          return;
        }

        newAddress.push(item);
        newaddressArray.push(item);
      });

      if (checkForDuplicates(newAddress)) {
        EventBus.publish("error", `Dublicate Address Found!`);
        props.setLoader({
          status: false,
        });
        return
      }


      let newAmount = []
      let totalAmount = 0;
      amountArray.map(item => {
        let amount = item;
        if (parseFloat(amount) <= 0) {
          EventBus.publish("error", `Airdrop amount cannot be zero for an address!`);
          return;
        }
        totalAmount += parseFloat(amount);
        amount = web3.utils.toWei(amount.toString(), "ether");
        newAmount.push(amount);
        newamountArray.push(amount);
      });


      totalAmount = web3.utils.toWei(totalAmount.toString(), "ether");
      let XRC20Token = new web3.eth.Contract(XRC20ABI, tokenAddress);
      let balance = await XRC20Token.methods.balanceOf(deployer).call();
      let symbol = await XRC20Token.methods.symbol().call();

      if (parseInt(balance) < parseInt(totalAmount)) return EventBus.publish("error", `Insufficient ${symbol} tokens!`);

      props.setLoader({
        message: `Approve ${symbol} Token(s)...`,
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: tokenAddress,
          gas: 5000000,
          data: XRC20Token.methods
            .approve(TokenAirDropAddress, totalAmount)
            .encodeABI(),
        })
        .on('transactionHash', hash => {
          console.log(`************** deploy contract hash = ${hash}`);
          settxhash(hash);
        })
        .on('receipt', async receipt => {

          props.setLoader({
            message: "Airdrop in Progress...",
            status: true,
          });

          await web3.eth
            .sendTransaction({
              from: deployer,
              value: 0,
              to: TokenAirDropAddress,
              gas: 5000000,
              data: TokenAirDropContract.methods
                .airdropBatch(addressArray, newAmount, tokenAddress)
                .encodeABI(),
            })
            .on('transactionHash', hash => {
              console.log(`************** deploy contract hash = ${hash}`);
              settxhash(hash);
            })
            .on('receipt', async receipt => {

              await refreshBalance();

              setaddressFile("");
              settokenAddress("");
              setaddressArray([]);
              setamountArray([]);
              setnewaddressArray([]);
              setnewamountArray([]);
              props.setLoader({ status: false });
              EventBus.publish("success", `${symbol} Token(s) Airdropped Successfully!`);
            });
        });

    } catch (e) {
      console.log(e);
      setaddressFile("");
      setaddressArray([]);
      setamountArray([]);
      setnewaddressArray([]);
      setnewamountArray([]);
      props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Airdrop Failed`);
    }
  };

  async function copiedAddress() {
    EventBus.publish("success", "Transaction Hash Copied");
  }

  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {(addressFile !== "" || tokenAddress !== "") &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />
      </div>

      <div className="form-wrap min">
        <div className="inner">
          <div className="head">
            <h2>Token Airdrop<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

            <span>
              <a className="sample-csv-file" href="https://contractbanner.s3.us-east-2.amazonaws.com/tokentest.csv" download>Sample CSV File</a>
            </span>
          </div>

          <div className="airdrop-tabs">
            <ul className="nav nav-tabs mb-2" id="tab1" role="tablist">
              <li className="nav-item">
                <a onClick={() => setContractAirdrop(true)} className="nav-link active" id="contract-airdrop-tab" data-toggle="tab" href="#contract-airdrop" role="tab" aria-controls="contract-airdrop" aria-selected="true">Contract Airdrop</a>
              </li>
              <li className="nav-item">
                <a onClick={() => setWalletAirdrop(true)} className="nav-link" id="wallet-airdrop-tab" data-toggle="tab" href="#wallet-airdrop" role="tab" aria-controls="wallet-airdrop" aria-selected="false">Wallet Airdrop</a>
              </li>
            </ul>
            <div className="tab-pane fade show active" id="contract-airdrop" role="tabpanel" aria-labelledby="contract-airdrop-tab">
              {/* if you want some content in contract airdrop */}

              <form onSubmit={airdrop}>
                <div className="form-group">
                  <input
                    type="text"
                    name='tokenAddress'
                    value={tokenAddress}
                    onChange={handleOnInput}
                    maxlength="43"
                    placeholder="Token address*"
                  />

                  <div className="info-wrap">
                    <img src={infocircle} alt="" />

                    <div className="info">

                      <p>Enter Your XRC20 Token Smart Contract Address Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group filewrap">
                  {addressFile == "" ?
                    <>
                      <span>Upload CSV file</span>
                      <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload CSV file</div>
                      <input
                        type="file"
                        name='addressFile'
                        placeholder="Upload CSV"
                        accept=".csv"
                        onChange={handleOnInput}
                      />

                      <div className="info-wrap">
                        <img src={infocircle} alt="" />

                        <div className="info">

                          <p>Upload your CSV file containing the addresses you want to airdrop your XRC20 tokens</p>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <span>{addressFile ? (addressFile['name'] && addressFile['name'].substring(0, 10) + '...') : "Upload File*"}</span>
                      <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload File</div>
                      <input
                        type="file"
                        name='addressFile'
                        placeholder={addressFile ? (addressFile['name'] && addressFile['name'].substring(0, 10) + '...') : "Upload File*"}
                        accept=".csv"
                        onChange={handleOnInput}
                      />

                      <div className="info-wrap">
                        <img src={infocircle} alt="" />

                        <div className="info">

                          <p>Upload Your Airdrop Address CSV Here</p>
                        </div>
                      </div>
                    </>
                  }
                </div>

                <button type="submit">
                  Airdrop
                </button>
              </form>
            </div>
            <div className="tab-pane fade" id="wallet-airdrop" role="tabpanel" aria-labelledby="wallet-airdrop-tab">
              {/* if you want some content in wallet airdrop */}

              <form onSubmit={airdropByWallet}>
                <div className="form-group">
                  <input
                    type="text"
                    name='tokenAddress'
                    value={tokenAddress}
                    onChange={handleOnInput}
                    maxlength="43"
                    placeholder="Token address*"
                  />

                  <div className="info-wrap">
                    <img src={infocircle} alt="" />

                    <div className="info">

                      <p>Enter Your XRC20 Token Smart Contract Address Here</p>
                    </div>
                  </div>
                </div>

                <div className="form-group filewrap">
                  {addressFile == "" ?
                    <>
                      <span>Upload CSV file</span>
                      <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload CSV file</div>
                      <input
                        type="file"
                        name='addressFile'
                        placeholder="Upload CSV"
                        accept=".csv"
                        onChange={handleOnInput}
                      />

                      <div className="info-wrap">
                        <img src={infocircle} alt="" />

                        <div className="info">

                          <p>Upload your CSV file containing the addresses you want to airdrop your XRC20 tokens</p>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <span>{addressFile ? (addressFile['name'] && addressFile['name'].substring(0, 10) + '...') : "Upload File*"}</span>
                      <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload File</div>
                      <input
                        type="file"
                        name='addressFile'
                        placeholder={addressFile ? (addressFile['name'] && addressFile['name'].substring(0, 10) + '...') : "Upload File*"}
                        accept=".csv"
                        onChange={handleOnInput}
                      />

                      <div className="info-wrap">
                        <img src={infocircle} alt="" />

                        <div className="info">

                          <p>Upload Your Airdrop Address CSV Here</p>
                        </div>
                      </div>
                    </>
                  }
                </div>

                <button type="submit">
                  Airdrop
                </button>
              </form>
            </div>
          </div>
          {
            txhash !== "" &&
            <p>
              <CopyToClipboard
                text={txhash}
                onCopy={copiedAddress}
              >
                <a>{`Transaction Hash: ${txhash}`}</a>
              </CopyToClipboard>
            </p>
          }

          <p>Note: You will need {subPrice} CIFI Tokens for one time subscription to airdrop your collection.</p>
        </div>
      </div>

      <Modal
        open={microeconomyToolModal}
        onClose={() => setMicroeconomyToolModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyToolModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={tokenairdrop} alt="" />

          <h3>Token Airdrop Tool</h3>

          <p>Engage and grow your community with our flexible and customizable airdrop tool. Set up and execute airdrops effortlessly to incentivize participation and drive community engagement.</p>
        </div>
      </Modal>

      <Modal
        open={stepModal1}
        onClose={() => setStepModal1(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal1(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 1/2</h3>

            <p>Add XRC20 token address or you can Deploy your XRC20 token from <span>Deploy Your Token</span> under <span>Micro economy.</span></p>
          </div>


          <img src={deploytokenstepinfo} alt="" />

          <div className="steps-btns">
            <button disabled>Previous step</button>

            <button onClick={() => { setStepModal2(true); setStepModal1(false) }}>Next step</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal2}
        onClose={() => setStepModal2(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal2(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 2/2</h3>

            <p>Upload CSV file of download sample CSV file.</p>
          </div>


          <img src={uploadfilestepinfo} alt="" />

          <div className="steps-btns">
            <button onClick={() => { setStepModal1(true); setStepModal2(false) }}>Previous step</button>

            <button disabled>Next step</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  setBalance,
  setGrootBalance,
  getICO
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, currentMode, isLoader, kycStatus, kybStatus, icoAddress } = Auth;
  return { publicAddress, currentMode, isLoader, kycStatus, kybStatus, icoAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenAirdrop);