import React from 'react';
import './index.css';
import { render } from 'react-dom';
import { logger } from 'redux-logger';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux';
import { Web3OnboardProvider } from '@web3-onboard/react'

import App from './App';
import rootSaga from './store/sagas';
import rootReducer from './store/reducers';
import web3Onboard from './store/web3-onboard'
const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));

sagaMiddleware.run(rootSaga);
render(
  <Web3OnboardProvider web3Onboard={web3Onboard}>
    <Provider store={store}>
      <App />
    </Provider>
  </Web3OnboardProvider>,
  document.getElementById('root'),
);
if (module.hot) { module.hot.accept(App); }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();