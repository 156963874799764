import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import goarrow from "../../images/go-arrow.png";
import { validate } from "wallet-address-validator";
import modalcloseicon from "../../images/close.png";
import infocircle from "../../images/infocircle.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import stakegeneration from "../../images/stakegeneration.png";
import microeconomylogo from "../../images/microeconomy-icon.png";
import stakelpstepinfo from "../../images/stake-lp-step-info.png";
import copylpstepinfo from "../../images/copy-lp-step-info.png";
import deploytokenstepinfo from "../../images/deploy-token-step-info.png";
import tokengenerationstepinfo from "../../images/token-generation-step-info.png";
import { setLoader, uploadLPStake, setBalance, setGrootBalance, getKybStatus } from "../../store/actions/Auth";
import { networkId, XRC20ABI, GrootRouterAddress, CiFiLPStakeABI, CiFiLPStakeBytecode, GrootPairABI, CIFI, GrootSubscription, GrootSubscriptionAddress } from "../../store/config";
import { useNavigate } from "react-router-dom";


const LPStakeGeneration = (props) => {

    const [apy, setapy] = useState("");
    const [tokenAddress, settokenAddress] = useState("");
    const [xrc20address, setxrc20address] = useState("");
    const [pairAddress, setpairAddress] = useState("");
    const [subPrice, setSubPrice] = useState(50);

    const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
    const [stepModal1, setStepModal1] = useState(true);
    const [stepModal2, setStepModal2] = useState(false);
    const [stepModal3, setStepModal3] = useState(false);
    const [stepModal4, setStepModal4] = useState(false);

    const [model, setModel] = useState(true);
    let [successModal, setSuccessModal] = useState(false);

    const navigate = useNavigate()


    setTimeout(() => {
        const modalRoot = document.querySelector('.react-responsive-modal-root');

        if (modalRoot) {
            //Add a custom class to the root element
            const stepsModal = modalRoot.querySelector('.steps-modal');

            if (stepsModal) {
                // Perform your action here
                modalRoot.classList.add('custom-modal-root');
            }

        } else {
            console.error("Element not found.");
        }
    }, 1000);

    async function handleOnInput(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "xrc20address") {
            let input = e.target.value;
            let output = input.substring(0, 3); // checks first three char of address
            if (output == "xdc") {
                let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
                setxrc20address(result);
            } else {
                setxrc20address(e.target.value);
            }
        } else if ([e.target.name] == "pairAddress") {
            let input = e.target.value;
            let output = input.substring(0, 3); // checks first three char of address
            if (output == "xdc") {
                let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
                setpairAddress(result);
            } else {
                setpairAddress(e.target.value);
            }
        } else if ([e.target.name] == "apy") {
            if (parseInt(e.target.value) > 0) {
                setapy(parseInt(e.target.value));
            } else {
                setapy("");
            }
        }
    }

    useEffect(() => {
        if (web3 && props.publicAddress) refreshBalance()
    }, [web3, props.publicAddress])


    async function refreshBalance() {
        let req = await GrootSubscription.methods.stakeSubscriptionPrice().call();
        let req1 = web3.utils.fromWei(req.toString(), 'ether');
        setSubPrice(parseFloat(req1));
        let address = (await web3.currentProvider.enable())[0];
        props.getKybStatus({ publicAddress: address });
        let balance = await web3.eth.getBalance(address); //Will give value in.
        let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
        tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
        balance = await web3.utils.fromWei(balance.toString(), "ether");
        let precision = 3;
        let power = Math.pow(10, precision);
        balance = parseFloat(balance);
        balance = Math.trunc(balance * power) / power;
        props.setBalance(balance);
        tokenBalance = parseFloat(tokenBalance);
        tokenBalance = Math.trunc(tokenBalance * power) / power;
        props.setGrootBalance(tokenBalance);
    }

    async function deploy(e) {
        try {

            e.preventDefault();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (props.kybStatus !== "approved") {
              EventBus.publish("error", `Submit KYB verification to access this function!`);
              return;
            }

            if (xrc20address == "") {
                EventBus.publish("error", `Please enter XRC20 Token Address`);
                return;
            }

            if (!xrc20address.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter XRC20 Token Address`);
                return;
            }

            if (!xrc20address.match(/^[a-zA-Z0-9]+$/)) {
                EventBus.publish("error", `Invalid XRC20 Token Address`);
                return;
            }

            if (pairAddress == "") {
                EventBus.publish("error", `Please enter XRC721 Token Address`);
                return;
            }

            if (!pairAddress.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter XRC721 Token Address`);
                return;
            }

            if (!pairAddress.match(/^[a-zA-Z0-9]+$/)) {
                EventBus.publish("error", `Invalid XRC Token Address`);
                return;
            }

            let code1 = await web3.eth.getCode(xrc20address.toLowerCase());
            if (code1 === "0x") {
                EventBus.publish("error", `XRC20 Token Address is not a contract!`);
                return;
            }

            let code2 = await web3.eth.getCode(pairAddress.toLowerCase());
            if (code2 === "0x") {
                EventBus.publish("error", `LP Token Address is not a contract!`);
                return;
            }

            if (apy == "") {
                EventBus.publish("error", `Please enter APY value`);
                return;
            }

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            if (!validate(xrc20address, "ETH")) {
                EventBus.publish("error", `Please provide valid XRC20 token address`);
                return;
            }

            if (!validate(pairAddress, "ETH")) {
                EventBus.publish("error", `Please provide valid XRC721 token address`);
                return;
            }

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            let sub = await GrootSubscription.methods.subscribeStakes(from).call();
            if (!sub['status']) {
                props.setLoader({
                    message: "Subscription in Progress...",
                    status: true,
                });
                let balance = await CIFI.methods.balanceOf(from).call();
                let req = await GrootSubscription.methods.stakeSubscriptionPrice().call();
                let req1 = web3.utils.fromWei(req.toString(), 'ether');
                setSubPrice(parseFloat(req1));
                if (parseInt(balance) < parseInt(req)) {
                    EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
                    props.setLoader({ status: false });
                    return;
                } else {
                    props.setLoader({
                        message: "Subscription in Progress...",
                        status: true,
                      });
                    await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
                    await GrootSubscription.methods.stakeSubscription(req).send({ from: deployer });
                }
            }

            props.setLoader({
                message: "Deployment in Progress...",
                status: true,
            });

            let contract1 = new web3.eth.Contract(GrootPairABI, pairAddress);
            let contract2 = new web3.eth.Contract(XRC20ABI, xrc20address);

            let token0Address = await contract1.methods.token0().call();
            let token1Address = await contract1.methods.token1().call();

            // Create contract instances for token0 and token1
            let token0Contract = new web3.eth.Contract(XRC20ABI, token0Address);
            let token1Contract = new web3.eth.Contract(XRC20ABI, token1Address);

            // Fetch the symbols of token0 and token1
            let token0Symbol = await token0Contract.methods.symbol().call();
            let token1Symbol = await token1Contract.methods.symbol().call();

            let pairName = `${token0Symbol}/${token1Symbol} CIFI-LP`;
            let pairSymbol = `${token0Symbol}/${token1Symbol}`;

            let isMinter = await contract2.methods.isMinter(deployer).call();
            if (!isMinter) {
                EventBus.publish("error", `You must be minter of reward token to deploy staking contract!`);
                props.setLoader({ status: false });
                return;
            }
            
            props.setLoader({
                message: "Deployment in Progress...",
                status: true,
            });

            let contract = new web3.eth.Contract(CiFiLPStakeABI);
            let deploy = await contract.deploy({ data: CiFiLPStakeBytecode, arguments: [pairAddress, xrc20address, GrootRouterAddress, apy] });

            await deploy.send({ from: deployer })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {

                    let data = new FormData();
                    data.append('pairAddress', pairAddress);
                    data.append('tokenAddress', xrc20address);
                    data.append('token0Address', token0Address);
                    data.append('token1Address', token1Address);
                    data.append('stakeAPY', apy);
                    data.append('stakeAddress', receipt['contractAddress']);
                    data.append('pairName', pairName);
                    data.append('pairSymbol', pairSymbol);
                    data.append('owner', from);
                    data.append('network', parseInt(networkId));
                    await props.uploadLPStake(data);

                    props.setLoader({ status: false });

                    await refreshBalance();

                    EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
                    console.log(`************** deploy stake address = `, receipt['contractAddress']);
                    settokenAddress(receipt['contractAddress']);
                    waitFor(1500);
                    setSuccessModal(true);
                    setapy("");
                    setxrc20address("");
                    setpairAddress("");
                    props.setLoader({ status: false });
                });
        } catch (e) {
            console.log(e);
            props.setLoader({ status: false });
            EventBus.publish("error", `Unable To Deploy`);
        }
    };

    async function copiedAddress() {
        EventBus.publish("success", "Contract Address Copied");
    }

    return (
        <div className="microeconomy-wrapper">
            <div className="microeconomy-head">
                <a href="#" className="logo-wrap">
                    <img src={microeconomylogo} alt="" />
                    <span>Micro Economy</span>
                </a>
                {props.isLoader.status == true &&
                    <Prompt when={model}
                        message="Transaction in progress, leaving page may result in transaction failure!"
                        beforeUnload={true}
                    />
                }
                {(apy !== "" || xrc20address !== "" || pairAddress !== "") &&
                    <Prompt when={model}
                        message="Leaving page will result in losing details entered!"
                        beforeUnload={true}
                    />
                }
                <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
                <ConnectWallet />
            </div>

            <div className="form-wrap min">
                <div className="inner">
                    <h2>LP Stake Generation<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

                    {
                        tokenAddress !== "" &&
                        <>
                            <div className="copy-wrap">
                                <p>
                                    <CopyToClipboard
                                        text={tokenAddress}
                                        onCopy={copiedAddress}
                                    >
                                        {networkId == 137 ? (
                                            <a href={`https://polygonscan.com/address/k${tokenAddress}`} target="_blank">
                                                {`Staking Contract Address:`}
                                                <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                                            </a>
                                        ) : networkId == 80001 ? (
                                            <a href={`https://mumbai.polygonscan.com/address/${tokenAddress}`} target="_blank">
                                                {`Staking Contract Address:`}
                                                <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                                            </a>
                                        ) : networkId == 56 ? (
                                            <a href={`https://bscscan.com/address/${tokenAddress}`} target="_blank">
                                                {`Staking Contract Address:`}
                                                <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                                            </a>
                                        ) : networkId == 97 ? (
                                            <a href={`https://testnet.bscscan.com/address/${tokenAddress}`} target="_blank">
                                                {`Staking Contract Address:`}
                                                <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                                            </a>
                                        ) : networkId == 50 ? (
                                            <a href={`https://xdc.blocksscan.io/address/${tokenAddress}`} target="_blank">
                                                {`Staking Contract Address:`}
                                                <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                                            </a>
                                        ) : networkId == 51 ? (
                                            <a href={`https://apothem.blocksscan.io/address/${tokenAddress}`} target="_blank">
                                                {`Staking Contract Address:`}
                                                <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                                            </a>
                                        ) : (
                                            <></>
                                        )}
                                    </CopyToClipboard>
                                </p>

                            </div>
                            <p className="mb-4">You can visit (Stake Your LP Tokens) page to view your LP token staking</p>
                        </>
                    }

                    <form onSubmit={deploy}>
                        <div className="form-group">
                            <input
                                type="text"
                                name='xrc20address'
                                value={xrc20address}
                                onChange={handleOnInput}
                                maxlength="43"
                                placeholder="Add Crypto Reward Token Address*"
                            />

                            <div className="info-wrap">
                                <img src={infocircle} alt="" />

                                <div className="info">

                                    <p>This is the address of your Crypto reward token to enable staking of your LP tokens</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                name='pairAddress'
                                value={pairAddress}
                                onChange={handleOnInput}
                                maxlength="43"
                                placeholder="Add LP Token Address*"
                            />

                            <div className="info-wrap">
                                <img src={infocircle} alt="" />

                                <div className="info">

                                    <p>This is the address of your LP token to enable staking of your LP Tokens</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                name='apy'
                                value={apy}
                                onChange={handleOnInput}
                                placeholder="APY Percentage*"
                            />

                            <div className="info-wrap">
                                <img src={infocircle} alt="" />

                                <div className="info">

                                    <p>Enter the annual percentile per year at which rewards will be distributed according to pair price</p>
                                </div>
                            </div>
                        </div>

                        <button type="submit">
                            Deploy Staking
                        </button>
                    </form>

                    <p>Note: You will need {subPrice} CIFI Tokens for one time subscription to add staking to your token pair.</p>
                </div>
            </div>

            <Modal
                open={microeconomyToolModal}
                onClose={() => setMicroeconomyToolModal(false)}
                classNames={{
                    modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal`,
                }}
                center
            >
                <button className="absolute right-4 top-6">
                    <img
                        src={modalcloseicon}
                        onClick={() => setMicroeconomyToolModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <img src={stakegeneration} alt="" />

                    <h3>Stake Generation</h3>

                    <p> Deploy staking smart contracts with ease using our affordable staking smart contract builder. Enable your community to participate in your project's success by staking tokens.</p>
                </div>
            </Modal>

            <Modal
                open={successModal}
                onClose={() => setSuccessModal(false)}
                classNames={{
                    overlay: 'custom-overlay',
                    modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
                }}
                center
            >
                <button className="absolute right-4 top-6">
                    <img
                        src={modalcloseicon}
                        onClick={() => setSuccessModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="steps-head">
                        <p>Visit your <span>Profile</span> under <span>Micro economy</span> to mint tokens in staking contract</p>
                    </div>

                    <img src={tokengenerationstepinfo} alt="" />

                </div>
            </Modal>

            <Modal
                open={stepModal1}
                onClose={() => setStepModal1(false)}
                classNames={{
                    overlay: 'custom-overlay',
                    modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
                }}
                center
            >
                <button className="absolute right-4 top-6">
                    <img
                        src={modalcloseicon}
                        onClick={() => setStepModal1(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="steps-head">
                        <h3>Step 1/3</h3>

                        <p>Add your crypto token address or you can Deploy your crypto token from <span> Deploy your token</span> under <span>Micro economy.</span></p>
                    </div>


                    <img src={deploytokenstepinfo} alt="" />

                    <div className="steps-btns">
                        <button disabled>Previous step</button>

                        <button onClick={() => { setStepModal2(true); setStepModal1(false) }}>Next step</button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={stepModal2}
                onClose={() => setStepModal2(false)}
                classNames={{
                    overlay: 'custom-overlay',
                    modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
                }}
                center
            >
                <button className="absolute right-4 top-6">
                    <img
                        src={modalcloseicon}
                        onClick={() => setStepModal2(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="steps-head">
                        <h3>Step 2/3</h3>

                        <p>Add  LP token address or you can LP token address from <span>View Pools</span> under <span>Circularity Finance.</span></p>
                    </div>


                    <img src={copylpstepinfo} alt="" />

                    <div className="steps-btns">
                        <button onClick={() => { setStepModal1(true); setStepModal2(false) }}>Previous step</button>

                        <button onClick={() => { setStepModal3(true); setStepModal2(false) }}>Next step</button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={stepModal3}
                onClose={() => setStepModal3(false)}
                classNames={{
                    overlay: 'custom-overlay',
                    modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
                }}
                center
            >
                <button className="absolute right-4 top-6">
                    <img
                        src={modalcloseicon}
                        onClick={() => setStepModal3(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="steps-head">
                        <h3>Step 3/3</h3>

                        <p>Enter your APY percentage against pair price.</p>
                    </div>
                    <div className="steps-btns">
                        <button onClick={() => { setStepModal2(true); setStepModal3(false) }}>Previous step</button>

                        <button onClick={() => { setStepModal4(true); setStepModal3(false) }}>Next step</button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={stepModal4}
                onClose={() => setStepModal4(false)}
                classNames={{
                    overlay: 'custom-overlay',
                    modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
                }}
                center
            >
                <button className="absolute right-4 top-6">
                    <img
                        src={modalcloseicon}
                        onClick={() => setStepModal4(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="steps-head">
                        <p>You can view your staking contract on <span>Stake your LP Tokens</span> under <span>Micro economy.</span></p>
                    </div>

                    <img src={stakelpstepinfo} alt="" />
                </div>
            </Modal>
        </div>
    );
}

const mapDispatchToProps = {
    setLoader,
    getKybStatus,
    uploadLPStake,
    setBalance,
    setGrootBalance
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, currentMode, isLoader, kycStatus, kybStatus } = Auth;
    return { publicAddress, currentMode, isLoader, kycStatus, kybStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(LPStakeGeneration);